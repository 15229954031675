import React, {ChangeEvent, Dispatch, MouseEvent, SetStateAction, useRef} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPause, faPlay, faWindowRestore} from '@fortawesome/pro-light-svg-icons';
import {CheckboxForm, SelectForm, TextForm} from 'components/forms';
import {faCircleInfo, faFileLines} from '@fortawesome/pro-thin-svg-icons';
import {IDatasheetLocalDbWidgetSubjectInfoDetail} from 'api/LocalDatabaseProvider';
import {IPythonEditorRunConfig} from 'components/pc/widgets/pythonEditor/types';
import {IPythonEditorOutflowResult} from 'components/pc/widgets/PythonEditorWidget';

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  //justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

const Section = styled.div`
  height: 24px;
  width: max-content;
  display: flex;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #acbcc0;
  &:nth-of-type(5) {
    border: none;
  }
  &:last-of-type {
    flex: 1;
    border: none;
    justify-content: space-between;
  }
  .checkbox-form {
    min-width: 170px;
    label {
      font-size: 17px;
    }
  }
  .interval-value {
    width: 44px;
    height: 40px;
  }
  .interval-unit {
    width: 120px;
    height: 40px;
  }
  .environment {
    width: 140px;
    height: 40px;
  }
`;

const IconContainer_1 = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;

  &.selected {
    background-color: #d5e1f3;
  }

  &:hover {
    background-color: rgba(213, 225, 243, 0.7);
  }
`;

const IconContainer_2 = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  &.selected {
    background-color: #d5e1f3;
  }
  &:hover {
    background-color: rgba(213, 225, 243, 0.7);
  }
`;

const LastSectionLeft = styled.div`
  display: flex;
`;

const LastSectionRight = styled.div`
  display: flex;
`;

const intervalUnitOption = [
  {label: 'second', value: 1000},
  {label: 'minute', value: 60 * 1000},
  {label: 'hour', value: 60 * 60 * 1000}
];

type IProps = {
  isRunState: [boolean, Dispatch<SetStateAction<boolean>>];
  isShowEnvironmentPackageListModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  runConfigState?: [IPythonEditorRunConfig, Dispatch<SetStateAction<IPythonEditorRunConfig>>];
  enableEnvironmentState?: [any[], Dispatch<SetStateAction<any[]>>];
  connectedDatasheetSubjectInfo?: IDatasheetLocalDbWidgetSubjectInfoDetail;
  onChangeRunConfig(key: string, value: unknown): void;
  runCode(): Promise<unknown>;
  stopPythonCodeRunning(): void;
};
function PythonEditorActionPanel({
  isRunState,
  isShowEnvironmentPackageListModalState,
  runConfigState,
  enableEnvironmentState,
  connectedDatasheetSubjectInfo,
  onChangeRunConfig,
  runCode,
  stopPythonCodeRunning
}: IProps) {
  const [runCfg] = runConfigState;
  const [isRun, setIsRun] = isRunState;
  const [environmentList] = enableEnvironmentState;
  const [isShowEnvironmentPackageListModal, setIsShowEnvironmentPackageListModal] =
    isShowEnvironmentPackageListModalState;
  const runCfgRef = useRef(null);
  runCfgRef.current = runCfg;

  const onChangeBooleanConfig = (key: string, value: boolean) => {
    onChangeRunConfig(key, value);
    switch (key) {
      case 'batchRun': {
        setTimeout(() => connectedDatasheetSubjectInfo?.ref?.current?.changeReadOnly?.(value), 0);
        break;
      }
    }
  };

  const onChange = (e: ChangeEvent) => {
    const {name, value} = e.target as HTMLInputElement;
    switch (name) {
      case 'intervalValue': {
        if (!isNaN(Number(value)) && Number(value) > 0) {
          onChangeRunConfig(name, value);
        } else {
          onChangeRunConfig(name, '');
        }
        break;
      }
      case 'environment':
      case 'intervalUnit': {
        onChangeRunConfig(name, value);
        break;
      }
      default:
        break;
    }
  };

  const onClickRun = (e: MouseEvent) => {
    e.stopPropagation();
    setIsRun((prev) => !prev);
    if (isRun) {
      stopPythonCodeRunning();
    }
    runCode();
  };

  return (
    <Container>
      <Section>
        <IconContainer_1 onClick={onClickRun}>
          <FontAwesomeIcon icon={isRun ? faPause : faPlay} fontSize={24} />
        </IconContainer_1>
      </Section>
      <Section>
        <CheckboxForm
          name="autoRun"
          checked={runCfg?.autoRun}
          onChange={() => onChangeBooleanConfig('autoRun', !runCfg?.autoRun)}
          label="Auto - run interval"
          labelPosition="right"
          disabled={true}
        />
      </Section>
      <Section>
        <TextForm
          name="intervalValue"
          className="interval-value"
          onChange={onChange}
          value={runCfg?.intervalValue}
          disabled={true}
        />
        <SelectForm
          name="intervalUnit"
          className="interval-unit"
          onChange={onChange}
          options={intervalUnitOption}
          value={runCfg?.intervalUnit}
          disabled={true}
        />
      </Section>
      <Section>
        <CheckboxForm
          name="batchRun"
          checked={runCfg?.batchRun}
          onChange={() => onChangeBooleanConfig('batchRun', !runCfg?.batchRun)}
          label="Use for batch run"
          labelPosition="right"
          disabled={!Boolean(connectedDatasheetSubjectInfo)}
        />
      </Section>
      <Section>
        <SelectForm
          name="environment"
          className="environment"
          onChange={onChange}
          options={environmentList?.map((item) => ({label: item, value: item}))}
          value={runCfg?.environment}
        />
      </Section>
      <Section>
        <LastSectionLeft>
          <IconContainer_1 onClick={() => setIsShowEnvironmentPackageListModal(true)}>
            <FontAwesomeIcon icon={faCircleInfo} fontSize={24} />
          </IconContainer_1>
        </LastSectionLeft>
        <LastSectionRight>
          <IconContainer_2>
            <FontAwesomeIcon icon={faWindowRestore} fontSize={18} />
          </IconContainer_2>
          <IconContainer_2>
            <FontAwesomeIcon icon={faFileLines} fontSize={18} />
          </IconContainer_2>
        </LastSectionRight>
      </Section>
    </Container>
  );
}

export default PythonEditorActionPanel;
