import {memo, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {NodeProps, NodeResizer} from 'reactflow';
import {WidgetHeader} from 'components/pc/widgets/parts';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {ContactShadows, Environment, Grid, OrbitControls} from '@react-three/drei';
import {ModelShells} from 'components/pc/widgets/three/renderer/block/Model_Shells';
import {Canvas} from '@react-three/fiber';
import {LeftMenu} from 'components/pc/widgets/three/renderer/ui';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import ThreeLandscapeWidgetContainer from 'components/pc/widgets/three/renderer/label/ThreeLandscapeWidgetContainer';
import ThreeCanvasWrapper from 'components/pc/widgets/three/ThreeCanvasWrapper';
import Pipes from 'components/pc/widgets/three/Pipes';
import ThreeLandscapeLabelsWrapper from 'components/pc/widgets/three/renderer/label/ThreeLandscapeLabelsWrapper';
import Blocks from 'components/pc/widgets/three/Blocks';
import {
  canvasConfig,
  controlsConfig,
  gltfModelsPaths,
  gridConfig,
  shadowConfig
} from 'components/pc/widgets/three/constants/config';
import {getApiHost} from 'api/function';
import WhiteBackground from 'components/pc/widgets/three/WhiteBackground';

function ThreeLandscapeWidget({data, id, ...rest}: NodeProps): ReactElement {
  const {isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;
  const [isLoading, setLoading] = useState(true);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [isTransparent, updateTransparent] = useState(true);
  const [isWire, updateWire] = useState(false);
  const [labelsVisible, updateLabels] = useState(false);

  const [blockVisibility, setBlockVisibility] = useState<any>({
    '01': true,
    '02': true,
    '03': true,
    '04': true,
    '05': true,
    '06': true,
    '07': true
  });

  const [streamVisibility, setStreamVisibility] = useState<any>({
    '01': true,
    '02': true,
    '03': true
  });

  const containerRef = useRef(null);
  const controlsRef = useRef();

  useEffect(() => {
    const API_HOST = getApiHost();
    const loader = new GLTFLoader();
    const loadModelPromises = gltfModelsPaths.map((pathForGltf) => {
      return loader
        .loadAsync(API_HOST + pathForGltf)
        .then((response) => {
          response.parser.json.meshes.map((meshGroup) => {
            setLoadingMessage(`Loading ${meshGroup.name}...`);
          });
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    });

    Promise.all(loadModelPromises).then(() => {
      setLoading(false);
    });
  }, []);

  const onToggleBlockVisibility = (buttonName: string) => {
    setBlockVisibility((prevBlockVisibility) => {
      const areAllButtonsPressed = Object.values(prevBlockVisibility).every((value) => value === true);
      const updatedBlockVisibility = {};
      if (areAllButtonsPressed) {
        Object.keys(prevBlockVisibility).forEach((key) => {
          updatedBlockVisibility[key] = key === buttonName;
        });
      } else {
        Object.keys(prevBlockVisibility).forEach((key) => {
          updatedBlockVisibility[key] = key === buttonName ? !prevBlockVisibility[key] : prevBlockVisibility[key];
        });
      }
      return updatedBlockVisibility;
    });
  };

  const onToggleStreamVisibility = (buttonName: string) => {
    setStreamVisibility((prevStreamVisibility) => {
      const areAllButtonsPressed = Object.values(prevStreamVisibility).every((value) => value === true);
      const updatedStreamVisibility = {};
      if (areAllButtonsPressed) {
        Object.keys(prevStreamVisibility).forEach((key) => {
          updatedStreamVisibility[key] = key === buttonName;
        });
      } else {
        Object.keys(prevStreamVisibility).forEach((key) => {
          updatedStreamVisibility[key] = key === buttonName ? !prevStreamVisibility[key] : prevStreamVisibility[key];
        });
      }
      return updatedStreamVisibility;
    });
  };

  return (
    <>
      <ThreeLandscapeWidgetContainer>
        <WidgetHeader
          {...rest}
          type="ThreeLandscapeWidget"
          icon={data.icon}
          title={
            data.customizedTitle ? data.title : getWidgetTitle({type: 'ThreeLandscapeWidget', titleData: '', data})
          }
          suffix="- 3D Landscape"
          id={id}
        />
        <ThreeCanvasWrapper ref={containerRef} isLoading={isLoading} loadingMessage={loadingMessage}>
          <Canvas {...canvasConfig} style={{height: 'calc(100% - 40px)', overflow: 'visible', background: '#ffffff'}}>
            <WhiteBackground />
            <ambientLight intensity={Math.PI} />
            <Environment preset="city" blur={0.5} />
            <OrbitControls ref={controlsRef} domElement={containerRef?.current} {...controlsConfig} />
            <ContactShadows {...shadowConfig} />
            <Grid {...gridConfig} />
            <ModelShells
              transparent={isTransparent}
              wireframes={isWire}
              blockVisibility={blockVisibility}
              controlsRef={controlsRef}
            />
            <ThreeLandscapeLabelsWrapper labelsVisible={labelsVisible} />
            <Blocks blockVisibility={blockVisibility} />
            <Pipes streamVisibility={streamVisibility} />
          </Canvas>
        </ThreeCanvasWrapper>
        <LeftMenu
          blockVisibility={blockVisibility}
          streamVisibility={streamVisibility}
          onToggleBlock={onToggleBlockVisibility}
          onToggleStream={onToggleStreamVisibility}
          isShowWireFrame={isWire}
          isShowLabel={labelsVisible}
          onToggleWireFrame={() => updateWire(!isWire)}
          onToggleShowLabel={() => updateLabels(!labelsVisible)}
        />
        {/*<Footer
          transparent={isTransparent}
          wireframes={isWire}
          labels={labelsVisible}
          updateTransparent={updateTransparent}
          updateWire={updateWire}
          updateLabels={updateLabels}
        />*/}
      </ThreeLandscapeWidgetContainer>
      <NodeResizer color="#ff0071" isVisible={rest.selected && !isFreeze} />
    </>
  );
}
export default memo(ThreeLandscapeWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
