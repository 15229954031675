import React, {memo, RefObject, useContext, useRef} from 'react';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import {IAnnotation, IPfdAction, IPfdCfg} from 'components/mpfd/type';
import MetaPfdDataTable from 'components/mpfd/annotation/MetaPfdDataTable';
import MetaPfdDataChart from 'components/mpfd/annotation/MetaPfdDataChart';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import ResizableFrame from 'components/mpfd/annotation/ResizableFrame';
import classnames from 'classnames';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

const Container = styled.div<ContainerStyleProps>`
  position: absolute;
  z-index: ${META_PFD_zINDEX.TABLE_LAYER};
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const InnerContainer = styled.div`
  position: relative;
`;

type PointObjectNotation = {
  x: number;
  y: number;
};

type ContainerStyleProps = {
  $iw: number;
  $ih: number;
  $imagePosition: {
    topLeft: PointObjectNotation;
    bottomRight: PointObjectNotation;
  };
};

type CommonProps = {
  iw: number;
  ih: number;
  mat: any;
  isViewer: boolean;
  cfg: IPfdCfg;
  latestTagHandler?: ILatestTagHandlerReturn;
  imageDimensions: {
    naturalWidth: number;
    naturalHeight: number;
  };
  dispatchToReducer(action: IPfdAction): void;
};

const renderMetaPfdDataTable = (
  region: IAnnotation,
  idx: number,
  id: string,
  commonProps: CommonProps,
  refObject: RefObject<HTMLDivElement>
) => {
  const tagInfos = region?.metaPfdTagInfo?.filter((item) => item?.isTableDisplay) || [];

  const afterDragEndTable = (left: number, top: number, containerRef: RefObject<HTMLDivElement>) => {
    const element = refObject.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const relativeX = left / rect.width;
      const relativeY = top / rect.height;
      commonProps.dispatchToReducer({
        type: 'CHANGE_REGION',
        region: {
          ...region,
          isTableDragging: false,
          tablePosition: {
            ...region?.tablePosition,
            x: relativeX,
            y: relativeY
          }
        }
      });
    }
  };

  const afterResizeEndTable = (width: number, height: number, containerRef: RefObject<HTMLDivElement>) => {
    const element = refObject.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const relativeW = width / rect.width;
      const relativeH = height / rect.height;
      commonProps.dispatchToReducer({
        type: 'CHANGE_REGION',
        region: {
          ...region,
          tablePosition: {
            ...region?.tablePosition,
            w: relativeW,
            h: relativeH
          }
        }
      });
    }
  };

  const onDragStartTable = () => {
    commonProps.dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {
        ...region,
        isTableDragging: true
      }
    });
  };

  if (tagInfos.length > 0 && region?.isTableVisible) {
    const headerRow = region?.isShowTableHeader ? 1 : 0;
    const tags = region.metaPfdTagInfo.filter((item) => item.isTableDisplay).map((item) => item.tag);
    const displayInTableRowNumber = Number(tags?.length + headerRow);
    const cellHeight = 32;
    const tableMaxHeight = cellHeight * displayInTableRowNumber;
    const x = region.tablePosition.x || region.x || 0.01;
    const y = region.tablePosition.y || region.y || 0.01;
    const height = Math.min(region.tablePosition.h * commonProps.ih + 1, tableMaxHeight + 1);
    const width = Math.max(10, region.tablePosition.w * commonProps.iw);
    return (
      <ResizableFrame
        width={width * commonProps.mat?.a}
        height={height * commonProps.mat?.a}
        left={x * commonProps.iw}
        top={y * commonProps.ih}
        scale={1 / commonProps.mat?.a}
        disabled={commonProps?.isViewer}
        id={id}
        relativeRefObject={refObject}
        smoothCss={true}
        minHeight={32}
        minWidth={100}
        maxHeight={tableMaxHeight}
        afterDragEnd={afterDragEndTable}
        afterResizeEnd={afterResizeEndTable}
        onDragStart={onDragStartTable}
        key={'table' + id + idx}
      >
        <MetaPfdDataTable region={region} id={`${region?.id}${id}`} {...commonProps} />
      </ResizableFrame>
    );
  }
  return null;
};

const renderMetaPfdDataChart = (
  region: IAnnotation,
  idx: number,
  id: string,
  commonProps: CommonProps,
  refObject: RefObject<HTMLDivElement>
) => {
  const tagInfos = region?.metaPfdTagInfo?.filter((item) => item?.isChartDisplay) || [];

  const afterDragEndChart = (left: number, top: number, containerRef: RefObject<HTMLDivElement>) => {
    const element = refObject.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const relativeX = left / rect.width;
      const relativeY = top / rect.height;
      commonProps.dispatchToReducer({
        type: 'CHANGE_REGION',
        region: {
          ...region,
          isChartDragging: false,
          chartPosition: {
            ...region?.chartPosition,
            x: relativeX,
            y: relativeY
          }
        }
      });
    }
  };

  const afterResizeEndChart = (width: number, height: number, containerRef: RefObject<HTMLDivElement>) => {
    const element = refObject.current;
    if (element) {
      const rect = element.getBoundingClientRect();
      const relativeW = width / rect.width;
      const relativeH = height / rect.height;
      commonProps.dispatchToReducer({
        type: 'CHANGE_REGION',
        region: {
          ...region,
          chartPosition: {
            ...region?.chartPosition,
            w: relativeW,
            h: relativeH
          }
        }
      });
    }
  };

  const onDragStartChart = () => {
    commonProps.dispatchToReducer({
      type: 'CHANGE_REGION',
      region: {
        ...region,
        isChartDragging: true
      }
    });
  };

  if (tagInfos.length > 0 && region?.isNormalChartVisible) {
    const x = region.chartPosition.x || region.x || 0.01;
    const y = region.chartPosition.y || region.y || 0.01;

    const width = Math.max(region.chartPosition.w * commonProps.iw);
    const height = Math.max(region.chartPosition.h * commonProps.ih);
    // const scale = commonProps.isViewer ? 1 : 1 / commonProps.mat?.a;

    return (
      <ResizableFrame
        width={width * commonProps.mat?.a}
        height={height * commonProps.mat?.a}
        left={x * commonProps.iw}
        top={y * commonProps.ih}
        scale={1 / commonProps.mat?.a}
        disabled={commonProps?.isViewer}
        id={id}
        relativeRefObject={refObject}
        smoothCss={false}
        // minHeight={200}
        // minWidth={200}
        afterDragEnd={afterDragEndChart}
        afterResizeEnd={afterResizeEndChart}
        onDragStart={onDragStartChart}
        key={'chart' + id + idx}
      >
        <MetaPfdDataChart region={region} id={`${region?.id}${id}`} {...commonProps} />
      </ResizableFrame>
    );
  }
  return null;
};

type IProps = {
  imagePosition: {
    topLeft: PointObjectNotation;
    bottomRight: PointObjectNotation;
  };
  imageDimensions: {
    naturalWidth: number;
    naturalHeight: number;
  };
  regions: IAnnotation[];
  cfg: IPfdCfg;
  isViewer?: boolean;
  id?: string;
  tableLatestTagHandler?: ILatestTagHandlerReturn;
  chartLatestTagHandler?: ILatestTagHandlerReturn;
  dispatchToReducer(action: IPfdAction): void;
};

function DataLayer({
  imagePosition,
  imageDimensions,
  regions = [],
  cfg,
  isViewer = false,
  id = 'none-widget',
  tableLatestTagHandler,
  chartLatestTagHandler,

  dispatchToReducer
}: IProps) {
  const {matrix} = useContext(MetaPfdContext);
  const ref = useRef(null);
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;

  const commonProps = {
    iw,
    ih,
    mat: matrix,
    isViewer,
    cfg,
    dispatchToReducer,
    imageDimensions
  };

  const tableProps = {
    ...commonProps,
    latestTagHandler: tableLatestTagHandler
  };

  const chartProps = {
    ...commonProps,
    latestTagHandler: chartLatestTagHandler
  };

  return (
    <Container
      ref={ref}
      $iw={iw}
      $ih={ih}
      $imagePosition={imagePosition}
      className={classnames(`data-layer-${id}`, 'data-layer')}
    >
      <InnerContainer>
        {regions.map((region, idx) => renderMetaPfdDataTable(region, idx, id, tableProps, ref))}
        {regions.map((region, idx) => renderMetaPfdDataChart(region, idx, id, chartProps, ref))}
      </InnerContainer>
    </Container>
  );
}

export default DataLayer;
