import React, {useContext} from 'react';
import Annotation from 'components/mpfd/annotation/Annotation';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

type IProps = {
  iw: number;
  ih: number;
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
};

function AnnotationList(props: IProps) {
  const {state} = useContext(MetaPfdContext);
  const {regions} = state;
  return (
    <>
      {regions.map((r) => (
        <Annotation key={r?.id} type={r?.type} region={r} {...props} />
      ))}
    </>
  );
}

export default AnnotationList;
// export default memo(
//   AnnotationList,
//   (prevProps, nextProps) => prevProps.iw === nextProps.iw && prevProps.ih === nextProps.ih
// );
