import React, {ChangeEvent, memo, ReactElement, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {IWidgetNodeData} from 'components/pc/types';
import NormalSpreadsheet from 'components/spreadsheet/NormalSpreadsheet';
import useNormalSpreadSheetEffect from 'components/spreadsheet/hooks/useNormalSpreadSheetEffect';
import useNormalSpreadSheetHandler from 'components/spreadsheet/hooks/useNormalSpreadSheetHandler';
import styled from 'styled-components';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import NodeSelector from 'components/pc/node-selector/NodeSelector';
import {faTableCells} from '@fortawesome/pro-solid-svg-icons';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {CellChange, ChangeSource} from 'handsontable/common';
import {TreeCategory} from 'api/DataProvider';
import WidgetModal from 'components/spreadsheet/parts/WidgetModal';
import useLatestTagHandler from 'hooks/useLatesetTagHandler';
import WidgetConfigLayer from 'components/pc/widgets/parts/WidgetConfigLayer';
import DataSheetSetting from 'components/pc/widgets/datasheet/DataSheetSetting';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const defaultRowIndex = {
  selectRowIndex: '1'
};

const defaultPlayTime = {
  updatePlayTimeUnit: 1000 * 60,
  updatePlayTimeVal: '1'
};

const defaultStatus = {
  settingModalOpen: false
};

export type IDataSheetPlayTime = {
  updatePlayTimeUnit: number;
  updatePlayTimeVal: string;
  // selectRowIndex: number;
};

export type IDataSheetRowIndex = {
  selectRowIndex: string;
};

export type IDataSheetStatus = {
  settingModalOpen: boolean;
};

const categoryList = ['Model', 'Plant', 'Weather', 'Commodity'] as TreeCategory[];

function DataSheetWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const spreadSheetMetaDataState = useState<IHandsonTableSpreadSheetData>({
    colHeaders: null,
    rowHeaders: null,
    colWidth: 100,
    data: [],
    rendererType: 'NormalDataRenderer'
  });
  const [spreadsheetMetaData, setSpreadSheetMetaData] = spreadSheetMetaDataState;
  const handsontableRef = useRef<any>(null); // unknown, object 둘다 안됨 HotTableClass (타입스크립트 에러 해결 안되서 any 일단 처리)
  const cellInfoState = useState<ISpreadSheetCellInfo[][]>(null);
  const [cellInfo] = cellInfoState;
  const tagSelectorInfoState = useState<ISpreadSheetCellInfo>(null);
  const [tagSelectorInfo, setTagSelectorInfo] = tagSelectorInfoState;
  const selectedTagsState = useState<string[][]>([]);
  const [selectedTags, setSelectedTags] = selectedTagsState;
  const isShowWidgetModalState = useState(false);
  const [isShowWidgetModal, setIsShowWidgetModal] = isShowWidgetModalState;
  const [status, setStatus] = useState<IDataSheetStatus>(defaultStatus);
  const [playTime, setPlayTime] = useState<IDataSheetPlayTime>(defaultPlayTime);
  const [rowIndex, setRowindex] = useState<IDataSheetRowIndex>(defaultRowIndex);

  const {setNodes} = useReactFlow();
  const dataRef = useRef(null);
  dataRef.current = spreadsheetMetaData;

  const latestTagHandlerForTable = useLatestTagHandler({type: 'latest_count', latest_count: 1});
  useNormalSpreadSheetEffect(data, id, spreadSheetMetaDataState, cellInfoState, latestTagHandlerForTable);
  const {onSelectTags, onContextMenuPane, onKeyDownCell, beforeOnCellMouseDown} = useNormalSpreadSheetHandler(
    spreadSheetMetaDataState,
    cellInfoState,
    tagSelectorInfoState,
    handsontableRef,
    selectedTagsState,
    isShowWidgetModalState
  );

  const afterChange = (changes: CellChange[], source: ChangeSource) => {
    switch (source) {
      case 'updateData': {
        break;
      }
      default: {
        /**
         * react 리렌더링 (Handsontable 이 이미 어레이참조를 통하여서 data 값은 변화하였지만, react 가 변화를 감지하지 못해서 리렌더링이 일어나지않기떄문에, setState
         */
        setSpreadSheetMetaData((prev) => ({...prev}));
      }
    }
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {...node?.data, metaData: {...node?.data?.metaData, cellInfo, spreadsheetMetaData: dataRef.current}}
            }
          : node
      )
    );
  };

  const onClickDocking = (): void => {
    setNodes((nodes) =>
      nodes.map((node) => {
        if (node.id === id) {
          node.data.metaData.docked = true;
          node.hidden = true;
        }
        return node;
      })
    );
  };

  const onClickStatusToggle = (key: keyof IDataSheetStatus) => {
    setStatus((prev) => ({...prev, [key]: !status[key]}));
  };

  const onChangePlayTime = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setPlayTime((prev) => ({...prev, updatePlayTimeVal: target.value}));
  };

  const onChangePlayTimeUnit = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    Number(target.value);
    setPlayTime((prev) => ({...prev, updatePlayTimeUnit: Number(target.value)}));
  };

  const onChangeRowIndex = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setRowindex((prev) => ({...prev, selectRowIndex: target.value}));
  };

  return (
    <WidgetContainer {...rest} docked={data.docked} type="SpreadsheetWidget">
      <WidgetHeader
        {...rest}
        type="SpreadsheetWidget"
        icon={faTableCells}
        id={id}
        hasDocking
        docked={data.docked}
        title={data.customizedTitle ? data.title : getWidgetTitle({type: 'DataSheetWidget', titleData: '', data})}
        suffix="- Datasheet"
        onConfig={() => onClickStatusToggle('settingModalOpen')}
        onClose={() => console.log()}
      />
      <WidgetActionPanel type="bold">
        <></>
      </WidgetActionPanel>
      <WidgetBody>
        <Wrapper
          onKeyDown={(e) => {
            if (e.key === 'Delete') {
              e.stopPropagation();
              onKeyDownCell(e);
            }
          }}
        >
          {spreadsheetMetaData?.data?.length > 0 && (
            <NormalSpreadsheet
              spreadsheetMetaData={spreadsheetMetaData}
              cellInfo={cellInfo}
              onContextMenuPane={onContextMenuPane}
              handsontableRef={handsontableRef}
              onKeyDownCell={onKeyDownCell}
              afterChange={afterChange}
              beforeOnCellMouseDown={beforeOnCellMouseDown}
            />
          )}
          {Boolean(tagSelectorInfo) && (
            <NodeSelector
              categoryList={categoryList}
              selectedTags={selectedTags}
              limit={2}
              zIndex={200}
              onSelect={onSelectTags}
              onClose={() => {
                setTagSelectorInfo(null);
                setSelectedTags([]);
              }}
            />
          )}
        </Wrapper>
        {isShowWidgetModal && (
          <WidgetModal title="Notice" onClose={() => setIsShowWidgetModal(false)}>
            Can not modify directly tag connected cells
          </WidgetModal>
        )}
      </WidgetBody>
      <WidgetConfigLayer
        title="Settings"
        isShow={status.settingModalOpen}
        onClose={() => setStatus((prev) => ({...prev, settingModalOpen: false}))}
      >
        <DataSheetSetting setSettingModalOpen={() => onClickStatusToggle('settingModalOpen')} />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}
export default memo(DataSheetWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
