import React, {ChangeEvent, memo, ReactElement, useEffect, useRef, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import styled from 'styled-components';
import {Button} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import DateTimeSelectBar from 'components/pc/widgets/timeseries/DateTimeSelectBar';
import {DateObject} from 'react-multi-date-picker';
import useTimeSeriesData from 'components/pc/widgets/timeseries/useTimeSeriesData';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import BasicSpinner from 'components/common/BasicSpinner';
import useDraggable from 'utils/useDraggable';
import TimeSeriesTable from 'components/pc/widgets/timeseries/TimeSeriesTable';
import {ITagNameMenuAction} from 'components/table/parts/TagName';
import {ITimeSeriesWidgetData} from 'components/pc/types';
import TimeSeriesChart from 'components/pc/widgets/timeseries/TimeSeriesChart';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import ResizeHandle from 'components/common/resizer/ResizeHandle';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';

const TableArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  //overflow: hidden;
  box-shadow:
    0 -5px 15px rgba(0, 0, 0, 0.15),
    0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-top: 1px solid #fff;
`;
const SelectNodesButton = styled(Button)`
  white-space: nowrap;
`;
const SelectTagButtonLabel = styled.span`
  margin-left: 6px;
`;

function TimeSeriesWidget({data, id, ...rest}: NodeProps): ReactElement {
  const {setNodes} = useReactFlow();

  // drag 관련 ref 설정
  const boundaryRef = useRef(null);
  const dragHandleRef = useRef(null);
  const defaultPosition = {x: 0, y: 60};
  const defaultPadding = {top: 60, right: 0, bottom: 0, left: 0};
  const [dateTimePosition, onMouseDownResizeHandle, setInitialChartSize] = useDraggable(
    boundaryRef,
    dragHandleRef,
    defaultPosition,
    defaultPadding
  );
  const [isLive, setIsLive] = useState(false);

  // range 설정
  const now = new DateObject();
  const end = now.unix;
  const start = now.subtract(7, 'day').unix;
  const rangeState = useState<number[]>(data?.metaData ? data?.metaData?.range : [start, end]);
  const [range, setRange] = rangeState;

  // 선택한 tag
  // const [selectedTags, setSelectedTags] = useState<string[][]>([]);
  const [selectedNodes, setSelectedNodes] = useState<string[][]>([]);
  //
  const selectedRowState = useState<string[]>([]);
  const [selectedRow, setSelectedRow] = selectedRowState;

  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>();

  const {isLoadingSeries, series, liveSeries, requestSeries, removeSeries, clearSeries, updateSeries, goLive} =
    useTimeSeriesData(rangeState);

  // 최초 데이터 세팅
  useEffect(() => {
    console.log('time series widget >> data?.metaData', data?.metaData);
    if (data?.metaData) {
      const {tags, nodes, chartHeight} = data.metaData as ITimeSeriesWidgetData;
      /**
       * MIGRATION-4
       * Commodity => commodity
       * Model => model_fcc , Plant => plant_fcc
       */
      const nodeList =
        nodes ||
        [...(tags || [])].map(function (tag) {
          const [database, ...rest] = tag;
          let lowerCase;
          switch (database) {
            case 'Weather':
            case 'Commodity': {
              lowerCase = database?.toLowerCase();
              break;
            }
            case 'Model': {
              lowerCase = 'model_fcc';
              break;
            }
            case 'Plant': {
              lowerCase = 'plant_fcc';
              break;
            }
          }
          if (lowerCase) {
            return [lowerCase, ...rest];
          } else {
            return tag;
          }
        });
      /**
       * MIGRATION-4
       * Commodity => commodity
       * Model => model_fcc , Plant => plnat_fcc
       */
      if (nodeList?.length) {
        setSelectedNodes(nodeList);
        requestSeries(nodeList, range);
      }

      // if (isLive) {
      //   setTimeout(() => {
      //     goLive(true);
      //   }, 5000);
      // }
      setInitialChartSize({x: 0, y: chartHeight || 0});
      // todo: top, bottom, color, extreme 세팅 필요
    }
  }, [data?.metaData]); // data.selected 가 변경되는 경우에도 재호출 되므로 현재는 빈배열로 처리하고 차후 처리 필요

  useEffect(() => {
    requestSeries(selectedNodes, range);
  }, [selectedNodes, range]);

  // 상태 변경에 의한 데이터 세팅
  useEffect(() => {
    const metaData = {
      nodes: selectedNodes,
      range,
      isLive,
      chartHeight: dateTimePosition.y
    };
    setNodes((nodes) => nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData}} : node)));
  }, [id, selectedNodes, selectedRow, range, isLive, dateTimePosition]);

  useEffect(() => {
    console.log('series', series);
    setLastUpdateTime(new Date());
  }, [series]);

  /**
   * row 중 td 에 해당하는 정보(row data)가 변경됨
   * @param e
   */
  const onChangeRow = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value, title, name} = e.target;
    console.log(title, name, value);
    const found = series.find((item) => JSON.stringify(item.keys) === name);
    // 현재 series 를 찾아보고 없으면 바로 중단
    if (found === undefined) return;

    switch (title) {
      case 'color':
        found.color = value;
        break;
      case 'top':
        found.top = Number(value);
        break;
      case 'bottom':
        found.bottom = Number(value);
        break;
      default:
        // case 가 없으면 변경이 없는 것으로 간주하고 바로 중단. 즉 updateSeries() 를 호출하지 않음
        return;
    }
    updateSeries();
  };

  const onMenu = (action: ITagNameMenuAction): void => {
    // console.log(action.type, action);
    switch (action.type) {
      case 'CHANGE':
        setIsShowNodeSelector(true);
        break;
      case 'REMOVE':
        // setSelectedTags(selectedTags.filter((tag) => tag.join('-') !== action.keys.join('-')));
        setSelectedNodes(selectedNodes.filter((node) => node.join('-') !== action.keys.join('-')));
        removeSeries(action.keys);
        break;
      default:
      // do nothing...
    }
  };

  const onChangeLive = (): void => {
    // todo:
    const bool = !isLive;
    setIsLive(bool);
    goLive(bool);
  };

  const onSelectNode = (checkedList: string[]): void => {
    const parsed = checkedList.map((item) => JSON.parse(item));
    // console.log('>>> checkedList', checkedList, parsed);
    setSelectedNodes(parsed);
    requestSeries(parsed, range);
  };

  return (
    <WidgetContainer {...rest} docked={data.docked} type="TimeSeriesWidget">
      <WidgetHeader
        {...rest}
        type="TimeSeriesWidget"
        icon={data.icon}
        id={id}
        title={
          data.customizedTitle ? data.title : getWidgetTitle({type: 'TimeSeriesWidget', titleData: selectedRow, data})
        }
        suffix="- Time Series"
        hasDocking
        docked={data.docked}
      />
      <WidgetActionPanel disabled={isShowNodeSelector}>
        <DateTimeSelectBar
          id={id}
          rangeState={rangeState}
          lastUpdateTime={lastUpdateTime}
          // series={series}
          direction={boundaryRef.current?.clientHeight - dateTimePosition.y > dateTimePosition.y ? 'bottom' : 'top'}
          goLive={onChangeLive}
        >
          <SelectNodesButton variant="dark" height={34} onClick={() => setIsShowNodeSelector(true)}>
            <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
            <SelectTagButtonLabel>Select Nodes</SelectTagButtonLabel>
          </SelectNodesButton>
          <BasicSpinner isShow={isLoadingSeries} margin="0 10px" size="md" type="inline" />
        </DateTimeSelectBar>
      </WidgetActionPanel>
      <WidgetBody ref={boundaryRef} actionMenuHeight={42}>
        <TimeSeriesChart
          series={series}
          liveSeries={liveSeries}
          rangeState={rangeState}
          selectedRowState={selectedRowState}
          height={dateTimePosition.y}
        />
        <TableArea>
          <ResizeHandle ref={dragHandleRef} onMouseDown={onMouseDownResizeHandle} />
          <TimeSeriesTable series={series} selectedRowState={selectedRowState} onMenu={onMenu} onChange={onChangeRow} />
          {isShowNodeSelector && (
            <NodeSelectorRevision
              title="Select Nodes"
              selectedNodes={selectedNodes}
              onClose={() => setIsShowNodeSelector(false)}
              onSelect={onSelectNode}
            />
          )}
        </TableArea>
      </WidgetBody>
    </WidgetContainer>
  );
}

export default memo(TimeSeriesWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
