import {ChangeEvent, ChangeEventHandler, ReactElement} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import arrowDown from '../../assets/images/icon/icon-select-arrow-down.svg';
import arrowDownBlue from '../../assets/images/icon/icon-select-arrow-down-blue.svg';

const SelectContainer = styled.select`
  flex: initial;
  flex-shrink: 0;
  padding: 10px;
  box-sizing: border-box;
  height: 40px;
  //width: 400px;
  color: #575660;
  font-size: 15px;
  letter-spacing: -0.05em;
  border: 1px solid #b6b4c0;
  border-radius: 5px;
  background-image: url(${arrowDown});
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 55%;
  background-color: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  cursor: pointer;

  &:focus {
    outline: 1px solid #3d51fe;
    background-image: url(${arrowDownBlue});
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 55%;
    background-color: #fff;
  }

  &:disabled {
    opacity: 0.4;
  }

  &.width-auto {
    flex: 1;
    flex-shrink: initial;
  }
`;

type IProps<T> = {
  className?: string;
  id?: string;
  name: string;
  placeholder?: string;
  options?: T[];
  value?: string | number | readonly string[] | undefined | null;
  valueKey?: keyof T | 'value';
  labelKey?: keyof T | 'label';
  width?: string | number;
  readOnly?: boolean;
  disabled?: boolean;
  disabledPlaceholder?: boolean;
  onChange?: ChangeEventHandler;
};

function SelectForm<T>({
  className,
  id,
  name,
  placeholder,
  options,
  value,
  valueKey = 'value',
  labelKey = 'label',
  width,
  readOnly,
  disabled,
  disabledPlaceholder,
  onChange
}: IProps<T>): ReactElement {
  const onSelectChange = (e: ChangeEvent): void => {
    return onChange?.(e);
  };

  return (
    <SelectContainer
      className={classNames('common-select-form', className, width ?? 'width-auto')}
      id={id}
      name={name}
      onChange={onSelectChange}
      style={{width}}
      disabled={disabled}
      value={value ?? ''}
    >
      {placeholder && (
        <option value="" disabled={disabledPlaceholder}>
          {placeholder || 'select'}
        </option>
      )}
      {options?.map((item) => (
        <option
          value={item[valueKey as keyof T] as string}
          key={item[valueKey as keyof T] as string}
          disabled={readOnly}
        >
          {item[labelKey as keyof T] as string}
        </option>
      ))}
    </SelectContainer>
  );
}
export default SelectForm;
