import styled from 'styled-components';
import {ChangeEvent, useContext, useEffect, useState} from 'react';
import {DataContext} from 'api/DataProvider';
import {IAnnotation, IPfdAction} from 'components/mpfd/type';
import {getDetailInfoOfTag} from 'components/mpfd/panel/parts/property/function';
import InputCheckbox from 'components/table/parts/InputCheckbox';
import ComponentTable, {IComponentTableField} from 'components/table/ComponentTable';

const Container = styled.div`
  height: calc(100% - 250px);
`;

const fields: IComponentTableField[] = [
  {
    key: 'name',
    label: 'FCC tag name',
    style: {header: {width: 150}},
    css: {body: 'ellipsis'}
  },
  {
    key: 'unit',
    label: 'Unit',
    style: {header: {width: 80}},
    css: {body: 'ellipsis'}
  },
  {
    key: 'isTableDisplay',
    style: {header: {width: 22}},
    css: {body: 'checkbox'},
    customHeaderComponent: true,
    headerTooltip: 'Table Display',
    component: InputCheckbox
  },
  {
    key: 'isChartDisplay',
    style: {header: {width: 22}},
    css: {body: 'checkbox'},
    customHeaderComponent: true,
    headerTooltip: 'Chart Display',
    component: InputCheckbox
  }
];

type IProps = {
  selectedAnnot: IAnnotation;
  dispatchToReducer(action: IPfdAction): void;
};

function PropertyTable({selectedAnnot, dispatchToReducer}: IProps) {
  const {modelHierarchy} = useContext(DataContext);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const hierarchyInfo = selectedAnnot?.metaPfdTagInfo?.map((item) => item.tag);
    if (!hierarchyInfo || hierarchyInfo?.length === 0 || !modelHierarchy) {
      setRows([]);
      return;
    }
    // :todo PlantTagDetail 얻는것
    const refined = selectedAnnot?.metaPfdTagInfo?.map((item) => ({
      ...getDetailInfoOfTag(item.tag, modelHierarchy),
      flattenKeys: item.tag.join('-'),
      isTableDisplay: Boolean(item?.isTableDisplay),
      isChartDisplay: Boolean(item?.isChartDisplay),
      keys: item.tag.join('-')
    }));
    setRows(refined);
  }, [modelHierarchy, selectedAnnot]);

  const onChange = (e: ChangeEvent<HTMLInputElement>, th?: boolean): void => {
    const {value, name, id} = e.target;
    if (id) {
      dispatchToReducer({
        type: 'CHANGE_REGION',
        region: {
          ...selectedAnnot,
          metaPfdTagInfo: selectedAnnot?.metaPfdTagInfo?.map((item) => {
            const updatedItem = {...item, [id]: value === 'true'};
            return th ? updatedItem : item?.tag?.join('-') === name ? updatedItem : item;
          })
        }
      });
      return;
    }
  };

  return (
    <Container>
      <ComponentTable fields={fields} rows={rows} onChange={onChange} />
    </Container>
  );
}

export default PropertyTable;
