import {ChangeEvent, KeyboardEvent, ReactElement, useEffect, useState} from 'react';
import {Button, TextForm} from 'components/forms';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagnifyingGlass} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';

const SearchUrlInput = styled(TextForm)`
  input {
    border-radius: 3px 0 0 3px !important;
  }
`;
const SearchButton = styled(Button)`
  width: 40px;
  padding: 10px;
  border-radius: 0 3px 3px 0;
  background-color: #394add;

  svg {
    font-size: 20px;
  }
`;

type IProps = {
  defaultUrl?: string;
  onConfirm(url: string): void;
};

function UrlSearchBar({defaultUrl, onConfirm}: IProps): ReactElement {
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (defaultUrl) {
      onConfirm(defaultUrl);
      setUrl(defaultUrl);
    }
  }, [defaultUrl]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setUrl(value);
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onClickConfirm();
    }
  };

  const onClickConfirm = () => {
    onConfirm(url);
  };

  return (
    <>
      <TextForm
        name="url-search-bar"
        value={url}
        style={{borderRadius: '3px 0 0 3px', borderRight: 'none'}}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder="Please enter url"
      />
      <SearchButton onClick={onClickConfirm}>
        <FontAwesomeIcon icon={faMagnifyingGlass} />
      </SearchButton>
    </>
  );
}

export default UrlSearchBar;
