import {ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight} from '@fortawesome/pro-solid-svg-icons';

const Container = styled.div`
  padding: 13px 25px;
  position: relative;
  display: flex;
  height: 44px;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f2f2f2;
  color: #373737;

  font-weight: 600;
  font-size: 13px;
  border-bottom: 1px solid #a6a6a6;
  &.active {
    background-color: #d3d3d3;
    border-bottom: 0;
  }
`;

const IconContainer = styled.div`
  //position: absolute;
  //right: 30px;
`;

type AccordionSummaryProps = {
  header: ReactNode;
  isActive?: boolean;
  onToggle?: () => void;
};

export function AccordionHeader({header, isActive, onToggle}: AccordionSummaryProps): ReactElement {
  return (
    <Container className={classnames(isActive && 'active')} onClick={onToggle}>
      {header}
      <IconContainer>
        <FontAwesomeIcon icon={isActive ? faChevronDown : faChevronRight} />
      </IconContainer>
    </Container>
  );
}

export default AccordionHeader;
