import React from 'react';
import styled from 'styled-components';
import LoginScreenHeader from 'components/auth/login/parts/LoginScreenHeader';
import {Button} from 'components/forms';
import LoginScreenContainer from 'components/auth/login/parts/LoginScreenContainer';
import {useNavigate} from 'react-router-dom';

const NoticeContainer = styled.div`
  font-size: 16px;
  font-weight: 100;
`;

function RegistrationComplete() {
  const navigate = useNavigate();

  return (
    <LoginScreenContainer>
      <LoginScreenHeader title="Registration successful!" fontSize="24px" />
      <NoticeContainer>
        You can now proceed to log in with your newly created account credentials, granting you access to our platform's
        features and services.
      </NoticeContainer>
      <Button height="40px" onClick={() => navigate('/login')}>
        Try Login
      </Button>
    </LoginScreenContainer>
  );
}

export default RegistrationComplete;
