import {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import {ITagTableData, ISeries, ISelectedRowState} from 'components/pc/widgets/timeseries/types';
import DefaultTable, {ITableField} from 'components/table/DefaultTable';
import {ColorPicker, TagName} from 'components/table/parts';
import styled from 'styled-components';
import {ITagNameMenuAction} from 'components/table/parts/TagName';
import InputMinMaxValue from 'components/table/parts/InputMinMaxValue';
import {getDataType} from 'components/pc/widgets/timeseries/timeseries-functions';
import {toDateFormat} from 'utils/commons';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';

const Container = styled.div`
  height: calc(100% - 6px);
`;

const fields: ITableField[] = [
  {
    key: 'checkbox',
    style: {header: {width: 22}},
    css: {body: 'checkbox'}
  },
  {
    key: 'color',
    style: {header: {width: 22}},
    css: {body: 'no-padding'},
    component: ColorPicker
  },
  {
    key: 'name',
    label: 'Node Name',
    style: {header: {width: 200}},
    component: TagName
  },
  {
    key: 'avg',
    label: 'Avg.',
    toFixed: 2,
    style: {header: {width: 100}, body: {textAlign: 'right'}}
  },
  {
    key: 'max',
    label: 'Max',
    toFixed: 2,
    style: {header: {width: 100}, body: {textAlign: 'right'}}
  },
  {
    key: 'min',
    label: 'Min',
    toFixed: 2,
    style: {header: {width: 100}, body: {textAlign: 'right'}}
  },
  {
    key: 'top',
    label: 'Top',
    style: {header: {width: 80}},
    component: InputMinMaxValue
  },
  {
    key: 'bottom',
    label: 'Bottom',
    style: {header: {width: 80}},
    component: InputMinMaxValue
  },
  {
    key: 'description',
    label: 'Description',
    css: {body: 'ellipsis'}
  }
];

type IProps = {
  children?: ReactElement;
  loadingComponent?: ReactElement;
  series: ISeries[];
  selectedRowState: ISelectedRowState;
  onChange(e: ChangeEvent): void;
  onMenu(action: ITagNameMenuAction): void;
};

function TimeSeriesTable({
  children,
  loadingComponent,
  series,
  selectedRowState,
  onChange,
  onMenu
}: IProps): ReactElement {
  const [tableData, setTableData] = useState<ITagTableData[]>([]);
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;

  const convert = (item: ISeries, valueKey: keyof ISeries): number | string | undefined => {
    const value = item?.[valueKey];
    if (!value) return undefined;
    if (getDataType(item.keys) === 'datetime') {
      return toDateFormat(Number(value) * 1000, 'HH:mm:ss');
    } else {
      return toSignificantDigit(Number(value), globalSettings.significantDigit);
    }
  };

  // series 가 변경되면 DefaultTable 에서 표시할 수 있도록 형태롤 만들어 props 로 전단한다.
  useEffect(() => {
    const refined: ITagTableData[] = series.map((item) => ({
      ...item,
      flattenKeys: item.keys.join('-'),
      avg: convert(item, 'avg'),
      min: convert(item, 'min'),
      max: convert(item, 'max'),
      top: undefined,
      bottom: undefined,
      description: undefined,
      checked: true,
      onChange,
      onMenu
    }));
    const [, setSelectedRows] = selectedRowState;
    // 선택상태는 초기값이 모두 선택
    setSelectedRows(refined.map((item) => item.flattenKeys));
    // 데이터 SET
    setTableData(refined);
  }, [series]);

  return (
    <Container>
      <DefaultTable
        fields={fields}
        fieldKey="key"
        fieldLabelKey="label"
        rows={tableData}
        rowKey="flattenKeys"
        selectedRowState={selectedRowState}
        loadingComponent={loadingComponent}
        onChangeRow={onChange}
      >
        {children}
      </DefaultTable>
    </Container>
  );
}

export default TimeSeriesTable;
