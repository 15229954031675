import {memo, PropsWithChildren, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import Highcharts from 'highcharts/highstock';
import HighchartsReact, {HighchartsReactProps} from 'highcharts-react-official';
import {ISelectedRowState, ISeries, ITimestampRangeState} from 'components/pc/widgets/timeseries/types';
import {toDateFormat} from 'utils/commons';
import {formatter, getDataType} from 'components/pc/widgets/timeseries/timeseries-functions';
import {DataContext} from 'api/DataProvider';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import dayjs from 'dayjs';

if (typeof window !== `undefined`) {
  highchartsAccessibility(Highcharts);
}

const initialOptions: HighchartsReactProps = {
  chart: {
    // type: 'spline',
    // animation: false,
    events: {
      // load: onChartLoad
    },
    showInNavigator: true,
    zoomType: 'x',
    alignTicks: false,
    style: {
      fontFamily: 'inherit'
    },
    backgroundColor: '#f1f4f6'
  },
  navigator: {
    enabled: true
  },
  credits: {
    enabled: false
  },
  title: false,
  xAxis: {
    type: 'timestamp',
    tickPixelInterval: 200,
    labels: {
      formatter: function () {
        return dayjs(this.value).format('YYYY-MM-DD HH:mm:ss');
      }
    }
  },
  legend: false,

  plotOptions: {
    series: {
      showInNavigator: true,
      label: {
        connectorAllowed: false
      },
      pointStart: 2010
    }
  },
  tooltip: {
    formatter: function () {
      return formatter.sharedTooltip(this.points, 4);
    },

    shared: true
    // formatter: function () {
    //   return formatter.separatedTooltip(this);
    // }
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }
    ]
  }
};

const Container = styled.div`
  background-color: #e9eff3;
  min-height: 50px;
  overflow: hidden;
  flex-shrink: 0;
`;

type IProps = PropsWithChildren & {
  series: ISeries[];
  liveSeries: ISeries[];
  rangeState: ITimestampRangeState;
  selectedRowState: ISelectedRowState;
  height: number;
  // onChangeDateRange(range: number[]): void;
};

function TimeSeriesChart({series, liveSeries, rangeState, selectedRowState, height}: IProps): ReactElement {
  const ref = useRef(null);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  // initialOptions.chart.events.load = function () {
  //   console.log('<<<<< load ', this.reflow);
  // };
  const [options, setOptions] = useState<HighchartsReactProps>(initialOptions); //{xAxis: {type: 'timestamp'}}
  const [range, setRange] = rangeState;
  const [selectedRows, setSelectedRows] = selectedRowState;
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;

  // useEffect(() => {
  //   console.log('changed timestamp range height', height, range);
  // }, [height]);

  useEffect(() => {
    console.log('changed selectedRows', selectedRows);
    // console.log('> 차트 관련 옵션', chartRef?.current?.chart?.series);
    setOptions((prev) => ({
      ...prev,
      series: prev?.series?.map(function (item) {
        if (selectedRows?.indexOf(item?.flattenKeys) === -1) {
          return {...item, visible: false};
        } else {
          return {...item, visible: true};
        }
      })
    }));
  }, [selectedRows]);

  /* const requestLive = useCallback(() => {
    const chart = chartRef?.current?.chart;

    console.log('> charts', chart);

    setInterval(function () {
      // console.log('> setInterval', chart);
      // chartRef?.current?.chart?.series.forEach((item, index) => {
      //   const x = new Date().getTime() * 0.001; // current time
      //   const y =
      //     Math.random() * (Number(series?.[index]?.max) - Number(series?.[index]?.min) + Number(series?.[index]?.min));
      //   console.log('> run>>>>>>', x, y, item);
      //   item.addPoint([x, y], true, true);
      //
      //
      // });
      series.forEach((item, index) => {
        const x = new Date().getTime() * 0.001; // current time
        const y = Math.random() * (Number(item.max) - Number(item.min) + Number(item.min));
        console.log('> run>>>>>>', x, y, item);
        chartRef?.current?.chart?.series[index].addPoint([x, y], true, true);
      });
    }, 5000);
  }, [series]);*/
  /**
   *  yAxis title : {text : ""} 인 경우 정상적으로 차트가 보여지지 않음
   */

  const setExtremes = (e): void => {
    console.log('>>>> setExtremese', e);
  };

  useEffect(() => {
    const yAxis = series.map((item) => ({
      title: {text: item?.name ? item?.name : undefined, style: {color: item.color}},
      opposite: false,
      labels: {
        style: {color: item.color},
        formatter: function () {
          if (getDataType(item?.keys) === 'datetime') {
            const yVal = this?.value * 1000;
            return toDateFormat(yVal, 'HH:mm:ss');
          } else {
            return this.value;
          }
        }
      },
      max: item?.top || item?.max,
      min: item?.bottom || item?.min
      // type: getDataType(item?.keys) // tag type 에 따라 y Axis 축 타입 지정이 필요성 ( sunrise 등)
    }));

    /*
    // live 일 경우
    xAxis: {
      overscroll: 10 * 1000 // 10 seconds
    },*/
    const xAxis = {type: 'timestamp'};
    const refined = series.map(
      (item, index) =>
        ({
          data: item.data,
          name: item?.name,
          color: item?.color,
          yAxis: index,
          marker: {symbol: 'circle'},
          flattenKeys: item.flattenKeys,
          featureArray: item.keys // series.userOptions 를 통하여서 확인 가능
        }) as ISeries
    );

    setOptions((prev) => ({...prev, xAxis, yAxis, series: refined}));
    // requestLive();
  }, [series]);

  /*useEffect(() => {
    const c: Highcharts.StockChart = chartRef?.current?.chart;
    console.log('>>> chart', c, chartRef);
    liveSeries.forEach((item, index) => {
      // const x = new Date().getTime() * 0.001; // current time
      // const y = Math.random() * (Number(item.max) - Number(item.min) + Number(item.min));
      console.log('live >', item.flattenKeys, item.data);
      const s = c?.series?.[index] as Highcharts.Series;
      s?.addPoint([...item.data], false, true);
    });
    c.reflow();
  }, [liveSeries, series]);*/

  /*useEffect(() => {
    console.log('<<<< liveSeries', liveSeries);

    // const c: Highcharts.StockChart = chartRef?.current?.chart;
    // const c: Highcharts.StockChart = chartRef?.current?.chart;
    // console.log('>>> chart', c, chartRef);
    // liveSeries.forEach((item, index) => {
    //   // const x = new Date().getTime() * 0.001; // current time
    //   // const y = Math.random() * (Number(item.max) - Number(item.min) + Number(item.min));
    //   const s = c?.series?.[index] as Highcharts.Series;
    //   console.log('live >', s?.addPoint, item.flattenKeys, item.data);
    //   s?.addPoint([...item.data], true, true);
    // });
    // c.reflow();

    // const c: Highcharts.StockChart = chartRef?.current?.chart;
    // setOptions((prev) => {
    //   prev.chart.events.load = function () {
    //     // console.log('<<<<< load ', this.reflow);
    //     liveSeries.forEach((item, index) => {
    //       // const x = new Date().getTime() * 0.001; // current time
    //       // const y = Math.random() * (Number(item.max) - Number(item.min) + Number(item.min));
    //       const s = c?.series?.[index] as Highcharts.Series;
    //       console.log('live >', s?.addPoint, item.flattenKeys, item.data);
    //       s?.addPoint([...item.data], true, true);
    //     });
    //   };
    //   return prev;
    // });
    // chartRef?.current?.chart?.reflow();

    const merged =
      options?.series?.data?.map((s, index) => {
        return [...s, ...liveSeries?.[index]?.data];
      }) || [];
    setOptions((prev) => ({
      ...prev,
      series: merged
    }));
  }, [liveSeries]);*/

  useEffect(() => {
    setOptions((prev) => ({
      ...prev,
      tooltip: {
        formatter: function () {
          return formatter.sharedTooltip(this.points, globalSettings.significantDigit);
        },
        shared: true
      }
    }));
  }, [globalSettings]);

  // useEffect(() => {
  //   const observer = new ResizeObserver((entries) => {
  //     chartRef?.current?.chart?.reflow();
  //   });
  //
  //   observer.observe(ref.current);
  // }, []);

  return (
    <Container style={{height}} ref={ref}>
      <HighchartsReact
        highcharts={Highcharts}
        ref={chartRef}
        allowChartUpdate
        // immutable={false}
        containerProps={{style: {height: '100%', minHeight: 200}}}
        options={options}
      />
    </Container>
  );
}

// export default TimeSeriesChart;
export default memo(TimeSeriesChart);
