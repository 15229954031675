import {useState} from 'react';
import styled from 'styled-components';
import {Button} from 'components/forms';

type IProps = {
  pythonEnv: string;
  packageList: string[];
};

const Container = styled.div`
  height: calc(100% - 32px);
  font-size: 14px;
  //padding: 20px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const PackageListContainer = styled.div`
  flex: 1;
  margin: 16px 0 20px 0;
  display: flex;
  flex-direction: column;
  gap: 3px;
  &::-webkit-scrollbar {
    width: 10px;
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
  }
`;

function PythonEditorWidgetEnvironmentPackageList({pythonEnv, packageList}: IProps) {
  return (
    <Container>
      <PackageListContainer className="thin-scrollbar">
        {packageList.map((item) => (
          <div>{item?.replace('==', ' : ')}</div>
        ))}
      </PackageListContainer>
      <BtnContainer>
        <Button variant="cancel" width={69} height={24}>
          Close
        </Button>
      </BtnContainer>
    </Container>
  );
}

export default PythonEditorWidgetEnvironmentPackageList;
