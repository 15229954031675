import styled from 'styled-components';
import React, {ReactNode, useContext, useEffect, useRef} from 'react';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import classnames from 'classnames';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import {transform, translate} from 'transformation-matrix';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';

type StyleProps = {
  $imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  $iw: number;
  $ih: number;
  $mat?: any;
};

const Container = styled.div<StyleProps>`
  position: absolute;
  top: ${(props) => props.$imagePosition.topLeft.y}px;
  left: ${(props) => props.$imagePosition.topLeft.x}px;

  //z-index: ${META_PFD_zINDEX.TABLE_LAYER};
  width: ${(props) => props.$iw}px;
  height: ${(props) => props.$ih}px;
  &.pan {
    * {
      pointer-events: none;
    }
  }
`;

type IProps = {
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  children: ReactNode;
  layoutChanging?: boolean;
  isViewer?: boolean;
};

function LayerWrapper({imagePosition, children, layoutChanging, isViewer = false}: IProps) {
  const {dispatchToReducer, matrix, changeMatrix, state, detectLine, stageEnteredState} = useContext(MetaPfdContext);

  const selectedTool = state.selectedTool;
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;
  const previousTool = useRef<ToolIds | null>(null);
  const containerRef = useRef(null);
  const [stageEntered] = stageEnteredState;
  const onMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    const c = document.getElementById('layer-wrapper');
    if (!containerRef.current) return;
    const startXInImage = e.clientX - c.getBoundingClientRect().left;
    const startYInImage = e.clientY - c.getBoundingClientRect().top;
    const xPercentageOfImage = startXInImage / iw;
    const yPercentageOfImage = startYInImage / ih;

    if (e.button === 0) {
      dispatchToReducer({type: 'MOUSE_DOWN', x: xPercentageOfImage, y: yPercentageOfImage});
    } else if (e.button === 2) {
      dispatchToReducer({type: 'MOUSE_DOWN_RIGHT_BTN', x: xPercentageOfImage, y: yPercentageOfImage});
    }
    if (selectedTool === 'line-detection') {
      detectLine(xPercentageOfImage, yPercentageOfImage);
    }

    if (selectedTool === 'pan') {
      const c = document.getElementById('standard-canvas');
      if (!c) return;
      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;

      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        const translateMatrix = translate(startX - x, startY - y);
        const newMat = transform([matrix, translateMatrix]);
        changeMatrix({...newMat});
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        if (previousTool.current) {
          dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
        }
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});

      e.preventDefault();
    } else if (e.button === 1) {
      const c = document.getElementById('standard-canvas');
      if (!c) return;
      if (!previousTool.current) {
        previousTool.current = selectedTool;
      }

      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;

      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        const translateMatrix = translate(startX - x, startY - y);
        const newMat = transform([matrix, translateMatrix]);
        changeMatrix({...newMat});
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});
      e.preventDefault();
    } else if (selectedTool === 'create-expanding-line') {
      e.preventDefault();
    } else {
      const onMouseMove = (e: MouseEvent): void => {
        const currentX = e.clientX - c.getBoundingClientRect().left;
        const currentY = e.clientY - c.getBoundingClientRect().top;
        dispatchToReducer({type: 'MOUSE_MOVE', x: currentX / iw, y: currentY / ih});
      };

      const onMouseUp = (e: MouseEvent) => {
        document.removeEventListener('mousemove', onMouseMove);
        const currentX = e.clientX - c.getBoundingClientRect().left;
        const currentY = e.clientY - c.getBoundingClientRect().top;
        dispatchToReducer({type: 'MOUSE_UP', x: currentX / iw, y: currentY / ih});
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});
      e.preventDefault();
    }
  };

  const onMouseMove = (e: React.MouseEvent): void => {
    if (selectedTool !== 'create-expanding-line') return;
    selectedTool === 'create-expanding-line' && e.stopPropagation();
    const c = document.getElementById('layer-wrapper');
    if (!containerRef.current) return;
    const currentX = e.clientX - c.getBoundingClientRect().left;
    const currentY = e.clientY - c.getBoundingClientRect().top;
    dispatchToReducer({type: 'MOUSE_MOVE', x: currentX / iw, y: currentY / ih});
  };

  useEffect(() => {
    // spacebar 눌렀을 때 pan 모드로 변경
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (!previousTool.current) {
          previousTool.current = state.selectedTool;
        }
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: 'pan'});
        document.removeEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);
      }
    };

    // spacebar 놓았을 때 이전 tool로 돌아감
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === ' ' || e.code === 'Space') {
        if (previousTool.current) {
          dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
          previousTool.current = null;
          document.addEventListener('keydown', handleKeyDown);
          document.removeEventListener('keyup', handleKeyUp);
        }
      }
    };

    if (stageEntered) {
      document.addEventListener('keydown', handleKeyDown);
    } else {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [stageEntered]);

  return (
    <Container
      className={classnames(layoutChanging && 'layoutChanging', 'layer-wrapper', selectedTool === 'pan' && 'pan')}
      $imagePosition={imagePosition}
      $iw={iw}
      $ih={ih}
      $mat={matrix}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      ref={containerRef}
      id="layer-wrapper"
    >
      {children}
    </Container>
  );
}

export default LayerWrapper;
