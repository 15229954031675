import {ChangeEvent, ReactElement, useContext, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, SelectForm, TextForm} from 'components/forms';
import {SignificantLevelOptions} from 'components/pc/widgets/hmb/const';
import {DataContext} from 'api/DataProvider';
import FormRow from 'components/forms/FormRow';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {IGlobalSetting} from 'api/data-types';
import styled from 'styled-components';
import {LocalStorageManager} from 'utils/local-storage-manager';

const ValidMessage = styled.div`
  color: ${({theme}) => theme.color.invalid};
  font-size: 14px;
  line-height: 30px;
`;

type IProps = {
  onClose(): void;
};

function GlobalSettingsModal({onClose}: IProps): ReactElement {
  const {globalSettingsModalState, globalSettingsState} = useContext(DataContext);
  const [isShow] = globalSettingsModalState;
  const [globalSettings, setGlobalSetting] = globalSettingsState;
  const [settings, setSettings] = useState<IGlobalSetting>(globalSettings);
  const [panDistanceValidMessage, setPanDistanceValidMessage] = useState<string>();

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  const validate = (type: 'significantDigit' | 'panDistance' | string, value: string): string | null => {
    switch (type) {
      case 'panDistance':
        return Number(value) < 10 ? 'Please input greater than 10' : null;
      default:
        return null;
    }
  };

  const onChangeNumber = (e: ChangeEvent<HTMLInputElement>): void => {
    const {name, value} = e.target;
    const valid = validate(name, value);
    setPanDistanceValidMessage(valid);
    setSettings((prev) => ({...prev, [name]: value}));
  };

  const onConfirm = (): void => {
    // console.log('>>>> settings', settings);
    setGlobalSetting({...settings});
    LocalStorageManager.setItem('GLOBAL_SETTINGS', settings);
    onClose();
  };

  if (isShow) {
    return (
      <ModalWrapper type="settings">
        <ModalHeader onClose={onClose}>Global Settings</ModalHeader>
        <ModalBody align="top" size="full-width">
          <FormRow header="Significant Figures">
            <SelectForm
              width={100}
              name="significantDigit"
              options={SignificantLevelOptions}
              value={settings.significantDigit}
              onChange={onChangeNumber}
            />
          </FormRow>
          <FormRow header="Keyboard Panning Pixel Distance">
            <ValidMessage>{panDistanceValidMessage}</ValidMessage>
            <TextForm
              width={100}
              name="panDistance"
              type="number"
              position="right"
              value={settings.panDistance}
              onChange={onChangeNumber}
            />
          </FormRow>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modal-btn"
            variant="primary"
            size="sm"
            disabled={Boolean(panDistanceValidMessage)}
            onClick={onConfirm}
          >
            Save
          </Button>
          <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default GlobalSettingsModal;
