import React, {useCallback, MouseEvent} from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import {hexToRGBA} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';

type BoxRectProps = {
  $color: string;
  $highlighted: boolean;
  $createMode: boolean;
};

const BoxRect = styled.rect<BoxRectProps>`
  fill: ${(props) => hexToRGBA(props.$color, 0.25)};
  pointer-events: ${(props) => (props.$createMode ? 'none' : '')};

  &.boxHoverType {
    &:hover {
      fill: ${(props) => (props.$highlighted ? hexToRGBA(props.$color, 0.25) : hexToRGBA(props.$color, 0.45))};
    }
  }
`;

type IProps = {
  iw: number;
  ih: number;
  color: string;
  region: IAnnotation;
  drawLineMode: boolean;
  boxHoverType: boolean;
  cfg: IPfdCfg;
  onMouseDown(e, idx?: number): void;
};

function Box({iw, ih, color, region, drawLineMode, boxHoverType, onMouseDown, cfg}: IProps) {
  const handleMouseDown = useCallback(
    (e: MouseEvent<SVGGElement>) => {
      onMouseDown(e);
    },
    [onMouseDown]
  );

  return (
    <g
      transform={`translate(${region?.x * iw} ${region?.y * ih})`}
      onMouseDown={handleMouseDown}
      className="annotation"
    >
      {region?.labelVisible && cfg?.isDisplayLabels && (
        <text
          x={Math.max(region?.w * iw, 0) / 2}
          y={-Math.floor(iw * 0.007)}
          fill={color}
          fontSize={Math.floor(iw * 0.014)}
          fontWeight={700}
          textAnchor="middle"
          className="annot-label"
        >
          {region?.name}
        </text>
      )}
      <BoxRect
        strokeWidth={1}
        x={0}
        y={0}
        width={Math.max(region?.w * iw, 0)}
        height={Math.max(region?.h * ih, 0)}
        stroke={hexToRGBA(color, 0.8)}
        className={classnames(boxHoverType && 'boxHoverType')}
        $color={color}
        $highlighted={region?.highlighted}
        $createMode={drawLineMode}
      />
    </g>
  );
}

export default Box;
