import React, {useState, useEffect, useContext} from 'react';
import {useLocation, Navigate, Outlet, useNavigate} from 'react-router-dom';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {verifyTokenRequest} from 'components/auth/login/login-api-functions';
import AppMenu from 'components/menu/AppMenu';
import {CommonContext} from 'components/common/CommonProvider';

type IAuthorized = true | false | null;

function ProtectedRoute() {
  const {showSpinner, hideSpinner} = useContext(CommonContext);
  const [authorized, setAuthorized] = useState<IAuthorized>(null); // initially undefined!
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const authorize = () => {
      showSpinner({});
      const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;
      if (token) {
        verifyTokenRequest(token).then((isValidate) => {
          setAuthorized(isValidate);
          if (!isValidate) {
            LocalStorageManager.removeItem('PROCESSMETAVERSE_LOGIN_TOKEN');
            navigate('/login');
          }
          hideSpinner();
        });
      } else {
        setAuthorized(false);
        hideSpinner();
      }
    };
    authorize();
  }, [location]);

  if (authorized === null) {
    return <></>;
  }

  return authorized ? (
    <>
      <AppMenu />
      <Outlet />
    </>
  ) : (
    <Navigate to="/login" replace state={{from: location}} />
  );
}
export default ProtectedRoute;
