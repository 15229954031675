import React, {ReactNode} from 'react';
import styled from 'styled-components';

type IProps = {
  children: ReactNode;
};

const Container = styled.div`
  width: 100%;
  height: calc(100% - 211px);
  display: flex;
`;
function PythonEditorBodyBottom({children}: IProps) {
  return <Container>{children}</Container>;
}

export default PythonEditorBodyBottom;
