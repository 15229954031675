import {ReactElement, useContext} from 'react';
import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

type IProps = {
  keys: string[] | string;
  title: string;
  value: number | undefined;
  field?: ITableField;
};

function SignificantValue({keys, title, value, field}: IProps): ReactElement {
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  return <Container>{toSignificantDigit(value, globalSettings.significantDigit)}</Container>;
}

export default SignificantValue;
