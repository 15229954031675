import {useState} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

const Container = styled.div`
  display: none;
  &.visible {
    display: block;
    pointer-events: none;
  }
`;

const Label = styled.div`
  padding: 3px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 10px 10px 10px 0;
  border-left: 5px solid rgb(190, 255, 181);
  color: #ffffff;
  height: 15px;
  width: 62px;
  text-align: center;
  vertical-align: middle;
  line-height: 14px;
  font-size: 12px;
  position: relative;

  &.equipment {
    background-color: rgba(22, 122, 42, 0.85);
  }

  &.streams {
    background-color: rgba(198, 89, 11, 0.85);
    border: none;
    border-radius: 50%;
    width: 0;
    height: 0;
    font-size: 3px;
    transform: scale(4);

    span {
      position: absolute;
      top: -4px;
      left: -6.75px;
      width: 20px;
      height: 20px;
      display: block;
    }
  }
`;

const Description = styled.div`
  text-align: left;
  background: rgba(29, 29, 29, 0.75);
  color: #d1d1d1;
  padding: 10px 15px;
  border-radius: 5px;
  width: 200px;
  font-size: 13px;
  cursor: pointer;
`;

type IProps = {
  visible: boolean;
  type: string;
  title: string;
  description: string;
};

function ThreeLandscapeLabel({title, description, visible, type}: IProps) {
  const [isActive, setIsActive] = useState(false);
  let visibleDescription = false;

  return (
    <Container className={classnames({visible}, 'three-label-container')}>
      <Label className={`label ${type}`} onClick={() => setIsActive(!isActive)}>
        <span>{title}</span>
        {isActive && visibleDescription && (
          <Description
            dangerouslySetInnerHTML={{__html: description}}
            onClick={() => setIsActive(!isActive)}
          ></Description>
        )}
      </Label>
    </Container>
  );
}

export default ThreeLandscapeLabel;
