import {ChangeEvent, ReactElement, useContext, useRef, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {useNavigate} from 'react-router-dom';
import {Button, CheckboxForm} from 'components/forms';
import styled from 'styled-components';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {faDiagramProject} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {META_PFD_FILE_EXTENSION, PROCESS_CANVAS_FILE_EXTENSION} from 'components/menu/constants';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';
import {MetaPfdContext} from 'components/mpfd';

const ElementContainer = styled.div`
  width: 450px;
`;
const InputFile = styled.input`
  display: none;
`;
const OpenOptions = styled.div`
  height: 60px;
  margin-top: 10px;

  svg {
    margin-right: 10px;
  }
`;
const FileName = styled.p`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 10px 0;
`;
const Row = styled.div`
  display: flex;
  justify-content: right;
`;
const MessageText = styled.p`
  svg {
    margin-right: 10px;
  }
`;
const ImportedFileName = styled.span`
  margin-right: 10px;
`;
const Message = styled.span`
  color: ${({theme}) => theme.color.info};
`;

function MetaPfdImportModal(): ReactElement {
  const {importModalState, load, importFile, getFileList} = useContext(MetaPfdContext);
  // IFile 이 아닌, JavaScript File 객체임에 주의
  const [targetFile, setTargetFile] = useState<File>();
  const [, setIsShowImportModal] = importModalState;
  const inputFileRef = useRef(null);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [step, setStep] = useState(0);

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onCancel() // todo meta pfd enter key 이미 사용중. 다른 key로 교체 필요
  });

  const onCancel = (): void => {
    setIsShowImportModal(false);
  };

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    const {checked} = e.target;
    setIsChecked(checked);
  };

  const onClickConfirm = (): void => {
    if (step === 0) {
      if (targetFile) {
        importFile(targetFile).then(async (response) => {
          const list = await getFileList();

          if (isChecked) {
            const found = list.find((file) => file._id === response.id);
            if (found) {
              load(found);
              navigate(`/mpfd/${found._id}`);
            }
          }

          setStep(1);
        });
      }
    } else {
      setIsShowImportModal(false);
    }
  };

  const onClickUploadButton = (): void => {
    inputFileRef.current.click();
  };

  const onChangeUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      fr.onload = function () {
        setTargetFile(file);
      };
      fr.readAsDataURL(file);
    }
  };

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onCancel}>Import file</ModalHeader>
      <ModalBody>
        <StepContainer width={560}>
          <StepScroller total={2} step={step}>
            <Step>
              <ElementContainer>
                <Button width="100%" height={40} onClick={onClickUploadButton}>
                  Select Meta Pfd File to import
                  <InputFile
                    type="file"
                    ref={inputFileRef}
                    onChange={onChangeUploadFile}
                    accept={META_PFD_FILE_EXTENSION}
                  />
                </Button>

                <OpenOptions>
                  {targetFile && (
                    <>
                      <FileName>
                        <FontAwesomeIcon icon={faDiagramProject} size="lg" />
                        <span>{targetFile?.name}</span>
                      </FileName>
                      <Row>
                        <CheckboxForm
                          name="open-with-import"
                          checked={isChecked}
                          label="Open after import"
                          onChange={onChangeCheckbox}
                        />
                      </Row>
                    </>
                  )}
                </OpenOptions>
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <MessageText>
                  <FontAwesomeIcon icon={faDiagramProject} size="lg" />
                  <ImportedFileName>{targetFile?.name}</ImportedFileName>
                  <Message>Import successful.</Message>
                </MessageText>
                {!isChecked && <p>You can find the imported file in the Layout menu on the left.</p>}
              </ElementContainer>
            </Step>
          </StepScroller>
        </StepContainer>
      </ModalBody>
      <ModalFooter>
        <Button
          className="modal-btn"
          variant="primary"
          size="sm"
          onClick={onClickConfirm}
          disabled={!Boolean(targetFile)}
        >
          {step === 0 ? 'Import' : 'Confirm'}
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onCancel}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default MetaPfdImportModal;
