import {
  Dispatch,
  DragEvent,
  DragEventHandler,
  MouseEvent,
  SetStateAction,
  useCallback,
  useContext,
  useState
} from 'react';
import {addEdge, applyNodeChanges, Connection, Edge, MarkerType, Node, NodeChange, useReactFlow} from 'reactflow';
import {pcHooks} from 'utils/processCanvas-functions';
import {getHelperLines} from 'components/pc/helper-lines/helper-lines-utils';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {OnConnect, OnNodesChange} from '@reactflow/core/dist/esm/types/general';
import {NodeDragHandler, NodeMouseHandler} from '@reactflow/core/dist/esm/types/nodes';
import {IMPfdData} from 'api/data-types';
import {localDatabaseRelatedWidgetTypes} from 'api/LocalDatabaseProvider';

export function useCanvasHandlers(
  nodes: Node[],
  edges: Edge[],
  setNodes: Dispatch<SetStateAction<Node<any, string>[]>>,
  setEdges: Dispatch<SetStateAction<Edge<any>[]>>,
  setChanges: (changes: any) => void
) {
  const reactFlow = useReactFlow();
  const {file, onCanvasChange, hasSmartAlignState} = useContext(ProcessCanvasContext);
  const {dragItem, onDragOverSettingZoneId} = useContext(CommonContext);
  const [horizontalHelperLine, setHorizontalHelperLine] = useState<number | undefined>(undefined);
  const [verticalHelperLine, setVerticalHelperLine] = useState<number | undefined>(undefined);
  const [hasSmartGuide] = hasSmartAlignState;

  const onDragOver: DragEventHandler = (evt: DragEvent<HTMLDivElement>) => {
    dragItem && onDragOverSettingZoneId('canvas');
    !dragItem && evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };

  const onNodeClick: NodeMouseHandler = (event: MouseEvent, node: Node) => {
    const newNodes = pcHooks.onNodeClick(node, nodes);
    setNodes(newNodes);
    const newEdges = pcHooks.onNodeZIndexChangedEdgeZIndexCalc(newNodes, node, edges);
    setEdges(newEdges);
  };

  const onNodeContextMenu: NodeMouseHandler = (event: MouseEvent, node: Node) => {
    const newNodes = pcHooks.onNodeClick(node, nodes);
    setNodes(newNodes);
    const newEdges = pcHooks.onNodeZIndexChangedEdgeZIndexCalc(newNodes, node, edges);
    setEdges(newEdges);
  };

  const customApplyHelperLineToNodeChange = useCallback((changes: NodeChange[], nodes: Node[]): Node[] => {
    setHorizontalHelperLine(undefined);
    setVerticalHelperLine(undefined);
    if (changes.length === 1 && changes[0].type === 'position' && changes[0].dragging && changes[0].position) {
      const helperLines = getHelperLines(changes[0], nodes);
      changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
      changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;
      setHorizontalHelperLine(helperLines.horizontal);
      setVerticalHelperLine(helperLines.vertical);
    }
    return applyNodeChanges(changes, nodes);
  }, []);

  const customDimensionNodeChanges = (changes: NodeChange[], nodes: Node[]): Node[] => {
    if (changes[0]?.type === 'dimensions') {
      const dimNode = reactFlow.getNode(changes[0]?.id);
      if (dimNode?.type === 'MetaPfdWidget') {
        const dim = changes[0]?.dimensions;
        if (dimNode?.type === 'MetaPfdWidget' && dimNode?.data?.mPfdFileData?.stateData && changes[0]?.dimensions) {
          const mPfdData = dimNode.data.mPfdFileData.stateData as IMPfdData;
          const imageRatio = mPfdData.imageInfo.naturalHeight / mPfdData.imageInfo.naturalWidth;
          const w =
            mPfdData?.imageInfo?.naturalWidth / 2 < dim?.width ? dim?.width : mPfdData?.imageInfo?.naturalWidth / 2;
          const calculatedHeight = imageRatio * w + 40 + 42;

          /**
           * MIGRATION-1 :
           * data : {stateData: IMPfdData} :2.12 version
           * data : {mPfdFileData : {stateData: IMPfdData}} : 2.13 version
           */
          if (isNaN(w) || isNaN(calculatedHeight)) {
          } else {
            changes[0].dimensions.width = w;
            changes[0].dimensions.height = calculatedHeight;
          }
        }
      }
    }
    return applyNodeChanges(changes, nodes);
    // }
  };

  const onNodeChangeClosure: OnNodesChange = (changes: NodeChange[]) => {
    setNodes(function (nodes) {
      setChanges((oldChanges) => [...changes, ...oldChanges].slice(0, 20));
      const newEdges = pcHooks.onNodeChangeHandlingEdgesPosition(changes, nodes, edges);
      if (newEdges) {
        setEdges(newEdges);
      }
      const isChangedFlag = pcHooks.onNodeChangeHandlingIsChangedFlag(file, changes);
      if (isChangedFlag) {
        onCanvasChange();
      }
      let nodesTemp = nodes?.length > 0 ? [...nodes] : nodes;
      if (hasSmartGuide) {
        nodesTemp = customApplyHelperLineToNodeChange(changes, nodesTemp);
      }
      if (changes[0]?.type === 'dimensions') {
        nodesTemp = customDimensionNodeChanges(changes, nodesTemp);
        if (!nodesTemp) return nodes;
      } else {
        nodesTemp = applyNodeChanges(changes, nodesTemp);
      }

      return nodesTemp;
    });
    // setNodes((nodes) => applyNodeChanges(changes, nodes));
  };

  // const afterEdgeUpdate = () => {
  //   const subscript = {};
  //   for (let i = 0; i < edges?.length; i++) {
  //     const edge = edges[i] as Edge;
  //     const sourceNodeId = edge.source;
  //     const targetNodeId = edge.target;
  //
  //     const sourceNode = reactFlow.getNode(sourceNodeId);
  //     const targetNode = reactFlow.getNode(targetNodeId);
  //
  //     if (
  //       localDatabaseRelatedWidgetTypes.includes(targetNode?.type) &&
  //       localDatabaseRelatedWidgetTypes.includes(sourceNode?.type)
  //     ) {
  //       const targetSubscript = subscript[targetNodeId];
  //       if (targetSubscript) {
  //         subscript[targetNodeId] = [...targetSubscript, sourceNodeId];
  //       } else {
  //         subscript[targetNodeId] = [sourceNodeId];
  //       }
  //     }
  //     renewLocalDatabaseSubscriptionEntire(subscript);
  //   }
  // }
  //
  // const onConnect: OnConnect = (params: Connection) => {
  //   const targetNode = reactFlow.getNode(params.target);
  //   const sourceNode = reactFlow.getNode(params.source);
  //   if (
  //     localDatabaseRelatedWidgetTypes.includes(targetNode.type) &&
  //     localDatabaseRelatedWidgetTypes.includes(sourceNode.type)
  //   ) {
  //     reactFlow.setEdges((eds) =>
  //       addEdge(
  //         {
  //           ...params,
  //           type: 'buttonEdge',
  //           style: {stroke: '#00616e', strokeWidth: 5},
  //           markerEnd: {
  //             type: MarkerType.Arrow,
  //             color: '#00616e',
  //             width: 10,
  //             height: 10,
  //             strokeWidth: 2
  //           }
  //         },
  //         eds
  //       )
  //     );
  //   }
  //   else if (targetNode.type === 'MetaPfdWidget' && sourceNode.type === 'MetaPfdWidget') {
  //     const refinedParams = pcHooks.onConnect(params);
  //     const {target, source} = params;
  //     const zIndexArr = nodes
  //       .filter((item) => item.id === target || item.id === source)
  //       .map((item) => item.zIndex)
  //       .sort();
  //     const edgeZIndex = zIndexArr?.[1] + 1 || 1;
  //     onCanvasChange();
  //     reactFlow.setEdges((eds) =>
  //       addEdge(
  //         {
  //           ...refinedParams,
  //           type: 'buttonEdge',
  //           zIndex: edgeZIndex,
  //           style: {stroke: '#00616e', strokeWidth: 5, zIndex: edgeZIndex},
  //           markerEnd: {
  //             type: MarkerType.Arrow,
  //             color: '#00616e',
  //             width: 10,
  //             height: 10,
  //             strokeWidth: 2
  //           }
  //         },
  //         eds
  //       )
  //     );
  //   }
  //
  //   afterEdgeUpdate()
  //
  // };

  return {
    onDragOver,
    onNodeClick,
    onNodeContextMenu,
    onNodeChangeClosure,
    horizontalHelperLine,
    verticalHelperLine
  };
}
