import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import FormRow from 'components/forms/FormRow';
import {Button} from 'components/forms';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {ICopilotSettings} from 'components/menu/copilot/CopilotSettingModal';
import {LocalStorageManager} from 'utils/local-storage-manager';
import styled from 'styled-components';
import SelectForm from 'components/forms/SelectForm';
import DatePicker, {DateObject} from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
import {ITimestampRangeState} from 'components/pc/widgets/timeseries/types';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import Badge from 'components/forms/Badge';

const SwitchWrap = styled.div``;

const DatePickerWrap = styled.div`
  input {
    width: 100%;
  }
`;

const SelectNodeBtnWrap = styled.div`
  margin-bottom: 15px;
  width: 100%;
  .select-node-btn {
    width: 100%;
  }
  svg {
    margin-right: 5px;
  }
`;

const NodeListContainer = styled.div`
  display: flex;
  /*  max-height: 240px;
  min-height: 240px;
  flex: 1;
  border: 1px solid #171523;
  align-items: center;*/
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  height: 30px;
  width: 100%;
  max-height: 30px;
  background-color: #5556a9;
  color: #b0b1ea;
  white-space: nowrap;
  top: -2px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
`;

const SelectNodeList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 290px;
  //overflow-y: auto;
  overflow: auto;
`;

const SelectNodeItem = styled.div`
  display: flex;
`;

export type ICopilotNewChatSettings = {
  dataList: string[];
};

// data range 형태 변환 함수
export const generateMonthlyArray = (startPoint: number, endPoint: number) => {
  const startYear = Math.floor(startPoint / 100);
  const startMonth = startPoint % 100;
  const endYear = Math.floor(endPoint / 100);
  const endMonth = endPoint % 100;

  const result = [];

  for (let year = startYear; year <= endYear; year++) {
    const start = year === startYear ? startMonth : 1;
    const end = year === endYear ? endMonth : 12;

    for (let month = start; month <= end; month++) {
      result.push(`${year}${String(month).padStart(2, '0')}`);
    }
  }
  return result;
};

const dateToString = (startPoint: number, endPoint: number): [string, string] => {
  const startString = String(startPoint);
  const endString = String(endPoint);
  return [startString, endString];
};

const defaultSettings: ICopilotSettings = {dataIsOn: false};

const dateUnit = ['select', 'minutes', 'hours', 'days', 'months', 'years'] as const;
type NumUnit = (typeof dateUnit)[number];

type IProps = {
  onClose(): void;
};

function NewChatModal({onClose}: IProps) {
  const {setIsOn, checkAvailableDateRange, setDataList, isWaiting, changeDataRange, dataList, dataRange} =
    useContext(CopilotContext);

  const savedSettings = LocalStorageManager.getItem<ICopilotSettings>('COPILOT_SETTINGS') || defaultSettings;
  const [settings, setSettings] = useState<ICopilotSettings>(savedSettings);
  const [selectedStartDate, setSelectedStartDate] = useState<number>();
  const [selectedEndDate, setSelectedEndDate] = useState<number>();

  const [selectedNodes, setSelectedNodes] = useState<string[][]>([]);
  const [isShowNodeSelector, setIsShowNodeSelector] = useState(false);

  const [numUnit, setNumUnit] = useState<NumUnit>('select');
  const now = new DateObject();
  const end = now.unix;
  const start = now.subtract(7, 'day').unix;
  const rangeState = useState<number[]>([start, end]);
  const [range, setRange] = rangeState;

  useEffect(() => {
    checkAvailableDateRange();
  }, []);

  useEffect(() => {
    setDataList(dateToString(selectedStartDate, selectedEndDate));
  }, [selectedStartDate, selectedEndDate]);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  /*  const msg = {
    time_range: [1723007516, 1723076754],
    node_infos: [{node: ['FCC', 'E19FC305'], database: 'plant_fcc'}]
  };*/

  const onConfirm = () => {
    LocalStorageManager.setItem('COPILOT_SETTINGS_NEWCHAT', settings);
    // setIsOn(settings.dataIsOn);
    setDataList(dataList);

    // console.log(dataList);

    if (isWaiting === false) {
      changeDataRange(); // todo : ()안에 인자 값으로 start select date 전달
      // changeDataRange(msg); // todo : ()안에 인자 값으로 start select date 전달
      // changeDataRange(dataList); // todo : ()안에 인자 값으로 start select date 전달
    }
    /*    const r = rawData as unknown;
    console.log(JSON.parse(r as string));*/ // todo: rawData json.parse 처리 필요
    onClose();
  };

  /*const onToggle = (name: string, value: boolean) => {
    const changed = {
      ...settings,
      [name]: value // 동적 key 할당
    };
    setSettings(changed);
  };*/

  const startRange = parseFloat(dataRange?.[0]);
  const endRange = parseFloat(dataRange?.[1]);

  //todo: 202401을 available_data_ranged return 배열[0]으로 변경

  //todo: selectedStartDate가 selectedEndDate보다 큰 경우는 conFirm을 막거나 선택지 변화 주기
  const dateOptions = generateMonthlyArray(startRange, endRange).map((date) => ({
    value: date,
    label: `${date.slice(0, 4)}-${date.slice(4)}`
  }));

  useEffect(() => {
    if (dateOptions.length > 0 && selectedStartDate === undefined && selectedEndDate === undefined) {
      setSelectedStartDate(Number(dateOptions[0].value));
      setSelectedEndDate(Number(dateOptions[dateOptions.length - 1].value));
    }
  }, [dateOptions, selectedStartDate, selectedEndDate]);

  const onChangeStartDateType = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedStartDate(Number(e.target.value));
  };

  const onChangeEndDateType = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedEndDate(Number(e.target.value));
  };

  // const disabled = settings.dataIsOn === false ? true : false;

  const startOptions = dateOptions.filter((option) => Number(option.value) <= selectedEndDate);
  const endOptions = dateOptions.filter((option) => Number(option.value) >= selectedStartDate);

  /*  const onChangeRange = (range: DateObject[]): void => {
    const [start, end] = range;
    if (start && end) {
      console.log(
        'change range\n',
        new DateObject(start.unix * 1000).toDate(),
        '\n',
        new DateObject(end.unix * 1000).toDate()
      );
      // DatePicker 에 적용하고, 다른 객체에도 전달
      setRange([start.unix, end.unix]);
      // select 를 리셋
      setNumUnit('select');
    }
  };*/

  const onSelectNode = (checkedList: string[]): void => {
    const parsed = checkedList.map((item) => JSON.parse(item));
    // console.log('>>> checkedList', checkedList, parsed);
    setSelectedNodes(parsed);
  };

  console.log(selectedNodes);

  const noSelectNodes = selectedNodes.length === 0;

  return (
    <ModalWrapper type="md">
      <ModalHeader onClose={onClose}>New Chat Settings</ModalHeader>
      <ModalBody align="top" size="full-width">
        {/* <FormRow header="Chat with data">
          <SwitchWrap>
            <DataModeToggle name="dataIsOn" dataIsOn={settings.dataIsOn} onToggle={onToggle} />
          </SwitchWrap>
        </FormRow>*/}
        {/*<FormRow header="Set the time range" type="double">
          <SelectForm
            name="Start date type"
            options={startOptions}
            onChange={onChangeStartDateType}
            value={selectedStartDate}
            disabled={startOptions.length === 0 ? true : false}
          />
          ~{' '}
          <SelectForm
            name="End date type"
            options={endOptions}
            onChange={onChangeEndDateType}
            value={selectedEndDate}
            disabled={endOptions.length === 0 ? true : false}
          />
        </FormRow>*/}
        {/*  <DatePickerWrap>
          <DatePicker
            value={range?.map((t) => t * 1000)}
            range
            inputClass="date-picker-input"
            format="YYYY-MM-DD HH:mm:ss"
            showOtherDays
            numberOfMonths={numUnit === 'months' || numUnit === 'years' ? 2 : 1}
            // calendarPosition={direction}
            plugins={[<TimePicker position="bottom" />]}
            onChange={onChangeRange}
          />
        </DatePickerWrap>*/}
        {/*  <SelectNodeBtnWrap>
          <Button className="select-node-btn" variant="dark" height={34} onClick={() => setIsShowNodeSelector(true)}>
            <FontAwesomeIcon icon={faTags} color="#aaa" size="xl" />
            <span>Select Nodes</span>
          </Button>
        </SelectNodeBtnWrap>*/}
        <NodeListContainer>
          {/*   <Header>Select Node List</Header>
          <SelectNodeList className="thin-scrollbar md">
            {selectedNodes.map((item) => (
              // <Badge type="public">
              <SelectNodeItem>{item.join('.')}</SelectNodeItem>
              // </Badge>
            ))}
            {noSelectNodes && <span>No Selected Nodes</span>}
          </SelectNodeList>*/}
          <span>The Node Selection feature is currently under development.</span>
        </NodeListContainer>

        {isShowNodeSelector && (
          <NodeSelectorRevision
            title="Select Nodes"
            selectedNodes={selectedNodes}
            onClose={() => setIsShowNodeSelector(false)}
            onSelect={onSelectNode}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onConfirm}>
          Create new chat
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default NewChatModal;
