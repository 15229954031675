import React, {MouseEvent, ReactElement, useContext, useEffect, useState} from 'react';
import {DataContext} from 'api/DataProvider';
import styled from 'styled-components';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFrame, faTags} from '@fortawesome/pro-regular-svg-icons';
import Tooltip from 'components/common/Tooltip';

const Container = styled.div`
  width: 200px;
  height: calc(100% - 40px);
  transition: left 0.2s;
  background-color: #f0f0f0;
  position: absolute;
  top: 40px;
  left: -200px;
  z-index: 100000000;
  display: flex;
  flex-direction: column;
  cursor: default;

  &.show {
    left: 0;

    .close-icon {
    }
  }
`;
const InnerContainer = styled.div`
  width: 100%;
  height: calc(100% - 31px);
  overflow: auto;
  flex: 1;
`;
const MenuList = styled.ul`
  display: none;

  &.active {
    display: block;
  }
`;
const MenuItem = styled.li`
  padding: 5px 10px;
  font-size: 15px;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &.selected {
    background-color: rgba(0, 0, 166, 0.1);
  }
`;
const MenuHeader = styled.div`
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  flex-shrink: 0;
  padding: 0 10px;
  // color: ${({theme}) => theme.color.optional};
`;
const TitleText = styled.h3`
  margin: 0;
  font-weight: 400;
`;
const ButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
  gap: 10px;

  > svg {
    cursor: pointer;
  }
`;
const MenuTabs = styled.div`
  background-color: #e5e0e0;
  width: 100%;
  display: flex;
  font-size: 14px;
  flex-shrink: 0;
  box-shadow:
    0 -6px 6px rgba(0, 0, 0, 0.03),
    0 -1px 2px rgba(0, 0, 0, 0.1);
`;
const MenuTab = styled.div`
  padding: 0 10px;
  width: 50%;
  height: 30px;
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.color.optional};
  border-top: 1px solid #eee;
  justify-content: center;

  &.active {
    background-color: #fff;
    color: ${({theme}) => theme.color.info};
  }
`;
const MenuBody = styled.div`
  width: 200px;
  height: calc(100% - 30px);
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;

type IProps = {
  streamVisibility: any;
  blockVisibility: any;
  isShowLabel: boolean;
  isShowWireFrame: boolean;
  onToggleStream(name: string): void;
  onToggleBlock(name: string): void;
  onToggleWireFrame(): void;
  onToggleShowLabel(): void;
};

function LeftMenu({
  blockVisibility,
  streamVisibility,
  isShowWireFrame,
  isShowLabel,
  onToggleBlock,
  onToggleStream,
  onToggleWireFrame,
  onToggleShowLabel
}: IProps): ReactElement {
  const {modelHierarchy} = useContext(DataContext);
  const [isShow, setIsShow] = useState(true);
  const [title, setTitle] = useState();
  // const blockButtons = ['01', '02', '03', '04', '05', '06', '07'];
  // const streamButtons = ['01', '02', '03'];

  const [streamList, setStreamList] = useState([]);
  const [blockList, setBlockList] = useState([]);

  useEffect(() => {
    if (!Array.isArray(modelHierarchy)) return;

    const [hierarchy] = modelHierarchy;
    if (!hierarchy) return;

    const [streams, blocks] = hierarchy.subnode;
    setTitle(hierarchy.name);
    setStreamList(streams.subnode);
    setBlockList(blocks.subnode);
  }, [modelHierarchy]);

  const onClickBlock = (item: string) => {
    onToggleBlock(item);
  };

  const onClickStream = (item: string) => {
    onToggleStream(item);
  };

  const onClickShowButton = (): void => {
    console.log('>>>> ');
    setIsShow(!isShow);
  };
  const onWheel = (e) => {
    console.log('>>>. e', e);
    e.stopPropagation();
  };

  const [activeTabName, setActiveTabName] = useState('streams');
  const onClickTab = (e: MouseEvent<HTMLDivElement>): void => {
    const {title} = e.currentTarget;
    setActiveTabName(title);
  };
  const [selectedItem, setSelectedItem] = useState<string>();

  const onSelectItem = (type: 'streams' | 'blocks', name: string): void => {
    setSelectedItem(name);
    if (type === 'blocks') {
      onClickBlock(name);
    } else if (type === 'streams') {
      onClickStream(name);
    }
  };

  return (
    <Container className={classNames('nowheel nodrag', {show: isShow})}>
      {/*<CloseButton
        className={classNames('close-icon', {show: isShow})}
        icon={isShow ? faChevronRight : faChevronLeft}
        onClick={onClickShowButton}
      />*/}
      <MenuHeader>
        <TitleText> {title}</TitleText>

        <ButtonGroup>
          <Tooltip content="Show Wireframe">
            <FontAwesomeIcon icon={faFrame} color={isShowWireFrame ? '#000' : '#999'} onClick={onToggleWireFrame} />
          </Tooltip>
          <Tooltip content="Show Label">
            <FontAwesomeIcon icon={faTags} color={isShowLabel ? '#000' : '#999'} onClick={onToggleShowLabel} />
          </Tooltip>
        </ButtonGroup>
      </MenuHeader>
      <MenuBody>
        <MenuTabs>
          <MenuTab title="streams" className={activeTabName === 'streams' && 'active'} onClick={onClickTab}>
            Streams
          </MenuTab>
          <MenuTab title="blocks" className={activeTabName === 'blocks' && 'active'} onClick={onClickTab}>
            Blocks
          </MenuTab>
        </MenuTabs>
        <InnerContainer className="thin-scrollbar md">
          <MenuList className={activeTabName === 'streams' && 'active'}>
            {streamList.map((stream) => (
              <MenuItem
                key={stream.name}
                title={stream.name}
                className={selectedItem === stream.name && 'selected'}
                onClick={() => onSelectItem('streams', stream.name)}
              >
                {stream.name}
              </MenuItem>
            ))}
          </MenuList>
          <MenuList className={activeTabName === 'blocks' && 'active'}>
            {blockList?.map((block) => (
              <MenuItem
                key={block.name}
                title={block.name}
                className={selectedItem === block.name && 'selected'}
                onClick={() => onSelectItem('blocks', block.name)}
              >
                {block.name}
              </MenuItem>
            ))}
          </MenuList>
        </InnerContainer>
      </MenuBody>
    </Container>
  );
}

export default LeftMenu;
