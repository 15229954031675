import {IAnnotation, AnnotationTypes, IDetectedLine, IPfdState, IPfdAction} from 'components/mpfd/type';
import {api} from 'api/api';
import {getUniqueKey} from 'utils/commons';
import {IApiReturnBasic, IFile} from 'api/data-types';
import {compose, rotate, scale, translate} from 'transformation-matrix';
import {getApiHost} from 'api/function';
import {annotation} from 'components/mpfd/hooks/functions/action-calculation-functions';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
// const API_HOST = process.env.REACT_APP_API_HOST;
const API_HOST = getApiHost();

export const getMatchNumber = (str: string, type: AnnotationTypes) => {
  const regex = /^(.*?)-(\d+)$/;
  if (!str) {
    switch (type) {
      case 'box':
        return {name: 'region', id: 0};
      case 'point':
        return {name: 'point', id: 0};
      case 'expanding-line':
        return {name: 'line', id: 0};
      case 'port':
        return {name: 'port', id: 0};
      default:
        return {name: 'unknown', id: 0};
    }
  }
  const match = str.match(regex);

  if (match) {
    return {name: match[1], id: parseInt(match[2])};
  } else {
    return {name: str, id: 0};
  }
};

export function mergeCommonArrays(arr) {
  let result = [];
  let origin = [...arr];
  if (arr?.length === 0) {
    return result;
  }

  while (arr.length > 0) {
    let first = arr.pop();
    let rest = arr.filter((set) => !set.some((val) => first.some((i) => i.x === val.x && i.y === val.y)));
    let merged = arr
      .filter((set) => set.some((val) => first.some((i) => i.x === val.x && i.y === val.y)))
      .reduce((acc, set) => acc.concat(set), first);
    result.push(merged);
    arr = rest;
  }

  // minimize 안될때까지 반복
  if (origin?.length !== result?.length) {
    return mergeCommonArrays([...result]);
  } else {
    return result;
  }
}

type polyLineInfo = {
  polyLineString: string;
  isArrow: boolean;
  startArrow: boolean;
  endArrow: boolean;
};

export function convertPoint2String(r: IAnnotation, iw, ih) {
  const {points, candidatePoint} = r;

  const arrowPoints = [...(r?.arrowPoints || [])];
  const pointsArray = points.concat(candidatePoint ? [candidatePoint] : []);
  const pointStringList = pointsArray.map((p) => `${p.x * iw} ${p.y * ih}`);
  const polyLineList: polyLineInfo[] = [];
  const uniquePointList = [];

  const startPointsArray = pointsArray.filter((item, idx) => idx % 2 === 0);
  const endPointsArray = pointsArray.filter((item, idx) => idx % 2 === 1);

  for (let i = 0; i < pointsArray?.length; i = i + 2) {
    const startP = pointsArray[i];
    const endP = pointsArray[i + 1];
    let isArrow = false;
    let polyLineString = pointStringList[i] + ' ' + pointStringList[i + 1];
    let startArrow = false;
    let endArrow = false;
    // 연결되어있지 않으면서 시작점인경우
    if (
      !endPointsArray.some((p) => p?.x === startP?.x && p?.y === startP?.y) &&
      arrowPoints.some((item) => item.x === startP.x && item.y === startP.y)
    ) {
      // polyLineString = pointStringList[i + 1] + ' ' + pointStringList[i];
      isArrow = true;
      startArrow = true;
    }
    // 연결되어있지않으면서 끝점인경우
    if (
      !startPointsArray.some((p) => p?.x === endP?.x && p?.y === endP?.y) &&
      arrowPoints.some((item) => item.x === endP.x && item.y === endP.y)
    ) {
      isArrow = true;
      endArrow = true;
    }

    polyLineList.push({isArrow, polyLineString, startArrow, endArrow});
  }
  for (let i = 0; i < points.length; i++) {
    if (!uniquePointList.some((item) => item.x === points[i].x && item.y === points[i].y)) {
      uniquePointList.push(points[i]);
    }
  }
  return [pointStringList, polyLineList, uniquePointList];
}

export function getModeInfo(mode, region: IAnnotation) {
  let pointMoveMode = false;
  let drawExpandingLine = false;
  let annotationCircle = undefined;
  let lineGroupIdx = -1;
  let GroupOfLine = [];
  let GroupOfLineTemp = [];
  let moveTargetIsEndPoint = false;
  let {points} = region;
  let mergeCandidatePointBorderColor = '#ee5c84';
  let annotationCircleBorderColor = '#ee5c84';

  switch (mode?.mode) {
    case 'MOVE_POINT_OF_LINE': {
      const {regionId, targetPointsIdx, annotationPointCenter, moveTargetPointIsEndPoint} = mode;
      // const targetRegion = annotation
      pointMoveMode = true;
      moveTargetIsEndPoint = moveTargetPointIsEndPoint;
      let gli = 0;
      while (gli < points.length - 1) {
        if (points.length === 0) break;
        const p1 = points[gli];
        const p2 = points[gli + 1];
        if (gli === 0) {
          GroupOfLineTemp.push([p1, p2]);
          gli = gli + 2;
        } else {
          const lineGroupIdx = GroupOfLineTemp.findIndex((item) =>
            item.some((p) => (p.x === p1.x && p.y === p1.y) || (p.x === p2.x && p.y === p2.y))
          );

          if (lineGroupIdx === -1) {
            GroupOfLineTemp.push([p1, p2]);
          } else {
            GroupOfLineTemp[lineGroupIdx] = GroupOfLineTemp[lineGroupIdx].concat([p1, p2]);
          }
          gli = gli + 2;
        }
      }
      GroupOfLine = mergeCommonArrays(GroupOfLineTemp);

      if (region?.id === regionId) {
        if (targetPointsIdx.length > 0) {
          lineGroupIdx = GroupOfLine.findIndex((item) =>
            item.some((p) => p?.x === points[targetPointsIdx[0]].x && p?.y === points[targetPointsIdx[0]].y)
          );
        }
        // 하나만 렌더링
        if (annotationPointCenter) {
          annotationCircle = annotationPointCenter;
        }
      } else {
        mergeCandidatePointBorderColor = '#29b6f2';
      }

      if (mode?.mergeTargetPointAnnotId && mode?.mergeTargetPointAnnotId !== region?.id) {
        annotationCircleBorderColor = '#29b6f2';
      }

      break;
    }
    case 'DRAW_EXPANDING_LINE': {
      drawExpandingLine = true;
      break;
    }
    default:
  }
  return {
    pointMoveMode,
    drawExpandingLine,
    annotationCircle,
    lineGroupIdx,
    GroupOfLine,
    moveTargetIsEndPoint,
    mergeCandidatePointBorderColor,
    annotationCircleBorderColor
  };
}

export function getMouseHoverType(selectedTool: string) {
  let point = false;
  let box = false;
  let polyLine = false;
  let pointOfLine = false;
  switch (selectedTool) {
    case 'select': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'pan': {
      point = false;
      box = false;
      polyLine = false;
      pointOfLine = false;
      break;
    }
    case 'create-point': {
      point = false;
      box = false;
      polyLine = false;
      pointOfLine = false;
      break;
    }
    case 'create-box': {
      point = false;
      box = false;
      polyLine = false;
      pointOfLine = false;
      break;
    }
    case 'create-expanding-line': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'line-detection': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'line-eraser': {
      point = true;
      box = true;
      polyLine = true;
      pointOfLine = false;
      break;
    }
    case 'line-splitter': {
      point = false;
      box = false;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    case 'line-splitter-2': {
      point = false;
      box = false;
      polyLine = true;
      pointOfLine = true;
      break;
    }
    default:
  }
  return {pointHoverType: point, boxHoverType: box, polyLineHoverType: polyLine, pointOfLineHoverType: pointOfLine};
}

export function removeFileExtension(filename: string) {
  if (!filename) {
    return '';
  }
  return filename.replace(/\.[^/.]+$/, '');
}

export const getDetectLine = async (state: IPfdState, xP: number, yP: number) => {
  const {detectedFileName, viewport, detectedLines} = state.detectionInfo;
  const {width, height} = viewport;
  const x = xP * width;
  const y = yP * height;
  return api.post<IDetectedLine>(`/line_detection/click/${detectedFileName}/${x}/${y}`, {}).then((res) => {
    if (res.success) {
      const {line_data: data, line_number: number} = res.data;
      const filteredData = [];
      for (let i = 0; i < data.length; i++) {
        filteredData.push(data[i][0]);
        filteredData.push(data[i][1]);
      }
      // let n = number ||
      const p = filteredData.map((item) => ({x: item[0] / width, y: item[1] / height}));

      console.log(state.regions);
      console.log(state.detectionInfo.detectedLines);

      let newLine;
      if (number) {
        newLine = {
          type: 'expanding-line' as AnnotationTypes,
          unfinished: false,
          points: p,
          // open: true,
          // isDetected: true,
          highlighted: true,
          color: '#EE5C84',
          cls: 'Soft Sensor',
          id: getUniqueKey() + '-' + number,
          name: number,
          editingLabels: false,
          labelVisible: true,
          isShowTableHeader: true,
          chartPosition: {
            w: 0.1,
            h: 0.1
          },
          tablePosition: {
            w: 0.1,
            h: 0.1
          },
          metaPfdTagInfo: []
        };
      } else {
        const {newAnnotName, newAnnotNum} = annotation.getAnnotName(state.regions, 'line');
        newLine = {
          type: 'expanding-line' as AnnotationTypes,
          unfinished: false,
          points: p,
          // open: true,
          // isDetected: true,
          highlighted: true,
          color: '#EE5C84',
          cls: 'Soft Sensor',
          id: getUniqueKey() + '-' + number,
          name: newAnnotName + '-' + newAnnotNum,
          editingLabels: false,
          labelVisible: true,
          isShowTableHeader: true,
          chartPosition: {
            w: 0.1,
            h: 0.1
          },
          tablePosition: {
            w: 0.1,
            h: 0.1
          },
          metaPfdTagInfo: []
        };
      }

      // const alreadyDetectedLine = detectedLines.find((item) => {
      //   for (let i = 0; i < p?.length; i++) {
      //     if (item?.points[i]?.x !== p[i]?.x || item?.points[i]?.y !== p[i]?.y) {
      //       return false;
      //     } else if (i === p?.length - 1) {
      //       return true;
      //     }
      //   }
      // });
      //
      // console.log(alreadyDetectedLine);

      let isExistSameLine = false;
      const lines = state.regions.filter((item) => item.type === 'expanding-line');
      for (let i = 0; i < lines?.length; i++) {
        let renderedLine = lines[i];
        if (p?.length !== renderedLine?.points?.length) {
          continue;
        }
        for (let i = 0; i < renderedLine?.points?.length; i++) {
          if (renderedLine?.points?.[i]?.x !== p?.[i]?.x || renderedLine?.points?.[i]?.y !== p?.[i]?.y) {
            break;
          }
          if (i === renderedLine?.points?.length - 1) {
            isExistSameLine = true;
            break;
          }
        }
        if (isExistSameLine) {
          break;
        }
      }

      if (isExistSameLine) {
        return;
      }

      const newDetectedLines = [
        ...state.detectionInfo.detectedLines.map((item: IAnnotation) => ({...item, highlighted: false})),
        newLine
      ] as IAnnotation[];

      return {
        type: 'MODIFY_DETECTION_LINES',
        region: newLine,
        detectionInfo: {detectedLines: newDetectedLines}
      };
    }
  });
};

export const detectTargetFileUpload = async (state: IPfdState) => {
  if (!!state?.detectionInfo?.detectedFileName) {
    // prevent duplicate detection upload action
    return;
  }
  const formData = new FormData();
  let myFile: File;
  if (state.images?.imgFile) {
    myFile = state?.images?.imgFile;
  } else if (state.images?.imgBlob) {
    myFile = new File([state.images.imgBlob], state.images.fileName, {
      type: state.images.imgBlob.type
    });
  }
  formData.append('file', myFile);
  formData.append('data', JSON.stringify({detection_id: state?.detectionInfo?.detectedFileName}));

  return await fetch(API_HOST + '/line_detection/detect_file', {method: 'POST', body: formData})
    .then((res) => res.json())
    .then(function (res) {
      if (res?.success) {
        const data = res?.data;
        const [h, w] = data?.view_port;
        return {
          type: 'INITIALIZE_DETECTION_INFO',
          detectionInfo: {
            viewport: {width: w, height: h},
            detectedFileName: data.detection_id,
            detectedLines: []
          },
          selectedTool: 'line-detection' as ToolIds
        };
      }
    });
};

export const getSaveFileData = async (
  file: IFile,
  state: IPfdState,
  fileName: string,
  isSaveAs: boolean,
  username: string,
  isPublic: boolean
) => {
  const fileData = {
    ...file,
    fileName: fileName || state?.saveName,
    // pidName: '',
    // user: 'Simacro',
    owner: username,
    public: isPublic,
    stateData: {
      regions: state.regions,
      detectionInfo: state?.detectionInfo,
      imageInfo: {
        imageSourceType: state?.images?.imgSourceType,
        naturalWidth: state?.images?.naturalWidth,
        naturalHeight: state?.images?.naturalHeight
      },
      cfg: state?.cfg
    }
  } as IFile;

  if (fileData.localInfo) {
    delete fileData.localInfo;
  }
  if (isSaveAs || fileData._id === 'new-file') {
    delete fileData._id;
  }
  if (!fileName) return;
  const formData = new FormData();
  formData.append('data', JSON.stringify(fileData));

  if (state.images.imgSourceType === 'upload') {
    let myFile: File;
    if (state.images?.imgFile) {
      myFile = state?.images?.imgFile;
    } else if (state.images?.imgBlob) {
      myFile = new File([state.images.imgBlob], state.images.fileName, {
        type: state.images.imgBlob.type
      });
    }
    if (!myFile) return;
    formData.append('file', myFile);
  }
  return api.post<IApiReturnBasic>('/file_manage/save_file', formData, {type: 'formData'});
};

export const getRegionColor = (region: IAnnotation) => {
  let color: string;
  const {cls, type} = region;

  if (type === 'port') {
    return '#19CC81';
  }

  if (cls === undefined) {
    color = '#808080';
  } else if (cls === 'Soft Sensor') {
    color = '#f44336';
  } else if (cls === 'Physical Sensor') {
    color = '#2196f3';
  }
  return color;
};

export const hexToRGBA = (hex: string, opacity: number) => {
  const r = parseInt(hex?.slice(1, 3), 16);
  const g = parseInt(hex?.slice(3, 5), 16);
  const b = parseInt(hex?.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

// export const getDefaultMat = () => Matrix.from(1, 0, 0, 1, -10, -10);
export const getDefaultMat = () => compose(translate(0, 0), rotate(0), scale(1, 1));

export const defaultMat = compose(translate(0, 0), rotate(0), scale(1, 1));

export const findMinValueAndIndex = (arr) => {
  let minValue = Infinity;
  let idx = -1;

  for (let i = 0; i < arr.length; i++) {
    if (arr[i] > 0 && arr[i] < minValue) {
      minValue = arr[i];
      idx = i;
    }
  }

  return minValue === Infinity ? null : {minValue, idx};
};

export const pointDistance = (x1: number, y1: number, x2: number, y2: number) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

const getImg = async (address: string) => {
  try {
    return await api.getImgBlob(address);
  } catch (err) {
    return '';
  }
};

export const metaPfdInitialize = async (file: IFile): Promise<IPfdAction | undefined> => {
  if (!file) return undefined;

  const getImageDimensions = (src: string): Promise<{width: number; height: number}> => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = src;
      image.onload = () => {
        resolve({width: image.width, height: image.height});
      };
      image.onerror = reject;
    });
  };

  if (file?.localInfo?.uploadImageDataUrl) {
    const extension = file?.localInfo?.uploadImageFile.name.match(/\.([^.]+)$/);
    const fileType = extension ? extension[1] : undefined;

    const {width, height} = await getImageDimensions(file?.localInfo?.uploadImageDataUrl);

    return {
      type: 'SET_IMAGE_INFO',
      images: {
        imageSrc: file?.localInfo?.uploadImageDataUrl,
        imgSourceType: 'upload',
        imgFile: file?.localInfo?.uploadImageFile,
        imgExtension: fileType,
        naturalWidth: width,
        naturalHeight: height
      }
    };
  }

  if (file?.pid_extension) {
    const imgBlob = (await getImg('/file_manage/upload_folder/' + file._id + '.' + file.pid_extension)) as Blob;
    const imageSrc = URL.createObjectURL(imgBlob);

    const {width, height} = await getImageDimensions(imageSrc);

    const newImgData = {
      imgBlob,
      imageSrc,
      imgSourceType: 'upload',
      fileName: file._id + '.' + file.pid_extension,
      imgExtension: file?.pid_extension,
      naturalWidth: width,
      naturalHeight: height
    };
    return {type: 'SET_IMAGE_INFO', images: newImgData};
  }

  let pidName = file.pidName;
  if (!pidName) return undefined;

  const imgBlob = (await getImg('/file_manage/pfd_folder/' + pidName)) as Blob;
  if (!imgBlob) {
    return null;
  }
  const imageSrc = URL.createObjectURL(imgBlob);
  const extension = pidName.match(/\.([^.]+)$/);
  const fileType = extension ? extension[1] : undefined;

  const {width, height} = await getImageDimensions(imageSrc);

  const newImgData = {
    fileName: pidName,
    imgSourceType: 'upload',
    pidName,
    imgBlob,
    imageSrc,
    imgExtension: fileType,
    naturalWidth: width,
    naturalHeight: height
  };
  return {type: 'SET_IMAGE_INFO', images: newImgData};
};
