import {Html} from '@react-three/drei';
import annotationsData from 'components/pc/widgets/three/constants/annotationsData.json';
import ThreeLandscapeLabel from 'components/pc/widgets/three/renderer/label/ThreeLandscapeLabel';

type IProps = {
  labelsVisible: boolean;
};

function ThreeLandscapeLabelsWrapper({labelsVisible}: IProps) {
  return (
    <>
      {annotationsData.map(
        (
          item,
          i // Annotations HERE
        ) => (
          <Html key={i} position={[item.position[0] / 5, item.position[1] / 5, item.position[2] / 5]}>
            <ThreeLandscapeLabel
              title={item.title}
              description={item.description}
              visible={labelsVisible}
              type={item.type}
            />
          </Html>
        )
      )}
    </>
  );
}

export default ThreeLandscapeLabelsWrapper;
