import {type DragEvent, ReactElement, useCallback, useContext} from 'react';
import styled from 'styled-components';
import {useNodes} from 'reactflow';
import {widgetCollection} from 'components/pc/widgets/widget-constants';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {frozenLayoutModalOptions, noFileModalOptions} from 'components/menu/constants';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  //display: flex;
  flex-shrink: 0;
  height: calc(100vh - 40px);
  margin-top: 40px;
  background-color: #e7edf3;
  overflow: hidden auto;
  padding-bottom: 60px;
  box-sizing: border-box;
`;
const DockedNode = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 10px;
  box-sizing: border-box;
  position: relative;
`;

type IProps = {
  width?: number;
};

function DockingLayout({width}: IProps): ReactElement {
  const nodes = useNodes();
  const {showModal} = useContext(CommonContext);
  const {dockState, file, isFreezeState} = useContext(ProcessCanvasContext);
  const [dockedIdList] = dockState;
  const [isFreeze] = isFreezeState;

  const onDragStartItem = (e: DragEvent<HTMLDivElement>, id: string): void => {
    if (isFreeze) {
      showModal(frozenLayoutModalOptions);
      return;
    }
    if (!file) {
      showModal(noFileModalOptions);
      return;
    }

    e.dataTransfer?.setData('application/dataTransfer/widget/docked', JSON.stringify(id));
  };

  const getWidgetById = useCallback(
    (id: string): ReactElement => {
      const node = nodes.find((node) => node.id === id);
      if (!node) return undefined;

      const WidgetComponent = widgetCollection[node.type];
      return (
        <DockedNode
          key={node.id}
          onDragStart={(e) => onDragStartItem(e, node.id)}
          style={{height: node.height}}
          draggable
        >
          <WidgetComponent {...node} />
        </DockedNode>
      );
    },
    [nodes]
  );

  return (
    <Container className="docking thin-scrollbar md" style={{width}}>
      {dockedIdList.map((id) => getWidgetById(id))}
    </Container>
  );
}

export default DockingLayout;
