import {ReactNode} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {SizeTypes} from 'components/common/types';

type IProps = {
  type?: SizeTypes | 'settings' | 'user-info' | 'confirm';
  children?: ReactNode;
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const ModalWrapper = styled.div`
  margin: auto;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 20px;
  &:has(.dropzone) {
    position: relative;
  }

  &.xs {
    min-width: 400px;
    min-height: 200px;
  }
  &.sm {
    min-width: 560px;
    min-height: 235px;
  }
  &.md {
    min-width: 560px;
    min-height: 500px;
  }
  &.lg {
    min-width: 800px;
    min-height: 640px;
  }
  &.settings {
    width: 600px;
    min-height: 440px;
  }
  &.user-info {
    min-width: 460px;
    min-height: 400px;
  }
  &.confirm {
    width: 360px;
    min-height: 235px;
    position: absolute;
    top: 350px;
    left: 35px;
  }
`;

function Modal({type = 'sm', children}: IProps): JSX.Element {
  return (
    <Container className="modal-wrapper">
      <ModalWrapper className={classNames(type)} onClick={(e) => e.stopPropagation()}>
        {children}
      </ModalWrapper>
    </Container>
  );
}

export default Modal;
