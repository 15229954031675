import React, {PropsWithChildren, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {faEllipsisVertical} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import HistoryAllOption from 'components/menu/copilot/HistoryAllOption';
import HistoryList from 'components/menu/copilot/HistoryList';
import {ICopilotThread} from 'components/menu/copilot/types';
import BasicSpinner from 'components/common/BasicSpinner';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';

function formatDate(date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')} 
    ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date
      .getSeconds()
      .toString()
      .padStart(2, '0')}`;
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  //transition: height 0.2s;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
  }
`;

const HistoryHead = styled.div`
  padding: 0 16px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: #2f2f2f;
  align-items: center;

  p {
    margin: 0;
    font-size: 15px;
    font-weight: 400;
  }

  .history-all-option {
    padding-left: 8px;
    padding-right: 8px;
    margin-right: -4px;
    color: #939393;
    &:hover {
      color: #fff;
      cursor: pointer;
    }
  }
`;

const AllOptionIcon = styled.div`
  color: #939393;
  &:hover {
    color: #fff;
  }
`;

const Scroller = styled.div`
  box-sizing: border-box;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const History = styled.div`
  list-style: none;
  font-size: 15px;
  font-weight: 400;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;

  div {
    color: #2f2f2f;
    cursor: pointer;
  }
  &:hover {
    transition: 0.2s;
    div {
      color: #fff;
    }
  }
`;

export type UiOpenTypes = 'open' | 'close';

type IProps = PropsWithChildren & {
  height: number;
  userID: string;
  threadList: ICopilotThread[];
};

function ChatHistory({height}: IProps): ReactElement {
  const {isNewChat} = useContext(CopilotContext);
  const [isShowOptionMenu, setIsShowOptionMenu] = useState(false);
  const selectedRef = useRef(null);

  useEffect(() => {
    function handleDocumentClick(event) {
      if (selectedRef.current && selectedRef.current.contains(event.target)) {
        return false;
      }
      setIsShowOptionMenu(false);
    }
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  // history 전체 삭제 기능
  const onClickOptionButton = () => {
    setIsShowOptionMenu(!isShowOptionMenu);
  };

  return (
    <Container style={{height}}>
      <HistoryHead>
        <p>Chat History</p>
        <div className="history-all-option" ref={selectedRef} onClick={onClickOptionButton}>
          <AllOptionIcon>
            <FontAwesomeIcon icon={faEllipsisVertical} size="lg" color="orange" />
          </AllOptionIcon>
          {isShowOptionMenu ? <HistoryAllOption setIsOpen={setIsShowOptionMenu} /> : ''}
        </div>
      </HistoryHead>
      <HistoryList />
      <BasicSpinner isShow={isNewChat} type="overlay" />
    </Container>
  );
}

export default ChatHistory;
