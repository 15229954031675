import React, {PropsWithChildren, ReactElement} from 'react';
import styled, {CSSProp, ThemeProvider} from 'styled-components';
import {defaultTheme} from 'theme/theme';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;

  .page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

function Layout({children}: PropsWithChildren): ReactElement {
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container className="layout">{children}</Container>
    </ThemeProvider>
  );
}

export default Layout;
