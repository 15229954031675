import {Dispatch, useContext, useEffect, useState} from 'react';
import {IPfdAction, IPfdCfg} from 'components/mpfd/type';
import {useReactFlow} from 'reactflow';
import {IFile, IMPfdData} from 'api/data-types';
import {mPfdWidgetInitializeReturn} from 'components/pc/widgets/metaPfd/functions';
import {MetaPfdContext} from 'components/mpfd';
import {IMetaPFDWidgetData, IWidgetNodeData} from 'components/pc/types';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import useApi from 'api/useApi';

function useMetaPfdWidgetEffect(
  data: IWidgetNodeData,
  id: string,
  dispatchToReducer: Dispatch<IPfdAction>,
  setImgWidth: Dispatch<number>,
  setImgHeight: Dispatch<number>,
  latestTagHandlerForTable: ILatestTagHandlerReturn,
  latestTagHandlerForChart: ILatestTagHandlerReturn
) {
  const [imgLoadStatus, setImgLoadStatus] = useState('');
  // const layoutParams = useRef<layoutParamType | null>({});
  const {fileList} = useContext(MetaPfdContext);
  const reactFlow = useReactFlow();
  const cfgState = useState<IPfdCfg>({
    isDisplayLabels: true,
    isDisplayDataValues: true,
    isLiveUpdateDataValues: true
  });
  const [, setCfg] = cfgState;
  const api = useApi();

  /**
   * useAPI Hook 사용하기 위해서 Component 내부로 위치 변경
   */
  const mPfdWidgetInitialize = async (stateData: IFile): Promise<mPfdWidgetInitializeReturn> => {
    if (!stateData) return;
    if (stateData?.pid_extension) {
      const imgBlob = (await api.getImgBlob(
        '/file_manage/upload_folder/' + stateData._id + '.' + stateData.pid_extension
      )) as Blob;
      const imageSrc = URL.createObjectURL(imgBlob);
      const newImgData = {imgBlob, imageSrc, imgSourceType: 'upload'};
      return {images: newImgData, imgLoadStatus: 'complete'};
    }

    let pidName = stateData.pidName;
    if (!pidName) return;
    const imgBlob = (await api.getImgBlob('/file_manage/pfd_folder/' + pidName)) as Blob;
    if (!imgBlob) {
      return {imgLoadStatus: 'fail'};
    }
    const imageSrc = URL.createObjectURL(imgBlob);
    const newImgData = {fileName: pidName, pidName, imgBlob, imageSrc};
    return {images: newImgData, imgLoadStatus: 'complete'};
  };

  const initialize = async () => {
    /**
     * MIGRATION-1 :
     * data : {stateData: IMPfdData} :2.12 version
     * data : {mPfdFileData : {stateData: IMPfdData}} : 2.13 version
     */
    let stateData: IFile;
    if (data?.mPfdFileData) {
      stateData = data?.mPfdFileData;
    } else {
      stateData = data as IFile;
    }
    // let stateData = data?.mPfdFileData;
    const mPfdFileId = stateData?._id;
    const mPfdFile = fileList.find((mPfd) => mPfd._id === mPfdFileId);
    setImgLoadStatus('loading');
    let result: mPfdWidgetInitializeReturn;
    if (mPfdFile && mPfdFile.updatedTime !== stateData.updatedTime) {
      stateData = mPfdFile;
      reactFlow.setNodes((nodes) =>
        nodes.map((item) => (item.id === id ? {...item, data: {mPfdFileData: mPfdFile}} : item))
      );
      result = await mPfdWidgetInitialize(mPfdFile);
    } else {
      result = await mPfdWidgetInitialize(stateData);
    }

    if (result?.imgLoadStatus === 'complete') {
      setImgLoadStatus('complete');
      const metaData = data?.metaData as IMetaPFDWidgetData;
      latestTagHandlerForTable.onLoadLatestTagResult(metaData?.latestDataForTable || {});
      latestTagHandlerForChart.onLoadLatestTagResult(metaData?.latestDataForChart || {});

      dispatchToReducer({type: 'SET_IMAGE_INFO', images: result?.images});
      const fileData = stateData.stateData as IMPfdData;
      dispatchToReducer({type: 'onLoad', fileData});

      const newCfg = {
        isDisplayLabels: fileData?.cfg?.isDisplayLabels || Boolean(metaData?.cfg?.isDisplayLabels),
        isDisplayDataValues: fileData?.cfg?.isDisplayDataValues || Boolean(metaData?.cfg?.isDisplayDataValues),
        isLiveUpdateDataValues: Boolean(metaData?.cfg?.isLiveUpdateDataValues)
      } as IPfdCfg;
      setCfg(newCfg);

      /**
       * 처음 로드했을 때, 이미지의 비율이 맞지 않는 문제 해결을 위해
       * 초기에 한번 계산을 시행합니다
       */
      const mPfdWidgetNode = reactFlow.getNode(id);
      const widgetNodeWidth = mPfdWidgetNode.width;
      const mPfdData = data.mPfdFileData.stateData as IMPfdData;
      const imageRatio = mPfdData.imageInfo.naturalHeight / mPfdData.imageInfo.naturalWidth;
      const w =
        mPfdData?.imageInfo?.naturalWidth / 2 < widgetNodeWidth
          ? widgetNodeWidth
          : mPfdData?.imageInfo?.naturalWidth / 2;
      const calculatedHeight = imageRatio * w;
      setImgWidth(w);
      setImgHeight(calculatedHeight);
    } else {
      setImgLoadStatus(result?.imgLoadStatus);
    }
  };

  useEffect(() => {
    initialize().then();
  }, []);

  return {
    cfgState,
    imgLoadStatus
  };
}

export default useMetaPfdWidgetEffect;
