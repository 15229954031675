import TimestampDataRenderer from 'components/spreadsheet/renderer/TimestampDataRenderer';
import NormalDataRenderer from 'components/spreadsheet/renderer/NormalDataRenderer';
import {faTableCells} from '@fortawesome/pro-light-svg-icons';

export const SpreadsheetRendererComponent = {
  TimestampDataRenderer,
  NormalDataRenderer
};

export const spreadSheetWidgetData = {
  id: 'SpreadsheetWidget',
  title: 'Spreadsheet',
  description: 'Create SpreadsheetWidget',
  icon: faTableCells,
  type: 'SpreadsheetWidget',
  style: {
    width: 600,
    height: 500,
    minWidth: 200,
    minHeight: 200
  }
};
