import {BaseSyntheticEvent, ReactElement, useContext, useEffect, useState} from 'react';
import {
  disableListByFreezeLayout,
  disableListByNewFile,
  disableListByNoneFile,
  disableListByNotOwner,
  IPullDownMenu,
  NEW_FILE_ROUTE,
  processCanvasMenu,
  PullDownFunctions,
  widgetIdList,
  WidgetIdTypes
} from 'components/menu/constants';
import styled from 'styled-components';
import PullDownMenuItem from 'components/menu/pulldown/PullDownMenuItem';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {useParams} from 'react-router-dom';
import useHotkey from 'hooks/useHotkey';
import {useReactFlow} from 'reactflow';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';

const MenuLayout = styled.ul`
  display: flex;
  height: 40px;
  user-select: none;
`;
const MainMenuItem = styled.li`
  &.active {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
const MainMenuItemTitle = styled.div`
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: default;
  font-size: 14px;
  color: #fff;
`;
const DropDownMenuList = styled.ul`
  position: absolute;
  background-color: #272546;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
`;

function ProcessCanvasPullDownMenu(): ReactElement {
  const {file, isFileOwner, isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;
  const commands = useProcessCanvasCommand();
  const {
    create,
    close,
    save,
    saveAs,
    rename,
    showGlobalSettings,
    addWidget,
    zoomTo,
    zoomIn,
    zoomOut,
    toggleLock,
    toggleSmartAlign,
    toggleMiniMap,
    toggleZoomScale,
    fitView,
    fullScreen,
    copy,
    paste,
    deleteWidget,
    importFile,
    exportFile,
    openOwnershipModal,
    openChangeAccessibilityModal
  } = commands;
  useHotkey(commands);
  const [menuList, setMenuList] = useState<IPullDownMenu[]>(processCanvasMenu);
  const [activeMenu, setActiveMenu] = useState<IPullDownMenu>({} as IPullDownMenu);
  const {getNodes} = useReactFlow();
  const {id} = useParams();

  useEffect(() => {
    if (!document?.hasFocus()) return;
    try {
      const selectedNodes = getNodes().find((item) => item?.selected);
      navigator?.clipboard?.readText().then((clipboardData) => {
        let isNotExistCopiedWidget = true;
        if (clipboardData) {
          try {
            const target = JSON.parse(clipboardData);
            if (target?.selectedNode) {
              isNotExistCopiedWidget = false;
            }
          } catch (e) {
            console.warn(e);
          }
        }

        const editMenu = processCanvasMenu.find((menu) => menu.id === 'edit');
        editMenu.children = [
          {
            id: 'copy',
            title: 'Copy Widget',
            hotkey: 'Ctrl + shift + c',
            disabled: !selectedNodes
          },
          {
            id: 'paste',
            title: 'Paste Widget',
            hotkey: 'Ctrl + shift + v',
            disabled: isNotExistCopiedWidget
          },
          {
            id: 'delete',
            title: 'Delete Widget',
            hotkey: 'Delete',
            disabled: !selectedNodes
          }
        ];
        const lockMenu = processCanvasMenu
          .find((menu) => menu.id === 'view')
          .children.find((menu) => menu.id === 'freeze');
        lockMenu.checked = isFreeze;

        setMenuList([...processCanvasMenu]);
      });
    } catch (e) {
      console.warn(e);
    }
  }, [activeMenu, getNodes, isFreeze]);

  useEffect(() => {
    if (file) {
      const fileMenu = processCanvasMenu.find((menu) => menu.id === 'file');
      const changeAccessibilityItem = fileMenu.children.find((child) => child.id === 'change-accessibility');
      if (!changeAccessibilityItem.disabled) {
        changeAccessibilityItem.title = `Change to ${file.public ? 'Private' : 'Public'}`;
      }
      const viewMenu = processCanvasMenu.find((menu) => menu.id === 'view');
      const freezeItem = viewMenu.children.find((child) => child.id === 'freeze');
      freezeItem.checked = isFreeze;
    }
  }, [file]);

  /**
   * 실행 가능한 메뉴(대메뉴가 아닌 2depth 메뉴)가 클릭 되었음
   * @param e
   * @param menu
   */
  const onClickMenu = (e: BaseSyntheticEvent, menu: IPullDownMenu): void => {
    /**
     * id 키에 해당하는 메뉴의 checked 값을 toggle 해 줌
     * @param id
     */
    const changeChecked = (id: PullDownFunctions): void => {
      setMenuList((prevState) =>
        prevState.map((menu) => {
          menu.children.map((child) => {
            if (child.id === id) {
              child.checked = !child.checked;
            }
            return child;
          });
          return menu;
        })
      );
    };

    const {id} = menu;
    switch (id) {
      case 'create':
        create();
        break;
      case 'close':
        close();
        break;
      case 'save':
        save();
        break;
      case 'save-as':
        saveAs();
        break;
      case 'rename':
        rename();
        break;
      case 'settings':
        showGlobalSettings();
        break;
      case 'copy':
        copy();
        break;
      case 'paste':
        paste();
        break;
      case 'delete':
        deleteWidget();
        break;
      case 'zoom-in':
        zoomIn();
        break;
      case 'zoom-out':
        zoomOut();
        break;
      case 'zoom-reset':
        zoomTo(1);
        break;
      case 'minimap':
        changeChecked('minimap');
        toggleMiniMap();
        break;
      case 'freeze':
        changeChecked('freeze');
        toggleLock();
        break;
      case 'smart-align':
        changeChecked('smart-align');
        toggleSmartAlign();
        break;
      case 'fit-to-screen':
        fitView();
        break;
      case 'fullscreen':
        fullScreen();
        break;
      case 'zoom-panel':
        changeChecked('zoom-panel');
        toggleZoomScale();
        break;
      case 'import':
        importFile();
        break;
      case 'export':
        exportFile();
        break;
      case 'transfer-ownership':
        openOwnershipModal();
        break;
      case 'change-accessibility':
        openChangeAccessibilityModal();
        break;
      case 'sticky':
      case 'time-series':
      case '3d-landscape':
      case 'weather':
      case 'commodity':
      case 'youtube':
      case 'web':
      case 'datasheet':
      case 'hmb':
      case 'python-editor':
      case 'jmp':
        addWidget(id);
        break;
      default:
    }
    // 메뉴를 선택하고 닫음
    setActiveMenu({} as IPullDownMenu);
  };

  /**
   * 현재 Process Canvas 메뉴의 disabled 현황
   * 1. 열려 있는 파일이 없는 경우  disableListByNoneFile 에 있는 메뉴
   * 2. 새 파일인 경우 disableListByNewFile 에 있는 메뉴
   * 3. View > Freeze Layout 인 상태에서 disableListByNewFile 에 있는 메뉴
   * 4. file 의 owner 가 아닌 경우 disableListByNotOwner 에 있는 메뉴
   * @param childId
   */
  const getMenuDisabled = (childId: PullDownFunctions): boolean => {
    return (
      (id === undefined && disableListByNoneFile.includes(childId)) ||
      (id === NEW_FILE_ROUTE && disableListByNewFile.includes(childId)) ||
      (isFreeze && disableListByFreezeLayout.includes(childId)) ||
      (!isFileOwner && disableListByNotOwner.includes(childId))
    );
  };

  return (
    <MenuLayout>
      {menuList.map((menu) => (
        <MainMenuItem
          key={menu.id}
          className={activeMenu.id === menu.id && 'active'}
          onMouseEnter={() => setActiveMenu(menu)}
          onMouseLeave={() => setActiveMenu({} as IPullDownMenu)}
        >
          <MainMenuItemTitle>{menu.title}</MainMenuItemTitle>
          {activeMenu.id === menu.id && (
            <DropDownMenuList>
              {menu.children?.map((child) => (
                <PullDownMenuItem
                  key={child.id}
                  data={child}
                  disabled={getMenuDisabled(child.id)}
                  onClick={onClickMenu}
                />
              ))}
            </DropDownMenuList>
          )}
        </MainMenuItem>
      ))}
    </MenuLayout>
  );
}

export default ProcessCanvasPullDownMenu;
