import React, {MouseEvent, useCallback, useContext} from 'react';
import {IAnnotation} from 'components/mpfd/type';
import {getMouseHoverType, getRegionColor} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import Point from 'components/mpfd/annotation/parts/Point';
import Box from 'components/mpfd/annotation/parts/Box';
import Line from 'components/mpfd/annotation/parts/Line';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import useMetaPfdMouseEvents from 'components/mpfd/hooks/useMetaPfdMouseEvents';

const annotationComponents = {
  'expanding-line': Line,
  point: Point,
  box: Box,
  port: Box
};

type IProps = {
  type: string;
  region: IAnnotation;
  iw: number;
  ih: number;
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
};

function Annotation({type, region, iw, ih, imagePosition}: IProps) {
  const {annotationAction, state} = useContext(MetaPfdContext);
  const {mode, cfg, selectedTool} = state;
  const {onMouseDown} = useMetaPfdMouseEvents(imagePosition);

  const color = getRegionColor(region);

  const drawLineMode = mode?.mode === 'DRAW_EXPANDING_LINE';

  const handleMouseDown = (e: MouseEvent, idx = -1) => {
    e.stopPropagation();
    if (e.button === 2) return;
    const svgTarget = e.target as SVGSVGElement;
    const handleSplit = (
      toolHandler: (
        region: IAnnotation,
        targetPointsIdx?: number,
        point?: {x: number; y: number},
        clientXY?: {x: number; y: number}
      ) => void
    ) => {
      const {right, left, bottom, top} = svgTarget.getBoundingClientRect();

      let x = right !== left ? Math.abs((e.clientX - left) / (right - left)) : 0;
      let y = bottom !== top ? Math.abs((e.clientY - top) / (bottom - top)) : 0;

      if (x <= 1 && y <= 1) {
        toolHandler(region, idx * 2, {x, y});
      } else {
      }
    };

    switch (selectedTool as ToolIds) {
      case 'line-eraser':
        if (['point', 'box', 'port'].includes(region.type)) {
          annotationAction.deleteAnnotation(region);
        } else if (region.type === 'expanding-line') {
          annotationAction.removeLine(region, idx * 2);
        }
        break;
      case 'line-splitter':
        if (region.type === 'expanding-line') handleSplit(annotationAction.splitAnnotationViaLine);
        break;
      case 'line-splitter-2':
        if (region.type === 'expanding-line') handleSplit(annotationAction.createBreakPointOnLine);
        break;
      case 'select':
      case 'create-expanding-line':
      case 'line-detection':
        e.stopPropagation();
        annotationAction.selectAnnotation(region);
        break;
      default:
        break;
    }
  };

  const handleMouseDownPointsOfLine = (e: MouseEvent, idx = -1, targetPoint = {x: 0, y: 0}) => {
    e.stopPropagation();
    if (e.button === 2) {
      e.stopPropagation();
      return;
    }

    switch (selectedTool as ToolIds) {
      case 'line-splitter':
        annotationAction.splitAnnotationViaPoint?.(region, -1, targetPoint);
        break;
      case 'select':
      case 'line-detection':
        if (!drawLineMode) {
          annotationAction.beginMovePointOfLine?.(region, -1, targetPoint);
        }
        break;
      case 'remove-joint-of-line':
        annotationAction.removeBreakPointOnLine(region, -1, targetPoint);
        break;
      default:
        break;
    }
  };

  const handleMouseUpPointsOfLine = (e: MouseEvent, idx = -1, targetPoint = {x: 0, y: 0}) => {
    if (e.button === 2) {
      e.stopPropagation();
      annotationAction.beginSelectPointOfLine?.(region, -1, targetPoint, {x: e.clientX, y: e.clientY});
    }
  };
  const onHandleMouseDown = (e: MouseEvent, idx = -1) => {
    if (e.button === 2 || e.button === 1) return;
    onMouseDown(e, () => handleMouseDown(e, idx));
  };

  const onHandleMouseDownPointsOfLine = (e: MouseEvent, idx = -1, targetPoint = {x: 0, y: 0}) => {
    if (e.button === 2 || e.button === 1) return;
    onMouseDown(e, () => handleMouseDownPointsOfLine(e, idx, targetPoint));
  };

  const {pointHoverType, boxHoverType, polyLineHoverType, pointOfLineHoverType} = getMouseHoverType(selectedTool);
  const AnnotationComponent = annotationComponents[type];
  if (!AnnotationComponent) return null;

  return (
    <AnnotationComponent
      iw={iw}
      ih={ih}
      mode={mode}
      region={region}
      drawLineMode={drawLineMode}
      cfg={cfg}
      createWithPrimary={selectedTool.includes('create')}
      onMouseDown={onHandleMouseDown}
      onMouseDownPointsOfLine={onHandleMouseDownPointsOfLine}
      onMouseUpPointsOfLine={handleMouseUpPointsOfLine}
      color={color}
      pointHoverType={pointHoverType}
      polyLineHoverType={polyLineHoverType}
      boxHoverType={boxHoverType}
      pointOfLineHoverType={pointOfLineHoverType}
    />
  );
}

export default Annotation;
