import styled from 'styled-components';

const Container = styled.div`
  top: 5px;
`;

type NodeInfoProps = {
  id: string;
  type: string;
  selected: boolean;
  x: number;
  y: number;
  width?: number;
  height?: number;
  data: any;
  style: any;
  zIndex: number;
};

function NodeInfo({id, type, selected, x, y, width, height, data, style, zIndex}: NodeInfoProps) {
  if (!width || !height) {
    return null;
  }

  return (
    <Container
      style={{
        position: 'absolute',
        transform: `translate(${x}px, ${y + height}px)`,
        width: width * 2
      }}
    >
      <div>id: {id}</div>
      <div>zIndex : {zIndex}</div>
      <div>type: {type}</div>
      <div>selected: {selected ? 'true' : 'false'}</div>
      <div>
        position: {x.toFixed(1)}, {y.toFixed(1)}
      </div>
      <div>
        dimensions: {width} × {height}
      </div>
      <div>style: {JSON.stringify(style, null, 2)}</div>
      <div>data: {JSON.stringify(data, null, 2)}</div>
    </Container>
  );
}

export default NodeInfo;
