import {ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import Popup from 'components/popup/Popup';
import {Button} from 'components/forms';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import {DataContext, TreeCategory, treeCategoryList} from 'api/DataProvider';
import TreeMenu, {CustomNode} from 'components/pc/node-selector/TreeMenu';
import {getUnitTemp} from 'components/pc/common/shapes/function';
import {ITagHierarchy, ITagData} from 'api/data-types';
import classnames from 'classnames';

type ContainerStyleProps = {
  $zIndex: number;
};

const Container = styled.div<ContainerStyleProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${(props) => props.$zIndex};
  display: flex;
  &.default {
    margin: auto;
  }
  &.time-series {
    margin: auto auto auto 300px;
  }
`;

const TreeMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 10px;
`;

const Footer = styled.div`
  button {
    width: 100%;
    height: 50px;
    font-size: 15px;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    em {
      color: tomato;
      font-style: normal;
    }
  }
`;

type IProps = {
  type?: 'default' | 'time-series' | 'stream';
  categoryList?: TreeCategory[];
  selectedTags: string[][];
  limit?: number;
  zIndex?: number;
  beforeOnCheck?(candidate: string[], checkedList: string[]): string[];
  onSelect?(tag: string[][], tagInfos?: ITagData[]): void;
  onClose(): void;
};

/**
 * Tag 를 선택하고 결과를 호스트코드로 전송
 * @param type
 * @param tagList
 * @param onSelect
 * @param onClose
 * @constructor
 */
function NodeSelector({
  type = 'default',
  selectedTags,
  categoryList,
  limit = 20,
  zIndex = 1,
  beforeOnCheck,
  onSelect,
  onClose
}: IProps): ReactElement {
  const {tagList, getTree, modelHierarchy, commodityJson, plantJson, weatherJson} = useContext(DataContext);
  const checkedState = useState<string[]>([]);
  const [checkedList] = checkedState;
  const expandedState = useState<string[]>([]);
  const [nodes, setNodes] = useState<CustomNode[]>([]);

  useEffect(() => {
    if (tagList.length === treeCategoryList.length) {
      const targetNodes = [];
      for (let i = 0; i < categoryList.length; i++) {
        targetNodes.push(getTree(categoryList[i]));
      }
      setNodes(targetNodes);
    }
  }, [categoryList, tagList]);

  /**
   * 호스트코드쪽으로 선택 상태를 보냄
   * 보내기 전에 가공이 필요
   */

  const onClickConfirm = (): void => {
    const tagSelectorInfoList: ITagData[] = checkedList.map((item) => {
      const [category, ...rest] = JSON.parse(item) as string[];
      let hierarchy: ITagHierarchy[];
      switch (category) {
        case 'Model':
          hierarchy = modelHierarchy;
          break;
        case 'Weather':
          hierarchy = weatherJson;
          break;
        case 'Commodity':
          hierarchy = commodityJson;
          break;
        case 'Plant':
          hierarchy = plantJson;
          break;
      }
      const unit = getUnitTemp(category, rest, hierarchy);
      return {
        unit,
        tag: JSON.parse(item) as string[]
      } as ITagData;
    });
    console.log('tagSelectReturn', tagSelectorInfoList);
    onSelect(
      checkedList.map((item: string) => JSON.parse(item)),
      tagSelectorInfoList
    );
    onClose();
  };

  return (
    <Container $zIndex={zIndex} className={classnames(type, 'node-selector-container')}>
      <Popup type="doubleModal" title="Select Items" onClose={onClose}>
        <TreeMenuContainer>
          <TreeMenu
            nodes={nodes}
            selected={selectedTags}
            checkedState={checkedState}
            expandedState={expandedState}
            beforeOnCheck={beforeOnCheck}
          />
          {onSelect && (
            <Footer>
              <Button variant="light" disabled={checkedList.length >= limit} onClick={onClickConfirm}>
                <div>
                  {checkedList.length} Node{checkedList.length > 1 && 's'} selected
                </div>
                {checkedList.length >= limit && <em>Tags number should be less then {limit}.</em>}
              </Button>
            </Footer>
          )}
        </TreeMenuContainer>
      </Popup>
    </Container>
  );
}

export default NodeSelector;
