import React, {ReactElement} from 'react';
import {MiniMap} from 'reactflow';
import styled from 'styled-components';

const Container = styled(MiniMap)`
  display: flex;
  border-radius: 5px;
  border: 2px solid #aaa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 80px;
`;

function ProcessCanvasMiniMap(): ReactElement {
  return <Container position="bottom-right" pannable zoomable zoomStep={1} />;
}

export default ProcessCanvasMiniMap;
