import React, {RefObject, useRef} from 'react';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import MetaPfdDataTable from 'components/mpfd/annotation/MetaPfdDataTable';
import MetaPfdDataChart from 'components/mpfd/annotation/MetaPfdDataChart';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import ResizableFrame from 'components/mpfd/annotation/ResizableFrame';
import classnames from 'classnames';

const Container = styled.div`
  position: absolute;
  z-index: ${META_PFD_zINDEX.TABLE_LAYER};
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const InnerContainer = styled.div`
  position: relative;
`;

type CommonProps = {
  iw: number;
  ih: number;
  mat: any;
  cfg: IPfdCfg;
  latestTagHandler?: ILatestTagHandlerReturn;
};

const renderMetaPfdDataTable = (
  region: IAnnotation,
  idx: number,
  id: string,
  commonProps: CommonProps,
  refObject: RefObject<HTMLDivElement>
) => {
  const tagInfos = region?.metaPfdTagInfo?.filter((item) => item?.isTableDisplay) || [];

  if (tagInfos.length > 0 && region?.isTableVisible) {
    const headerRow = region?.isShowTableHeader ? 1 : 0;
    const tags = region.metaPfdTagInfo.filter((item) => item.isTableDisplay).map((item) => item.tag);
    const displayInTableRowNumber = Number(tags?.length + headerRow);
    const cellHeight = 32;
    const tableMaxHeight = cellHeight * displayInTableRowNumber;
    const x = region.tablePosition.x || region.x || 0.01;
    const y = region.tablePosition.y || region.y || 0.01;
    const height = Math.min(region.tablePosition.h * commonProps.ih + 1, tableMaxHeight + 1);
    const width = Math.max(10, region.tablePosition.w * commonProps.iw);

    return (
      <ResizableFrame
        width={width * commonProps.mat?.a}
        height={height * commonProps.mat?.a}
        left={x * commonProps.iw}
        top={y * commonProps.ih}
        scale={1 / commonProps.mat?.a}
        disabled={true}
        id={id}
        relativeRefObject={refObject}
        smoothCss={true}
        minHeight={32}
        minWidth={100}
        maxHeight={tableMaxHeight}
        key={'table' + id + idx}
      >
        <MetaPfdDataTable region={region} id={`${region?.id}${id}`} {...commonProps} />
      </ResizableFrame>
    );
  }
  return null;
};

const renderMetaPfdDataChart = (
  region: IAnnotation,
  idx: number,
  id: string,
  commonProps: CommonProps,
  refObject: RefObject<HTMLDivElement>
) => {
  const tagInfos = region?.metaPfdTagInfo?.filter((item) => item?.isChartDisplay) || [];

  if (tagInfos.length > 0 && region?.isNormalChartVisible) {
    const x = region.chartPosition.x || region.x || 0.01;
    const y = region.chartPosition.y || region.y || 0.01;
    const width = Math.max(region.chartPosition.w * commonProps.iw);
    const height = Math.max(region.chartPosition.h * commonProps.ih);

    return (
      <ResizableFrame
        width={width * commonProps.mat?.a}
        height={height * commonProps.mat?.a}
        left={x * commonProps.iw}
        top={y * commonProps.ih}
        scale={1 / commonProps.mat?.a}
        disabled={true}
        id={id}
        relativeRefObject={refObject}
        smoothCss={false}
        key={'chart' + id + idx}
      >
        <MetaPfdDataChart region={region} id={`${region?.id}${id}`} {...commonProps} />
      </ResizableFrame>
    );
  }
  return null;
};

type IProps = {
  imagePosition: {
    topLeft: PointObjectNotation;
    bottomRight: PointObjectNotation;
  };
  imageDimensions: {
    naturalWidth: number;
    naturalHeight: number;
  };
  regions: IAnnotation[];
  cfg: IPfdCfg;
  isViewer?: boolean;
  id?: string;
  tableLatestTagHandler?: ILatestTagHandlerReturn;
  chartLatestTagHandler?: ILatestTagHandlerReturn;
  mat: any;
};

function MetaPfdWidgetDataLayer({
  imagePosition,
  regions = [],
  cfg,
  id,
  tableLatestTagHandler,
  chartLatestTagHandler,
  mat
}: IProps) {
  const ref = useRef(null);
  const iw = imagePosition.bottomRight.x - imagePosition.topLeft.x;
  const ih = imagePosition.bottomRight.y - imagePosition.topLeft.y;

  const commonProps = {
    iw,
    ih,
    mat,
    cfg
  };

  const tableProps = {
    ...commonProps,
    latestTagHandler: tableLatestTagHandler
  };

  const chartProps = {
    ...commonProps,
    latestTagHandler: chartLatestTagHandler
  };

  return (
    <Container className={classnames(`data-layer-${id}`, 'data-layer')}>
      <InnerContainer>
        {regions.map((region, idx) => renderMetaPfdDataTable(region, idx, id, tableProps, ref))}
        {regions.map((region, idx) => renderMetaPfdDataChart(region, idx, id, chartProps, ref))}
      </InnerContainer>
    </Container>
  );
}

export default MetaPfdWidgetDataLayer;
