import {ChangeEvent, ReactElement, useState} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;

  > input[type='color'] {
    width: 100%;
    height: 33px;
    padding: 0;
    border: none;
    flex: 1;

    &::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    &::-webkit-color-swatch {
      border: none;
    }
  }
`;

type IProps = {
  keys: string[];
  color: string;
  onChange(e: ChangeEvent): void;
};

/**
 * DefaultTable 내부에서 색상을 선택
 * @param keys
 * @param color
 * @param onChange
 * @constructor
 */
function ColorPicker({keys, color, onChange}: IProps): ReactElement {
  // 변경값을 컴포넌트 내부에 저장
  const [pickedColor, setPickedColor] = useState(color);

  const onChangeColor = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    setPickedColor(value);
    onChange?.(e);
  };
  return (
    <Container>
      <input type="color" name={JSON.stringify(keys)} title="color" value={pickedColor} onChange={onChangeColor} />
    </Container>
  );
}

export default ColorPicker;
