import React, {ReactElement, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSidebarFlip} from '@fortawesome/pro-light-svg-icons';

type StyleProps = {
  $width: number;
};

const Container = styled.div<StyleProps>`
  flex-shrink: 0;
  z-index: 9999;
  height: calc(100vh - 40px);
  margin-top: 40px;
  overflow: hidden auto;
  padding: 10px;
  box-sizing: border-box;
  width: ${(props) => props.$width}px;
  position: absolute;
  right: 0;
  top: 0;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(140, 178, 250, 0.3);
  border: 4px solid rgba(140, 178, 250, 0.5);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  //flex-wrap: wrap;
  color: rgba(140, 178, 250, 0.7);

  > div {
    text-align: center;
    font-size: 18px;
    margin: auto;
    overflow: hidden;
  }
`;

type IProps = {
  width?: number;
  isVisible: boolean;
};

function DockingPlace({width = 0, isVisible}: IProps): ReactElement {
  const [currentWidth, setCurrentWidth] = useState(0);

  useEffect(() => {
    if (isVisible) {
      setCurrentWidth(width);
    } else {
      setCurrentWidth(0);
    }
  }, [isVisible, width]);

  return (
    <Container $width={currentWidth}>
      {isVisible && (
        <InnerContainer>
          <div>
            <FontAwesomeIcon icon={faSidebarFlip} size="xl" />
            <div>Docking Here</div>
          </div>
        </InnerContainer>
      )}
    </Container>
  );
}

export default DockingPlace;
