import {ReactElement, useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import BasicSpinner from 'components/common/BasicSpinner';

function LoadingSpinner(): ReactElement {
  const {spinner} = useContext(CommonContext);

  if (spinner) {
    return <BasicSpinner isShow type="overlay" position="center-center" />;
  } else {
    return undefined;
  }
}

export default LoadingSpinner;
