import styled from 'styled-components';
import {useEffect, useRef, useState} from 'react';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import HighchartsReact, {HighchartsReactProps} from 'highcharts-react-official';
import Highcharts from 'highcharts/highstock';
import {formatter} from 'components/pc/widgets/timeseries/timeseries-functions';
import {colors} from 'common/colors';
import {toDateFormat} from 'utils/commons';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import {toSignificantDigit} from 'utils/number-utils';

type StyleProps = {
  $isLiveUpdate: boolean;
};

const Container = styled.div<StyleProps>`
  height: 100%;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 20px;
    z-index: 99;
    transform: rotate(45deg) translate(8px, -12px);
    background-color: ${(props) => (props.$isLiveUpdate ? props.theme.color.info : props.theme.color.warning)};
  }
`;

if (typeof window !== `undefined`) {
  highchartsAccessibility(Highcharts);
}

const initialOptions: HighchartsReactProps = {
  chart: {
    // type: 'spline',
    animation: false,
    spacing: [10, 10, 10, 10],
    events: {
      // load: onChartLoad
    },
    showInNavigator: true,
    zooming: {
      mouseWheel: false
    },
    // zoomType: 'x',
    alignTicks: false,
    style: {
      fontFamily: 'inherit'
    }
    // backgroundColor: '#f1f4f6'
  },
  credits: {
    enabled: false
  },
  title: false,
  xAxis: {
    type: 'timestamp',
    gridLineWidth: 1,
    tickPixelInterval: 200,
    labels: {
      formatter: function () {
        return toDateFormat(this.value, 'HH:mm:ss');
      },
      style: {
        fontSize: '20px',
        fontWeight: '400',
        fontFamily: 'Helvetica'
      }
    }
  },
  legend: false,
  plotOptions: {
    series: {
      showInNavigator: true,
      animation: false,
      label: {
        connectorAllowed: false
      },
      pointStart: 2010
    }
  },
  tooltip: {
    formatter: function () {
      return formatter.sharedTooltip(this.points, 4);
    },
    shared: true
  },

  responsive: {
    rules: [
      {
        condition: {
          maxWidth: 500
        },
        chartOptions: {
          legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom'
          }
        }
      }
    ]
  }
};

type IProps = {
  iw: number;
  ih: number;
  mat: any;
  cfg: IPfdCfg;
  latestTagHandler?: ILatestTagHandlerReturn;
  region: IAnnotation;
  id?: string;
};

function MetaPfdDataChart({region, id, cfg, latestTagHandler, iw, ih, mat}: IProps) {
  const [options, setOptions] = useState<HighchartsReactProps>(initialOptions); //{xAxis: {type: 'timestamp'}}
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const {latestResult, renewSubscribe, disconnectSubscribe} = latestTagHandler;
  const chartWidth = iw * region?.chartPosition?.w;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const fnc = () => {
      const tags = region.metaPfdTagInfo.filter((item) => item.isChartDisplay).map((item) => item.tag);
      renewSubscribe(id, tags, cfg?.isLiveUpdateDataValues);
    };
    timer = setTimeout(() => fnc(), 1000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [region.metaPfdTagInfo, id, cfg?.isLiveUpdateDataValues]);

  useEffect(() => {
    const res = [...(latestResult[id] || [])];
    //:todo object 검사하여 동일하면 return
    if (res) {
      const tags = region.metaPfdTagInfo
        .filter((item) => item.isChartDisplay)
        .map((item) => {
          const [category, ...rest] = item.tag;
          return [category.toLowerCase(), ...rest].join('-');
        });
      const newOpt = [...(res || [])]
        .filter((item) => tags.some((tag) => tag === item.key))
        .map(function (item_, index) {
          return {
            keys: item_.key,
            flattenKeys: item_.tag.join('-'),
            name: item_.key,
            color: colors.chart[index % colors.chart.length],
            data: [...(item_.value || [])] // 참조문제,
          };
        });
      const yAxis = newOpt.map((item) => ({
        title: {
          text: null
        },
        // gridLineWidth: 1,
        opposite: false,
        labels: {
          style: {color: item.color, fontSize: '20px', fontWeight: '400'},
          visible: false,
          formatter: function () {
            return toSignificantDigit(this.value, 2);
          }
        },
        visible: chartWidth * mat?.a > 300
      }));
      // const yAxis = {
      //   // alternateGridColor: '#FDFFD5',
      //   gridLineWidth: 1,
      //   title: {
      //     text: null
      //   },
      //   labels: {
      //     style: {
      //       fontWeight: '400',
      //       fontFamily: 'Helvetica'
      //     },
      //     formatter: function () {
      //       return toSignificantDigit(this.value, 2);
      //     }
      //   }
      // };
      const refined = newOpt.map((item, index) => ({
        data: item.data,
        name: item?.name,
        color: item?.color,
        yAxis: index,
        marker: {symbol: 'circle'},
        flattenKeys: item.flattenKeys,
        featureArray: item.keys
      }));

      setOptions((prev) => ({...prev, series: refined, yAxis}));
    }
  }, [latestResult, id, region.metaPfdTagInfo, chartWidth, mat]);

  useEffect(() => {
    return () => {
      disconnectSubscribe(id);
    };
  }, []);

  console.log(options);
  console.log(latestResult);

  return (
    <Container $isLiveUpdate={cfg?.isLiveUpdateDataValues}>
      <HighchartsReact
        highcharts={Highcharts}
        ref={chartRef}
        containerProps={{style: {height: '100%', minHeight: 10}}}
        options={options}
      />
    </Container>
  );
}

export default MetaPfdDataChart;
