import {ICommodityBasic} from 'components/pc/widgets/commodity/types';

export const defaultList: ICommodityBasic[] = [
  {
    tag: ['energy', 'crude oil'],
    unit: 'usd/bbl'
  },
  {
    tag: ['energy', 'gasoline'],
    unit: 'usd/gal'
  },
  {
    tag: ['energy', 'naphtha'],
    unit: 'usd/t'
  },
  {
    tag: ['energy', 'natural gas'],
    unit: 'usd/mmbtu'
  },
  {
    tag: ['energy', 'propane'],
    unit: 'usd/gal'
  },
  {
    tag: ['metals', 'lithium'],
    unit: 'cny/t'
  },
  {
    tag: ['metals', 'steel'],
    unit: 'cny/t'
  }
];
