import React, {useState} from 'react';
import useEventCallback from 'use-event-callback';

function RemoveScrollWrapper({children, ...otherProps}) {
  const [mouseOver, changeMouseOver] = useState(false);
  const onMouseMove = useEventCallback((e) => {
    if (!mouseOver) changeMouseOver(true);
    if (otherProps.onMouseMove) {
      otherProps.onMouseMove(e);
    }
  });
  // const onMouseLeave = useEventCallback(() => {
  //   setTimeout(() => {
  //     if (mouseOver) {
  //       changeMouseOver(false);
  //     }
  //   }, 100);
  // });

  return (
    <div
      className="containerDiv"
      id={otherProps?.id}
      onMouseMove={onMouseMove}
      // onMouseLeave={onMouseLeave}
      style={{width: '100%', height: '100%', position: 'absolute'}}
      {...otherProps}
    >
      {/*<RemoveScroll enabled={mouseOver} removeScrollBar={false}>*/}
      {children}
      {/*</RemoveScroll>*/}
    </div>
  );
}

export default RemoveScrollWrapper;
