import React, {BaseSyntheticEvent, ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import FileInfo from 'components/menu/FileInfo';
import {Button} from 'components/forms';
import {faArrowProgress} from '@fortawesome/pro-light-svg-icons';
import {ActionMenuBody, ActionMenuItem, ActionMenuItemLabel} from 'components/menu/action';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {useNavigate} from 'react-router-dom';
import useMetaPfdCommand from 'components/menu/pulldown/useMetaPfdCommand';
import ActionMenuFileSearchField from 'components/menu/ActionMenuFileSearchField';
import {IFile} from 'api/data-types';
import useRollActionMenu from 'components/menu/action/useRollActionMenu';

/**
 * MetaP&ID 의 저장 파일 목록을 표시
 * @constructor
 */
function MetaPfdFileList(): ReactElement {
  const [selectedList, setSelectedList] = useState<IFile[]>([]);
  const [selectedItem, setSelectedItem] = useState<IFile>();
  const searchInputState = useState<string>('');
  const [searchInput] = searchInputState;

  const {file, fileList, checkIsChanged} = useContext(MetaPfdContext);
  const navigate = useNavigate();
  const {create, remove} = useMetaPfdCommand();
  const [expandedItemId, onMouseEnter, onMouseLeave] = useRollActionMenu();

  useEffect(() => {
    if (fileList.length) {
      const pfdFile = fileList.filter((item) => item.fileType === 'meta_pfd');
      if (searchInput) {
        const filteredFile = pfdFile.filter((item) => item.fileName.toLowerCase().includes(searchInput.toLowerCase()));
        setSelectedList(filteredFile);
      } else {
        setSelectedList(pfdFile);
      }
    }
  }, [fileList, searchInput]);

  const onSelectItem = (item: IFile): void => {
    setSelectedItem(item);
  };

  const onClickLoad = (item: IFile): void => {
    const fnc = () => {
      navigate(`/mpfd/${item?._id}`);
    };
    if (checkIsChanged(fnc, 'load', item._id)) {
      fnc();
    }
  };

  const onClickDelete = async (e: BaseSyntheticEvent, item: IFile) => {
    e.currentTarget.blur();
    remove(item, item._id);
  };

  const onClickCreateFile = (): void => {
    create();
    // showMPfdModal('create');
    // navigate('/mpfd/new-file');
  };

  return (
    <>
      <ActionMenuHeader main="Meta PFD" sub="(Files)" description="Create, Edit, Delete Meta PFD Files">
        <Button variant="info" onClick={onClickCreateFile}>
          New
        </Button>
      </ActionMenuHeader>
      <ActionMenuBody>
        <ActionMenuFileSearchField searchInputState={searchInputState} />
        {selectedList.map((item) => (
          <ActionMenuItem key={item._id} id={item._id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ActionMenuItemLabel
              isActive={file?._id === item._id}
              icon={faArrowProgress}
              title={item?.fileName}
              userName={item?.createdUser}
              expanded={expandedItemId === item._id}
              onClick={() => onSelectItem(item)}
              item={item}
            />
            <FileInfo item={item} isActive={selectedItem?._id === item._id}>
              <Button variant="danger" disabled={file?._id === item._id} onClick={(e) => onClickDelete(e, item)}>
                Delete
              </Button>
              <Button onClick={() => onClickLoad(item)}>Load</Button>
            </FileInfo>
          </ActionMenuItem>
        ))}
      </ActionMenuBody>
    </>
  );
}

export default MetaPfdFileList;
