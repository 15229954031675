import React, {ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 211px;
`;

type IProps = {
  children?: ReactNode;
};
function PythonEditorBodyTop({children}: IProps) {
  return <Container>{children}</Container>;
}

export default PythonEditorBodyTop;
