import React, {useContext} from 'react';
import classnames from 'classnames';
import styled, {keyframes} from 'styled-components';
import {IAnnotation, pBoxCoords} from 'components/mpfd/type';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import {getRegionColor} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import useMetaPfdMouseEvents from 'components/mpfd/hooks/useMetaPfdMouseEvents';

type styledCoords = {
  width: number;
  height: number;
  top: number;
  left: number;
};

type IHighlightSvgProps = {
  $styleCoords: styledCoords;
  $zoomWithPrimary: boolean;
  $dragWithPrimary: boolean;
  $createWithPrimary: boolean;
  $highlighted: boolean;
  $color: string;
};

type IProps = {
  region: IAnnotation;
  pBox: pBoxCoords;
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
};

const highlightAnimation = keyframes`
  from {
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: 100;
  }
`;

const HighlightSvg = styled.svg<IHighlightSvgProps>`
  width: ${({$styleCoords}) => $styleCoords.width}px;
  height: ${({$styleCoords}) => $styleCoords.height}px;
  position: absolute;
  top: ${({$styleCoords}) => $styleCoords.top}px;
  left: ${({$styleCoords}) => $styleCoords.left}px;
  cursor: ${({$zoomWithPrimary, $dragWithPrimary, $createWithPrimary, $highlighted, type}) => {
    if ($highlighted) {
      return type === 'point' || type === 'block' ? 'grab' : 'auto';
    } else {
      return !($zoomWithPrimary || $dragWithPrimary || $createWithPrimary) ? 'pointer' : 'auto';
    }
  }};
  opacity: ${({$highlighted}) => ($highlighted ? 1 : 0)};
  pointer-events: ${({$highlighted, $zoomWithPrimary, $dragWithPrimary, $createWithPrimary, type}) => {
    if ($highlighted) {
      return type !== 'point' && type !== 'block' ? 'none' : 'auto';
    } else {
      return $zoomWithPrimary || $dragWithPrimary || ($createWithPrimary && !$highlighted) ? 'none' : 'auto';
    }
  }};
  z-index: ${META_PFD_zINDEX.HIGHLIGHT_BOX}; // 임의수정
  transition: opacity 500ms;

  path {
    vector-effect: non-scaling-stroke;
    stroke-width: 2px;
    stroke: ${({$color}) => $color};
    fill: none;
    stroke-dasharray: 5;
    animation: ${highlightAnimation} 4s linear infinite running;
  }
`;

function SelectedAnnotation({imagePosition, region: r, pBox}: IProps) {
  const {annotationAction, state} = useContext(MetaPfdContext);

  const {onMouseDown} = useMetaPfdMouseEvents(imagePosition);

  const {selectedTool} = state;

  if (!pBox.w || pBox.w === Infinity) return null;
  if (!pBox.h || pBox.h === Infinity) return null;
  if (r?.unfinished) return null;
  let styleCoords: {width: number; height: number; left: number; top: number};
  let pathD: string;
  let color = getRegionColor(r);

  const iw = imagePosition?.bottomRight.x - imagePosition?.topLeft.x;
  const ih = imagePosition?.bottomRight.y - imagePosition?.topLeft.y;

  pathD =
    r?.type === 'point'
      ? `M5,5 L${iw * 0.02 + 5},5 L${iw * 0.02 + 5},${iw * 0.02 + 5} L5,${iw * 0.02 + 5} Z`
      : `M5,5 L${r.w * iw + 5},5 L${r.w * iw + 5},${r.h * ih + 5} L5,${r.h * ih + 5} Z`;
  if (r.type === 'point') {
    styleCoords = {
      left: (r.x - 0.01) * iw - 5,
      top: r.y * ih - 0.01 * iw - 5,
      width: iw * 0.02 + 10,
      height: iw * 0.02 + 10
    };
  } else {
    styleCoords = {
      left: r.x * iw - 5,
      top: r.y * ih - 5,
      width: r.w * iw + 10,
      height: r.h * ih + 10
    };
  }

  return (
    <HighlightSvg
      key={r?.id}
      className={classnames({highlighted: r?.highlighted})}
      $zoomWithPrimary={selectedTool === 'zoom'}
      $dragWithPrimary={selectedTool === 'pan'}
      $createWithPrimary={selectedTool.includes('create')}
      $highlighted={r?.highlighted}
      type={r?.type}
      $styleCoords={styleCoords}
      $color={color} // Add color as a prop
      onMouseDown={(e) => {
        if (r?.type === 'point' && r?.highlighted && e.button === 0) {
          return onMouseDown(e, () => annotationAction.beginMovePoint(r));
          // return annotationAction.beginMovePoint(r);
        }
        if (e.button === 0 && !selectedTool.includes('create')) {
          return annotationAction.selectAnnotation(r);
        }
        // mouseEvents.onMouseDown(e);
      }}
    >
      <path d={pathD} />
    </HighlightSvg>
  );
}

export default SelectedAnnotation;
