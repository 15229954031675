import {
  Context,
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState
} from 'react';
import actionReducer from 'components/mpfd/hooks/reducer/action';
import {IAnnotation, IImageData, IPfdAction, IPfdState} from 'components/mpfd/type';
import useApi from 'api/useApi';
import {CommonContext} from 'components/common/CommonProvider';
import {ISaveFile} from 'components/menu/pulldown/ProcessCanvasSaveAsModal';
import {CONST} from 'components/mpfd/hooks/functions/action-calculation-functions';
import {metaPfdMenu, NEW_FILE_ROUTE} from 'components/menu/constants';
import {
  defaultMat,
  detectTargetFileUpload,
  getDetectLine,
  getSaveFileData,
  removeFileExtension
} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import {
  IApiReturnBasic,
  IFile,
  IGetFileListReturn,
  IImportFileReturn,
  IImportFileReturnData,
  IMPfdData
} from 'api/data-types';
import {ICloseWarning} from 'components/menu/pulldown/MetaPfdCloseWarningModal';
import useLatestTagHandler, {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import {AuthContext} from 'components/auth/AuthProvider';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {useRafState} from 'react-use';
import {Matrix} from 'transformation-matrix';
import {getApiHost} from 'api/function';
import {ISaveStatusModalState} from 'components/menu/pulldown/SaveLoadingModal';

type AnnotationLayerAction = (
  region: IAnnotation,
  targetPointsIdx?: number,
  point?: {x: number; y: number},
  clientXY?: {x: number; y: number}
) => void;

export type IMetaPfdContext = {
  fileList: IFile[];
  getFileById(id: string): IFile;
  getFileList(): Promise<IFile[]>;
  file: IFile | null;
  isFileOwner: boolean;
  isFilePrivate: boolean;
  create(imageInfo: Partial<IImageData>): void;
  change(imageInfo: Partial<IImageData>): void;
  load(file: IFile | null): void;
  close(): void;
  save(fileName: string, isSaveAs: boolean, state?: IPfdState): Promise<IFile>;
  localSave(): void;
  remove(id: string): Promise<IApiReturnBasic>;
  exportFile(fileName: string): Promise<Blob | null>;
  importFile(targetFile: File): Promise<IImportFileReturnData | null>;
  hasZoomScaleState: [boolean, Dispatch<SetStateAction<boolean>>];
  changeMatrix: Dispatch<SetStateAction<Matrix>>;
  matrix: Matrix;
  isShowPanelLayout: boolean;
  state: IPfdState;
  saveLoadingModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  saveState: [ISaveStatusModalState, Dispatch<SetStateAction<ISaveStatusModalState>>];
  saveAsModalState: [ISaveFile, Dispatch<SetStateAction<ISaveFile>>];
  closeModalState: [ICloseWarning, Dispatch<SetStateAction<ICloseWarning>>];
  ownershipModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  accessibilityModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  exportModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  importModalState: [boolean, Dispatch<SetStateAction<boolean>>];
  stageEnteredState: [boolean, Dispatch<SetStateAction<boolean>>];
  setIsShowPanelLayout(isShowPanelLayout: boolean): void;
  dispatchToReducer(action: IPfdAction): void;
  annotationAction: {
    selectAnnotation(region: IAnnotation): void;
    deleteAnnotation(region: IAnnotation): void;
    unSelectAnnotation(): void;
    beginMovePoint(region: IAnnotation): void;
    beginBoxTransform(region: IAnnotation, directions: number[]): void;
    removeLine: AnnotationLayerAction;
    splitAnnotationViaLine: AnnotationLayerAction;
    createBreakPointOnLine: AnnotationLayerAction;
    splitAnnotationViaPoint: AnnotationLayerAction;
    beginMovePointOfLine: AnnotationLayerAction;
    beginSelectPointOfLine: AnnotationLayerAction;
    removeBreakPointOnLine: AnnotationLayerAction;
  };
  utilAction: {
    cancel(): void;
  };
  uploadDetectionFile(): Promise<boolean>;
  detectLine(x: number, y: number): Promise<boolean>;
  isDetectProgress: boolean;
  onChangeDetectProgress(b: boolean): void;
  mPfdModal: {type: null | string};
  showMPfdModal(type: string): void;
  hideMPfdModal(): void;
  pfdList: string[];
  latestTagHandlerForTable: ILatestTagHandlerReturn;
  latestTagHandlerForChart: ILatestTagHandlerReturn;
  isPublic: boolean;
  isRename: boolean;
  setIsPublic(isPublic: boolean): void;
  setIsRename(isRename: boolean): void;
  transferOwnership(): Promise<IApiReturnBasic>;
  changeAccessibility(): Promise<IFile>;
  rename(newFileName: string): Promise<void>;
  checkIsChanged(confirmAction: () => void, actionType: string, afterActionAddress?: string): boolean;
  isMetaPfdModalOpened: boolean;
};

export const MetaPfdContext: Context<IMetaPfdContext> = createContext(null);

function MetaPfdProvider({children}: PropsWithChildren) {
  const api = useApi();
  const {addNotice, version, showContextMenu} = useContext(CommonContext);
  const {userProfile, ownerName} = useContext(AuthContext);
  const token = LocalStorageManager.getItem('PROCESSMETAVERSE_LOGIN_TOKEN') as string;
  const [file, setFile] = useState<IFile>(null);
  const isFileOwner = Boolean(file?.owner && file?.owner === userProfile?.username);
  const isFilePrivate = Boolean(file?.owner && file?.owner === userProfile?.username && file.public === true);
  const [fileList, setFileList] = useState<IFile[]>([]);
  const viewPullDownMenu = metaPfdMenu.find((menu) => menu.id === 'view').children;
  const hasZoomScaleState = useState(viewPullDownMenu.find((menu) => menu.id === 'zoom-panel').checked);
  const saveLoadingModalState = useState<boolean>(false);
  const [isShowSaveLoadingModal, setIsShowSaveLoadingModal] = saveLoadingModalState;
  const saveState = useState<ISaveStatusModalState>();
  const [, setSaveLoadingState] = saveState;
  const saveAsModalState = useState<ISaveFile>();
  const closeModalState = useState<ICloseWarning>();
  const ownershipModalState = useState<boolean>(false);
  const accessibilityModalState = useState<boolean>(false);
  const exportModalState = useState<boolean>(false);
  const importModalState = useState<boolean>(false);
  const [, setCloseModal] = closeModalState;
  const [pfdList, setPfdList] = useState([]);
  const latestTagHandlerForTable = useLatestTagHandler({type: 'latest_count', latest_count: 1});
  const latestTagHandlerForChart = useLatestTagHandler({type: 'latest_count', latest_count: 10});
  const [isPublic, setIsPublic] = useState(false);
  const [isRename, setIsRename] = useState(false);
  const [matrix, changeMatrix] = useRafState(defaultMat);
  const [isShowPanelLayout, setIsShowPanelLayout] = useState(true);
  const stageEnteredState = useState(true);
  // :todo Common Provider modal 대체
  const [mPfdModal, setMPfdModal] = useState({type: null});
  const isMetaPfdModalOpened =
    ownershipModalState[0] ||
    exportModalState[0] ||
    Boolean(closeModalState[0]) ||
    Boolean(saveAsModalState[0]) ||
    importModalState[0] ||
    Boolean(mPfdModal.type);

  const getFileList = async (): Promise<IFile[]> => {
    const response = await api.post<IGetFileListReturn>('/file_manage/get_file_list', {
      fileType: 'meta_pfd'
    });
    if (response) {
      const list = response?.data as IFile[];
      setFileList(list);
      return list;
    }
  };

  const getFileById = (id: string): IFile => {
    return fileList.find((file) => file._id === id);
  };

  const remove = async (_id: string) => {
    return api.post<IApiReturnBasic>('/file_manage/delete_file', {_id, fileType: 'meta_pfd'});
  };

  const create = (imageInfo: Partial<IImageData>): void => {
    dispatchToReducer({type: 'Initialize'});
    const imgFile = imageInfo?.imgFile;
    let newFile = {
      fileName: removeFileExtension(imageInfo?.fileName),
      fileType: 'meta_pfd',
      owner: userProfile?.username,
      pidName: imageInfo?.fileName,
      createdUser: 'Simacro',
      stateData: {},
      _id: 'new-file',
      localInfo: imgFile && {
        uploadImageDataUrl: imageInfo.imageSrc,
        uploadImageFile: imgFile
      }
    } as IFile;
    setFile(newFile);
    addNotice({text: 'Created Meta Pfd file.'});
  };

  const load = (file: IFile): void => {
    dispatchToReducer({type: 'Initialize'});
    const stateData = file.stateData as IMPfdData;
    dispatchToReducer({type: 'onLoad', fileData: {...stateData, detectionInfo: {}}});
    setFile({...file, localInfo: {...file?.localInfo, loadComplete: true}});
    if (window.location.pathname !== `/mpfd/${NEW_FILE_ROUTE}`) {
      addNotice({text: 'Load complete Meta Pfd file.'});
    }

    if (stateData?.detectionInfo?.detectedFileName) {
      const API_HOST = getApiHost();
      fetch(API_HOST + '/line_detection/check_detection_file', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({detection_id: stateData?.detectionInfo?.detectedFileName})
      })
        .then((res) => res.json())
        .then((res) => {
          if (!res?.success) {
            dispatchToReducer({
              type: 'INITIALIZE_DETECTION_INFO',
              detectionInfo: {
                viewport: {width: 0, height: 0},
                detectedFileName: '',
                detectedLines: []
              }
            });
          } else {
            dispatchToReducer({
              type: 'INITIALIZE_DETECTION_INFO',
              detectionInfo: stateData.detectionInfo
            });
          }
        });
    } else {
      dispatchToReducer({
        type: 'INITIALIZE_DETECTION_INFO',
        detectionInfo: stateData.detectionInfo
      });
    }
  };
  const close = (): void => {
    setFile(null);
    dispatchToReducer({type: 'Initialize'});
  };

  const change = (imageInfo: Partial<IImageData>) => {
    setFile((prev) => ({...prev, pidName: imageInfo.pidName}));
  };

  const [state, dispatchToReducer] = useReducer(actionReducer, CONST.state);
  const pfdStateRef = useRef(null);
  pfdStateRef.current = state;
  const pfdFileRef = useRef(null);
  pfdFileRef.current = file;
  const [detectionApiLoading, setDetectionApiLoading] = useState(false);
  const [isDetectProgress, setIsDetectProgress] = useState(false);

  const onChangeDetectProgress = (bool: boolean) => {
    setIsDetectProgress(bool);
  };

  const rename = async (newFileName: string): Promise<void> => {
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});
    const renameData = {
      _id: file._id,
      fileType: file.fileType,
      newFileName: newFileName
    };
    return api.post<IApiReturnBasic>('/file_manage/rename_file', renameData).then((res) => {
      setTimeout(() => setSaveLoadingState({title: 'Save', stepMessage2: 'Save Completed'}), 1000);
      if (res.success) {
        setFile((prev) => ({...prev, fileName: newFileName}));
      }
    });
  };

  const save = async (fileName: string, isSaveAs: boolean, pfdState: IPfdState): Promise<IFile> => {
    setSaveLoadingState(
      !isSaveAs
        ? {title: 'Save', stepMessage1: 'Saving current file'}
        : {title: 'Save As', stepMessage1: 'Saving current file'}
    );
    let tempState = pfdState || state;
    return getSaveFileData(file, tempState, fileName, isSaveAs, userProfile.username, isPublic).then((response) => {
      setTimeout(
        () =>
          setSaveLoadingState(
            !isSaveAs
              ? {title: 'Save', stepMessage2: 'Save Completed'}
              : {title: 'Save As', stepMessage2: 'Save Completed'}
          ),
        1000
      ); // modal이 1초 이상 보이도록 하기 위함

      addNotice({text: 'Save complete Meta Pfd file.'});
      getFileList().then();
      dispatchToReducer({type: 'ON_SAVE'});
      return response.data as IFile;
    });
  };

  const localSave = (): void => {
    if (pfdFileRef.current) {
      setFile((prev) => ({
        ...pfdFileRef.current,
        stateData: {
          regions: pfdStateRef.current?.regions,
          detectionInfo: pfdStateRef.current?.detectionInfo,
          cfg: {
            isDisplayLabels: Boolean(pfdStateRef.current?.cfg?.isDisplayDataValues),
            isDisplayDataValues: Boolean(pfdStateRef.current?.cfg?.isDisplayDataValues),
            isLiveUpdateDataValues: Boolean(pfdStateRef.current?.cfg?.isLiveUpdateDataValues)
          }
        },
        localInfo: {
          ...pfdFileRef?.current?.localInfo,
          loadComplete: false
        }
      }));
    }
  };

  const checkIsChanged = (confirmAction: () => void, actionType: string, afterActionAddress?: string) => {
    const isChangedFlag = pfdStateRef?.current?.isChanged;
    if (isChangedFlag) {
      setCloseModal({
        title: 'File Changed',
        content: !isFileOwner
          ? `This Layout has changed. But You can't save to the current file if the user ID does not match the file owner.\n Use 'Save As' to save your changes.`
          : 'This Layout has changed. Do you want to save the file?',
        fileName: file?.fileName,
        isSaveAs: false,
        actionType,
        confirmAction,
        afterActionAddress,
        pfdState: state,
        confirmLabel: 'Save As'
      });
      return false;
    } else {
      confirmAction();
      return true;
    }
  };

  const transferOwnership = async (): Promise<IApiReturnBasic> => {
    const transferData = {
      _id: file._id,
      fileType: file.fileType,
      new_owner: ownerName
    };

    return api.post<IApiReturnBasic>('/file_manage/transfer_ownership', transferData);
  };

  const changeAccessibility = async (): Promise<IFile> => {
    setSaveLoadingState({title: 'Save', stepMessage1: 'Saving current file'});
    const fileData = {
      ...file,
      public: !isPublic
    };
    const formData = new FormData();
    formData.append('data', JSON.stringify(fileData));
    return api.post<IApiReturnBasic>('/file_manage/save_file', formData, {type: 'formData'}).then((response) => {
      setTimeout(
        () =>
          setSaveLoadingState({
            title: 'Save',
            stepMessage2: `Change successfully completed to ${!isPublic ? 'public' : 'private'}`
          }),
        1000
      );
      addNotice({text: `Change complete to ${isPublic ? 'private' : 'public'}`});
      return getFileList().then(() => {
        const updatedFile = response?.data as IFile;
        setFile(updatedFile);
        return updatedFile;
      });
    });
  };

  const uploadDetectionFile = async () => {
    try {
      const dispatchObj = await detectTargetFileUpload(state);

      if (dispatchObj) {
        dispatchToReducer(dispatchObj);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    } finally {
    }
  };

  const detectLine = async (xP: number, yP: number) => {
    try {
      setDetectionApiLoading(true);
      const dispatchObj = await getDetectLine(state, xP, yP);
      if (dispatchObj) {
        dispatchToReducer(dispatchObj);
      }
      setDetectionApiLoading(false);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    } finally {
      setDetectionApiLoading(false);
    }
  };

  const showMPfdModal = (type: string) => {
    setMPfdModal({type});
  };

  const hideMPfdModal = () => {
    setMPfdModal({type: null});
  };

  const annotationLayerAction =
    (type: string) =>
    (
      region: IAnnotation,
      targetPointsIdx?: number,
      point?: {x: number; y: number},
      clientXY?: {x: number; y: number}
    ) => {
      dispatchToReducer({type, region, targetPointsIdx, point, clientXY});
    };

  const annotationAction = {
    selectAnnotation: (region: IAnnotation) => {
      dispatchToReducer({type: 'SELECT_REGION', region});
    },
    deleteAnnotation: (region: IAnnotation) => {
      dispatchToReducer({type: 'DELETE_REGIONS', regions: [region]});
    },
    unSelectAnnotation: () => {
      dispatchToReducer({type: 'UNSELECT_REGION'});
    },
    beginMovePoint: (region: IAnnotation) => {
      dispatchToReducer({type: 'BEGIN_MOVE_POINT', point: region});
    },
    beginBoxTransform: (region: IAnnotation, directions: number[]) => {
      dispatchToReducer({type: 'BEGIN_BOX_TRANSFORM', box: region, directions});
    },
    removeLine: annotationLayerAction('REMOVE_PARTS_OF_REGION'),
    splitAnnotationViaLine: annotationLayerAction('SPLIT_LINE_VIA_LINE'),
    createBreakPointOnLine: annotationLayerAction('CREATE_BREAK_POINT_REGION'),
    splitAnnotationViaPoint: annotationLayerAction('SPLIT_LINE_VIA_POINT'),
    beginMovePointOfLine: annotationLayerAction('BEGIN_MOVE_POINT_OF_LINE'),
    beginSelectPointOfLine: annotationLayerAction('BEGIN_SELECT_POINT_OF_LINE'),
    removeBreakPointOnLine: annotationLayerAction('REMOVE_BREAK_POINT_ON_LINE')
  };

  const utilAction = {
    cancel: () => {
      dispatchToReducer({type: 'CANCEL'});
      showContextMenu(null);
    }
  };

  const importFile = async (targetFile: File): Promise<IImportFileReturnData | null> => {
    const file = targetFile;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('info', JSON.stringify({owner: userProfile?.username}));
    const response = await api.post<IImportFileReturn>('/file_manage/import_file', formData, {type: 'formData'});

    if (response) {
      return response.data;
    }
    return null;
  };

  const exportFile = async (fileName: string): Promise<Blob | null> => {
    const fileData = {
      fileName,
      fileType: file.fileType,
      _id: file._id,
      version
    };

    const response = await api.exportFile('/file_manage/export_file', fileData);
    if (response) {
      const blob = await response.blob();
      const contentDisposition = response.headers.get('Content-Disposition');
      // 응답 blob 이 뭔가 시원찮으면 실패 리턴
      if (!contentDisposition) return null;

      let filename = '';
      const filenameRegex = /filename\*?=(?:UTF-8'')?([^;]*)/i;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = decodeURIComponent(matches[1]);
      }

      if (!filename) return null;

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
      return blob;
    }

    return null;
  };

  useEffect(() => {
    if (userProfile && token) {
      api.get<IApiReturnBasic>('/file_manage/get_pfd_list').then((res) => {
        const r = res.data as IFile[];
        setPfdList(r);
      });
      getFileList().then();
    }
  }, [token, userProfile]);

  const collection = {
    fileList,
    getFileById,
    getFileList,
    file,
    isFileOwner,
    isFilePrivate,
    transferOwnership,
    changeAccessibility,
    create,
    change,
    remove,
    close,
    load,
    save,
    localSave,
    changeMatrix,
    matrix,
    state,
    saveLoadingModalState,
    saveState,
    saveAsModalState,
    ownershipModalState,
    accessibilityModalState,
    stageEnteredState,
    hasZoomScaleState,
    isShowPanelLayout,
    exportModalState,
    importModalState,
    setIsShowPanelLayout,
    dispatchToReducer,
    annotationAction,
    uploadDetectionFile,
    detectLine,
    isDetectProgress,
    onChangeDetectProgress,
    mPfdModal,
    showMPfdModal,
    hideMPfdModal,
    pfdList,
    closeModalState,
    latestTagHandlerForTable,
    latestTagHandlerForChart,
    exportFile,
    importFile,
    isPublic,
    isRename,
    setIsPublic,
    setIsRename,
    rename,
    checkIsChanged,
    isMetaPfdModalOpened,
    utilAction
  };

  return <MetaPfdContext.Provider value={collection}>{children}</MetaPfdContext.Provider>;
}

export default MetaPfdProvider;
