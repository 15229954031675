import {useViewport} from 'reactflow';
import styled from 'styled-components';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {Button} from 'components/forms';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus, faMinus} from '@fortawesome/pro-light-svg-icons';
import React from 'react';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 180px;
  height: 40px;
  padding: 5px 10px;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  border: 2px solid #aaa;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  right: 0;
  bottom: 60px;

  &.on {
    bottom: 230px;
  }
`;

const ContentWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  span {
    flex-shrink: 0;
    width: 73px;
    font-size: 14px;
  }
`;

const ScaleBtnWrap = styled.div`
  flex: 1;
`;

type IProps = {
  hasMiniMap: boolean;
};

function ProcessCanvasZoomScaleViewer({hasMiniMap}: IProps) {
  const {zoomTo, zoomIn, zoomOut} = useProcessCanvasCommand();
  const viewport = useViewport();
  const checkMiniMap = hasMiniMap === true ? 'on' : 'off';

  const zoomPercentage = Math.round(((viewport.zoom / 4) * 400) / 5) * 5;

  return (
    <Container className={classNames('zoom-panel', checkMiniMap)}>
      <ContentWrap>
        <span>{zoomPercentage}%</span>
        <ScaleBtnWrap>
          <Button variant="none" className="zoom-in-btn" onClick={zoomIn}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
          <Button variant="none" className="zoom-out-btn" onClick={zoomOut}>
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        </ScaleBtnWrap>
        <Button
          variant="reset"
          onClick={() => {
            zoomTo(1);
          }}
        >
          Reset
        </Button>
      </ContentWrap>
    </Container>
  );
}

export default ProcessCanvasZoomScaleViewer;
