import React, {useState} from 'react';
import {useGLTF} from '@react-three/drei';
import * as THREE from 'three';
import {useThree} from '@react-three/fiber';
import useGltfCustom from 'components/pc/widgets/three/hooks/useGltfCustom';
import {Vector3Like} from 'three';
import {Vector3} from '@react-three/fiber/dist/declarations/src/three-types';

export function ModelShells({transparent, wireframes, blockVisibility, controlsRef}) {
  const modelScale = 0.2;
  const {nodes, materials} = useGltfCustom('/gltf/Shells/Shells.gltf');
  const shellOpacity = transparent ? 0.5 : 1;
  const annotations = [];
  const wireframesMaterial = new THREE.MeshBasicMaterial({color: '#0f0f0f', wireframe: true});
  const materialShell = wireframes ? wireframesMaterial : materials['Simacro_Shells'];

  const {camera, scene} = useThree();

  const [hoveredMesh, setHoveredMesh] = useState(null);

  const meshClick = (focusedMesh) => {
    console.log('>>>>focusedMesh', focusedMesh);
    const mesh = nodes[`${focusedMesh}_Shell`];
    const meshPosition = mesh.position as Vector3Like;

    // Desired distance and elevation
    const distance = 80;
    const elevationFactor = 20;

    // Calculate the direction from the camera to the mesh
    const direction = new THREE.Vector3().subVectors(meshPosition, camera.position).normalize();

    // Final position for the camera
    const finalPosition = new THREE.Vector3()
      .addVectors(meshPosition, direction.multiplyScalar(-distance))
      .multiplyScalar(modelScale);
    finalPosition.y += elevationFactor * modelScale;

    // Check for occlusion and adjust camera rotation if necessary
    const raycaster = new THREE.Raycaster(camera.position, direction);
    const intersects = raycaster.intersectObjects(scene.children, true);

    let occlusionDetected = false;
    for (let i = 0; i < intersects.length; i++) {
      // if (intersects[i].object !== mesh) {
      //   occlusionDetected = true;
      //   break;
      // }
    }

    if (occlusionDetected) {
      // Rotate the camera 45 degrees around the Y-axis of the mesh
      const yAxis = new THREE.Vector3(0, 1, 0);
      const angle = 45 * (Math.PI / 180); // Converting degrees to radians

      // Calculate the new camera position after rotation
      const rotatedPosition = new THREE.Vector3().subVectors(camera.position, meshPosition);
      rotatedPosition.applyAxisAngle(yAxis, angle);
      rotatedPosition.add(meshPosition);

      // Update the final position to the new rotated position
      finalPosition.copy(rotatedPosition);
    }

    // Animate camera movement
    const duration = 500;
    const startTime = Date.now();

    function animate() {
      const elapsed = Date.now() - startTime;
      const fraction = elapsed / duration;

      if (fraction < 1) {
        // Interpolate camera position
        camera.position.lerpVectors(camera.position, finalPosition, fraction);

        // Update OrbitControls target
        if (controlsRef && controlsRef.current) {
          const currentTarget = controlsRef.current.target;
          const newTarget = new THREE.Vector3(
            meshPosition.x * modelScale,
            meshPosition.y * modelScale,
            meshPosition.z * modelScale
          );
          currentTarget.lerpVectors(currentTarget, newTarget, fraction);
          controlsRef.current.update();
        }

        requestAnimationFrame(animate);
      } else {
        // Finalize camera position
        camera.position.copy(finalPosition);

        if (controlsRef && controlsRef.current) {
          controlsRef.current.target.set(
            meshPosition.x * modelScale,
            meshPosition.y * modelScale,
            meshPosition.z * modelScale
          );
          controlsRef.current.update();
        }
      }
    }

    animate();
  };

  return (
    <group dispose={null} scale={modelScale}>
      {Object.keys(blockVisibility).map((key) => (
        <mesh
          key={key}
          name={`Shell_${key}`}
          visible={blockVisibility[key]}
          geometry={nodes[`${key}_Shell_1`].geometry}
          // material-transparent
          material-opacity={shellOpacity}
          material={materialShell}
          position={nodes[`${key}_Shell`].position as Vector3}
          rotation={nodes[`${key}_Shell`].rotation}
          scale={nodes[`${key}_Shell`].scale}
          onClick={() => {
            meshClick(key);
          }}
        />
      ))}
    </group>
  );
}

// useGLTF.preload('/gltf/Shells/Shells.gltf');
