import {ReactElement, useContext} from 'react';
import {CommonContext} from 'components/common/CommonProvider';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common/index';
import {Button} from 'components/forms';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import useServerEvent from 'hooks/useServerEvent';

function DefaultModal(): ReactElement {
  const {modal, hideModal} = useContext(CommonContext);

  useModalKeyBoardEvent(
    {
      onConfirm: () => onConfirmModal(),
      onCancel: () => onCancelModal()
    },
    Boolean(modal)
  );

  const onCloseModal = () => {
    hideModal();
    modal?.onClose?.();
  };

  const onConfirmModal = () => {
    hideModal();
    modal?.onConfirm?.();
  };

  const onCancelModal = () => {
    hideModal();
    modal?.onCancel?.();
  };

  // 서버에서 400~600 을 보내면 여기서 처리
  useServerEvent();

  if (modal) {
    return (
      <ModalWrapper>
        <ModalHeader onClose={onCloseModal}>{modal?.title}</ModalHeader>
        <ModalBody>{modal.content}</ModalBody>
        <ModalFooter>
          {modal?.isShowConfirm && (
            <Button className="modal-btn" variant="primary" size="sm" onClick={onConfirmModal}>
              {modal.confirmLabel || 'Confirm'}
            </Button>
          )}
          {modal?.isShowCancel && (
            <Button className="modal-btn" variant="cancel" size="sm" onClick={onCancelModal}>
              {modal.cancelLabel || 'Cancel'}
            </Button>
          )}
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default DefaultModal;
