export enum META_PFD_zINDEX {
  REGION_COMPONENTS_POLYLINE = 0,
  IMAGE_BACKGROUND = 0,
  REGION_SHAPE_LAYER = 2,
  REGION_SHAPE = 3,
  DETECTED_LINE = 4,
  HIGHLIGHT_BOX = 5,
  ZOOM_ACTION_LAYER = 9,
  TABLE_LAYER = 8,
  TABLE_CONTAINER = 7,
  REGION_SELECT_AND_TRANSFORM_BOX_GRABBER = 7,
  CURSOR_IMG = 200,
  INFO_BOX = 8,
  DETECTED_LINE_INFO = 8,
  TOOLBOX = 9,
  FORMTOOLBOX = 10
}
