import {useContext} from 'react';
import {Node} from '@reactflow/core/dist/esm/types/nodes';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {CommonContext} from 'components/common/CommonProvider';
import {useReactFlow} from 'reactflow';
import {useNavigate, useParams} from 'react-router-dom';
import {DataContext} from 'api/DataProvider';
import {NEW_FILE_ROUTE, PullDownFunctions, widgetList} from 'components/menu/constants';
import {getUniqueKey} from 'utils/commons';
import {pcFunc} from 'utils/processCanvas-functions';
import {IFile} from 'api/data-types';
import {AuthContext} from 'components/auth/AuthProvider';
import {IWidgetMetaData} from 'components/pc/types';

type IReturn = {
  create(): void;
  close(): void;
  save(): void;
  saveAs(fileName?: string): void;
  rename(): void;
  remove(item: IFile, id: string): void;
  showGlobalSettings(): void;
  addWidget(widgetId: PullDownFunctions, clientX?: number, clientY?: number): void;
  copilotAddWidget(widgetId: PullDownFunctions, metaData?: IWidgetMetaData): void;
  copilotRemoveWidget(type: string): void;
  zoomIn(): void;
  zoomOut(): void;
  zoomTo(zoomLevel: number): void;
  pan(x: number, y: number): void;
  dock(isIn: boolean, idList: string[]): void;
  toggleLock(): void;
  toggleSmartAlign(): void;
  toggleMiniMap(): void;
  toggleZoomScale(): void;
  fitView(): void;
  fullScreen(): void;
  copy(): void;
  paste(x?: number, y?: number): void;
  deleteWidget(): void;
  importFile(): void;
  exportFile(): void;
  openOwnershipModal(): void;
  openChangeAccessibilityModal(): void;
};

function useProcessCanvasCommand(): IReturn {
  const {userProfile} = useContext(AuthContext);
  const {
    file,
    fileList,
    saveLoadingModalState,
    saveAsModalState,
    isFreezeState,
    dockState,
    hasSmartAlignState,
    hasMiniMapState,
    hasZoomScaleState,
    close,
    create,
    save,
    remove,
    getFileList,
    importModalState,
    exportModalState,
    ownershipModalState,
    accessibilityModalState,
    // exportFile,
    setIsRename,
    isFileOwner,
    checkIsChanged
  } = useContext(ProcessCanvasContext);
  const {globalSettingsModalState} = useContext(DataContext);
  const [, setIsShowSetting] = globalSettingsModalState;
  const [, setIsFreeze] = isFreezeState;
  const [, setDockIdList] = dockState;
  const [, setHasSmartAlign] = hasSmartAlignState;
  const [, setHasMiniMap] = hasMiniMapState;
  const [, setHasZoomScale] = hasZoomScaleState;
  const reactFlow = useReactFlow();
  const {addToast, showModal, showRemoteTooltip, showSpinner, hideSpinner} = useContext(CommonContext);
  const navigate = useNavigate();
  const {id} = useParams();
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;
  const [, setSaveFileModalData] = saveAsModalState;
  const [, setImportModalData] = importModalState;
  const [, setIsShowExportModal] = exportModalState;
  const [, setIsShowOwnerModal] = ownershipModalState;
  const [, setIsShowAccessibilityModal] = accessibilityModalState;

  const createLayout = (): void => {
    const fnc = () => {
      create();
      navigate(`/pc/${NEW_FILE_ROUTE}`);
    };

    if (checkIsChanged(fnc, 'create')) {
      fnc();
    }
  };

  const closeLayout = (): void => {
    const fnc = () => {
      close();
      navigate('/pc');
      reactFlow.setNodes([] as Node[]);
    };
    if (checkIsChanged(fnc, 'close')) {
      fnc();
    }
  };

  const saveLayout = (): void => {
    if (id === NEW_FILE_ROUTE) {
      // 저장한 적이 없으면 모달을 띄움
      setIsRename(false);
      setSaveFileModalData({title: 'Save', fileName: file.fileName, isSaveAs: false});
      console.log('no save');
    } else if (!isFileOwner) {
      // user name과 file owner가 일치하지 않을 때 상황을 알리는 모달을 띄움
      showModal({
        title: 'Save',
        content: `You can't save to the current file if the user ID does not match the file owner.\n Use 'Save As' to save your changes.`,
        confirmLabel: 'Save as',
        cancelLabel: 'Cancel',
        isShowCancel: true,
        onConfirm() {
          setSaveFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
        }
      });
    } else {
      // 저장한 적이 있으면 그냥 저장 (Save loading modal 띄우기)
      setIsShowSaveLoadingModal(true);
      save(file.fileName, reactFlow.getNodes(), reactFlow.getEdges(), false).then((fileData) => {
        setSaveFileModalData(undefined);
        // getFileList();
      });
    }
  };

  const removeLayout = (item: IFile, id: string): void => {
    if (id === file?._id) {
      // && file?.updatedTime === item?.updatedTime
      addToast({text: 'Can not delete opened file', delay: 5000});
    } else if (userProfile?.username === item.owner) {
      const found = fileList.find((file) => file._id === id);
      // todo: found 가 undefined 일 경우 예외 처리 필요
      showModal({
        title: 'Process Canvas',
        content: `Are you sure you want to delete this file?\n${found?.fileName}\nThis action cannot be undone. Please confirm before proceeding.`,
        onConfirm() {
          remove(id).then((response) => {
            if (response.success) {
              getFileList();
            }
          });
        }
      });
    } else {
      // user name과 file owner가 일치하지 않을 때 삭제 막기
      showModal({
        title: 'Process Canvas',
        content: `You are not the owner of this file, hence you do not have permission to delete it.`,
        cancelLabel: 'Close',
        isShowCancel: true,
        isShowConfirm: false
      });
    }
  };

  const openSaveAsModal = (fileName?: string): void => {
    setIsRename(false);
    setSaveFileModalData({title: 'Save as', fileName: fileName || file.fileName, isSaveAs: true});
  };

  const openRenameModal = (): void => {
    setIsRename(true);

    if (!isFileOwner) {
      // user name과 file owner가 일치하지 않을 때 상황을 알리는 모달을 띄움
      setIsRename(false);

      showModal({
        title: 'Rename',
        content: `You can't rename to the current file if the user ID does not match the file owner.\n Use 'Save As' to rename this file.`,
        confirmLabel: 'Save as',
        cancelLabel: 'Cancel',
        isShowCancel: true,
        onConfirm() {
          setSaveFileModalData({title: 'Save as', fileName: file.fileName, isSaveAs: true});
        }
      });
    } else {
      setSaveFileModalData({title: 'Rename', fileName: file.fileName, isSaveAs: false});
    }
  };

  const showGlobalSettings = (): void => {
    setIsShowSetting(true);
  };

  const addWidget = (widgetId: PullDownFunctions, clientX = 400, clientY = 50, metaData?: IWidgetMetaData): void => {
    let data = widgetList.find((widget) => widget.id === widgetId);
    const {type, style} = data;
    // todo: 중복 key 가 들어올 수 있다면 확인하고 방어 해야 함
    // todo: 화면에 위치해 있는 기존 widget 과 아래위로 겹치지 않는 위치를 구하는 로직 필요

    let position = reactFlow.screenToFlowPosition({x: clientX, y: clientY});

    const id = getUniqueKey();
    // zIndex
    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());
    reactFlow.addNodes({
      id,
      type,
      data: {icon: data?.icon, title: data?.title, type: data?.type, metaData},
      style,
      position,
      zIndex: highestZIndex
    });
  };

  const copilotAddWidget = (widgetId: PullDownFunctions, metaData?: IWidgetMetaData): void => {
    let widgetData = widgetList.find((widget) => widget.id === widgetId);
    const {type, style} = widgetData;

    // console.log('>>>>>>>>>>>  metaData', metaData);
    const position = {x: 400, y: 50};
    const id = getUniqueKey();

    const {highestZIndex} = pcFunc.getZIndex(reactFlow.getNodes());

    reactFlow.addNodes({
      id,
      type,
      data: {
        icon: widgetData?.icon,
        title: widgetData?.title,
        type: widgetData?.type,
        metaData
      },
      style,
      position,
      zIndex: highestZIndex
    });
  };

  const copilotRemoveWidget = (type: string): void => {
    const nodes = reactFlow.getNodes();
    nodes.forEach((node) => {
      if (node?.type === type) {
        reactFlow.deleteElements({nodes: [{id: node?.id}]});
      }
    });
  };

  const zoomIn = (): void => {
    reactFlow.zoomIn({duration: 500});
  };

  const zoomOut = (): void => {
    reactFlow.zoomOut({duration: 500});
  };

  const zoomTo = (zoomLevel: number): void => {
    reactFlow.zoomTo(zoomLevel, {duration: 500});
  };

  // x: 가로로 움직이는 픽셀 (변화량) 수, y: 세로로 움직이는 픽셀 (변화량) 수
  // todo: 키를 누르고 있는 경우에 대한 처리 필요
  const pan = (x: number, y: number): void => {
    const vp = reactFlow.getViewport();
    reactFlow.setViewport({x: vp.x - x, y: vp.y - y, zoom: vp.zoom});
  };

  const toggleLock = (): void => {
    setIsFreeze((prevState) => !prevState);
    reactFlow.setNodes((nodes) => nodes.map((item) => ({...item, selected: false})));
  };

  const toggleSmartAlign = (): void => {
    setHasSmartAlign((prevState) => !prevState);
  };

  const toggleMiniMap = (): void => {
    setHasMiniMap((prevState) => !prevState);
  };

  const toggleZoomScale = (): void => {
    setHasZoomScale((prevState) => !prevState);
  };

  const fitView = (): void => {
    reactFlow.fitView({duration: 500});
  };

  // todo useMetaPfdCommand 메소드와 refactoring 가능성 있음
  const fullScreen = () => {
    if (!document.fullscreenElement) {
      const body = document.getElementsByTagName('body')[0];
      if (body.requestFullscreen) return body.requestFullscreen();
      if (!document.fullscreenElement) {
        if (body.requestFullscreen) return body.requestFullscreen();
      } else if (document.exitFullscreen) return document.exitFullscreen();
      // console.log(isFullScreen);
      return Promise.resolve();
    } else {
      document.exitFullscreen();
    }
  };

  const copyWidget = async () => {
    const entireNodes = reactFlow.getNodes();
    const selectedNode = entireNodes.filter((item) => item?.selected);
    try {
      await navigator.clipboard.readText();
    } catch (e) {
      if (e.name === 'NotAllowedError') {
        // showRemoteTooltip('clipboard');
      }
      console.warn(e);
      return;
    }
    navigator.clipboard
      .writeText(JSON.stringify({selectedNode}))
      .then(() => {})
      .catch((err) => {});
  };

  const pasteWidget = async (clientX?: number, clientY?: number) => {
    const entireNodes = reactFlow.getNodes();
    const {highestZIndex} = pcFunc.getZIndex(entireNodes);
    try {
      await navigator.clipboard.readText();
    } catch (e) {
      if (e.name === 'NotAllowedError') {
        // showRemoteTooltip('clipboard');
      }
      console.warn(e);
      return;
    }
    navigator.clipboard
      .readText()
      .then((clipboardData) => {
        try {
          const target = JSON.parse(clipboardData);
          if (!target?.selectedNode) return;
          const position = reactFlow.screenToFlowPosition({x: clientX, y: clientY});
          const selectedNode = [...(target?.selectedNode || [])].map((item, idx) => ({
            ...item,
            id: getUniqueKey(),
            zIndex: highestZIndex + idx,
            position: {
              x: isNaN(position.x) ? item.position.x + 10 : position.x,
              y: isNaN(position.y) ? item.position.y + 10 : position.y
            }
            // positionAbsolute: position
          }));
          reactFlow.setNodes((nodes) =>
            [...(nodes || [])].concat(selectedNode).map((node) => ({...node, selected: false}))
          );
          if (selectedNode) {
            navigator.clipboard
              .writeText(JSON.stringify({selectedNode}))
              .then(() => {})
              .catch((err) => {});
          }
        } catch (err) {
          return;
        }
      })
      .catch((err) => {
        return;
      });
  };

  const deleteWidget = () => {
    const entireNodes = reactFlow.getNodes();
    const selectedNode = entireNodes.filter((item) => item?.selected);
    if (selectedNode?.length > 0) {
      const restNode = entireNodes.filter((item) => !item?.selected);
      reactFlow.setNodes(restNode);
    }
  };

  const exportFile = () => {
    if (file.localInfo.isChanged) {
      showModal({
        title: 'Export File',
        content: 'The layout file has been changed.\nDo you want to save before exporting?',
        isShowCancel: true,
        confirmLabel: 'Save',
        onConfirm() {
          // 저장하고 next();
          showSpinner({});
          const runSave = async () => {
            const savedFile = await save(file.fileName, reactFlow.getNodes(), reactFlow.getEdges(), false);
            if (savedFile) {
              setIsShowExportModal(true);
              hideSpinner();
            }
          };
          runSave();
        }
      });
    } else {
      setIsShowExportModal(true);
    }
  };

  const importFile = () => {
    setImportModalData(true);
  };

  // ownership 이전할 때 변동사항이 있을 경우 저장여부를 물어본다
  const openOwnershipModal = () => {
    if (file.localInfo.isChanged) {
      showModal({
        title: 'Transfer Ownership',
        content: 'The layout file has been changed.\nDo you want to save before transfer ownership?',
        isShowCancel: true,
        confirmLabel: 'Save',
        onConfirm() {
          // 저장하고 next();
          showSpinner({});
          const runSave = async () => {
            const savedFile = await save(file.fileName, reactFlow.getNodes(), reactFlow.getEdges(), false);
            if (savedFile) {
              setIsShowOwnerModal(true);
              hideSpinner();
            }
          };
          runSave();
        }
      });
    } else {
      setIsShowOwnerModal(true);
    }
  };

  const openChangeAccessibilityModal = () => {
    setIsShowAccessibilityModal(true);
  };

  const dock = (isIn: boolean, widgetIdList: string[]): void => {
    setDockIdList((prev) => {
      if (isIn) {
        // 추가한 케이스 (가장 위로 추가)
        return [...widgetIdList, ...prev];
      } else {
        // 제거한 케이스
        return prev.filter((widgetId) => !widgetIdList.includes(widgetId));
      }
    });
  };

  return {
    create: createLayout,
    close: closeLayout,
    save: saveLayout,
    saveAs: openSaveAsModal,
    rename: openRenameModal,
    remove: removeLayout,
    showGlobalSettings,
    addWidget,
    copilotAddWidget,
    copilotRemoveWidget,
    zoomIn,
    zoomOut,
    zoomTo,
    pan,
    dock,
    toggleLock,
    toggleSmartAlign,
    toggleMiniMap,
    toggleZoomScale,
    fitView,
    fullScreen,
    copy: copyWidget,
    paste: pasteWidget,
    deleteWidget,
    importFile,
    exportFile,
    openOwnershipModal,
    openChangeAccessibilityModal
  };
}

export default useProcessCanvasCommand;
