import {ModelPipes} from 'components/pc/widgets/three/renderer/stream/Model_Pipes';
import {ModelPipeFeed} from 'components/pc/widgets/three/renderer/stream/PipeFeed';
import {ModelPipeSlurryOil} from 'components/pc/widgets/three/renderer/stream/PipeSlurryOil';
import {ModelPipeLeanGas} from 'components/pc/widgets/three/renderer/stream/PipeLeanGas';
import {ModelPipe1Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_1_Line';
import {ModelPipe2Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_2_Line';
import {ModelPipe3Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_3_Line';
import {ModelPipe4_1Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_4_1_Line';
import {ModelPipe4_2Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_4_2_Line';
import {ModelPipe5Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_5_Line';
import {ModelPipe6Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_6_Line';
import {ModelPipe7Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_7_Line';
import {ModelPipe8Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_8_Line';
import {ModelPipe9Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_9_Line';
import {ModelPipe10Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_10_Line';
import {ModelPipe11Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_11_Line';
import {ModelPipe12Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_12_Line';
import {ModelPipe13Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_13_Line';
import {ModelPipe14Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_14_Line';
import {ModelPipe15Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_15_Line';
import {ModelPipe16Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_16_Line';
import {ModelPipe17Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_17_Line';
import {ModelPipe18Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_18_Line';
import {ModelPipe19Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_19_Line';
import {ModelPipe20Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_20_Line';
import {ModelPipe21Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_21_Line';
import {ModelPipe22Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_22_Line';
import {ModelPipe23Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_23_Line';
import {ModelPipe24Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_24_Line';
import {ModelPipe25Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_25_Line';
import {ModelPipe26Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_26_Line';
import {ModelPipe27Line} from 'components/pc/widgets/three/renderer/stream/Pipes_Pipe_27_Line';
import {ModelPipeText} from 'components/pc/widgets/three/renderer/stream/Pipes_Text';
import {ModelPipeArrow} from 'components/pc/widgets/three/renderer/stream/Pipes_Arrow';

type IProps = {
  streamVisibility: boolean;
};

function Pipes({streamVisibility}: IProps) {
  return (
    <>
      <ModelPipes />
      {/*<ModelPipeFeed streamVisibility={streamVisibility} />*/}
      <ModelPipeSlurryOil streamVisibility={streamVisibility} />
      <ModelPipeLeanGas streamVisibility={streamVisibility} />
      <ModelPipe1Line />
      <ModelPipe2Line />
      <ModelPipe3Line />
      <ModelPipe4_1Line />
      <ModelPipe4_2Line />
      <ModelPipe5Line />
      <ModelPipe6Line />
      <ModelPipe7Line />
      <ModelPipe8Line />
      <ModelPipe9Line />
      <ModelPipe10Line />
      <ModelPipe11Line />
      <ModelPipe12Line />
      <ModelPipe13Line />
      <ModelPipe14Line />
      <ModelPipe15Line />
      <ModelPipe16Line />
      <ModelPipe17Line />
      <ModelPipe18Line />
      <ModelPipe19Line />
      <ModelPipe20Line />
      <ModelPipe21Line />
      <ModelPipe22Line />
      <ModelPipe23Line />
      <ModelPipe24Line />
      <ModelPipe25Line />
      <ModelPipe26Line />
      <ModelPipe27Line />
      <ModelPipeText />
      <ModelPipeArrow />
    </>
  );
}

export default Pipes;
