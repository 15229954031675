import {type DragEvent, ReactElement, useContext} from 'react';
import {IWidgetActionMenu} from 'components/menu/types';
import {frozenLayoutModalOptions, noFileModalOptions, PullDownFunctions, widgetList} from 'components/menu/constants';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import {ActionMenuBody, ActionMenuItem, ActionMenuItemLabel} from 'components/menu/action';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {CommonContext} from 'components/common/CommonProvider';

/**
 * Widget 메뉴의 목록
 * @constructor
 */
function ActionMenuWidgetList(): ReactElement {
  const {showModal} = useContext(CommonContext);
  const {file, isFreezeState} = useContext(ProcessCanvasContext);
  const [isFreeze] = isFreezeState;
  const {addWidget} = useProcessCanvasCommand();

  const onClickCreate = (data: IWidgetActionMenu): void => {
    if (isFreeze) {
      showModal(frozenLayoutModalOptions);
      return;
    }
    if (!file) {
      showModal(noFileModalOptions);
      return;
    }
    addWidget(data.id as PullDownFunctions);
  };

  const onDragStartItem = (e: DragEvent<HTMLDivElement>, item: IWidgetActionMenu): void => {
    if (isFreeze) {
      showModal(frozenLayoutModalOptions);
      return;
    }
    if (!file) {
      showModal(noFileModalOptions);
      return;
    }

    let data = widgetList.find((widget) => widget.id === item.id);
    const dragWidgetData = {
      icon: data?.icon,
      title: data?.title,
      type: data?.type,
      style: data?.style
    };
    e.dataTransfer?.setData('application/dataTransfer/widget', JSON.stringify(dragWidgetData));
  };

  return (
    <>
      <ActionMenuHeader main="Widget" description="Add a Widget" />
      <ActionMenuBody>
        {widgetList
          .filter((item) => !item?.inactive)
          .map((item) => (
            <ActionMenuItem key={item.id} id={item.id} disabled={isFreeze || !file}>
              <ActionMenuItemLabel
                icon={item.icon}
                title={item.title}
                userName={item.description}
                draggable={!isFreeze || Boolean(file)}
                disabled={isFreeze || !file}
                onClick={() => onClickCreate(item)}
                onDragStart={(e) => onDragStartItem(e, item)}
              />
            </ActionMenuItem>
          ))}
      </ActionMenuBody>
    </>
  );
}

export default ActionMenuWidgetList;
