import React, {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import Accordion from 'components/common/arrocdion/Accordian';
import {IPythonEditorCodRunResult} from 'components/pc/widgets/pythonEditor/types';

const Container = styled.div`
  width: 100%;
  height: 100%;
`;

type IProps = {
  codRunResultState?: [IPythonEditorCodRunResult, Dispatch<SetStateAction<IPythonEditorCodRunResult>>];
};
function PythonCodeResult({codRunResultState}: IProps) {
  const [codeRunResult] = codRunResultState;

  return (
    <Container>
      <Accordion headers={['Install Output', 'Output', 'Error', 'Outflow Result']}>
        <pre>{codeRunResult?.install_results}</pre>
        <pre>{codeRunResult?.print_out}</pre>
        <pre>{codeRunResult?.error}</pre>
        <pre>{codeRunResult?.outflow_results}</pre>
      </Accordion>
    </Container>
  );
}

export default PythonCodeResult;
