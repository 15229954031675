import {MouseEvent, ReactElement, useContext, useRef, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/pro-light-svg-icons';
import styled from 'styled-components';
import {IContextMenuItem} from 'components/common/context-menu/ContextMenu';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  color: #ccc;
  position: relative;
  width: 180px;
  max-width: 180px;

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: #272546;
    color: #fff;
  }
  &.disabled {
    opacity: 0.4;
    cursor: default;
  }
`;
const MenuTitle = styled.div`
  white-space: nowrap;
  height: 40px;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 15px;
  justify-content: space-between;
  box-sizing: border-box;
`;
const MenuLayer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c9c9c9;
  background-color: rgba(39, 37, 70, 0.8);
  position: absolute;
  top: 0;
`;

type IProps = {
  data: IContextMenuItem;
};
function ContextMenuItem({data}: IProps): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const {showContextMenu} = useContext(CommonContext);
  const [left, setLeft] = useState<number>();
  const [isShowSubMenu, setIsShowSubMenu] = useState<boolean>(false);

  const onMouseEnter = (): void => {
    const width = ref.current?.clientWidth;
    if (width) setLeft(width);
    setIsShowSubMenu(true);
  };

  const onMouseLeave = (): void => {
    setIsShowSubMenu(false);
  };

  const onClick = (e: MouseEvent): void => {
    e.stopPropagation();
    if (data.disabled) return;

    data?.callback();

    if (data.callback) {
      showContextMenu(null);
    }
  };

  return (
    <Container
      ref={ref}
      className={data.disabled && 'disabled'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onClick}
    >
      <MenuTitle>
        {data.label}
        {data.children && <FontAwesomeIcon icon={faAngleRight} />}
      </MenuTitle>
      {isShowSubMenu && data.children && (
        <MenuLayer style={{left}}>
          {data.children.map((menu) => (
            <ContextMenuItem key={menu.value} data={menu} />
          ))}
        </MenuLayer>
      )}
    </Container>
  );
}

export default ContextMenuItem;
