import {
  CommodityWidget,
  DatasheetLocalDbWidget,
  DataSheetWidget,
  HmbRevisionWidget,
  HmbWidget,
  ImageWidget,
  JmpWidget,
  LineDetectionWidget,
  MetaPfdWidget,
  PythonEditorWidget,
  SampleWidget,
  ShapeNode,
  SpreadsheetWidget,
  StickyNoteWidget,
  ThreeLandscapeWidget,
  ThreeWidget,
  TimeSeriesWidget,
  WeatherWidget,
  WebWidget,
  YoutubeWidget
} from 'components/pc/widgets/index';

export const widgetCollection = {
  CommodityWidget,
  DataSheetWidget: DatasheetLocalDbWidget,
  DatasheetLocalDbWidget,
  HmbRevisionWidget,
  HmbWidget: HmbRevisionWidget,
  ImageWidget,
  JmpWidget,
  LineDetectionWidget,
  MetaPfdWidget,
  PythonEditorWidget,
  SampleWidget,
  SpreadsheetWidget,
  StickyNoteWidget,
  ThreeLandscapeWidget,
  ThreeWidget,
  TimeSeriesWidget,
  WeatherWidget,
  WebWidget,
  YoutubeWidget,
  shape: ShapeNode
};
