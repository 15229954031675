import React, {CSSProperties, ReactElement, useContext, useEffect} from 'react';
import styled from 'styled-components';
import {CommonContext} from 'components/common/CommonProvider';
import ContextMenuItem from 'components/common/context-menu/ContextMenuItem';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  //background: rgba(255, 0, 0, 0.2);
  //pointer-events: none;
  z-index: 10;
  display: flex;
`;
const MenuLayer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #c9c9c9;
  background-color: rgba(39, 37, 70, 0.8);
  padding: 5px 0;
  position: absolute;
`;

const Title = styled.div`
  //font-weight: bold;
  padding: 10px 15px;
  //color: #fff;
  color: #fad2bb;
`;

export type IContextMenuItem = {
  label: string;
  value: string;
  style?: CSSProperties;
  disabled?: boolean;
  callback?(): void;
  children?: IContextMenuItem[];
};
export type IContextMenu = {
  list: IContextMenuItem[];
  title?: string;
  event?: React.MouseEvent;
};

function ContextMenu(): ReactElement {
  const {contextMenu, showContextMenu} = useContext(CommonContext);

  const onWheel = () => {
    showContextMenu(null);
  };

  const onMouseDown = (e: React.MouseEvent | MouseEvent) => {
    e.stopPropagation();
    showContextMenu(null);
  };

  useEffect(() => {
    if (contextMenu) {
      document.addEventListener('mousedown', onMouseDown);
      document.addEventListener('wheel', onWheel);
    }
    return () => {
      document.removeEventListener('mousedown', onMouseDown);
      document.removeEventListener('wheel', onWheel);
    };
  }, [contextMenu]);

  //  onMouseDown={onMouseDown}

  if (contextMenu) {
    const bottomPos = contextMenu.event.pageY + 10 + 40 * contextMenu.list.length;
    const rightPosMaximum = contextMenu.event.pageX + 200;
    const appContainer = document.getElementById('pm-app-container') as HTMLDivElement;
    const rect = appContainer.getBoundingClientRect();
    const calcX = rect.right < rightPosMaximum ? rect.right - 180 : contextMenu.event?.pageX;
    const calcY =
      rect.bottom < bottomPos ? rect.bottom - (10 + 40 * contextMenu.list.length + 5) : contextMenu.event?.pageY;

    return (
      <Container>
        <MenuLayer style={{top: calcY, left: calcX}}>
          {contextMenu.title && <Title>{contextMenu.title}</Title>}
          {contextMenu.list?.map((menu) => <ContextMenuItem key={menu.value} data={menu} />)}
        </MenuLayer>
      </Container>
    );
  }
  return undefined;
}

export default ContextMenu;
