import React from 'react';
import styled from 'styled-components';
import LoginScreenHeader from 'components/auth/login/parts/LoginScreenHeader';
import {Button} from 'components/forms';
import LoginScreenContainer from 'components/auth/login/parts/LoginScreenContainer';
import {useNavigate} from 'react-router-dom';

const NoticeContainer = styled.div`
  font-size: 16px;
  font-weight: 100;
`;

function PasswordResetComplete() {
  const navigate = useNavigate();
  return (
    <LoginScreenContainer>
      <LoginScreenHeader title="Password reset complete!" fontSize="24px" />
      <NoticeContainer>
        From now on, you can securely access your account using your updated password. Please proceed to the login page
        to sign in and continue using our services.
      </NoticeContainer>
      <Button height="40px" onClick={() => navigate('/login')}>
        Try Login
      </Button>
    </LoginScreenContainer>
  );
}

export default PasswordResetComplete;
