import {Navigate, Outlet, useNavigate} from 'react-router-dom';
import React, {useContext, useEffect, useState} from 'react';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {verifyTokenRequest} from 'components/auth/login/login-api-functions';
import {CommonContext} from 'components/common/CommonProvider';

type IAuthorized = true | false | null;

function AnonymousRoute() {
  const {showSpinner, hideSpinner} = useContext(CommonContext);
  const [authorized, setAuthorized] = useState<IAuthorized>(null); // initially undefined!

  const navigate = useNavigate();

  useEffect(() => {
    const authorize = () => {
      showSpinner({});
      const token = LocalStorageManager.getItem<string>('PROCESSMETAVERSE_LOGIN_TOKEN');
      if (token) {
        verifyTokenRequest(token).then((isValidate) => {
          setAuthorized(isValidate);
          if (!isValidate) {
            LocalStorageManager.removeItem('PROCESSMETAVERSE_LOGIN_TOKEN');
            navigate('/login');
          }
          hideSpinner();
        });
      } else {
        setAuthorized(false);
        hideSpinner();
      }
    };
    authorize();
  }, []);

  if (authorized === null) {
    return <></>;
  }
  return authorized ? <Navigate to="/" replace /> : <Outlet />;
}
export default AnonymousRoute;
