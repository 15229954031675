import React, {Dispatch, SetStateAction} from 'react';
import styled from 'styled-components';
import Monaco from '@monaco-editor/react';

const Container = styled.div`
  width: 100%;
  height: 100%;
  .ace_scrollbar-v {
    width: 10px !important;
    &::-webkit-scrollbar {
      background-color: rgba(255, 255, 255, 0.0784);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.3);
    }
    .ace_scrollbar-inner {
      width: 10px !important;
    }
  }
`;

type IProps = {
  codeState?: [any, Dispatch<SetStateAction<any>>];
};
function PythonCodeEditor({codeState}: IProps) {
  const [code, setCode] = codeState;

  return (
    <Container>
      <Monaco
        height="100%"
        defaultLanguage="python"
        defaultValue="# Write your Python code here"
        value={code}
        theme="vs-dark"
        onChange={(value) => setCode(value)}
        // onMount={handleEditorDidMount}
        options={{
          fontSize: 15,
          minimap: {enabled: false},
          scrollBeyondLastLine: false
        }}
      />
    </Container>
  );
}

export default PythonCodeEditor;
