import {ITagData} from 'api/data-types';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import {Dispatch, KeyboardEvent, MouseEvent, RefObject, SetStateAction} from 'react';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {spreadsheetUtilFnc} from 'components/spreadsheet/spreadsheetl-functions';
import {IHmbSheetCellInfo, ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import {useReactFlow} from 'reactflow';

type IReturn = {
  onContextMenuPane(event: MouseEvent, row: number, col: number): Promise<void>;
  onKeyDownCell?(event: KeyboardEvent): void;
  beforeOnCellMouseDown(event: globalThis.MouseEvent, coords: CellCoords, TD: HTMLTableCellElement): void;
};
function useHmbNormalSpreadSheetHandler(
  id: string,
  spreadSheetMetaDataState: [IHandsonTableSpreadSheetData, Dispatch<SetStateAction<IHandsonTableSpreadSheetData>>],
  cellInfoState: [IHmbSheetCellInfo[][], Dispatch<SetStateAction<IHmbSheetCellInfo[][]>>],
  tagSelectorInfoState: [IHmbSheetCellInfo, Dispatch<SetStateAction<IHmbSheetCellInfo>>],
  handsontableRef: RefObject<any>,
  selectedTagsState: [string[][], Dispatch<SetStateAction<string[][]>>],
  isShowWidgetModalState: [boolean, Dispatch<SetStateAction<boolean>>],
  seperatorRowIdx: number,
  setHiddenFeatureList: Dispatch<SetStateAction<string[]>>
): IReturn {
  const [spreadsheetMetaData, setSpreadsheetMetaData] = spreadSheetMetaDataState;
  const [cellInfo, setCellInfo] = cellInfoState;
  const [tagSelectorInfo, setTagSelectorInfo] = tagSelectorInfoState;
  const [, setSelectedTags] = selectedTagsState;
  const [, setIsShowWidgetModal] = isShowWidgetModalState;
  const {setNodes} = useReactFlow();

  const onSelectTags = (tags: string[][], tagInfos: ITagData[]): void => {
    const newCellInfo =
      cellInfo ||
      spreadsheetUtilFnc.getArr(spreadsheetMetaData?.data.length, spreadsheetMetaData?.data[0].length, null);
    const newCellInfoRemake = newCellInfo.map((item) => [...item]);
    const selectedRangeArray = tagSelectorInfo?.selectedRangeArray;
    for (let i = 0; i < selectedRangeArray?.length; i++) {
      const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(selectedRangeArray[i]);
      for (let j = fromRow; j < toRow + 1; j++) {
        for (let k = fromCol; k < toCol + 1; k++) {
          newCellInfoRemake[j][k] = tagInfos?.[0];
        }
      }
    }
    setCellInfo(newCellInfoRemake as ISpreadSheetCellInfo[][]);
  };

  const [createContextMenu] = useContextMenu();

  const onContextMenuPane = async (event: MouseEvent, row: number, col: number) => {
    event.preventDefault();
    const cellTagInfo = cellInfo?.[row]?.[col] as ISpreadSheetCellInfo;
    const handsontableHotInstance = handsontableRef.current.hotInstance;
    let selectedRangeArray = handsontableHotInstance.getSelected();
    /**
     * 우클릭이 일어났지만, context menu 의 document.mousedown 에 의해서 선택이 되지 않았을 때, 현재 선택된 cell 을 수동으로 선택해줌
     */
    let columnQue = [];
    let rowQue = [];
    if (!selectedRangeArray) {
      handsontableRef.current.hotInstance.selectCell(row, col);
      selectedRangeArray = [[row, col, row, col]];
    }
    const selectedFeatures = [];
    for (let i = 0; i < selectedRangeArray?.length; i++) {
      const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(selectedRangeArray[i]);
      columnQue.push(fromCol);
      columnQue.push(toCol);
      rowQue.push(fromRow);
      rowQue.push(toRow);
      for (let j = fromRow; j < toRow + 1; j++) {
        selectedFeatures.push(spreadsheetMetaData?.data?.[j]?.[0]);
      }
    }

    const list = [
      {
        label: 'Set Stream',
        value: 'set-stream-node-selector',
        callback() {
          setTagSelectorInfo({
            row,
            col,
            selectedRangeArray
          });
          setSelectedTags(cellTagInfo?.tag ? [cellTagInfo?.tag] : []);
        }
      },
      // {
      //   label: 'Remove Selected Streams',
      //   value: 'hide-row',
      //   callback() {}
      // },
      {
        label: 'Hide Selected Rows',
        value: 'hide-row',
        callback() {
          const hideTargets = selectedFeatures.filter((item) => item !== 'Components' && item !== 'Name');
          setHiddenFeatureList((prev) => {
            setNodes((nodes) =>
              nodes.map((node) =>
                node.id === id
                  ? {
                      ...node,
                      data: {
                        ...node?.data,
                        metaData: {
                          ...node?.data?.metaData,
                          hiddenFeature: [...prev, ...hideTargets]
                        }
                      }
                    }
                  : node
              )
            );
            return [...prev, ...hideTargets];
          });
        }
      }
    ];
    createContextMenu({event, list});
  };

  const onKeyDownCell = (event: KeyboardEvent) => {
    event.preventDefault();
  };

  const beforeOnCellMouseDown = (event: globalThis.MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) => {};

  return {
    onContextMenuPane,
    onKeyDownCell,
    beforeOnCellMouseDown
  };
}

export default useHmbNormalSpreadSheetHandler;
