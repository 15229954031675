import styled from 'styled-components';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import React, {useContext, MouseEvent, BaseSyntheticEvent, useState, useEffect} from 'react';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import {Button} from 'components/forms';
import {faArrowUpLeft} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import useContextMenu from 'components/common/context-menu/useContextMenu';

const Container = styled.div`
  width: 100%;
  height: 100%;
  z-index: ${META_PFD_zINDEX.FORMTOOLBOX};
  position: relative;
`;

type ToolBoxProps = {
  $x: number;
  $y: number;
};

const ToolBoxContainer = styled.div<ToolBoxProps>`
  position: absolute;
  left: ${(props) => props.$x - 60}px;
  top: ${(props) => props.$y}px;
  z-index: ${META_PFD_zINDEX.FORMTOOLBOX};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 36px;
  padding: 5px;
  gap: 4px;
  pointer-events: auto;
  user-select: none;
  box-shadow: 0 2px 10px 0 #00000033;
  border: 2px solid #aaaaaa;
  background-color: #ffffff;
  border-radius: 5px;
`;

type IconWrapperProps = {
  $rightLines?: boolean;
};

const IconWrapper = styled.div<IconWrapperProps>`
  display: flex;
  flex-direction: column;

  > label {
    height: 50px;
    display: flex;
    align-items: center;
  }

  > input {
    width: 36px;
    height: 34px;
    border-radius: 5px;
    border: 1px solid black;
    text-align: center;
    font-size: 15px;
    font-weight: 400;
  }

  > button {
    height: 36px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin-right: 5px;
    }
    &.active {
      background-color: #d7eeff;
    }

    &:not(:disabled, .active):hover {
      background-color: #edf6ff;
    }

    &:disabled {
      cursor: not-allowed;
    }
    img {
      -webkit-user-drag: none;
    }
  }
`;

type IToolbox = {
  name: 'ArrowNormal';
  icon: string | null;
  type: 'ArrowNormal';
  rightLines?: boolean;
  active?: boolean;
};

type IToolInfo = {
  x: number;
  y: number;
  toolList: IToolbox[];
};

function FormToolBox() {
  const {state, dispatchToReducer} = useContext(MetaPfdContext);
  const [toolInfo, setToolInfo] = useState<IToolInfo>();
  // const [createContextMenu] = useContextMenu();
  //
  // const onContextMenuPane = (event: MouseEvent) => {
  //   // console.log('>>> ', event.pageX, event.pageY, event);
  //   event.preventDefault();
  //
  //   const list = [
  //     {
  //       label: `${item.active ? 'Remove' : 'Add'} arrow head`,
  //       value: 'paste-widget',
  //       callback: () => {
  //         paste(event.pageX, event.pageY);
  //       },
  //     },
  //
  //   ];
  //   createContextMenu({event, list});
  // };

  useEffect(() => {
    switch (state?.mode?.mode) {
      case 'SELECT_POINT_OF_LINE': {
        const mode = state?.mode;
        const {x, y} = mode.clientXYCoord;
        const active = [...(mode.region.arrowPoints || [])].some(
          (item) => item.x === mode.targetPoint.x && item.y === mode.targetPoint.y
        );
        // setToolInfo({
        //   x,
        //   y,
        //   toolList: [{name: 'ArrowNormal', icon: 'mPfdToolBoxNormalArrow', type: 'ArrowNormal', active}] as IToolbox[]
        // });
        break;
      }
      default:
        setToolInfo(null);
    }
  }, [state?.mode]);

  const onClickContainer = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    // dispatchToReducer({type: 'END_SELECT_POINT_OF_LINE'});
  };

  const onMouseDownContainer = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    dispatchToReducer({type: 'END_SELECT_POINT_OF_LINE'});
  };

  const onClickToolBox = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
  };

  const onMouseDownToolBox = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onClickToolButton = (e: BaseSyntheticEvent, toolType: string) => {
    e.stopPropagation();
    switch (toolType) {
      case 'ArrowNormal': {
        dispatchToReducer({type: 'APPLY_NORMAL_ARROW_TO_POINT'});
        return;
      }
    }
  };

  return <></>;

  // if (toolInfo) {
  //   const {toolList, x, y} = toolInfo;
  //   return (
  //     <Container onClick={onClickContainer} onContextMenu={onClickContainer} onMouseDown={onMouseDownContainer}>
  //       <ToolBoxContainer onClick={onClickToolBox} onMouseDown={onMouseDownToolBox} $x={x} $y={y}>
  //         {toolList.map((item: IToolbox) => (
  //           <IconWrapper key={item.name} $rightLines={item.rightLines}>
  //             <Button
  //               key={item.name}
  //               variant="none"
  //               name={item.name}
  //               className={item.active && 'active'}
  //               onClick={(e) => onClickToolButton(e, item.type)}
  //             >
  //               <FontAwesomeIcon icon={faArrowUpLeft} size="xl" />
  //               {item.active ? 'Remove' : 'Add'} arrow head
  //             </Button>
  //           </IconWrapper>
  //         ))}
  //       </ToolBoxContainer>
  //     </Container>
  //   );
  // }
}

export default FormToolBox;
