import {ChangeEvent, useContext, useState} from 'react';
import styled from 'styled-components';
import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import {Button, RadioFormGroup, SelectForm} from 'components/forms';
import LoginInputComponent from 'components/auth/login/LoginInput';
import {loginRequest} from 'components/auth/login/login-api-functions';
import SHA256 from 'crypto-js/sha256';
import {LocalStorageManager} from 'utils/local-storage-manager';
import {Link, useNavigate} from 'react-router-dom';
import {LoginConst} from 'components/auth/login/const';
import {LoginScreenContainer, LoginScreenInputContainer, LoginScreenHeader} from 'components/auth/login/parts';
import {IFormOption} from 'components/forms/types';
import {AuthContext} from 'components/auth/AuthProvider';
import {IApiServer} from 'api/data-types';

const RegisterLinkContainer = styled.div`
  span {
    font-size: 14px;
    &:nth-of-type(1) {
      color: #b6b4c0;
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      cursor: pointer;
    }
  }
`;

const LoginValidationMessageContainer = styled.div`
  position: absolute;
  top: 105px;
  font-size: 12px;
  color: red;
`;
const ServerSelector = styled.div`
  display: flex;
  gap: 10px;
  padding: 10px 20px;
  background-color: rgba(0, 166, 203, 0.1);
  color: ${({theme}) => theme.color.info};
`;

function LoginForm() {
  const {getUserInfo} = useContext(AuthContext);
  const loginInfoState = useState<ILoginInputState>(LoginConst.loginInfoInitialState);
  const [loginInfo, setLoginInfo] = loginInfoState;
  const [loginValidation, setLoginValidation] = useState(true);
  const navigate = useNavigate();
  const savedApiServer = LocalStorageManager.getItem<IApiServer>('API_SERVER') || {type: 'local'};
  const [apiServer, setApiServer] = useState<IApiServer>(savedApiServer);
  const serverTypes: IFormOption[] = [
    {
      label: 'Local',
      value: 'local'
    },
    {
      label: 'Cloud',
      value: 'cloud'
    }
  ];
  const cloudServers: IFormOption[] = [
    {
      label: 'US East',
      value: 'us-east'
    },
    {
      label: 'Seoul',
      value: 'seoul'
    }
  ];

  // 테스트용 서버가 있다면 추가함
  if (process.env.REACT_APP_ADDITIONAL_SERVER) {
    const parsed = String(process.env.REACT_APP_ADDITIONAL_SERVER).split(',');
    const list = parsed.map((server: string) => ({label: server, value: server}));
    cloudServers.push(...list);
  }

  const onChangeApiServer = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    const {name, value} = e.target;
    const merged = {...apiServer, [name]: value};
    setApiServer(merged);
    LocalStorageManager.setItem('API_SERVER', merged);
    window.location.reload();
  };

  const loginAccount = () => {
    const payLoad = {
      username: loginInfo?.userName?.value,
      password: SHA256(loginInfo?.password?.value).toString()
    };
    loginRequest(payLoad).then((res) => {
      if (res?.status === 200 && res?.body?.success && res?.body?.data.token) {
        setLoginValidation(true);
        LocalStorageManager.setItem('PROCESSMETAVERSE_LOGIN_TOKEN', res?.body?.data.token);
        getUserInfo();
        navigate('/');
      } else {
        setLoginValidation(false);
        setLoginInfo((prev) => ({...prev, password: {...prev.password, value: ''}}));
      }
    });
  };

  const onKeyPressDownEnter = () => {
    if (Boolean(!loginInfo?.userName?.value || !loginInfo?.password?.value)) {
      setLoginValidation(false);
    } else {
      loginAccount();
    }
  };

  return (
    <LoginScreenContainer>
      <LoginScreenHeader title="Login" />
      <LoginScreenInputContainer>
        <ServerSelector>
          <RadioFormGroup
            name="type"
            options={serverTypes}
            value={apiServer?.type}
            gap={10}
            onChange={onChangeApiServer}
          />
          <SelectForm
            name="area"
            disabled={apiServer?.type === 'local'}
            value={apiServer?.area}
            options={cloudServers}
            onChange={onChangeApiServer}
          />
        </ServerSelector>
        <LoginInputComponent loginInputState={loginInfoState} onKeyPressDownEnter={onKeyPressDownEnter}>
          {!loginValidation && (
            <LoginValidationMessageContainer>
              Invalid ID or password. Please check and try again.
            </LoginValidationMessageContainer>
          )}
        </LoginInputComponent>
        <Link to={'/login/password/recover'}>Forget your password?</Link>
      </LoginScreenInputContainer>
      <Button
        height="40px"
        disabled={Boolean(!loginInfo?.userName?.value || !loginInfo?.password?.value)}
        onClick={loginAccount}
      >
        Login
      </Button>
      <RegisterLinkContainer>
        <span>Don’t have an account?</span>
        <Link to={'/login/create'}>Create an account</Link>
      </RegisterLinkContainer>
    </LoginScreenContainer>
  );
}

export default LoginForm;
