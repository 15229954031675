import React, {useContext} from 'react';
import {IAnnotation} from 'components/mpfd/type';
import SelectedAnnotation from 'components/mpfd/annotation/SelectedAnnotation';
import {getRegionColor} from 'components/mpfd/hooks/functions/mpfd-calcultation-functions';
import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import useMetaPfdMouseEvents from 'components/mpfd/hooks/useMetaPfdMouseEvents';

const TransformGrabber = styled.div(() => ({
  width: 8,
  height: 8,
  zIndex: META_PFD_zINDEX.REGION_SELECT_AND_TRANSFORM_BOX_GRABBER,
  border: '1px solid black',
  position: 'absolute',
  pointerEvents: 'all'
}));
const boxCursorMap = [
  ['nw-resize', 'n-resize', 'ne-resize'],
  ['w-resize', 'grab', 'e-resize'],
  ['sw-resize', 's-resize', 'se-resize']
];

type IProps = {
  imagePosition: {topLeft: PointObjectNotation; bottomRight: PointObjectNotation};
  projectRegionBox(region: IAnnotation): {x: number; y: number; w: number; h: number};
};
function SelectedAnnotationLayer({imagePosition, ...rest}: IProps) {
  const {annotationAction, state, matrix} = useContext(MetaPfdContext);
  const {regions, selectedTool} = state;
  // const containerRef = useRef(null);

  const {onMouseDown} = useMetaPfdMouseEvents(imagePosition);

  const iw = imagePosition?.bottomRight.x - imagePosition?.topLeft.x;
  const ih = imagePosition?.bottomRight.y - imagePosition?.topLeft.y;

  return regions
    .filter((r) => r?.highlighted)
    .map((r) => {
      const pBox = rest.projectRegionBox(r);
      let color = getRegionColor(r);
      return (
        <div
          style={{position: 'absolute', width: '100%', height: '100%', zIndex: 5, pointerEvents: 'none'}}
          className="selected-annotation-layer"
          onClick={annotationAction.unSelectAnnotation}
          // ref={containerRef}
        >
          <SelectedAnnotation {...rest} region={r} pBox={pBox} imagePosition={imagePosition} />
          {(r?.type === 'box' || r?.type === 'port') &&
            !(selectedTool === 'zoom') &&
            !(selectedTool === 'pan') &&
            r?.highlighted &&
            // matrix.a < 1.8 &&
            [
              [0, 0],
              [0.5, 0],
              [1, 0],
              [1, 0.5],
              [1, 1],
              [0.5, 1],
              [0, 1],
              [0, 0.5],
              [0.5, 0.5]
            ].map(([px, py], i) => (
              <TransformGrabber
                key={i}
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => {
                  // if (e.button === 0) return annotationAction.beginBoxTransform(r, [px * 2 - 1, py * 2 - 1]);
                  console.log('>>>');
                  onMouseDown(e, () => annotationAction.beginBoxTransform(r, [px * 2 - 1, py * 2 - 1]));
                  // rest.mouseEvents.onMouseDown(e);
                }}
                style={{
                  left: r.x * iw + r.w * iw * px - 6,
                  top: r.y * ih + r.h * ih * py - 6,
                  cursor: boxCursorMap[py * 2][px * 2],
                  borderRadius: px === 0.5 && py === 0.5 ? 4 : undefined,
                  borderColor: color
                }}
              />
            ))}
        </div>
      );
    });
}

export default SelectedAnnotationLayer;
