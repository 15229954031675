import {ChangeEvent, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {Button, SelectForm} from 'components/forms';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import PrevButton from 'components/mpfd/panel/modal/PrevButton';
import {faArrowProgress, faFileSvg, faFilePng, faFileJpg, faFileGif} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconDefinition} from '@fortawesome/fontawesome-svg-core';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';

const Container = styled.div`
  overflow: hidden;
  width: 560px;
  height: 150px;
`;
const ElementContainer = styled.div`
  margin: auto;

  h3 {
    margin: 2px;
    color: #aaa;
    font-size: 14px;
  }
`;
const SelectedFileDisplay = styled.div`
  > span {
    font-size: 16px;
    margin-left: 5px;
  }
`;
const InputFile = styled.input`
  display: none;
`;

type IProps = {
  value: string;
  onSelect(fileName: string): void;
  onChange(imgSrc: string, imgFile: File): void;
};

function ProcessImageSelector({value, onSelect, onChange}: IProps): ReactElement {
  const {pfdList} = useContext(MetaPfdContext);
  const imageList = pfdList.map((item) => ({pidName: item, fileName: item, desc: ''}));

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const fileName = e.target.value;
    onSelect(fileName || '');
  };

  const onClickPrevButton = (): void => {
    onSelect(undefined);
  };

  const onChangeUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (file) {
      const fr = new FileReader();
      fr.onload = function () {
        onChange(fr.result as string, file);
      };
      fr.readAsDataURL(file);
    }
  };

  const inputFileRef = useRef(null);
  const [extIcon, setExtIcon] = useState<IconDefinition>();

  const onClickUploadButton = (): void => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    if (value) {
      const [ext] = value?.split('.').reverse();
      if (!ext) return;

      switch (ext.toLowerCase()) {
        case 'svg':
          setExtIcon(faFileSvg);
          break;
        case 'png':
          setExtIcon(faFilePng);
          break;
        case 'gif':
          setExtIcon(faFileGif);
          break;
        case 'jpg':
        case 'jpeg':
          setExtIcon(faFileJpg);
          break;
        default:
          setExtIcon(faArrowProgress);
      }
    }
  }, [value]);

  return (
    <StepContainer width={560}>
      <StepScroller total={2} step={value ? 1 : 0}>
        <Step>
          <ElementContainer>
            <h3>Authorized PFD</h3>
            <SelectForm
              name="mpfd-image"
              options={imageList}
              width={200}
              value={value}
              placeholder="Select PFD"
              disabledPlaceholder
              valueKey="fileName"
              labelKey="pidName"
              onChange={onChangeSelect}
            />
          </ElementContainer>
          <ElementContainer>
            <h3>Upload PFD</h3>
            <Button width={200} height={40} onClick={onClickUploadButton}>
              Upload PFD Image
              <InputFile type="file" ref={inputFileRef} onChange={onChangeUploadFile} accept="image/*" />
            </Button>
          </ElementContainer>
        </Step>
        <Step>
          <PrevButton onClick={onClickPrevButton}>Reselect</PrevButton>
          <ElementContainer>
            <SelectedFileDisplay>
              {extIcon && <FontAwesomeIcon icon={extIcon} size="xl" />}
              <span>{value}</span>
            </SelectedFileDisplay>
          </ElementContainer>
        </Step>
      </StepScroller>
    </StepContainer>
  );
}

export default ProcessImageSelector;
