import React, {BaseSyntheticEvent, MouseEvent, ReactElement, useContext, useEffect, useState} from 'react';
import ActionMenuHeader from 'components/menu/action/ActionMenuHeader';
import {Button} from 'components/forms';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {faDiagramProject} from '@fortawesome/pro-light-svg-icons';
import FileInfo from 'components/menu/FileInfo';
import {ActionMenuBody, ActionMenuItem, ActionMenuItemLabel} from 'components/menu/action';
import {useNavigate} from 'react-router-dom';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';
import ActionMenuFileSearchField from 'components/menu/ActionMenuFileSearchField';
import {IFile} from 'api/data-types';
import useRollActionMenu from 'components/menu/action/useRollActionMenu';

/**
 * ProcessCanvas 의 저장 파일 목록을 표시
 * @constructor
 */
function ProcessCanvasFileList(): ReactElement {
  const [selectedList, setSelectedList] = useState<IFile[]>([]);
  const [selectedItem, setSelectedItem] = useState<IFile>();
  const searchInputState = useState<string>('');
  const [searchInput] = searchInputState;
  const {fileList, file, checkIsChanged} = useContext(ProcessCanvasContext);
  const navigate = useNavigate();
  const {create, remove} = useProcessCanvasCommand();
  const [expandedItemId, onMouseEnter, onMouseLeave] = useRollActionMenu();

  useEffect(() => {
    if (fileList?.length) {
      const canvasFile = fileList.filter((item) => item.fileType === 'process_canvas');
      if (searchInput) {
        const filteredFile = canvasFile.filter((item) =>
          item.fileName.toLowerCase().includes(searchInput.toLowerCase())
        );
        setSelectedList(filteredFile);
      } else {
        setSelectedList(canvasFile);
      }
    }
  }, [fileList, searchInput]);

  const onSelectItem = (item: IFile): void => {
    setSelectedItem(item);
  };

  /**
   * ProcessCanvas 로드
   * @param item
   */
  const onClickLoad = (item: IFile): void => {
    const fnc = () => {
      // load({...item});
      navigate(`/pc/${item._id}`);
    };

    if (checkIsChanged(fnc, 'load', item._id)) {
      fnc();
    }
  };

  const onClickDelete = (e: BaseSyntheticEvent, item: IFile): void => {
    e.currentTarget.blur();
    remove(item, item._id);
  };

  const onClickCreateFile = (): void => {
    create();
  };

  return (
    <>
      <ActionMenuHeader main="Process Canvas" sub="(Files)" description="Select a Process Canvas Layout">
        <Button className="create-btn" variant="info" onClick={onClickCreateFile}>
          New
        </Button>
      </ActionMenuHeader>
      <ActionMenuFileSearchField searchInputState={searchInputState} />
      <ActionMenuBody>
        {selectedList.map((item) => (
          <ActionMenuItem key={item._id} id={item._id} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <ActionMenuItemLabel
              isActive={file?._id === item._id}
              icon={faDiagramProject}
              title={item.fileName}
              userName={item.createdUser}
              expanded={expandedItemId === item._id}
              onClick={() => onSelectItem(item)}
              item={item}
            />
            <FileInfo item={item} isActive={selectedItem?._id === item._id}>
              <Button variant="danger" disabled={file?._id === item._id} onClick={(e) => onClickDelete(e, item)}>
                Delete
              </Button>
              <Button onClick={() => onClickLoad(item)}>Load</Button>
            </FileInfo>
          </ActionMenuItem>
        ))}
      </ActionMenuBody>
    </>
  );
}

export default ProcessCanvasFileList;
