import {ChangeEvent, FocusEvent, CSSProperties, KeyboardEvent, ReactElement, useRef} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

type StyleProps = {
  $position: 'right' | 'left' | 'center' | 'none';
};

const Container = styled.div<StyleProps>`
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.$position !== 'none' ? props.$position : 'normal')};

  input {
    font-size: 15px;
    width: 100%;
    height: inherit;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #b6b4c0;
    color: #575660;
    outline: none;
    box-sizing: border-box;
  }

  input,
  textarea {
    &:disabled {
      background-color: #e2e8ee;
    }
    &:focus {
      border: 1px solid #3d97fe;
    }
  }
`;

type IProps = {
  name?: string;
  className?: string;
  id?: string;
  value?: string | number | null;
  style?: CSSProperties;
  placeholder?: string | undefined | null;
  autoFocus?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  type?: 'text' | 'password' | 'textarea' | 'number';
  width?: number;
  textAreaRows?: number;
  maxLength?: number;
  position?: 'right' | 'left' | 'center' | 'none';
  onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
  onKeyDown?(e: KeyboardEvent<HTMLInputElement>): void;
  onBlur?(e: FocusEvent<HTMLInputElement>): void;
};

function TextForm({
  type = 'text',
  name,
  className,
  id,
  value,
  style,
  placeholder,
  autoFocus,
  readOnly,
  disabled,
  width,
  textAreaRows,
  maxLength,
  onChange,
  onBlur,
  onKeyDown,
  position = 'none'
}: IProps): ReactElement {
  const ref = useRef<HTMLInputElement>();

  const onChangeTextForm = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    onChange(e);
  };

  const onKeyDownTextForm = (e: KeyboardEvent<HTMLInputElement>): void => {
    onKeyDown?.(e);
  };

  if (type === 'textarea') {
    return (
      <Container className={classNames('common-text-form', className)} $position={position}>
        <textarea
          name={name}
          id={id}
          style={{...style, width}}
          value={value}
          autoFocus={autoFocus}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          rows={textAreaRows}
          maxLength={maxLength}
          onChange={onChangeTextForm}
        />
      </Container>
    );
  } else {
    return (
      <Container className={classNames('common-text-form', className)} $position={position}>
        <input
          ref={ref}
          name={name}
          id={id}
          value={value}
          type={type}
          style={{...style, width}}
          autoFocus={autoFocus}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={onKeyDownTextForm}
          autoComplete="off"
          onBlur={onBlur}
        />
      </Container>
    );
  }
}

export default TextForm;
