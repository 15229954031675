import styled from 'styled-components';
import LoginScreenHeader from 'components/auth/login/parts/LoginScreenHeader';
import React, {useContext, useState} from 'react';
import {ILoginInputState} from 'components/auth/login/TextFormCheckValidate';
import {Button} from 'components/forms';
import LoginInputComponent from 'components/auth/login/LoginInput';
import {resetPasswordEmailRequest} from 'components/auth/login/login-api-functions';
import {LoginConst} from 'components/auth/login/const';
import LoginScreenContainer from 'components/auth/login/parts/LoginScreenContainer';
import LoginScreenInputContainer from 'components/auth/login/parts/LoginScreenInputContainer';
import {CommonContext} from 'components/common/CommonProvider';
import {Link} from 'react-router-dom';
import ApiHostTypeDisplay from 'components/common/ApiHostTypeDisplay';

const RegisterLinkContainer = styled.div`
  span {
    font-size: 14px;
    &:nth-of-type(1) {
      color: #b6b4c0;
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      cursor: pointer;
    }
  }
`;

function PasswordRecoverRequest() {
  const {showSpinner, hideSpinner} = useContext(CommonContext);
  const passwordRecoverState = useState<ILoginInputState>(LoginConst.passwordRecoverInitialState);
  const [passwordRecover] = passwordRecoverState;
  const isPasswordRecoverRequestEmailValid = !passwordRecover?.email?.value || !passwordRecover?.email?.valid;

  const RecoverRequestEmailSend = () => {
    const payLoad = {
      email: passwordRecover?.email?.value,
      domain: window.location.origin
    };
    showSpinner({});
    resetPasswordEmailRequest(payLoad).then((res) => {
      if (res?.status === 202 && res?.body?.success) {
        // alert(res?.body?.detail);
        alert('Successfully Send');
      } else {
        alert('email send failed');
      }
      hideSpinner();
    });
  };

  const onKeyPressDownEnter = () => {
    if (isPasswordRecoverRequestEmailValid) {
    } else {
      RecoverRequestEmailSend();
    }
  };

  return (
    <LoginScreenContainer>
      <LoginScreenHeader title="Recover Password" />
      <LoginScreenInputContainer>
        <ApiHostTypeDisplay />
        <LoginInputComponent loginInputState={passwordRecoverState} onKeyPressDownEnter={onKeyPressDownEnter} />
        <Button height="40px" disabled={isPasswordRecoverRequestEmailValid} onClick={RecoverRequestEmailSend}>
          Send
        </Button>
      </LoginScreenInputContainer>
      <RegisterLinkContainer>
        <span>Already have an account?</span>
        <Link to={'/login'}>Login</Link>
      </RegisterLinkContainer>
    </LoginScreenContainer>
  );
}

export default PasswordRecoverRequest;
