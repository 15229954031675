import styled from 'styled-components';
import React, {ReactNode, useContext, useRef, useState, WheelEvent} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import classNames from 'classnames';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import {compose, rotate, scale, transform, translate} from 'transformation-matrix';

type ContainerProps = {
  $createWithPrimary: boolean;
  $dragging?: boolean;
  $dragWithPrimary: boolean;
  $zoomWithPrimary: boolean;
  $mat?: {a: number};
  $detectWithPrimary: boolean;
};

const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &.select {
    .annotation {
      &.annotation-circle {
        pointer-events: all;
        &:not(.point-move-mode) {
          &:hover {
            stroke-width: 8;
          }
        }
      }
    }
  }

  &.select,
  &.line-eraser {
    .annotation {
      pointer-events: all;
    }
  }
  &.line-splitter,
  &.line-splitter-2 {
    .annotation {
      &.annotation-polyline {
        pointer-events: all;
      }
    }
  }
  &.remove-joint-of-line {
    .annotation {
      &.annotation-circle {
        pointer-events: all;
        &:hover {
          stroke-width: 8;
        }
      }
    }
  }

  .annotation {
    pointer-events: none;
  }

  &.pan {
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }
  &.zoom-in {
    cursor: zoom-in;
  }
  &.zoom-out {
    cursor: zoom-out;
  }

  .layer-wrapper {
    cursor: ${(props) =>
      props.$createWithPrimary
        ? 'crosshair'
        : props.$detectWithPrimary
          ? 'default'
          : /*     : props.$dragging
            ? 'grabbing'
            : props.$dragWithPrimary
              ? 'grab'*/
            props.$zoomWithPrimary
            ? props.$mat.a < 1
              ? 'zoom-out'
              : 'zoom-in'
            : ''};
  }
`;

type IProps = {
  children: ReactNode;
};

function ProcessImageViewerContainer({children}: IProps) {
  const {dispatchToReducer, matrix, changeMatrix, state, stageEnteredState} = useContext(MetaPfdContext);
  const [, setStageEntered] = stageEnteredState;

  const selectedTool = state.selectedTool;
  const previousTool = useRef<ToolIds | null>(null);

  const onMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (selectedTool === 'pan') {
      const c = document.getElementById('standard-canvas');
      if (!c) return;
      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;
      previousTool.current = selectedTool;

      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        const translateMatrix = translate(startX - x, startY - y);
        const newMat = transform([matrix, translateMatrix]);
        changeMatrix({...newMat});
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});

      e.preventDefault();
    } else if (e.button === 1) {
      const c = document.getElementById('standard-canvas');
      if (!c) return;
      previousTool.current = selectedTool;
      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;

      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        const translateMatrix = translate(startX - x, startY - y);
        const newMat = transform([matrix, translateMatrix]);
        changeMatrix({...newMat});
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});
      e.preventDefault();
    }
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    setStageEntered(true);
  };

  const onMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    setStageEntered(false);
  };

  const onWheel = (e: WheelEvent<HTMLDivElement>) => {
    const direction = e.deltaY > 0 ? 1 : e.deltaY < 0 ? -1 : 0;
    const c = document.getElementById('standard-canvas');
    if (!c) return;
    previousTool.current = selectedTool;
    const x = e.clientX - c.getBoundingClientRect().left;
    const y = e.clientY - c.getBoundingClientRect().top;
    zoomIn(direction, {x, y});
  };

  const zoomIn = (direction: number, point: {x: number; y: number}) => {
    const [mx, my] = [point.x, point.y];
    let scaleNum = 1 + 0.2 * direction;
    let newMat = compose([matrix, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum))]);
    newMat = compose([newMat, translate(-mx, -my)]);
    if (newMat.a < 0.1 || newMat.a > 5) {
      return;
    }
    changeMatrix({...newMat});
  };

  return (
    <Container
      className={classNames(selectedTool)}
      $createWithPrimary={selectedTool.includes('create')}
      // $dragging={layoutChanging}
      $dragWithPrimary={selectedTool === 'pan'}
      $zoomWithPrimary={selectedTool === 'zoom'}
      $detectWithPrimary={selectedTool === 'line-detection'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseDown={onMouseDown}
      onWheel={onWheel}
      onContextMenu={(e) => e.preventDefault()}
    >
      {children}
    </Container>
  );
}

export default ProcessImageViewerContainer;
