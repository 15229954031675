import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faArrowDownToLine,
  faArrowUpFromLine,
  faDropletPercent,
  faUmbrella,
  faWind
} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import {getCelsius, getFahrenheit} from 'components/pc/widgets/weather/const';
import {ISizeType} from 'components/common/types';
import Tooltip from 'components/common/Tooltip';

const Container = styled.div`
  display: flex;
  width: 100%;

  svg {
    width: 30px;
    height: 30px;
    //padding-right: 2px;
  }
`;

const SummarizeWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1.2rem;
  justify-content: space-between;
  background-color: #eef2ff;
  border-radius: 1.2rem;
  padding: 0.8rem;

  &.md {
    margin-top: 1rem;
    border-radius: 0.8rem;
    padding: 0.6rem;
  }
  &.sm {
    margin-top: 0.8rem;
    border-radius: 0.6rem;
    padding: 0.4rem;
  }
`;

const SummarizeItem = styled.div`
  font-size: 21px;
  font-weight: 400;
  padding: 0.8rem;
  display: flex;
  width: 20%;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 1.2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  white-space: nowrap;

  &.md {
    font-size: 16px;
    padding: 0.6rem;

    svg {
      width: 24px;
      height: 24px;
    }
  }
  &.sm {
    font-size: 12px;
    padding: 0.4rem;

    //&:nth-child(even) {
    //  display: none;
    //  //width: 0;
    //}

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &:hover {
    .tooltip {
      opacity: 1;
      transition: opacity 0.1s;
    }
  }
`;

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

type IProps = {
  darkModeChange: boolean;
  weatherData: IWeatherData;
  forecastData: IWeatherForcastData;
  isCelsius: boolean;
  size: ISizeType;
};

function WeatherWidgetSummarize({darkModeChange, isCelsius, forecastData, weatherData, size}: IProps) {
  const fMax = forecastData.daily?.[0].temp.max ? getFahrenheit(forecastData.daily?.[0].temp.max) : undefined;
  const cMax = forecastData.daily?.[0].temp.max ? getCelsius(forecastData.daily?.[0].temp.max) : undefined;
  const fMin = forecastData.daily?.[0].temp ? getFahrenheit(forecastData.daily?.[0].temp.min) : undefined;
  const cMin = forecastData.daily?.[0].temp.min ? getCelsius(forecastData.daily?.[0].temp.min) : undefined;
  const rain = forecastData?.daily?.[0].pop ? Math.round(forecastData?.daily?.[0].pop * 100) : undefined;

  return (
    <Container>
      <SummarizeWrapper className={size.width} style={darkModeChange === false ? {backgroundColor: '#3f425f'} : {}}>
        <SummarizeItem className={size.width}>
          <Tooltip content="Lowest Temperature" place="top">
            <ItemWrap>
              <FontAwesomeIcon icon={faArrowDownToLine} />
              {isCelsius ? (
                <>
                  {cMin}
                  {cMin && `°`}
                </>
              ) : (
                <>
                  {fMin}
                  {fMin && `°`}
                </>
              )}{' '}
            </ItemWrap>
          </Tooltip>
        </SummarizeItem>
        <SummarizeItem className={size.width}>
          <Tooltip content="Highest Temperature" place="top">
            <ItemWrap>
              <FontAwesomeIcon icon={faArrowUpFromLine} />
              {isCelsius ? (
                <>
                  {cMax}
                  {cMax && `°`}
                </>
              ) : (
                <>
                  {fMax}
                  {fMax && `°`}{' '}
                </>
              )}
            </ItemWrap>
          </Tooltip>
        </SummarizeItem>
        <SummarizeItem className={size.width}>
          <Tooltip content="Humidity" place="top">
            <ItemWrap>
              <FontAwesomeIcon icon={faDropletPercent} />
              {weatherData.main?.humidity} {weatherData.main?.humidity && `%`}
            </ItemWrap>
          </Tooltip>
        </SummarizeItem>
        <SummarizeItem className={size.width}>
          <Tooltip content="Wind" place="top">
            <ItemWrap>
              <FontAwesomeIcon icon={faWind} />
              {weatherData.wind?.speed} {weatherData.wind?.speed && `m / s`}
            </ItemWrap>
          </Tooltip>
        </SummarizeItem>
        <SummarizeItem className={size.width}>
          <Tooltip content="Rain" place="top">
            <ItemWrap>
              <FontAwesomeIcon icon={faUmbrella} />
              {rain || '0'} %
            </ItemWrap>
          </Tooltip>
        </SummarizeItem>
      </SummarizeWrapper>
    </Container>
  );
}

export default WeatherWidgetSummarize;
