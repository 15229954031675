import {Vector3} from '@react-three/fiber/dist/declarations/src/three-types';
import {Vector2} from 'three';

export const gltfModelsPaths = [
  '/gltf/Shells/Shells.gltf',
  '/gltf/Parts/Parts.gltf',
  '/gltf/Pipes/Pipes.gltf',
  '/gltf/PipeFeed/Pipes_Feed.gltf',
  '/gltf/PipeSlurryOil/Pipes_SlurryOil.gltf',
  '/gltf/PipeLeanGas/Pipes_LeanGas.gltf',
  '/gltf/PipeArrow/Pipes_Arrow.gltf',
  '/gltf/Pipe_1_Line/Pipes_Pipe_1_Line.gltf',
  '/gltf/Pipe_2_Line/Pipes_Pipe_2_Line.gltf',
  '/gltf/Pipe_3_Line/Pipes_Pipe_3_Line.gltf',
  '/gltf/Pipe_4_1_Line/Pipes_Pipe_4_1_Line.gltf',
  '/gltf/Pipe_4_2_Line/Pipes_Pipe_4_2_Line.gltf',
  '/gltf/Pipe_5_Line/Pipes_Pipe_5_Line.gltf',
  '/gltf/Pipe_6_Line/Pipes_Pipe_6_Line.gltf',
  '/gltf/Pipe_7_Line/Pipes_Pipe_7_Line.gltf',
  '/gltf/Pipe_8_Line/Pipes_Pipe_8_Line.gltf',
  '/gltf/Pipe_9_Line/Pipes_Pipe_9_Line.gltf',
  '/gltf/Pipe_10_Line/Pipes_Pipe_10_Line.gltf',
  '/gltf/Pipe_11_Line/Pipes_Pipe_11_Line.gltf',
  '/gltf/Pipe_12_Line/Pipes_Pipe_12_Line.gltf',
  '/gltf/Pipe_13_Line/Pipes_Pipe_13_Line.gltf',
  '/gltf/Pipe_14_Line/Pipes_Pipe_14_Line.gltf',
  '/gltf/Pipe_15_Line/Pipes_Pipe_15_Line.gltf',
  '/gltf/Pipe_16_Line/Pipes_Pipe_16_Line.gltf',
  '/gltf/Pipe_17_Line/Pipes_Pipe_17_Line.gltf',
  '/gltf/Pipe_18_Line/Pipes_Pipe_18_Line.gltf',
  '/gltf/Pipe_19_Line/Pipes_Pipe_19_Line.gltf',
  '/gltf/Pipe_20_Line/Pipes_Pipe_20_Line.gltf',
  '/gltf/Pipe_21_Line/Pipes_Pipe_21_Line.gltf',
  '/gltf/Pipe_22_Line/Pipes_Pipe_22_Line.gltf',
  '/gltf/Pipe_23_Line/Pipes_Pipe_23_Line.gltf',
  '/gltf/Pipe_24_Line/Pipes_Pipe_24_Line.gltf',
  '/gltf/Pipe_25_Line/Pipes_Pipe_25_Line.gltf',
  '/gltf/Pipe_26_Line/Pipes_Pipe_26_Line.gltf',
  '/gltf/Pipe_27_Line/Pipes_Pipe_27_Line.gltf',

  '/gltf/PipeText/Pipes_Text.gltf',
  '/gltf/AirCooler/AirCooler.gltf',
  '/gltf/Compressor/Compressor.gltf',
  '/gltf/HeatExchanger/HeatExchanger.gltf',
  '/gltf/Pump/Pump.gltf',
  '/gltf/VesselWithoutBoot/VesselWithoutBoot.gltf',
  '/gltf/VesselWithBoot/VesselWithBoot.gltf',
  '/gltf/VesselVertical/VesselVertical.gltf'
];

export const gridConfig = {
  cellSize: 1,
  cellThickness: 1,
  cellColor: '#3b3b3b',
  sectionSize: 10,
  sectionThickness: 1,
  sectionColor: '#e62424',
  fadeDistance: 100,
  fadeStrength: 1,
  followCamera: false,
  infiniteGrid: true,
  position: [0, -0.3, 0] as Vector3,
  args: [10.5, 10.5] as [width?: number, height?: number, widthSegments?: number, heightSegments?: number]
};
export const shadowConfig = {
  position: [0, -0.4, 0] as Vector3,
  opacity: 1,
  scale: 50,
  blur: 0.2,
  far: 3,
  resolution: 256,
  color: '#000000'
};

export const controlsConfig = {
  minPolarAngle: 0,
  maxPolarAngle: Math.PI / 1.9,
  makeDefault: true,
  zoomToCursor: true
  // target:[-5, 5, 0]as Vector3,
};

export const canvasConfig = {
  shadows: true,
  camera: {fov: 45, position: [-5, 15, 30] as Vector3}
};
