import {MouseEvent, WheelEvent, useRef, useEffect, useContext, useState} from 'react';
import {applyToPoint, compose, rotate, scale, translate, transform} from 'transformation-matrix';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {IPfdAction} from 'components/mpfd/type';
import {ToolIds} from 'components/mpfd/panel/Toolbox';

function useMouse({canvasEl, changeMat, changeDragging, zoomStart, changeZoomStart, layoutParams, mat, dragging}) {
  const {state, dispatchToReducer, detectLine} = useContext(MetaPfdContext);
  const mousePosition = useRef({x: 0, y: 0});
  const prevMousePosition = useRef({x: 0, y: 0});
  const matCopy = useRef(mat);
  const {selectedTool} = state;

  const [timerObj, setTimerObj] = useState<NodeJS.Timeout>(null);
  const timerRef = useRef(null);
  timerRef.current = timerObj;

  useEffect(() => {
    if (!dragging) {
      matCopy.current = mat;
    }
  }, [dragging, mat]);

  useEffect(() => {
    if (state.mode) {
      changeDragging(false);
    }
  }, [state.mode]);

  useEffect(() => {
    if (selectedTool !== 'pan') {
      changeDragging(false);
    }
  }, [selectedTool]);

  // const onMouseMove = (newState: Partial<IPfdAction>, mouseBtn?: number) => {
  //   dispatchToReducer({type: 'MOUSE_MOVE', ...newState});
  // };
  // const onMouseDown = (newState: Partial<IPfdAction>, mouseBtn?: number) => {
  //   if (mouseBtn === 0) {
  //     dispatchToReducer({type: 'MOUSE_DOWN', ...newState});
  //   } else if (mouseBtn === 2) {
  //     dispatchToReducer({type: 'MOUSE_DOWN_RIGHT_BTN', ...newState});
  //   }
  //   if (selectedTool === 'line-detection') {
  //     const {x, y} = newState;
  //     detectLine(x, y);
  //   }
  // };
  //
  // const onMouseUp = (newState: Partial<IPfdAction>, mouseBtn?: number) => {
  //   dispatchToReducer({type: 'MOUSE_UP', ...newState});
  // };
  //
  // const zoomIn = (direction: number | {to: number}, point: {x: number; y: number}) => {
  //   const [mx, my] = [point.x, point.y];
  //   let scaleNum = typeof direction === 'object' ? direction.to / mat.a : 1 + 0.2 * direction;
  //   const check = compose([matCopy.current, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum))]);
  //   if (check?.a > 2 || check?.a < 0.1) {
  //     return;
  //   }
  //
  //   let newMat;
  //   changeZoomStart(true);
  //   newMat = compose([matCopy.current, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum))]);
  //   if (newMat.a > 2) newMat = compose([newMat, scale(2 / newMat.a)]);
  //   if (newMat.a < 0.1) newMat = compose([newMat, scale(0.1 / newMat.a)]);
  //   newMat = compose([newMat, translate(-mx, -my)]);
  //   matCopy.current = newMat;
  //   const t = setTimeout(() => {
  //     changeMat({...newMat});
  //     setTimeout(() => {
  //       if (t === timerRef.current) {
  //         changeZoomStart(false);
  //         setTimerObj(null);
  //       }
  //     }, 600);
  //   }, 10);
  //   if (timerObj) {
  //     clearTimeout(timerObj);
  //   }
  //   timerRef.current = t;
  //   setTimerObj(t);
  // };

  const mouseEvents = {
    onMouseMove: (e: MouseEvent) => {
      // const {left, top} = canvasEl.current.getBoundingClientRect();
      // prevMousePosition.current = mousePosition.current;
      // const {currentX, currentY} = {currentX: e.clientX - left, currentY: e.clientY - top};
      // mousePosition.current = {x: currentX, y: currentY};
      //
      // const {x: prevX, y: prevY} = prevMousePosition.current;
      // const projMouse = applyToPoint(mat, {x: currentX, y: currentY});
      // const {iw, ih} = layoutParams.current;
      // const translateMatrix = translate(prevX - currentX, prevY - currentY);
      // const newMat = transform([matCopy.current, translateMatrix]);
      // // if (dragging) {
      // //   changeMat({...newMat});
      // //   matCopy.current = {...newMat};
      // // } else {
      // //   onMouseMove({x: projMouse.x / iw, y: projMouse.y / ih});
      // // }
      // onMouseMove({x: projMouse.x / iw, y: projMouse.y / ih});
      // e.preventDefault();
    },
    onMouseDown: (e: MouseEvent) => {
      // e.preventDefault();
      // // if (e.button === 1) {
      // //   previousTool.current = state.selectedTool;
      // //   dispatchToReducer({type: 'SELECT_TOOL', selectedTool: 'pan'});
      // // }
      // // if (e.button === 1 || (e.button === 0 && dragWithPrimary)) changeDragging(true);
      //
      // const projMouse = applyToPoint(mat, {x: mousePosition.current.x, y: mousePosition.current.y});
      // if (e.button === 0 || e.button === 2) {
      //   const {iw, ih} = layoutParams.current;
      //   onMouseDown({x: projMouse.x / iw, y: projMouse.y / ih}, e.button);
      // }
    },
    onMouseUp: (e: MouseEvent) => {
      // console.log('MOUSE UP');
      // e.preventDefault();
      // const projMouse = applyToPoint(mat, {x: mousePosition.current.x, y: mousePosition.current.y});
      // // if (e.button === 1) {
      // //   if (previousTool.current) {
      // //     dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
      // //     previousTool.current = null;
      // //   }
      // // }
      // // if (dragging) return changeDragging(false);
      // if (e.button === 0) {
      //   const {iw, ih} = layoutParams.current;
      //   onMouseUp({x: projMouse.x / iw, y: projMouse.y / ih});
      // }
    },
    onWheel: (e: WheelEvent<HTMLDivElement>) => {
      // e.preventDefault();
      // const direction = e.deltaY > 0 ? 1 : e.deltaY < 0 ? -1 : 0;
      // zoomIn(direction, mousePosition.current);
    },
    onContextMenu: (e) => {
      e.preventDefault();
    }
  };
  return {mouseEvents, mousePosition};
}

export default useMouse;
