import React, {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components';
import ComponentTable, {IComponentTableField} from 'components/table/ComponentTable';
import PythonVariableTableParts from 'components/table/parts/PythonVariableTableParts';
import {getUniqueKey} from 'utils/commons';
import {ILatestNode} from 'hooks/useLatestNodeHandler';
import {IPythonEditorVariable, IValueGroupObj} from 'components/pc/widgets/pythonEditor/types';

const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

const fields: IComponentTableField[] = [
  {
    key: 'variableName',
    label: 'Variable Name',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '244px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        // padding: '5px 7px',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: PythonVariableTableParts
  },
  {
    key: 'flowType',
    label: 'Information flow',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '131px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        // padding: '5px 7px',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: PythonVariableTableParts
  },
  {
    key: 'variableValue',
    label: 'Value',
    css: {body: 'ellipsis'},
    style: {
      header: {
        width: '123px',
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        // padding: '5px 7px',
        padding: 0,
        boxSizing: 'border-box'
      }
    },

    component: PythonVariableTableParts
  },
  {
    key: 'path',
    label: 'Definition / Path Information',
    css: {body: 'ellipsis'},
    style: {
      header: {
        fontSize: 14,
        height: '31px',
        boxSizing: 'border-box',
        backgroundColor: '#E3E3E3',
        color: '#373737'
      },
      body: {
        height: '38px',
        backgroundColor: '#f2f2f2',
        // padding: '5px 7px',
        padding: 0,
        boxSizing: 'border-box'
      }
    },
    component: PythonVariableTableParts,
    customHeaderComponent: true
  }
];

type IProps = {
  variableTableState?: [IPythonEditorVariable[], Dispatch<SetStateAction<IPythonEditorVariable[]>>];
  valueGroupObjectState?: [IValueGroupObj, Dispatch<SetStateAction<IValueGroupObj>>];
  valueFromDatasheet?: {
    [key: string]: string;
  };
  onSetNodeSelectorTargetInfo(key: string, path: string[]): void;
};
function PythonEditorVariableTable({
  variableTableState,
  valueGroupObjectState,
  onSetNodeSelectorTargetInfo,
  valueFromDatasheet
}: IProps) {
  // const [rows, setRows] = variableTableState;
  const [variableTable, setVariableTable] = variableTableState;
  const [rows, setRows] = useState([]);
  const [valueGroupObject, setValueGroupObject] = valueGroupObjectState;

  useEffect(() => {
    const newRows = variableTable.map(function (item) {
      const searchResult = valueGroupObject[item.keys] as ILatestNode;
      const searchResultFormDatasheet = valueFromDatasheet?.[item?.path?.[1]];
      if (item.flowType === 'Outflow') {
        return {...item, variableValue: ''};
      }
      if (searchResult) {
        return {...item, variableValue: searchResult?.value?.[0]?.[1]};
      } else if (searchResultFormDatasheet) {
        return {...item, variableValue: searchResultFormDatasheet};
      } else {
        return item;
      }
    });
    setRows(newRows);
  }, [variableTable, valueGroupObject, valueFromDatasheet]);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {value, id, name: keyValue} = e.target;
    switch (keyValue) {
      case 'variableName': {
        const targetRow = variableTable.find((item) => item.keys === id);
        if (targetRow) {
          targetRow[keyValue] = value;
        }
        break;
      }
      case 'flowType': {
        setVariableTable((prev) => prev.map((item) => (item.keys === id ? {...item, [keyValue]: value} : item)));
        break;
      }
      case 'variableValue': {
        break;
      }
      case 'path': {
        break;
      }
    }
  };

  const addRow = () => {
    setVariableTable((prev) =>
      prev.concat({
        variableName: '',
        flowType: 'Inflow',
        variableValue: '',
        path: [],
        keys: getUniqueKey()
      })
    );
  };

  const deleteRow = (key: string) => {
    setVariableTable((prev) => prev.filter((item) => item.keys !== key));
  };

  const onClick = (rowKey: string, columnKey?: string, key?: string, path?: string[]) => {
    if (!rowKey && !columnKey) {
      addRow();
    } else if (rowKey === 'path') {
      if (columnKey === 'change_path') {
        onSetNodeSelectorTargetInfo(key, path);
      } else if (columnKey === 'delete_row') {
        deleteRow(key);
      }
    }
  };

  return (
    <Container>
      <ComponentTable
        fields={fields}
        rows={rows}
        rowKey="keys"
        onChange={onChange}
        onClick={onClick}
        onClickOnlyInComponent={true}
      />
    </Container>
  );
}

export default PythonEditorVariableTable;
