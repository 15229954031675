import React, {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {useNavigate} from 'react-router-dom';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, CheckboxForm, FormRow, TextForm} from 'components/forms';
import styled from 'styled-components';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';

const ValidMessage = styled.p`
  color: ${({theme}) => theme.color.invalid};
`;

function MetaPfdSaveAsModal(): ReactElement {
  const {saveAsModalState, saveLoadingModalState, getFileList, save, isPublic, setIsPublic, isRename, rename} =
    useContext(MetaPfdContext);
  const [saveFileModalData, setSaveFileModalData] = saveAsModalState;
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;
  const [text, setText] = useState(saveFileModalData?.fileName || '');
  const [validMessage, setValidMessage] = useState('');
  const navigate = useNavigate();

  useModalKeyBoardEvent({
    onConfirm: () => (isRename === false ? onClickConfirm() : onClickRename()),
    onCancel: () => onClose() // todo meta pfd enter key 이미 사용중. 다른 key로 교체 필요
  });

  useEffect(() => {
    if (saveFileModalData) {
      setText(saveFileModalData?.fileName || '');
      setIsPublic(false);
    }
    console.log('saveFileModalData>>>', saveFileModalData);
  }, [saveFileModalData]);

  const onClose = (): void => {
    setSaveFileModalData(undefined);
  };

  const onChangePublic = () => {
    setIsPublic(!isPublic);
  };

  const onChangeText = (e: ChangeEvent<HTMLInputElement>): void => {
    const {value} = e.target;
    setText(value);
    setValidMessage(undefined);
  };

  const onClickConfirm = (): void => {
    if (!text?.trim()) {
      setValidMessage('Please input file name.');
      return;
    } else {
      setIsShowSaveLoadingModal(true);
      save(text, saveFileModalData.isSaveAs).then((fileData) => {
        // console.log(fileData);
        // console.log(saveFileModalData.isSaveAs);
        setSaveFileModalData(undefined);
        getFileList().then(() => {
          navigate(`/mpfd/${fileData._id}`);
        });
      });
    }
  };

  const onClickRename = (): void => {
    setIsShowSaveLoadingModal(true);
    rename(text).then(() => {
      setSaveFileModalData(undefined);
      getFileList().then();
    });
  };

  if (saveFileModalData) {
    return (
      <ModalWrapper type="sm">
        <ModalHeader onClose={onClose}>{saveFileModalData.title}</ModalHeader>
        <ModalBody>
          <TextForm autoFocus value={text} width={400} placeholder="Input file name" onChange={onChangeText} />
          {isRename === false && (
            <FormRow header="Save this file as public">
              <CheckboxForm align="center" name="public" checked={isPublic} onChange={onChangePublic} label="Public" />
            </FormRow>
          )}
          <ValidMessage>{validMessage}</ValidMessage>
        </ModalBody>
        <ModalFooter>
          <Button
            className="modal-btn"
            variant="primary"
            size="sm"
            onClick={isRename === false ? onClickConfirm : onClickRename}
          >
            Save
          </Button>
          <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalWrapper>
    );
  } else {
    return undefined;
  }
}

export default MetaPfdSaveAsModal;
