import React, {ChangeEvent, ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import ModalFooter from 'components/common/modal/ModalFooter';
import Button from 'components/forms/Button';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import CommoditySelectItemList from 'components/pc/widgets/commodity/CommoditySelectItemList';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTags} from '@fortawesome/pro-regular-svg-icons';
import ModalBody from 'components/common/modal/ModalBody';
import {ICommodityBasic} from 'components/pc/widgets/commodity/types';
import NodeSelector from 'components/pc/node-selector/NodeSelector';
import {ICommodityCfg} from 'components/pc/widgets/CommodityWidget';
import {FormRow, SelectForm, TextForm} from 'components/forms';
import {TimeUnitOptions} from 'components/pc/widgets/hmb/const';
import {config} from '@fortawesome/fontawesome-svg-core';
import {getUniqueKey} from 'utils/commons';
import {CommonContext} from 'components/common/CommonProvider';
import {useReactFlow} from 'reactflow';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: 94%;

  .modal-body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .select-tag-btn {
    width: 100%;
    margin-top: 14px;
    font-size: 15px;
    height: 34px;
    flex-shrink: 0;

    .tags-icon {
      margin-right: 6px;
    }
  }
`;

const TextFormWrap = styled.div`
  display: flex;
  width: 26%;
  flex-shrink: 0;
`;

const ListWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

type IProps = {
  id: string;
  defaultList: ICommodityBasic[];
  cfg: ICommodityCfg;
  onChangeCfg(newCfg: ICommodityCfg, action?: string): void;
  onConfirm(list: ICommodityBasic[]): void;
  onCancel(isShowConfig: boolean): void;
};

function CommoditySetting({id, defaultList, onConfirm, onCancel, cfg, onChangeCfg}: IProps): ReactElement {
  const [selectedItemList, setSelectedItemList] = useState<ICommodityBasic[]>(defaultList);
  const [isShowTagSelector, setIsShowTagSelector] = useState(false);
  const {addToast} = useContext(CommonContext);

  // const [copy, setCopy] = useState<ICommodityCfg>();
  const {setNodes} = useReactFlow();
  const {autoUpdate, updateIntervalUnit, updateIntervalVal} = cfg;
  const [copy, setCopy] = useState<ICommodityCfg>({
    autoUpdate,
    updateIntervalUnit,
    updateIntervalVal
  });

  useEffect(() => {
    setCopy(cfg);
  }, [cfg]);

  useModalKeyBoardEvent({
    onConfirm: () => {
      onClickConfirm();
    },
    onCancel: () => {
      onClickCancel();
    }
  });

  //todo: nodeSelector useLatestTagHandler nodeHandler로 적용할 시 interval 단위 설정 구현

  const onChangeTimeIntervalVal = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    setCopy((prev) => ({...prev, updateIntervalVal: target.value}));
  };

  const onChangeTimeIntervalUnit = (e: ChangeEvent) => {
    const target = e.target as HTMLInputElement;
    Number(target.value);
    setCopy((prev) => ({...prev, updateIntervalUnit: Number(target.value)}));
  };

  const onClickConfirm = () => {
    const {autoUpdate, updateIntervalUnit, updateIntervalVal} = copy;
    let dic = {};
    let action = '';

    if (
      autoUpdate !== cfg.autoUpdate ||
      updateIntervalUnit !== cfg.updateIntervalUnit ||
      updateIntervalVal !== cfg.updateIntervalVal
    ) {
      if (Number(updateIntervalVal) > 0) {
        dic = {...dic, autoUpdate, updateIntervalUnit, updateIntervalVal};
        action = 'updateTimer';
      } else {
        addToast({id: getUniqueKey(), text: 'Update interval value must be greater than 0'});
      }
    }
    onConfirm(selectedItemList);
    onChangeCfg({...cfg, ...dic}, action);

    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {
                  ...node.data.metaData,
                  config: {...cfg, ...dic}
                }
              }
            }
          : node
      )
    );
  };

  const onClickCancel = () => {
    onCancel(false);
  };

  const onClickAddTagButton = (): void => {
    setIsShowTagSelector(true);
  };

  const onCloseTagPopup = (): void => {
    setIsShowTagSelector(false);
  };

  const onSort = (list: ICommodityBasic[]): void => {
    setSelectedItemList(list);
  };

  const onSelectTag = (tag: string[][], tagInfos: ICommodityBasic[]): void => {
    // tagSelector에서 전달해주는 tag 배열의 태그 목록에서 첫번째 인덱스 값인 "Commodity"를 제거해주는 함수
    const filteredTags: ICommodityBasic[] = tagInfos.map(({tag, unit}) => ({
      tag: tag.slice(1),
      unit
    }));

    setSelectedItemList(filteredTags);

    setIsShowTagSelector(false);
  };

  // NodeSelector selectedTags 형식에 맞게 변환
  const selectedItemTag = selectedItemList.map((item) => ['Commodity', item.tag[0], item.tag[1]]);

  return (
    <Container className="thin-scrollbar ">
      <ModalBody align="top" size="full-width">
        <ListWrap>
          <CommoditySelectItemList selectedItemList={selectedItemList} onSort={onSort} />
          <Button variant="dark" className="select-tag-btn" size="lg" onClick={onClickAddTagButton}>
            <FontAwesomeIcon className="tags-icon" icon={faTags} color="#aaa" size="xl" />
            Select item data
          </Button>
        </ListWrap>

        <FormRow header="Update interval">
          <TextFormWrap className="text-form-wrap">
            <TextForm
              type="number"
              name="time-value"
              value={copy?.updateIntervalVal || ''}
              onChange={(e) => onChangeTimeIntervalVal(e)}
            />
          </TextFormWrap>
          <SelectForm
            name={'time-unit'}
            options={TimeUnitOptions}
            value={copy?.updateIntervalUnit}
            onChange={(e) => onChangeTimeIntervalUnit(e)}
          />
        </FormRow>
      </ModalBody>
      {isShowTagSelector && (
        <NodeSelector
          selectedTags={selectedItemTag}
          onSelect={onSelectTag}
          onClose={onCloseTagPopup}
          categoryList={['Commodity']}
        />
      )}
      <ModalFooter>
        <Button onClick={onClickConfirm} className="modal-btn" variant="primary">
          Confirm
        </Button>
        <Button onClick={onClickCancel} className="modal-btn" variant="cancel">
          Cancel
        </Button>
      </ModalFooter>
    </Container>
  );
}

export default CommoditySetting;
