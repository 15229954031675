import React, {ReactElement, useEffect, useState} from 'react';
import Button from 'components/forms/Button';
import {resetPasswordEmailRequest} from 'components/auth/login/login-api-functions';
import ConfirmModal from 'components/common/ConfirmModal';

type IProps = {
  email: string;
};

function AdminResetPasswordButton({email}: IProps): ReactElement {
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isApiSent, setIsApiSent] = useState<boolean>(false);
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState<boolean>(false);

  const payLoad = {
    email: email,
    domain: window.location.origin
  };

  useEffect(() => {
    setIsApiSent(false);
  }, []);

  const onClickAdminResetPasswordBtn = () => {
    setIsLoading(true);
    resetPasswordEmailRequest(payLoad).then((res) => {
      setIsApiSent(true);
      setIsLoading(false);

      if (res?.status === 202 && res?.body?.success) {
        setIsPasswordResetSuccess(true);
      } else {
        setIsPasswordResetSuccess(false);
      }

      setOpenConfirmModal(false);
    });
  };

  const onClickShowConfirmModal = () => {
    setOpenConfirmModal(true);
  };

  return (
    <>
      {openConfirmModal && (
        <ConfirmModal
          confirmButtonLabel="Reset"
          confirmHeaderMessage="Reset Password"
          confirmBodyMessage={'This will send an email to the following user: ' + email}
          onConfirm={onClickAdminResetPasswordBtn}
          setOpenConfirmModal={setOpenConfirmModal}
          isLoading={isLoading}
        />
      )}
      {isApiSent && (
        <ConfirmModal
          confirmButtonLabel="Confirm"
          confirmHeaderMessage="Reset Password"
          confirmBodyMessage={isPasswordResetSuccess ? 'Successfully send.' : 'Email send failed.'}
          onConfirm={() => {
            setOpenConfirmModal(false);
            setIsApiSent(false);
          }}
          setOpenConfirmModal={setOpenConfirmModal}
          showCancelButton={false}
        />
      )}
      <Button className="modal-btn" variant="primary" onClick={onClickShowConfirmModal}>
        Reset
      </Button>
    </>
  );
}
export default AdminResetPasswordButton;
