import {memo, useContext} from 'react';
import {DataContext} from 'api/DataProvider';
import {toSignificantDigit} from 'utils/number-utils';
import styled from 'styled-components';
import {toDateFormat} from 'utils/commons';

const ValueContainer = styled.div`
  text-align: right;
`;

const TimeStampContainer = styled.div`
  text-align: center;
`;

function TimestampDataRenderer(props) {
  const {globalSettingsState} = useContext(DataContext);
  const [globalSettings] = globalSettingsState;
  // the available renderer-related props are:
  // - `row` (row index)
  // - `col` (column index)
  // - `prop` (column property name)
  // - `TD` (the HTML cell element)
  // - `cellProperties` (the `cellProperties` object for the edited cell)
  if (props?.col > 0) {
    return (
      <ValueContainer>
        {props?.value && toSignificantDigit(props?.value, globalSettings?.significantDigit)}
      </ValueContainer>
    );
  } else {
    return <TimeStampContainer>{toDateFormat(Number(props?.value), 'YYYY-MM-DD HH:mm:ss')}</TimeStampContainer>;
  }
}

// export default RendererComponent;

export default memo(TimestampDataRenderer, (prevProps, nextProps) => {
  return prevProps.value === nextProps.value;
});
