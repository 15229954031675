import {PropsWithChildren, ReactElement} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 560px;
  height: 150px;
  overflow: hidden;
`;

type IProps = PropsWithChildren & {
  width: number;
};

function StepContainer({children, width}: IProps): ReactElement {
  return <Container style={{width}}>{children}</Container>;
}

export default StepContainer;
