import {WidgetActionPanel, WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {faTableCells} from '@fortawesome/pro-solid-svg-icons';
import React, {KeyboardEvent, memo, MouseEvent, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {NodeProps, useReactFlow} from 'reactflow';
import {IDatasheetLocalDbWidgetData, IWidgetNodeData} from 'components/pc/types';
import {
  IDatasheetLocalDbWidgetSubjectInfoDetail,
  ILocalDatabaseDetail,
  IPythonEditorWidgetSubjectInfoDetail,
  LocalDatabaseContext
} from 'api/LocalDatabaseProvider';
import LocalDbSpreadsheet from 'components/spreadsheet/LocalDbSpreadsheet';
import {IHandsonTableSpreadSheetData} from 'components/spreadsheet/spreadsheet-adapter';
import {CellChange, ChangeSource} from 'handsontable/common';
import {dataSheetContextMenuFnc, spreadsheetUtilFnc} from 'components/spreadsheet/spreadsheetl-functions';
import {IDatasheetWidgetAutoRunConfig} from 'components/pc/widgets/datasheet/types';
import DatasheetWidgetActionPanel from 'components/pc/widgets/datasheet/DatasheetWidgetActionPanel';
import {IPythonEditorOutflowResult} from 'components/pc/widgets/PythonEditorWidget';
import NodeSelectorRevision from 'components/pc/node-selector/NodeSelectorRevision';
import {ISpreadSheetCellInfo} from 'components/spreadsheet/types';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import CellCoords from 'handsontable/3rdparty/walkontable/src/cell/coords';
import useProcessCanvasMenuCheckUsable from 'components/menu/pulldown/useProcessCanvasMenuCheckUsable';
import {getWidgetTitle} from 'utils/processCanvas-functions';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

function DatasheetLocalDbWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const {updateSubjectInfo, enrollmentInfo, contentInfo} = useContext(LocalDatabaseContext);
  const spreadSheetMetaDataState = useState<IHandsonTableSpreadSheetData>({
    colHeaders: null,
    rowHeaders: null,
    colWidth: 100,
    data: spreadsheetUtilFnc.getArr(5, 5, ''),
    rendererType: 'NormalDataRenderer',
    initialized: false
  });
  const [spreadsheetMetaData, setSpreadsheetMetaData] = spreadSheetMetaDataState;
  const selectedRowIdxState = useState<number>(0);
  const [selectedRowIdx, setSelectedRowIdx] = selectedRowIdxState;
  const autoRunConfigState = useState<IDatasheetWidgetAutoRunConfig>({
    intervalValue: 1,
    intervalUnit: 1000 * 60,
    isRepeat: false
  });
  const [autoRunConfig, setAutoRunConfig] = autoRunConfigState;
  const [createTimestamp, setCreateTimestamp] = useState<number>(0);
  // const [connectedDbInfo, setConnectedDbInfo] = useState<ILocalDatabaseDetail[]>([]);
  const [prevConnectedDbInfo, setPrevConnectedDbInfo] = useState<ILocalDatabaseDetail[]>([]);
  const [readOnlyMode, setReadOnlyMode] = useState(false);

  const maxRow = spreadSheetMetaDataState[0]?.data?.length || 0;
  const {setNodes} = useReactFlow();
  const handsontableRef = useRef<any>(null);
  const dataRef = useRef(null);
  dataRef.current = spreadsheetMetaData;

  const focusedIdxRef = useRef(null);
  focusedIdxRef.current = selectedRowIdx;

  const [isSubscriberBatchRun, setIsSubscriberBatchRun] = useState(false);
  const prevConnectedDbInfoRef = useRef(null);
  prevConnectedDbInfoRef.current = prevConnectedDbInfo;
  const createTimestampRef = useRef(null);
  createTimestampRef.current = createTimestamp;
  const subjectRef = useRef(null);
  const [connectedDatasheetSubjectInfo, setConnectedDatasheetSubjectInfo] =
    useState<IDatasheetLocalDbWidgetSubjectInfoDetail>();

  const [connectedPythonEditorSubjectInfo, setConnectedPythonEditorSubjectInfo] =
    useState<IPythonEditorWidgetSubjectInfoDetail>();

  const [pythonRunOutflowResultArr, setPythonRunOutflowResultArr] = useState<IPythonEditorOutflowResult[]>([]);

  const tagSelectorInfoState = useState<ISpreadSheetCellInfo>(null);
  const [tagSelectorInfo, setTagSelectorInfo] = tagSelectorInfoState;
  const selectedTagsState = useState<string[][]>([]);
  const [selectedNodes, setSelectedNodes] = selectedTagsState;

  const next = async (): Promise<boolean> => {
    /**
     *  go to next Row Index
     */
    let success = false;
    setSelectedRowIdx(function (prev) {
      let nextRowIdx = -1;
      if (autoRunConfig.isRepeat) {
        if (prev + 2 > maxRow) {
          nextRowIdx = 0;
        } else {
          nextRowIdx = prev + 1;
        }
      } else {
        if (prev + 2 > maxRow) {
          nextRowIdx = prev;
        } else {
          nextRowIdx = prev + 1;
        }
      }
      if (prev !== nextRowIdx) {
        success = true;
      }
      return nextRowIdx;
    });
    return success;
  };

  const changeReadOnly = async (readOnly: boolean) => {
    /**
     *  Repeat disabled
     *  Auto update disabled (setTimeInterval function return)
     */
    setAutoRunConfig((prev) => ({...prev, intervalValue: 0, isRepeat: false}));
    setReadOnlyMode(readOnly);
    return true;
  };

  subjectRef.current = {
    dbData: spreadsheetMetaData?.data,
    focusedRowIdx: selectedRowIdx,
    next,
    changeReadOnly
  };

  useEffect(() => {
    updateSubjectInfo(id, 'DatasheetLocalDbWidget', subjectRef);
  }, []);

  useEffect(() => {
    const widgetSubscriptionInfo = enrollmentInfo?.[id];
    const datasheetSubjectInfoArray = [];
    const pythonEditorSubjectInfoArray = [];
    if (widgetSubscriptionInfo) {
      widgetSubscriptionInfo?.forEach((info) => {
        const widgetSubjectInfo = contentInfo?.[info.id];
        if (info?.type === 'PythonEditorWidget') {
          pythonEditorSubjectInfoArray.push(widgetSubjectInfo);
        } else if (info?.type === 'DatasheetLocalDbWidget') {
          datasheetSubjectInfoArray.push(widgetSubjectInfo);
        }
      });
    }
    setConnectedDatasheetSubjectInfo(datasheetSubjectInfoArray?.[0]);
    setConnectedPythonEditorSubjectInfo(pythonEditorSubjectInfoArray?.[0]);
  }, [enrollmentInfo, contentInfo]);

  useEffect(() => {
    if (data?.metaData) {
      const {autoRunConfig, spreadsheetMetaData, selectedRowIdx, connectedDbInfo, pythonRunOutflowResultArr} =
        data?.metaData as IDatasheetLocalDbWidgetData;
      if (spreadsheetMetaData) {
        setSpreadsheetMetaData({...spreadsheetMetaData, initialized: true});
      }
      if (autoRunConfig) {
        setAutoRunConfig(autoRunConfig);
      }
      if (selectedRowIdx) {
        setSelectedRowIdx(selectedRowIdx);
      }
      if (connectedDbInfo) {
        setPrevConnectedDbInfo(connectedDbInfo);
      }
      if (pythonRunOutflowResultArr) {
        setPythonRunOutflowResultArr(pythonRunOutflowResultArr);
      }
    } else {
      setSpreadsheetMetaData((prev) => ({...prev, initialized: true}));
    }
  }, []);

  useEffect(() => {
    /**
     * 변경예정
     */
    setNodes((nodes) =>
      nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                metaData: {
                  ...node?.data?.metaData,
                  autoRunConfig,
                  spreadsheetMetaData: dataRef.current,
                  selectedRowIdx: selectedRowIdx,
                  pythonRunOutflowResultArr
                }
              }
            }
          : node
      )
    );
  }, [autoRunConfig, selectedRowIdx, pythonRunOutflowResultArr]);

  const afterChange = (changes: CellChange[], source: ChangeSource) => {
    switch (source) {
      case 'updateData': {
        break;
      }
      default: {
        /**
         * react 리렌더링 (Handsontable 이 이미 어레이참조를 통하여서 data 값은 변화하였지만, react 가 변화를 감지하지 못해서 리렌더링이 일어나지않기떄문에, setState
         */

        break;
      }
    }
    if (dataRef.current?.initialized) {
      // renewLocalDbInfo(dataRef.current?.data);
    }
  };

  const convertPythonOutflowResultTo2dArray = (outflowResultArr: IPythonEditorOutflowResult[]) => {
    const origin = [...outflowResultArr];
    const headers = ['timestamp'];
    const rows = [];
    const timestampIndexMap = {};

    origin.forEach((item) => {
      const {timestamp, outflowResult} = item;
      if (!(timestamp in timestampIndexMap)) {
        timestampIndexMap[timestamp] = rows.length;
        rows.push([timestamp]);
      }
      Object.entries(outflowResult).forEach(([key, value]) => {
        if (!headers.includes(key)) {
          headers.push(key);
        }
        const rowIndex = timestampIndexMap[timestamp];
        const headerIndex = headers.indexOf(key);
        while (rows[rowIndex].length < headerIndex) {
          rows[rowIndex].push(null); // Fill with null to match the header length
        }
        rows[rowIndex][headerIndex] = value;
      });
    });

    rows.forEach((row) => {
      while (row.length < headers.length) {
        row.push(null);
      }
    });
    setSpreadsheetMetaData((prev) => ({...prev, data: [headers, ...rows]}));
  };

  useEffect(() => {
    if (connectedPythonEditorSubjectInfo?.ref?.current?.pythonRunOutflowResult) {
      setPythonRunOutflowResultArr((prev) => {
        const next = prev.concat([connectedPythonEditorSubjectInfo?.ref?.current?.pythonRunOutflowResult]);
        convertPythonOutflowResultTo2dArray(next);
        return next;
      });
    }
  }, [connectedPythonEditorSubjectInfo?.ref?.current?.pythonRunOutflowResult]);

  const onSelect = (checked: string[]) => {
    const parsedData = checked.map((item) => JSON.parse(item));
  };

  const onClose = () => {
    setTagSelectorInfo(null);
    setSelectedNodes([]);
  };

  const [createContextMenu] = useContextMenu();
  const cellInfoState = useState<ISpreadSheetCellInfo[][]>(null);
  const [cellInfo, setCellInfo] = cellInfoState;
  const {checkTagPaste} = useProcessCanvasMenuCheckUsable();

  const onContextMenuPane = async (event: MouseEvent, row: number, col: number) => {
    event.preventDefault();
    const cellTagInfo = cellInfo?.[row]?.[col] as ISpreadSheetCellInfo;
    const disabledPasteTagId = await checkTagPaste();
    const handsontableHotInstance = handsontableRef.current.hotInstance;
    let selectedRangeArray = handsontableHotInstance.getSelected();
    /**
     * 우클릭이 일어났지만, context menu 의 document.mousedown 에 의해서 선택이 되지 않았을 때, 현재 선택된 cell 을 수동으로 선택해줌
     */
    let columnQue = [];
    let rowQue = [];
    if (!selectedRangeArray) {
      handsontableRef.current.hotInstance.selectCell(row, col);
      selectedRangeArray = [[row, col, row, col]];
    }
    const selectedCellTagInfos: ISpreadSheetCellInfo[] = [];
    const selectedCells = [];
    for (let i = 0; i < selectedRangeArray?.length; i++) {
      const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(selectedRangeArray[i]);
      columnQue.push(fromCol);
      columnQue.push(toCol);
      rowQue.push(fromRow);
      rowQue.push(toRow);
      for (let j = fromRow; j < toRow + 1; j++) {
        for (let k = fromCol; k < toCol + 1; k++) {
          if (cellInfo?.[j]?.[k]) {
            selectedCellTagInfos.push({...cellInfo?.[j]?.[k]} as ISpreadSheetCellInfo);
          }
          selectedCells.push('-');
        }
      }
    }
    const [selectedColumnRanges, selectedColumnsIdx] = spreadsheetUtilFnc.deleteDuplicatesInSelectedRanges(columnQue);
    const [selectedRowRanges, selectedRowsIdx] = spreadsheetUtilFnc.deleteDuplicatesInSelectedRanges(rowQue);
    columnQue = selectedColumnRanges;
    rowQue = selectedRowRanges;

    const newCellInfo =
      cellInfo ||
      spreadsheetUtilFnc.getArr(spreadsheetMetaData?.data.length, spreadsheetMetaData?.data[0].length, null);
    const newCellInfo_2 = newCellInfo.map((item) => [...item]) as ISpreadSheetCellInfo[][];
    const newData = spreadsheetMetaData?.data.map((item: unknown[]) => [...item]);

    const list = [
      // {
      //   label: 'Get Tag',
      //   value: 'get-tag',
      //   callback() {
      //     dataSheetContextMenuFnc.getTag(selectedCellTagInfos);
      //   },
      //   disabled: !(selectedCellTagInfos?.length === 1)
      // },
      // {
      //   label: 'Set Tag',
      //   value: 'set-tag',
      //   async callback() {
      //     await dataSheetContextMenuFnc.setTag(row, col, newCellInfo_2, setCellInfo);
      //   },
      //   disabled: disabledPasteTagId || !(selectedCells?.length === 1)
      // },
      // {
      //   label: 'Set Tag (Tag Selector)',
      //   value: 'set-tag-node-selector',
      //   callback() {
      //     // setTagSelectorInfo({
      //     //   row,
      //     //   col,
      //     //   tag: cellTagInfo?.tag,
      //     //   name: cellTagInfo?.name,
      //     //   unit: cellTagInfo?.unit,
      //     //   selectedRangeArray
      //     // });
      //     // setSelectedTags(cellTagInfo?.tag ? [cellTagInfo?.tag] : []);
      //   }
      // },
      // {
      //   label: 'Copy Tag ID',
      //   value: 'copy-tag-id',
      //   callback() {
      //     dataSheetContextMenuFnc.copyTag(selectedCellTagInfos);
      //   },
      //   disabled: !(selectedCellTagInfos?.length === 1)
      // },
      // {
      //   label: 'Paste Tag ID',
      //   value: 'paste-tag-id',
      //   async callback() {
      //     await dataSheetContextMenuFnc.pasteTagId(
      //       row,
      //       col,
      //       newData,
      //       newCellInfo_2,
      //       setSpreadsheetMetaData,
      //       setCellInfo
      //     );
      //   },
      //   disabled: disabledPasteTagId || !(selectedCells?.length === 1)
      // },
      {
        label: 'Add Row Above',
        value: 'add-row-above',
        callback() {
          dataSheetContextMenuFnc.addRow(
            rowQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'above'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Add Row Below',
        value: 'add-row-below',
        callback() {
          dataSheetContextMenuFnc.addRow(
            rowQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'below'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Add Column Left',
        value: 'add-column-left',
        callback() {
          dataSheetContextMenuFnc.addColumn(
            columnQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'left'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Add Column Right',
        value: 'add-column-right',
        callback() {
          dataSheetContextMenuFnc.addColumn(
            columnQue,
            newData,
            newCellInfo_2,
            setSpreadsheetMetaData,
            setCellInfo,
            handsontableHotInstance,
            'right'
          );
        },
        disabled: rowQue?.length !== 2 || columnQue?.length !== 2
      },
      {
        label: 'Delete Column',
        value: 'delete-column',
        callback() {
          dataSheetContextMenuFnc.deleteColumn(
            newData,
            newCellInfo_2,
            selectedColumnsIdx,
            setSpreadsheetMetaData,
            setCellInfo
          );
        },
        disabled: selectedRangeArray?.length !== 1
      },
      {
        label: 'Delete Row',
        value: 'delete-row',
        callback() {
          dataSheetContextMenuFnc.deleteRow(
            newData,
            newCellInfo_2,
            selectedRowsIdx,
            setSpreadsheetMetaData,
            setCellInfo
          );
        }
      }
    ];
    createContextMenu({event, list});
  };

  const onKeyDownCell = (event: KeyboardEvent) => {
    event.preventDefault();
    let selectedRangeArray = handsontableRef.current.hotInstance.getSelected();
    if (event.key === 'Delete' && selectedRangeArray) {
      const newCellInfo =
        cellInfo ||
        spreadsheetUtilFnc.getArr(spreadsheetMetaData?.data.length, spreadsheetMetaData?.data[0].length, null);
      const newCellInfoRemake = newCellInfo.map((item) => [...item]);
      const newData = spreadsheetMetaData?.data.map((item: unknown[]) => [...item]);
      for (let i = 0; i < selectedRangeArray?.length; i++) {
        const [fromRow, toRow, fromCol, toCol] = spreadsheetUtilFnc.getFromToFromSelectedRangeArr(
          selectedRangeArray[i]
        );
        for (let j = fromRow; j < toRow + 1; j++) {
          for (let k = fromCol; k < toCol + 1; k++) {
            newCellInfoRemake[j][k] = null;
            newData[j][k] = null;
          }
        }
      }
      setCellInfo(newCellInfoRemake as ISpreadSheetCellInfo[][]);
      setSpreadsheetMetaData((prev) => ({...prev, data: newData}));
    }
  };

  const beforeOnCellMouseDown = (event: globalThis.MouseEvent, coords: CellCoords, TD: HTMLTableCellElement) => {
    const selected = handsontableRef.current.hotInstance.getSelected();

    if (cellInfo?.[coords.row]?.[coords.col]?.tag && TD?.ariaSelected && event?.button === 0) {
      if (selected?.length === 1 && selected[0][0] === selected[0][2] && selected[0][1] === selected[0][3]) {
        // setIsShowWidgetModal(true);
      }
    }
  };

  return (
    <WidgetContainer {...rest} data={data} type="DatasheetLocalDbWidget">
      <WidgetHeader
        type="SpreadsheetWidget"
        icon={faTableCells}
        id={id}
        hasDocking
        docked={data.docked}
        title={getWidgetTitle({type: data.type, titleData: data.title, data})}
      />
      <WidgetActionPanel>
        <DatasheetWidgetActionPanel
          selectedRowIdxState={selectedRowIdxState}
          autoRunConfigState={autoRunConfigState}
          // subjectDbInfo={subjectDbInfo}
          next={next}
          readOnlyMode={readOnlyMode}
          maxRow={maxRow}
          isSubscriberBatchRun={isSubscriberBatchRun}
        />
      </WidgetActionPanel>
      <WidgetBody>
        <Wrapper
          onKeyDown={(e) => {
            if (e.key === 'Delete') {
              e.stopPropagation();
              onKeyDownCell(e);
            }
          }}
        >
          <LocalDbSpreadsheet
            selectedRowIdx={selectedRowIdxState[0]}
            spreadsheetMetaData={spreadsheetMetaData}
            cellInfo={cellInfo}
            onContextMenuPane={onContextMenuPane}
            handsontableRef={handsontableRef}
            onKeyDownCell={onKeyDownCell}
            afterChange={afterChange}
          />
        </Wrapper>
        {Boolean(tagSelectorInfo) && <NodeSelectorRevision onSelect={onSelect} onClose={onClose} />}
      </WidgetBody>
    </WidgetContainer>
  );
}

// export default memo(DatasheetLocalDbWidget, (prevProps, nextProps) => {
//   return prevProps.selected === nextProps.selected;
// });
export default DatasheetLocalDbWidget;
