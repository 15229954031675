import {useCallback, useEffect, useState} from 'react';
import {ISeries, ITimestampRangeState} from 'components/pc/widgets/timeseries/types';
import useApi from 'api/useApi';
import {getDataType} from 'components/pc/widgets/timeseries/timeseries-functions';
import {DateObject} from 'react-multi-date-picker';
import {colors} from 'common/colors';
import {INode, INodeDataReturn, INodeListParams, INodeParams} from 'api/data-types';

const getSortedNodes = (data: number[][], node: string[]): number[] => {
  if (!data) return [];
  // timestamp 관련 정제
  const type = getDataType(node);
  const refined = data.map((point) => {
    // const [timestamp, value] = node.data;
    // return [timestamp * 1000, Number(type === 'datetime' ? value % (3600 * 24) : value)];
    point[0] = point[0] * 1000;
    point[1] = Number(point[1]);
    if (type === 'datetime') {
      point[1] = point[1] % (3600 * 24);
    }
    return point;
  });
  // console.log('>>> refined', refined);
  // 평균, 최소, 최대 값등을 구하기 위한 sort
  const sorted = refined
    .reduce((acc, point) => {
      acc.push(point[1]);
      return acc;
    }, [])
    .sort((a, b) => a - b);

  return sorted;
};

const getNodeParamList = (nodes: string[][], range: number | number[]): INodeParams[] => {
  // param 목록 구성
  return nodes.map((node) => {
    const [database, ...rest] = node;
    const merged = {
      node: [...rest],
      database
    };
    if (Array.isArray(range)) {
      merged['time_range'] = [...range];
    } else {
      merged['latest_count'] = range;
    }
    return merged;
  });
};

const getNextTime = (start: number, interval: number): number => {
  return new DateObject(start * 1000).add(interval, 'seconds').unix;
};

// get_tag_data, get_tag_data_list 를 통해 받은 결과 type
type ITagData = {
  timeRange?: number[];
  tag: string[];
  data: number[][];
};

// type ITagListParams = {tag_infos: IQueryParams[]};

// type INodeData = {
//   timeRange?: number[];
//   node: string[];
//   data: number[][];
// };

type IReturn = {
  isLoadingSeries: boolean;
  series: ISeries[];
  liveSeries: ISeries[];
  requestSeries(nodes: string[][], customRange: number[]): void;
  removeSeries(tags: string[]): void;
  clearSeries(): void;
  updateSeries(): void;
  goLive(bool: boolean): void;
  stopLive(): void;
};

/**
 *
 */
function useTimeSeriesData(rangeState: ITimestampRangeState, intervalSeconds: number = 60): IReturn {
  const api = useApi();
  const [series, setSeries] = useState<ISeries[]>([]);
  const [liveSeries, setLiveSeries] = useState<ISeries[]>([]);
  const [isLoadingSeries, setIsLoadingSeries] = useState(false);
  // 현재 chart 의 range
  const [range, setRange] = rangeState;
  // live 상태일 때 range 를 임시 저장하여, 다시 live 가 해제 되었을 때 복구하여 사용
  const [savedRange, setSavedRange] = useState<number[]>([]);
  const [liveRange, setLiveRange] = useState<number[]>([]);
  // const [tagList, setTagList] = useState<string[][]>([]);
  const [nodeList, setNodeList] = useState<string[][]>([]);

  /**
   * series 목록을 가져옴
   * @param nodeListParams
   */
  const getSeriesList = async (nodeListParams: INodeListParams): Promise<ISeries[]> => {
    const result = await api.post<INodeDataReturn>('/node_manage/get_node_data_list', nodeListParams);

    const {success, data} = result;
    if (success) {
      return data.map((item: INode, index) => {
        // const [first] = item.result;
        const sorted = getSortedNodes(item.records, item.node);
        const tagStart = item.node.length < 3 ? 0 : 2;
        const refined = {
          keys: item.node,
          flattenKeys: item.node.join('-'),
          name: [...item.node].splice(tagStart, 3).join('-'),
          color: colors.chart[index % colors.chart.length],
          avg:
            sorted?.length > 0 ? sorted.reduce((acc, curr) => Number(acc) + Number(curr)) / sorted.length : undefined,
          max: sorted?.[sorted.length - 1] ? Number(sorted?.[sorted.length - 1]) : undefined,
          min: sorted?.[0] ? Number(sorted?.[0]) : undefined, // String 일 시에 Highchart 인식 X
          data: item.records ?? []
        };
        return refined as ISeries;
      });
    }
    return [];
  };

  /**
   * Series 조회
   * @param nodes
   * @param customRange
   */
  const requestSeries = (nodes: string[][], customRange: number[]): void => {
    const [start, end] = customRange;
    // console.log('>>> node', nodes);
    const params = {node_infos: getNodeParamList(nodes, customRange)};

    // 데이터를 가져올 조건이 성립한다면,
    if (start && end) {
      setIsLoadingSeries(true);
      // 데이터를 서버에 요청하고
      getSeriesList(params).then((list) => {
        // 여기서 데이터 state 변경
        // console.log('>>>> series list', list);
        setSeries(list);
        setNodeList(nodes);
        setIsLoadingSeries(false);
      });
    }
  };

  // deps 변경 되면 전체 기간 재조회
  /*  useEffect(() => {
    // requestSeries(tagList, range);
    console.log('taglist, range 변경', range);
  }, [tagList, range]);*/

  /**
   * Tag 기준으로 Series 삭제
   * @param tag
   */
  const removeSeries = (tag: string[]): void => {
    const tagKey = tag.join('-');
    setSeries((prevState) => prevState.filter((item) => item.flattenKeys !== tagKey));
  };

  /**
   * Series 초기화
   */
  const clearSeries = (): void => {
    setSeries([]);
  };

  /**
   * live 용 Series 조회
   * @param tags
   * @param customRange
   */
  const requestLiveSeries = (tags: string[][], customRange: number[]): void => {
    const [start, end] = customRange;
    const params = {tag_infos: getNodeParamList(tags, customRange)};

    if (start && end) {
      setIsLoadingSeries(true);

      /*getSeriesList(params).then((list) => {
        setLiveSeries(list);
        setLiveRange(customRange);
        setIsLoadingSeries(false);
      });*/
    }
  };

  const [timerId, setTimerId] = useState<ReturnType<typeof setInterval>>();
  const stopLive = useCallback((): void => {
    if (timerId) {
      console.log('<----- clearInterval');
      clearInterval(timerId);
    }
  }, [timerId]);

  /**
   * Live 실행 토글
   */
  const goLive = useCallback(
    (bool: boolean): void => {
      stopLive();

      if (bool) {
        const [start, end] = range;
        setSavedRange([start, end]);
        setRange(() => {
          const newStart = getNextTime(end, -600);
          requestSeries(nodeList, [newStart, end]);
          return [newStart, end];
        });

        const id = setInterval(() => {
          setRange((prev) => {
            const [start, End] = prev;
            const newStart = getNextTime(start, intervalSeconds);
            const newEnd = getNextTime(End, intervalSeconds);
            requestSeries(nodeList, [newStart, newEnd]);
            return [newStart, newEnd];
          });
        }, intervalSeconds * 1000);

        setTimerId(id);
      } else {
        const [start, end] = savedRange;
        setRange([start, end]);
        requestSeries(nodeList, [start, end]);
        setSavedRange([]);
      }
    },
    [nodeList, range, liveRange, intervalSeconds, stopLive]
  );

  /**
   *
   */
  const updateSeries = (): void => {
    setSeries((prev) => [...prev]);
  };

  // 나갈 때는 clearInterval
  useEffect(() => {
    return () => stopLive();
  }, [stopLive]);

  return {
    isLoadingSeries,
    series,
    liveSeries,
    requestSeries,
    removeSeries,
    clearSeries,
    updateSeries,
    goLive,
    stopLive
  };
}

export default useTimeSeriesData;
