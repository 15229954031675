import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import {useContext} from 'react';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import styled from 'styled-components';

const ModalBodyMessage = styled.div`
  font-size: 17px;
  font-weight: 400;
  color: #525f7f;
  display: flex;
  justify-content: center;
`;

function MetaPfdChangeAccessibilityModal() {
  const {
    file,
    accessibilityModalState,
    saveLoadingModalState,
    changeAccessibility,
    getFileList,
    isPublic,
    setIsPublic
  } = useContext(MetaPfdContext);
  const [, setIsShowChangeAccessibilityModal] = accessibilityModalState;
  const [, setIsShowSaveLoadingModal] = saveLoadingModalState;

  useModalKeyBoardEvent({
    onConfirm: () => onClickConfirm(),
    onCancel: () => onClose()
  });

  const onClose = (): void => {
    setIsShowChangeAccessibilityModal(false);
  };

  const onClickConfirm = async () => {
    const updatedFile = await changeAccessibility();
    if (updatedFile) {
      setIsShowSaveLoadingModal(true);
      setIsPublic(!isPublic); // change isPublic state here
      setIsShowChangeAccessibilityModal(false);
    }
    getFileList();
  };

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>Change Accessibility</ModalHeader>
      <ModalBody align="center" size="full-width">
        <ModalBodyMessage>
          Save this file as {file.public ? 'private' : 'public'}.
          <br />
          {file.public ? 'Only you can view this file.' : 'All users can view this file.'}
        </ModalBodyMessage>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onClickConfirm}>
          Save as {file.public ? 'Private' : 'Public'}
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default MetaPfdChangeAccessibilityModal;
