import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, SelectForm} from 'components/forms';
import FormRow from 'components/forms/FormRow';
import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {AuthContext} from 'components/auth/AuthProvider';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {Step, StepContainer, StepScroller} from 'components/common/stepper';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDiagramProject} from '@fortawesome/pro-light-svg-icons';
import useProcessCanvasCommand from 'components/menu/pulldown/useProcessCanvasCommand';

const ElementContainer = styled.div`
  width: 500px;
  .width {
    width: 100%;
  }
`;

const MessageText = styled.p`
  svg {
    margin-right: 10px;
  }
`;
const Message = styled.span`
  color: ${({theme}) => theme.color.black};
`;

function ProcessCanvasTransferOwnershipModal() {
  const {ownershipModalState, transferOwnership, getFileList, file, isFilePrivate, load} =
    useContext(ProcessCanvasContext);
  const {ownerName, userInfoList, userProfile, setOwnerName, getUserInfoList} = useContext(AuthContext);
  const {close} = useProcessCanvasCommand();
  const [, setIsShowOwnershipModal] = ownershipModalState;
  const [step, setStep] = useState(0);

  useModalKeyBoardEvent({
    onConfirm: () => onClick(),
    onCancel: () => onClose()
  });

  useEffect(() => {
    getUserInfoList();
    setOwnerName('');
  }, []);

  const onClose = (): void => {
    if (step === 0) {
      setIsShowOwnershipModal(false);
    } else {
      /*  //ownership 이전 시 file이 public일 경우 화면 유지
      if (isFilePrivate) {
        setIsShowOwnershipModal(false);
        //ownership 이전 시 file이 private일 경우 해당 파일 close
      } else {*/
      setIsShowOwnershipModal(false);
      close();
      // }
    }
  };

  const onClick = async () => {
    if (step === 0) {
      const response = await transferOwnership();
      if (response.success) {
        setStep(1);
        getFileList();
      }
    } else {
      /*   if (isFilePrivate) {
        load(file);
        setIsShowOwnershipModal(false);
      } else {*/
      setIsShowOwnershipModal(false);
      close();
      // }
    }
  };

  const onChangeOwnership = (event: ChangeEvent<HTMLSelectElement>): void => {
    setOwnerName(event.target.value);
  };

  const userList = userInfoList.filter((item) => item?.username !== userProfile?.username) || [];

  const options = userList.map((user) => ({
    value: user.username,
    label: user.username
  }));

  const noSelectUser = ownerName === '';

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>Transfer Ownership</ModalHeader>
      <ModalBody align="top" size="full-width">
        <StepContainer width={560}>
          <StepScroller total={2} step={step}>
            <Step>
              <ElementContainer>
                <SelectForm
                  name="user-list"
                  placeholder="Selected User"
                  width="width"
                  options={options}
                  value={ownerName}
                  onChange={onChangeOwnership}
                />
              </ElementContainer>
            </Step>
            <Step>
              <ElementContainer>
                <MessageText>
                  <FontAwesomeIcon icon={faDiagramProject} size="lg" />
                  <Message>
                    The owner of File <strong>{file.fileName}</strong> has been changed to <strong>{ownerName}</strong>.
                    <br />
                    The current file will now be closed.
                  </Message>
                </MessageText>
              </ElementContainer>
            </Step>
          </StepScroller>
        </StepContainer>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" disabled={noSelectUser} onClick={onClick}>
          {step === 0 ? 'Transfer Ownership' : 'Close'}
        </Button>
        {step === 0 && (
          <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
            Cancel
          </Button>
        )}
      </ModalFooter>
    </ModalWrapper>
  );
}

export default ProcessCanvasTransferOwnershipModal;
