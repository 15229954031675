import styled from 'styled-components';
import {IAnnotation, AnnotationTypes} from 'components/mpfd/type';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faTrashCan, faCheck, faEye, faEyeSlash} from '@fortawesome/pro-regular-svg-icons';
import classnames from 'classnames';
import {ChangeEvent, useRef, useState, KeyboardEvent, MouseEvent, useContext} from 'react';
import {CheckboxForm} from 'components/forms';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {annotationTab} from 'components/mpfd/panel/parts/annotation/AnnotationListView';
import NoDataDisplay from 'components/common/NoDataDisplay';

const Container = styled.div`
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03);
  display: flex;
  flex-direction: column;
  flex: 1;
`;
const Annotation = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  border-bottom: 1px solid #ddd;
  flex-shrink: 0;
  cursor: pointer;
  font-size: 16px;
  align-items: center;
  &:hover {
    .icons {
      display: flex;
      opacity: 1;
    }
  }
  &.highlighted {
    background-color: #cfe2f3ff;
  }
`;

const NameWrapper = styled.div`
  max-width: 367px;
  display: flex;
  align-items: center;
  gap: 4px;
  height: 40px;
  div.input-text {
    width: max-content;
    padding-right: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    .region-name-input {
      width: 205px;
      border: 1px solid #9eb0c0;
      background-color: rgba(255, 255, 255, 0.1);
      font-size: inherit;
      color: inherit;
      padding: 5px 10px;
      border-radius: 4px;
      &:focus {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
`;
const CheckboxContainer = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
`;
const NameLabel = styled.span`
  padding: 0 11px;
`;

const IconWrapper = styled.div`
  align-items: center;
  gap: 5px;
  display: flex;
  color: #999;
  width: 90px;
  flex-shrink: 0;
  justify-content: space-evenly;
  margin-left: auto;
  opacity: 0;

  svg {
    cursor: pointer;
    width: 18px;
  }
`;

type IProps = {
  type: AnnotationTypes;
  entireRegions: IAnnotation[];
  selectedTargetRegions: string[];
  onChangeCheckbox(e: ChangeEvent<HTMLInputElement>): void;
};

function AnnotationList({type, entireRegions, selectedTargetRegions, onChangeCheckbox}: IProps) {
  const {dispatchToReducer} = useContext(MetaPfdContext);
  const [editTarget, setEditTarget] = useState<IAnnotation | null>(null);
  const containerRef = useRef(null);
  const annot = annotationTab.find((item) => item.type === type);

  const onDeleteAnnot = (e: MouseEvent<SVGSVGElement>, r: IAnnotation) => {
    e.stopPropagation();
    dispatchToReducer({type: 'DELETE_REGIONS', regions: [r]});
  };

  const onSelectAnnot = (r: IAnnotation) => {
    dispatchToReducer({type: 'SELECT_REGION', region: r});
  };

  const onChangeAnnotName = (e: ChangeEvent<HTMLInputElement>) => {
    const {value} = e.target;
    setEditTarget((prev) => ({...prev, name: value}));
  };

  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      dispatchToReducer({type: 'CHANGE_REGION', region: editTarget});
      setEditTarget(null);
    }
    if (e.key === 'Escape') {
      setEditTarget(null);
    }
  };

  const onClickEditButton = (e: MouseEvent<SVGSVGElement>, item: IAnnotation, type: 'edit-start' | 'edit-confirm') => {
    if (type === 'edit-start') {
      setEditTarget(item);
    } else if (type === 'edit-confirm') {
      e.stopPropagation();
      dispatchToReducer({type: 'CHANGE_REGION', region: editTarget});
      setEditTarget(null);
    }
  };

  const onClickLabelVisibleButton = (e: MouseEvent<SVGSVGElement>, item: IAnnotation) => {
    const labelVisible = !item?.labelVisible;
    dispatchToReducer({type: 'CHANGE_REGION', region: {...item, labelVisible}});
  };

  // const onBlurEditMode = () => {
  //   dispatchToReducer({type: 'CHANGE_REGION', region: editTarget});
  //   setEditTarget(null);
  // };

  return (
    <Container ref={containerRef} className="thin-scrollbar">
      {entireRegions.map((item, idx) => (
        <Annotation
          className={classnames(item?.highlighted && 'highlighted')}
          onClick={() => onSelectAnnot(item)}
          key={idx}
        >
          <NameWrapper>
            <CheckboxContainer>
              <CheckboxForm
                name={item.id}
                onChange={onChangeCheckbox}
                checked={selectedTargetRegions.includes(item.id)}
              />
            </CheckboxContainer>
            <FontAwesomeIcon style={{color: item.color}} icon={annot.icon} />
            {item?.id === editTarget?.id ? (
              <div className="input-text">
                <input
                  className="region-name-input"
                  value={editTarget.name}
                  onChange={onChangeAnnotName}
                  onKeyDown={onKeyDown}
                  autoFocus
                  // onBlur={onBlurEditMode}
                />
              </div>
            ) : (
              <NameLabel>{item.name ? item.name : ''}</NameLabel>
            )}
          </NameWrapper>
          <IconWrapper className="icons">
            <FontAwesomeIcon
              icon={item?.id === editTarget?.id ? faCheck : faPenToSquare}
              onClick={(e) => onClickEditButton(e, item, item?.id === editTarget?.id ? 'edit-confirm' : 'edit-start')}
            />
            <FontAwesomeIcon
              icon={item?.labelVisible ? faEye : faEyeSlash}
              onClick={(e) => onClickLabelVisibleButton(e, item)}
            />
            <FontAwesomeIcon icon={faTrashCan} onClick={(e) => onDeleteAnnot(e, item)} />
            {/*<Tooltip content={item?.id === editTarget?.id ? 'Confirm' : 'Rename'} place="top">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={item?.id === editTarget?.id ? faCheck : faPenToSquare}*/}
            {/*    onClick={(e) => onClickEditButton(e, item, item?.id === editTarget?.id ? 'edit-confirm' : 'edit-start')}*/}
            {/*  />*/}
            {/*</Tooltip>*/}
            {/*<Tooltip content="Toggle Label" place="top">*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon={item?.labelVisible ? faEye : faEyeSlash}*/}
            {/*    onClick={(e) => onClickLabelVisibleButton(e, item)}*/}
            {/*  />*/}
            {/*</Tooltip>*/}
            {/*<Tooltip content="Delete" place="top">*/}
            {/*  <FontAwesomeIcon icon={faTrashCan} onClick={(e) => onDeleteAnnot(e, item)} />*/}
            {/*</Tooltip>*/}
          </IconWrapper>
        </Annotation>
      ))}
      {entireRegions?.length === 0 && (
        <NoDataDisplay icon={annot.blankIcon} iconSize="lg" description={`No ${annot.label} item.`} />
      )}
    </Container>
  );
}

export default AnnotationList;
