import React, {ReactElement, useContext, useRef} from 'react';
import classNames from 'classnames';
import {IAppMenu} from 'components/menu/types';
import MetaPfdFileList from 'components/menu/MetaPfdFileList';
import ActionMenuWidgetList from 'components/menu/ActionMenuWidgetList';
import ProcessCanvasFileList from 'components/menu/ProcessCanvasFileList';
import ActionMenuCopilot from 'components/menu/ActionMenuCopilot';
import MetaPfdWidgetList from 'components/menu/MetaPfdWidgetList';
import styled from 'styled-components';
import {faChevronLeft} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CommonContext} from 'components/common/CommonProvider';
import ManagementList from 'components/menu/ManagementList';
import useScrollLoader from 'hooks/useScrollLoader';
import ScrollLoader from 'components/common/ScrollLoader';

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 60px;
  z-index: 10; // 가장 위에 있어야함
  width: 0;
  height: 100%;
  color: #ffffff;
  transition: ${({theme}) => theme.action.menu.transition};
  box-shadow:
    2px 0 4px #00000040,
    4px 0 16px #00000040;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;
  background-color: #2f2f2f;

  &.mpfd-files {
    background-color: #1c1628;
  }

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #555;
  }

  &::-webkit-scrollbar-thumb {
    background: #000;
  }

  &.active {
    width: ${({theme}) => theme.action.menu.width}px;
    display: flex;
    flex-direction: column;
  }

  h2 {
    height: 1.2em;
    font-size: calc(1.75 * 1rem);
    font-weight: 200;
    color: #ffffff;
    padding: 0 1rem;
    margin: 0;
  }
  h3 {
    font-size: calc(1 * 1rem);
    line-height: 1.5;
    font-weight: 400;
    color: #cb7faa;
    padding: 0 1rem;
    margin: 0 0 0.5rem 0;
  }
`;

const MenuCloseBtn = styled.div`
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  font-size: 20px;
  margin-right: 16px;
  margin-top: 16px;

  svg {
    cursor: pointer;
    color: #8d8d8dff;
  }
`;

const Scroller = styled.div`
  flex: 1;
  width: ${(props) => props.theme.action.menu.width}px;
  //height: 96%;
  height: calc(100% - 41px);
  //padding-top: 50px;
  padding-top: 44px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

type IProps = {
  appMenu?: IAppMenu;
};

function ActionMenu({appMenu}: IProps): ReactElement {
  const {remoteAppMenu, showActionMenu} = useContext(CommonContext);

  const onClickContainer = () => {
    if (remoteAppMenu) {
      showActionMenu(null);
    }
  };

  const bodyRef = useRef<HTMLDivElement>(null);
  const [isShowScroll] = useScrollLoader(bodyRef);

  return (
    <Container ref={bodyRef} className={classNames('action-menu', appMenu?.id, {active: appMenu})}>
      <MenuCloseBtn>
        <FontAwesomeIcon icon={faChevronLeft} size="lg" onClick={onClickContainer} />
      </MenuCloseBtn>
      <Scroller>
        {appMenu?.id === 'process-canvas' && <ProcessCanvasFileList />}
        {appMenu?.id === 'mpfd' && <MetaPfdWidgetList />}
        {appMenu?.id === 'widget' && <ActionMenuWidgetList />}
        {appMenu?.id === 'copilot' && <ActionMenuCopilot />}
        {appMenu?.id === 'mpfd-files' && <MetaPfdFileList />}
        {appMenu?.id === 'manage' && <ManagementList />}
      </Scroller>
      {isShowScroll && <ScrollLoader />}
    </Container>
  );
}

export default ActionMenu;
