import {ForwardedRef, forwardRef, ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import {useViewport} from 'reactflow';

type StyleProps = {
  $zoom: number;
};

const Container = styled.div<StyleProps>`
  width: ${(props) => 100 / props.$zoom}%;
  height: ${(props) => 100 / props.$zoom}%;
  > div {
    overflow: visible;
  }
  display: flex;
`;

const Loader = styled.div`
  border: 10px solid #f3f3f3;
  border-top: 10px solid #000000;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 48%;
  left: calc(50% - 40px);
  background-color: rgba(0, 0, 0, 0.3);
`;

const LoadingMessage = styled.span`
  position: absolute;
  top: 54%;
  padding: 10px;
  width: 100%;
  text-align: center;
  font-size: 10px;
`;

type IProps = {
  children?: ReactNode;
  isLoading: boolean;
  loadingMessage: string;
};

const ThreeCanvasWrapper = forwardRef(function ThreeCanvasWrapperLayout(
  {children, isLoading, loadingMessage}: IProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const vp = useViewport();

  if (isLoading) {
    return (
      <>
        <Loader />
        <LoadingMessage>{loadingMessage}</LoadingMessage>
      </>
    );
  } else {
    return (
      <Container $zoom={vp.zoom} className={classnames('wrapper', 'nowheel', 'nodrag')} ref={ref}>
        {children}
      </Container>
    );
  }
});

ThreeCanvasWrapper.displayName = 'ThreeCanvasWrapper';
export default ThreeCanvasWrapper;
