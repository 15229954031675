import styled from 'styled-components';
import {ITableField} from 'components/table/DefaultTable';
import {useEffect, useState} from 'react';
import {IAnnotation, IPfdCfg} from 'components/mpfd/type';
import ComponentTable from 'components/table/ComponentTable';
import {ILatestTagHandlerReturn} from 'hooks/useLatesetTagHandler';
import BasicSpinner from 'components/common/BasicSpinner';
import SignificantValue from 'components/table/parts/SignificantValue';

type StyleProps = {
  $isLiveUpdate: boolean;
};

const Container = styled.div<StyleProps>`
  height: calc(100%);

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 20px;
    z-index: 99;
    transform: rotate(45deg) translate(8px, -12px);
    background-color: ${(props) => (props.$isLiveUpdate ? props.theme.color.info : props.theme.color.warning)};
  }
`;

const LoadingContainer = styled.div`
  background-color: white;
  border: 1px solid #a5a5a5;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type IProps = {
  iw: number;
  ih: number;
  mat: any;
  cfg: IPfdCfg;
  latestTagHandler?: ILatestTagHandlerReturn;
  region: IAnnotation;
  id?: string;
};

function MetaPfdDataTable({region, id, cfg, latestTagHandler, iw, ih, mat}: IProps) {
  const [rows, setRows] = useState([]);
  const {latestResult, renewSubscribe, disconnectSubscribe} = latestTagHandler;

  useEffect(() => {
    let timer: NodeJS.Timeout;
    const fnc = () => {
      const tags = region.metaPfdTagInfo.filter((item) => item.isTableDisplay).map((item) => item.tag);
      renewSubscribe(id, tags, cfg?.isLiveUpdateDataValues);
    };
    timer = setTimeout(() => fnc(), 1000);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [region.metaPfdTagInfo, id, cfg?.isLiveUpdateDataValues]);

  useEffect(() => {
    const res = latestResult[id];
    //:todo object 검사하여 동일하면 return
    if (res) {
      const tags = region.metaPfdTagInfo
        .filter((item) => item.isTableDisplay)
        .map((item) => {
          const [category, ...rest] = item.tag;
          return [category.toLowerCase(), ...rest].join('-');
        });
      const newRows = res
        .filter((item) => tags.includes(item.key))
        .map((item) => ({
          tagName: item.tag.join('-'),
          value: item.value?.[0]?.[1]
        }));
      setRows(newRows);
    }
  }, [latestResult, id, region.metaPfdTagInfo]);

  useEffect(() => {
    return () => {
      disconnectSubscribe(id);
    };
  }, []);

  const cellHeight = 16;
  const cellFontSize = 20;

  const fields: ITableField[] = [
    {
      key: 'tagName',
      label: 'Tag Name',
      style: {
        header: {
          width: '60%',
          height: cellHeight,
          fontSize: cellFontSize,
          boxSizing: 'border-box',
          display: !region?.isShowTableHeader && 'none'
        },
        body: {fontSize: cellFontSize, height: cellHeight, boxSizing: 'border-box'}
      },
      css: {body: 'ellipsis'}
    },
    {
      key: 'value',
      label: 'Value',
      style: {
        header: {
          width: '40%',
          height: cellHeight,
          fontSize: cellFontSize,
          boxSizing: 'border-box',
          display: !region?.isShowTableHeader && 'none'
        },
        body: {textAlign: 'right', fontSize: cellFontSize, height: cellHeight, boxSizing: 'border-box'}
      },
      css: {body: 'ellipsis'},
      component: SignificantValue
    }
  ];

  return (
    <Container $isLiveUpdate={cfg?.isLiveUpdateDataValues}>
      {rows?.length > 0 && (
        <ComponentTable
          fields={fields}
          rows={rows}
          rowKey="tagName"
          headerCssProperties={{display: !region?.isShowTableHeader && 'none'}}
        />
      )}
      {rows?.length === 0 &&
        region.metaPfdTagInfo.filter((item) => item.isTableDisplay).map((item) => item.tag).length > 0 && (
          <LoadingContainer>
            <BasicSpinner isShow={true} type="default" position="center-center" size="md" />
          </LoadingContainer>
        )}
    </Container>
  );
}

export default MetaPfdDataTable;
