import {MouseEvent, ReactElement, useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowsRotate, faEllipsisVertical, faTrash} from '@fortawesome/pro-light-svg-icons';
import {Button} from 'components/forms';
import useContextMenu from 'components/common/context-menu/useContextMenu';
import {CommonContext} from 'components/common/CommonProvider';

const Container = styled.div`
  display: flex;
  align-items: center;
`;
const Name = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
const IconContainer = styled.div`
  margin-left: auto;
  position: relative;
`;
const MenuLayer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1000; // 노드보다 작은 z Index 값을 가지게 될 시에 노드가 먼저 클릭됨
  top: 0;
  background-color: #ffffff;
  margin-top: 5px;
  margin-left: -8px;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: default;

  div {
    display: flex;
    align-items: center;
    padding: 5px 8px;
    cursor: pointer;

    svg {
      margin-right: 5px;
    }

    &:hover {
      background-color: #daebf6;
    }
  }
`;

export type ITagNameMenuAction = {
  type: 'CHANGE' | 'REMOVE';
  keys: string[];
};

type IProps = {
  name: string;
  keys: string[];
  onMenu(action: ITagNameMenuAction): void;
};

function TagName({name, keys, onMenu}: IProps): ReactElement {
  const [isShowMenu, setIsShowMenu] = useState(false);
  const [createContextMenu] = useContextMenu();
  const {addNotice} = useContext(CommonContext);

  useEffect(() => {
    if (isShowMenu) {
      document.addEventListener('mousedown', () => setIsShowMenu(false), {once: true});
    }
  }, [isShowMenu]);

  const onClickMenu = (): void => {
    setIsShowMenu(!isShowMenu);
  };

  const onClickChange = (e: MouseEvent): void => {
    onMenu({type: 'CHANGE', keys});
    setIsShowMenu(false);
  };

  const onClickRemove = (): void => {
    onMenu({type: 'REMOVE', keys});
    setIsShowMenu(false);
  };

  const onContextMenu = (event: MouseEvent): void => {
    const list = [
      {
        label: 'Add Widget',
        value: 'add-widget',
        children: [
          {label: 'HMB Widget', value: 'hmb-widget', disabled: true},
          {label: 'Spreadsheet Widget', value: 'spreadsheet-widget', disabled: true},
          {label: 'Time Series Widget', value: 'time-series-widget', disabled: true},
          {label: '3D Model Widget', value: '3d-model-widget', disabled: true}
        ]
      },
      {
        label: 'Get Tag',
        value: 'get-tag',
        callback() {
          console.log('===> get tag', name);
        }
      },
      {
        label: 'Set Tag',
        value: 'set-tag',
        callback() {
          console.log('===> set tag', name);
        }
      },
      {
        label: 'Copy Tag ID',
        value: 'copy-tag-id',
        callback() {
          navigator.clipboard
            .writeText(name)
            .then(() => {
              addNotice({text: `Copied tag id ${name}`});
            })
            .catch((err) => {});
          console.log('===> copy tag id', name);
        }
      },
      {
        label: 'Paste Tag ID',
        value: 'paste-tag-id',
        callback() {
          console.log('===> paste tag id', name);
        }
      },
      {
        label: 'Delete Tag',
        value: 'delete-tag',
        disabled: true,
        callback() {
          console.log('===> delete tag', name);
        }
      },
      {
        label: 'Information',
        value: 'tag-information',
        callback() {
          console.log('===> information tag', name);
        }
      }
    ];
    createContextMenu({event, list, title: name});
  };

  return (
    <Container>
      <Name onContextMenu={onContextMenu}>{name}</Name>
      <IconContainer>
        <Button variant="none" onClick={onClickMenu}>
          <FontAwesomeIcon icon={faEllipsisVertical} className="ellipsis-icon" size="lg" />
          {isShowMenu && (
            <MenuLayer>
              <div onMouseDown={onClickChange}>
                <FontAwesomeIcon icon={faArrowsRotate} size="sm" /> Change
              </div>
              <div onMouseDown={onClickRemove}>
                <FontAwesomeIcon icon={faTrash} size="sm" /> Remove
              </div>
            </MenuLayer>
          )}
        </Button>
      </IconContainer>
    </Container>
  );
}

export default TagName;
