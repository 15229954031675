import CheckboxTree, {Node as TreeNode, OnCheckNode as OnCheckTreeNode} from 'react-checkbox-tree';
import styled from 'styled-components';
import * as React from 'react';
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronRight, faMinusSquare, faSquareCheck} from '@fortawesome/pro-solid-svg-icons';
import {faSquare} from '@fortawesome/pro-regular-svg-icons';
import {TextForm} from 'components/forms';
import {getAllValuesFromNodes, keywordFilter} from 'components/pc/node-selector/function';

const TreeDisplay = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;

  // overriding
  .react-checkbox-tree {
    overflow: auto;
    // overriding
    .rct-text {
      white-space: nowrap;
    }

    &::-webkit-scrollbar {
      background: rgba(0, 0, 0, 0.08);
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.3);
    }
  }
`;

const SearchForm = styled.div`
  height: 40px;
  flex-shrink: 0;
`;

export type CustomNode = TreeNode & {
  unit?: string;
};

type IProps = {
  nodes: TreeNode[];
  selected: string[][];
  checkedState: [string[], Dispatch<SetStateAction<string[]>>];
  expandedState: [string[], Dispatch<SetStateAction<string[]>>];
  iconOption?: 'modal' | 'normal';
  isSingleCheck?: boolean;
  beforeOnCheck?(candidate: string[], checkedList: string[]): string[];
};

const defaultColor = '#AAAAAA';
const check = <FontAwesomeIcon icon={faSquareCheck} color="#3D51FE" />;
const uncheck = <FontAwesomeIcon icon={faSquare} color={defaultColor} />;
const halfCheck = <FontAwesomeIcon icon={faMinusSquare} color="#ACB3FF" />;
const expandClose = <FontAwesomeIcon icon={faChevronRight} color={defaultColor} />;
const expandOpen = <FontAwesomeIcon icon={faChevronDown} color={defaultColor} />;
const expandAll = <FontAwesomeIcon icon={faChevronDown} color={defaultColor} />;

function TreeMenu({
  nodes,
  selected,
  checkedState,
  expandedState,
  iconOption = 'modal',
  isSingleCheck = false,
  beforeOnCheck
}: IProps) {
  const [checked, setChecked] = checkedState;
  const [expanded, setExpanded] = expandedState;
  const [keyword, setKeyword] = useState('');
  const [treeNode, setTreeNode] = useState<CustomNode[]>([]);

  const [icons, setIcons] = useState({});
  useEffect(() => {
    switch (iconOption) {
      case 'modal':
        setIcons({check, uncheck, halfCheck, expandClose, expandOpen, expandAll});
        break;
      case 'normal':
        setIcons({check: <></>, uncheck: <></>, halfCheck: <></>, expandClose, expandOpen, expandAll});
    }
  }, [iconOption]);

  useEffect(() => {
    setTreeNode(nodes);
  }, [nodes]);

  useEffect(() => {
    if (selected?.length > 0) {
      const checkedTagList = [...(selected || [])].map((tag) => JSON.stringify(tag));
      setChecked(checkedTagList);
      const expandedTagCandidateList = [];
      [...(selected || [])].map((tag) => tag?.map((item, idx) => expandedTagCandidateList.push(tag.slice(0, idx + 1))));
      const uniqueExpand = Array.from(new Set(expandedTagCandidateList.map((item) => JSON.stringify(item))));
      setExpanded(uniqueExpand);
    }
    console.log(selected);
  }, [selected]);

  const onCheck = (list: string[], node: OnCheckTreeNode & {isLeaf: boolean; unit?: string}): void => {
    console.log(node);
    if (isSingleCheck) {
      if (node?.value && node?.isLeaf) {
        setChecked([node?.value]);
      }
    } else {
      if (beforeOnCheck) {
        const valid = beforeOnCheck(list, checked);

        setChecked(valid);
      } else {
        setChecked(list);
      }
    }
  };

  // const onClick = (node: OnCheckTreeNode & {isLeaf: boolean}) => {
  //   if (node?.value && node?.isLeaf) {
  //     setChecked([node?.value]);
  //   }
  // };
  //
  // const onExpanded = () => {};

  const onChange = (event: ChangeEvent) => {
    const {value} = event.target as HTMLInputElement;
    if (!nodes || nodes?.length === 0) return;
    setKeyword(value);
    if (value?.trim().length > 1) {
      const searchedNodes = keywordFilter([...(nodes || [])], value, checked);
      const expandedNodes = getAllValuesFromNodes(searchedNodes, true);
      setTreeNode(searchedNodes);
      const restrictExpandedNodes = [...expandedNodes].slice(0, 100);
      setExpanded(restrictExpandedNodes);
    } else {
      setTreeNode([...(nodes || [])]);
      setExpanded([]);
    }
  };

  return (
    <>
      <SearchForm>
        <TextForm name="keyword" value={keyword} placeholder="Search Tag" onChange={onChange} />
      </SearchForm>
      <TreeDisplay>
        <CheckboxTree
          nodes={treeNode}
          checked={checked}
          expanded={expanded}
          onCheck={onCheck}
          // onClick={onClick}
          onExpand={setExpanded}
          expandOnClick
          showNodeIcon={false}
          icons={icons}
        />
      </TreeDisplay>
    </>
  );
}

export default TreeMenu;
