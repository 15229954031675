import {ReactNode} from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #8a9496;
  box-sizing: border-box;

  .footer {
    position: absolute;
    bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    z-index: 1;
  }

  .left-menu {
    position: absolute;
    top: 50px;
    left: 2%;
    height: calc(100% - 50px);
    display: flex;
    gap: 1rem;
    flex-direction: row;
  }

  .buttons-group {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
  }

  .btn {
    background-color: rgba(29, 29, 29, 0.5);
    border-radius: 10px;
    padding: 20px;
    font-size: 14px;
    color: #e0e0e0;
    cursor: pointer;
    border: none;
    height: 2rem;
    transition: 0.1s;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .btn.pressed {
    background-color: #1d1d1de6;
  }

  .btn span {
    font-size: 22px;
    margin-right: 10px;
    margin-top: -3px;
    color: #ffffff !important;
  }

  .btn:hover {
    scale: 1.1;
  }

  .btn:active {
    scale: 1.2;
  }
`;

type IProps = {
  children?: ReactNode;
};

function ThreeLandscapeWidgetContainer({children}: IProps) {
  return <Container>{children}</Container>;
}

export default ThreeLandscapeWidgetContainer;
