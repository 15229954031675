import React, {ChangeEvent, CSSProperties, FunctionComponent, ReactElement, ReactNode} from 'react';
import styled from 'styled-components';
import {CheckboxForm} from 'components/forms';
import {ISelectedRowState} from 'components/pc/widgets/timeseries/types';

const Container = styled.div`
  overflow: auto;
  height: 100%;

  table {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    //border-spacing: 1px;
    //background-color: #fff;
    background-color: #363779;

    tr {
      th,
      td {
        padding: 2px 5px;
      }
    }

    > thead {
      background-color: #363779;

      tr {
        th {
          height: 30px;
          max-height: 30px;
          border: 1px solid #363779;
          background-color: #5556a9;
          color: #b0b1ea;
          white-space: nowrap;
          position: sticky;
          top: -2px;
        }
      }
    }

    > tbody {
      background-color: #363779;

      tr {
        td {
          background-color: #fff;
          border: 1px solid #363779;

          &.no-data {
            text-align: center;
            padding: 35px;
            color: #aaa;
            //background-color: #e5e5f6;
            background-color: #ffffff;
            border: none;
          }

          &.nowrap {
            white-space: nowrap;
          }

          &.no-padding {
            padding: 0;
          }

          &.checkbox {
            //padding: 0;
            // todo: 테이블 내부 스타일 수정 필요
          }

          &.ellipsis {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            /*
                        // 
                        &.multiline {
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 2;
                          line-height: 1.5em;
                          max-height: 3em;
                        }*/
          }
        }
      }
    }
  }
`;

export type ITableField = {
  key?: string;
  label?: string;
  style?: {
    header?: CSSProperties;
    body?: CSSProperties;
  };
  css?: {
    header?: string;
    body?: string;
  };
  toFixed?: number;
  component?: FunctionComponent;
  icon?: {
    component?: ReactNode;
    actionType?: string;
  };
  keyOfMinMax?: {
    min?: string; // 'bottom'
    max?: string; // 'top
  };

  // [key: string]: string | number | object | null | undefined;
};

type IProps<T> = {
  children?: ReactElement;
  loadingComponent?: ReactElement;
  fields: ITableField[];
  fieldKey: keyof ITableField;
  fieldLabelKey: keyof ITableField;
  rows: T[];
  rowKey: string;
  noDataText?: string;
  selectedRowState: ISelectedRowState;
  onChangeRow?(e: ChangeEvent): void;
};

function DefaultTable<T>({
  children,
  loadingComponent,
  fields,
  fieldKey,
  fieldLabelKey,
  rows,
  rowKey,
  noDataText,
  selectedRowState
}: IProps<T>): ReactElement {
  // row 선택 상태를 로컬 컴포넌트에서 관리
  const [selectedRows, setSelectedRows] = selectedRowState;

  const onChangeCheckbox = (e: ChangeEvent<HTMLInputElement>): void => {
    const {checked, name} = e.target;
    // check 여부에 따라 다르게 배열 메서드 사용
    setSelectedRows((prev) => (checked ? prev.concat(name) : prev.filter((item) => item !== name)));
  };

  const onChangeCheckboxAll = (e: ChangeEvent<HTMLInputElement>): void => {
    if (selectedRows?.length !== rows?.length) {
      const flattenKeyList = rows.map((item) => item['flattenKeys' as keyof T] as string);
      setSelectedRows([...flattenKeyList]);
    } else {
      setSelectedRows([]);
    }
  };

  const getTd = (field: ITableField, row: T): ReactElement => {
    let value = row[field[fieldKey] as string];

    // field.toFixed 값이 있다면 숫자 (또는 문자 형 숫자) 로 간주함
    // if (!isNaN(Number(value)) && field?.toFixed > -1) {
    //   value = Number(value)?.toFixed(field.toFixed);
    // }

    switch (field.key) {
      case 'checkbox':
        const name = row[rowKey] as string;
        return <CheckboxForm name={name} checked={selectedRows.includes(name)} onChange={onChangeCheckbox} />;
    }

    if (field.component) {
      const CustomComponent = field.component as FunctionComponent;
      const refinedRow = {
        ...row,
        field
      };
      return <CustomComponent {...(refinedRow as T)} />;
    }

    return <>{value}</>;
  };

  const getTh = (field: ITableField): ReactElement => {
    let value = field[fieldLabelKey] as string;

    switch (field.key) {
      case 'checkbox':
        const name = 'checkbox-all' as string;
        return (
          <CheckboxForm
            name={name}
            checked={selectedRows?.length === rows?.length && rows?.length > 0}
            onChange={onChangeCheckboxAll}
          />
        );
      case 'color':
        return <></>;
    }
    return <>{value}</>;
  };

  return (
    <Container className="thin-scrollbar md">
      <table>
        <thead>
          <tr>
            {fields?.map((field) => (
              <th key={field[fieldKey] as string} style={field?.style?.header}>
                {getTh(field)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row) => (
            <tr key={row[rowKey] as string}>
              {fields?.map((field) => (
                <td key={field[fieldKey] as string} style={field?.style?.body} className={field?.css?.body}>
                  {getTd(field, row)}
                </td>
              ))}
            </tr>
          ))}
          {noDataText && rows?.length === 0 && (
            <tr>
              <td colSpan={fields?.length} className="no-data">
                No Data
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {loadingComponent}
      {children}
    </Container>
  );
}

export default DefaultTable;
