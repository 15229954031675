import React, {ReactElement, useContext, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import FormRow from 'components/forms/FormRow';
import {Button} from 'components/forms';
import styled from 'styled-components';
import UserModeToggle from 'components/menu/copilot/UserModeToggle';
import DataModeToggle from 'components/menu/copilot/DataModeToggle';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import VoiceModeToggle from 'components/menu/copilot/VoiceModeToggle';
import {LocalStorageManager} from 'utils/local-storage-manager';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';

const SwitchWrap = styled.div`
  //margin-right: -15px; //todo switchForm 오른쪽 margin 삭제 refactoring 필요
`;

type IProps = {
  onClose(): void;
};

export type ICopilotSettings = {
  dataIsOn?: boolean;
  userIsOn?: boolean;
  voiceIsOn?: boolean;
};

const defaultSettings: ICopilotSettings = {userIsOn: true, dataIsOn: false, voiceIsOn: true};

function CopilotSettingModal({onClose}: IProps): ReactElement {
  const {setIsOn, setVoiceIsOn, setUserIsOn} = useContext(CopilotContext); // isOn, setIsON 이름 적절하게 변경 필요

  const savedSettings = LocalStorageManager.getItem<ICopilotSettings>('COPILOT_SETTINGS') || defaultSettings;
  const [settings, setSettings] = useState<ICopilotSettings>(savedSettings);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  const onConfirm = () => {
    LocalStorageManager.setItem('COPILOT_SETTINGS', settings);
    setIsOn(settings.dataIsOn);
    setVoiceIsOn(settings.voiceIsOn);
    setUserIsOn(settings.userIsOn);
    onClose();
  };
  // 값 변경 로직
  const onToggle = (name: string, value: boolean) => {
    const changed = {
      ...settings,
      [name]: value //동적 key 할당
    };
    setSettings(changed);
  };
  // 저장 로직
  //todo switch Mode Toggle form 공통화 작업 필요
  return (
    <ModalWrapper type="settings">
      <ModalHeader onClose={onClose}>Copilot Settings</ModalHeader>
      <ModalBody align="top" size="full-width">
        <FormRow header="User mode">
          <SwitchWrap>
            <UserModeToggle name="userIsOn" userIsOn={settings.userIsOn} onToggle={onToggle} />
          </SwitchWrap>
        </FormRow>
        {/*  <FormRow header="Chat with data">
          <SwitchWrap>
            <DataModeToggle name="dataIsOn" dataIsOn={settings.dataIsOn} onToggle={onToggle} />
          </SwitchWrap>
        </FormRow>*/}
        <FormRow header="Use voice command">
          <SwitchWrap>
            <VoiceModeToggle name="voiceIsOn" voiceIsOn={settings.voiceIsOn} onToggle={onToggle} />
          </SwitchWrap>
        </FormRow>
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onConfirm}>
          Save
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}
export default CopilotSettingModal;
