import React, {useContext, useRef, useState} from 'react';
import useEventCallback from 'use-event-callback';
import styled from 'styled-components';
import {applyToPoint, compose, inverse, rotate, scale, translate} from 'transformation-matrix';
import {layoutParamType} from 'components/mpfd/type';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import FormToolBox from 'components/mpfd/panel/FormToolBox';
import {useProjectRegionBox} from 'components/mpfd/hooks';
import {AnnotationLayer, ConnectionLineLayer, ImageLayer, SelectedAnnotationLayer} from 'components/mpfd/layer';
import RemoveScrollWrapper from 'components/mpfd/etc/RemoveScrollWrapper';
import DataLayer from 'components/mpfd/layer/DataLayer';
import LayerWrapper from 'components/mpfd/layer/LayerWrapper';
import ProcessImageViewerContainer from 'components/mpfd/layer/parts/ProcessImageViewerContainer';
import ZoomLayer from 'components/mpfd/layer/ZoomActionLayer';

const StandardCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  opacity: 0.25;
`;

export const ProcessImageViewer = () => {
  const {state, dispatchToReducer, latestTagHandlerForTable, latestTagHandlerForChart, matrix, changeMatrix} =
    useContext(MetaPfdContext);
  const {regions, selectedTool, images} = state;
  const layoutParams = useRef<layoutParamType>({});
  const canvasEl = useRef(null);
  // const [dragging, changeDragging] = useRafState(false);
  // const [zoomStart, changeZoomStart] = useRafState(false);

  const projectRegionBox = useProjectRegionBox({layoutParams, mat: matrix});
  const [imageDimensions, changeImageDimensions] = useState({naturalWidth: 0, naturalHeight: 0});
  const imageLoaded = Boolean(imageDimensions.naturalWidth > 0);

  const onImageLoaded = useEventCallback(({naturalWidth, naturalHeight}) => {
    const dims = {naturalWidth, naturalHeight};
    changeImageDimensions(dims);
    setTimeout(() => changeImageDimensions(dims), 10);
  });

  const canvas = canvasEl.current;
  if (canvas && imageLoaded) {
    const {clientWidth, clientHeight} = canvas;
    const [iw, ih] = [imageDimensions.naturalWidth, imageDimensions.naturalHeight];
    layoutParams.current = {
      iw,
      ih,
      canvasWidth: clientWidth,
      canvasHeight: clientHeight
    };
  }

  const imagePosition = {
    topLeft: applyToPoint(inverse({...matrix}), {x: 0, y: 0}),
    bottomRight: applyToPoint(inverse({...matrix}), {
      x: imageDimensions?.naturalWidth,
      y: imageDimensions?.naturalHeight
    })
  };

  const zoomToPoint = (e: React.MouseEvent) => {
    const scaleNum = state.selectedTool === 'zoom-in' ? 0.8 : state.selectedTool === 'zoom-out' ? 1.2 : 1;
    const canvasEl = document.getElementById('standard-canvas');
    const [mx, my] = [
      e.clientX - canvasEl.getBoundingClientRect().left,
      e.clientY - canvasEl.getBoundingClientRect().top
    ];
    let newMat;

    newMat = compose(matrix, compose(translate(mx, my), rotate(0), scale(scaleNum, scaleNum)));
    newMat = compose([newMat, translate(-mx, -my)]);
    if ((newMat.a < 0.1 && state.selectedTool === 'zoom-in') || (newMat.a > 5 && state.selectedTool === 'zoom-out')) {
      return;
    }

    changeMatrix({...newMat});
  };

  const zoomViaArea = (area: {x: number; y: number; w: number; h: number}) => {
    let newMat = {...matrix};
    newMat = compose([matrix, translate(+area.x, +area.y)]);
    const canvasEl = document.getElementById('standard-canvas');
    let scaleN: number;
    if (area.w > area.h) {
      scaleN = area.w > 0 ? area.w / canvasEl.getBoundingClientRect().width : 1;
      newMat = compose([newMat, scale(scaleN, scaleN)]);
      // const newWidthAndHeight = applyToPoint(inverse({...newMat, e: 0, f: 0}), {
      //   x: imageDimensions?.naturalWidth,
      //   y: imageDimensions?.naturalHeight
      // });
      // const diffH = canvasEl.getBoundingClientRect().height - newWidthAndHeight.y;
      // if (diffH > 0) {
      //   newMat = compose([newMat, translate(0, -diffH / 2)]);
      // }
      // const diffW = canvasEl.getBoundingClientRect().width - newWidthAndHeight.x;
      // if (diffW > 0) {
      //   newMat = compose([newMat, translate(-diffW / 2, 0)]);
      // }
    } else {
      scaleN = area.h > 0 ? area.h / canvasEl.getBoundingClientRect().height : 1;
      newMat = compose([newMat, scale(scaleN, scaleN)]);
      // const newWidthAndHeight = applyToPoint(inverse({...newMat, e: 0, f: 0}), {
      //   x: imageDimensions?.naturalWidth,
      //   y: imageDimensions?.naturalHeight
      // });
      // const diffW = canvasEl.getBoundingClientRect().width - newWidthAndHeight.x;
      // if (diffW > 0) {
      //   newMat = compose([newMat, translate(-diffW / 2, 0)]);
      // }
      // const diffH = canvasEl.getBoundingClientRect().height - newWidthAndHeight.y;
      // if (diffH > 0) {
      //   newMat = compose([newMat, translate(0, -diffH / 2)]);
      // }
      // newMat = compose([newMat, translate(scaleN, scaleN)]);
    }

    const newWidthAndHeight = applyToPoint(inverse({...newMat, e: 0, f: 0}), {
      x: imageDimensions?.naturalWidth,
      y: imageDimensions?.naturalHeight
    });
    const diffH = canvasEl.getBoundingClientRect().height - newWidthAndHeight.y;
    if (diffH > 0) {
      newMat = compose([newMat, translate(0, -diffH / 2 - newMat.f)]);
    }
    const diffW = canvasEl.getBoundingClientRect().width - newWidthAndHeight.x;
    if (diffW > 0) {
      newMat = compose([newMat, translate(-diffW / 2 - newMat.e, 0)]);
    }

    if (newMat.a > 5 || newMat.a < 0.1) return;
    changeMatrix(newMat);
    // const currentimagePosition = {
    //   topLeft: applyToPoint(inverse({...matrix}), {x: 0, y: 0}),
    //   bottomRight: applyToPoint(inverse({...matrix}), {
    //     x: imageDimensions?.naturalWidth,
    //     y: imageDimensions?.naturalHeight
    //   })
    // };
    //
    // const canvasEl = document.getElementById('standard-canvas');
    //
    // /**
    //  * 현재 이미지 포지션에서 내가 스크린에서 잡은 부분이 어떤 부분인지 확인
    //  */
    // const currentImageWidth = Math.abs(currentimagePosition.topLeft.x - currentimagePosition.bottomRight.x);
    // const currentImageHeight = Math.abs(currentimagePosition.topLeft.y - currentimagePosition.bottomRight.y);
    // const realWidthPercent = area.w / currentImageWidth;
    // const realHeightPercent = area.h / currentImageHeight;
    // let scaleNum = Math.max(realWidthPercent, realHeightPercent);
    // /**
    //  * 왼쪽 끝은 x,y
    //  */
    // let newMM = compose([matrix, compose(translate(area.x, area.y), rotate(0), scale(scaleNum, scaleNum))]);
    // // const matrix = compose(translate(-mx, -my), rotate(0), scale(scaleNum, scaleNum));
    // // changeMat(newMM);
    //
    // const imagePosition = {
    //   topLeft: applyToPoint(inverse({...newMM}), {x: 0, y: 0}),
    //   bottomRight: applyToPoint(inverse({...newMM}), {
    //     x: imageDimensions?.naturalWidth,
    //     y: imageDimensions?.naturalHeight
    //   })
    // };
    //
    // const newImageWidth = imagePosition.bottomRight.x - imagePosition.topLeft.x;
    // const newImageHeight = imagePosition.bottomRight.y - imagePosition.topLeft.y;
    //
    // /**
    //  * 화면 사이즈보다 작다면 fit
    //  */
    // if (canvasEl.getBoundingClientRect().width > newImageWidth && scaleNum === realWidthPercent) {
    //   const scaleConst = newImageWidth / canvasEl.getBoundingClientRect().width;
    //   newMM = compose([newMM, scale(scaleConst, scaleConst)]);
    // } else if (canvasEl.getBoundingClientRect().height > newImageHeight && scaleNum === realHeightPercent) {
    //   const scaleConst = newImageHeight / canvasEl.getBoundingClientRect().height;
    //   newMM = compose([newMM, scale(scaleConst, scaleConst)]);
    // }
    //
    // /**
    //  * 중앙 정렬 focus로 잡은데가 중앙으로
    //  */
    // const imagePosition2 = {
    //   topLeft: applyToPoint(inverse({...newMM}), {x: 0, y: 0}),
    //   bottomRight: applyToPoint(inverse({...newMM}), {
    //     x: imageDimensions?.naturalWidth,
    //     y: imageDimensions?.naturalHeight
    //   })
    // };
    //
    // /**
    //  * area focus 잡은 범위가 화면 정중앙으로 가도록
    //  *
    //  * area.w area.h - canvas
    //  */
    // if (realWidthPercent < 1) {
    //   const ww = Math.max(
    //     canvasEl.getBoundingClientRect().width - (imagePosition2.bottomRight.x - Math.max(imagePosition2.topLeft.x, 0)),
    //     0
    //   );
    //   newMM = compose([newMM, translate(-ww / 2, 0)]);
    // } else {
    //   /**
    //    * 화면에 맞게 fitting 하기, 여백 없게
    //    */
    //   const wr = (imagePosition2.bottomRight.x - imagePosition2.topLeft.x) / canvasEl.getBoundingClientRect().width;
    //   newMM = compose([newMM, translate(-newMM.e, 0), scale(wr, wr)]);
    // }
    //
    // const imagePosition3 = {
    //   topLeft: applyToPoint(inverse({...newMM}), {x: 0, y: 0}),
    //   bottomRight: applyToPoint(inverse({...newMM}), {
    //     x: imageDimensions?.naturalWidth,
    //     y: imageDimensions?.naturalHeight
    //   })
    // };
    //
    // if (realHeightPercent < 1) {
    //   const hh = Math.max(
    //     canvasEl.getBoundingClientRect().height -
    //       (imagePosition3.bottomRight.y - Math.max(imagePosition3.topLeft.y, 0)),
    //     0
    //   );
    //   newMM = compose([newMM, translate(0, -hh / 2)]);
    // } else {
    //   /**
    //    * 화면에 맞게 fitting 하기, 여백 없게
    //    */
    //   const hr = (imagePosition3.bottomRight.y - imagePosition3.topLeft.y) / canvasEl.getBoundingClientRect().height;
    //   newMM = compose([newMM, translate(0, -newMM.f), scale(hr, hr)]);
    // }
    //
    // if (newMM.a < 0.1 || newMM.a > 5) {
    //   return;
    // }
    // changeMatrix(newMM);
  };

  return (
    <ProcessImageViewerContainer>
      <FormToolBox />
      <RemoveScrollWrapper style={{width: '100%', height: '100%'}}>
        <StandardCanvas ref={canvasEl} id="standard-canvas" />
        <LayerWrapper imagePosition={imagePosition}>
          <ImageLayer image={images} onLoad={onImageLoaded} />
          {imageLoaded && (
            <SelectedAnnotationLayer
              imagePosition={imagePosition}
              key="regionSelectAndTransformBoxes"
              projectRegionBox={projectRegionBox}
            />
          )}
          {imageLoaded && (
            <>
              <AnnotationLayer imagePosition={imagePosition} />
              {state?.cfg?.isDisplayDataValues && imageLoaded && (
                <>
                  <DataLayer
                    imageDimensions={imageDimensions}
                    imagePosition={imagePosition}
                    regions={regions}
                    cfg={state?.cfg}
                    tableLatestTagHandler={latestTagHandlerForTable}
                    chartLatestTagHandler={latestTagHandlerForChart}
                    dispatchToReducer={dispatchToReducer}
                  />
                  <ConnectionLineLayer
                    imagePosition={imagePosition}
                    regions={regions}
                    dispatchToReducer={dispatchToReducer}
                  />
                </>
              )}
            </>
          )}
        </LayerWrapper>
        {(selectedTool === 'zoom-in' || selectedTool === 'zoom-out') && (
          <ZoomLayer zoomViaPoint={zoomToPoint} zoomViaArea={zoomViaArea} />
        )}
      </RemoveScrollWrapper>
    </ProcessImageViewerContainer>
  );
};
