import styled from 'styled-components';
import {META_PFD_zINDEX} from 'components/mpfd/const';
import React, {useContext, useRef, useState} from 'react';
import {ToolIds} from 'components/mpfd/panel/Toolbox';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {transform, translate} from 'transformation-matrix';
import classnames from 'classnames';

const Container = styled.div`
  position: absolute;
  // z-index: ${META_PFD_zINDEX.ZOOM_ACTION_LAYER};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  user-select: none;
  g {
    pointer-events: all;
  }
  z-index: ${META_PFD_zINDEX.REGION_SHAPE_LAYER};
  &.selected {
    z-index: ${META_PFD_zINDEX.ZOOM_ACTION_LAYER};
  }

  //background-color: rgba(1, 1, 1, 0.1);
`;

const SelectedArea = styled.div<{startPos: Pos | null; endPos: Pos | null}>`
  position: absolute;
  border: 1px dotted rgba(0, 89, 220, 0.8);
  background-color: rgba(0, 89, 220, 0.08);
  ${({startPos, endPos}) => {
    if (!startPos || !endPos) return '';

    const x = Math.min(startPos.x, endPos.x);
    const y = Math.min(startPos.y, endPos.y);
    const width = Math.abs(startPos.x - endPos.x);
    const height = Math.abs(startPos.y - endPos.y);

    return `
      left: ${x}px;
      top: ${y}px;
      width: ${width}px;
      height: ${height}px;
    `;
  }}
`;

type IProps = {
  zoomViaPoint(e: React.MouseEvent): void;
  zoomViaArea(area: {x: number; y: number; w: number; h: number}): void;
};

type Pos = {
  x: number;
  y: number;
};
function ZoomActionLayer({zoomViaPoint, zoomViaArea}: IProps) {
  const {dispatchToReducer, matrix, changeMatrix, state} = useContext(MetaPfdContext);
  const [zoomStartPos, setZoomStartPos] = useState<Pos>(null);
  const [zoomEndPos, setZoomEndPos] = useState<Pos>(null);
  const zoomStartPosRef = useRef(null);
  const zoomEndPosRef = useRef(null);
  const containerRef = useRef(null);
  const selectedTool = state.selectedTool;
  zoomStartPosRef.current = zoomStartPos;
  zoomEndPosRef.current = zoomEndPos;

  // console.log(zoomStartPos);

  const previousTool = useRef<ToolIds | null>(null);

  const onMouseDown = (e: React.MouseEvent) => {
    e.stopPropagation();
    if ((selectedTool === 'zoom-in' || selectedTool === 'zoom-out') && e.button !== 1 && e.button !== 2) {
      const c = containerRef.current as HTMLDivElement;
      if (!c) return;
      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;
      setZoomStartPos({x: startX, y: startY});
      setZoomEndPos({x: startX, y: startY});
      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        setZoomEndPos({x, y});
      };
      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        if (zoomStartPosRef.current && zoomEndPosRef.current) {
          const w = Math.abs(zoomStartPosRef.current.x - zoomEndPosRef.current.x);
          const h = Math.abs(zoomStartPosRef.current.y - zoomEndPosRef.current.y);
          const area = {
            x: Math.min(zoomStartPosRef.current.x, zoomEndPosRef.current.x),
            y: Math.min(zoomStartPosRef.current.y, zoomEndPosRef.current.y),
            w,
            h
          };
          setZoomStartPos(null);
          setZoomEndPos(null);
          console.log(w, h);
          if (w > 1 && h > 1) {
            zoomViaArea(area);
          } else {
            zoomViaPoint(e);
          }

          console.log(w, h);
          if (w > 1 && h > 1) {
          }
        }
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});
    } else if (selectedTool === 'pan' || e.button === 1) {
      const c = containerRef.current as HTMLDivElement;
      if (!c) return;
      previousTool.current = selectedTool;
      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;

      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        setZoomEndPos({x, y});
        const translateMatrix = translate(startX - x, startY - y);
        const newMat = transform([matrix, translateMatrix]);
        changeMatrix({...newMat});
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});

      e.preventDefault();
    } else if (e.button === 1) {
      const c = containerRef.current as HTMLDivElement;
      if (!c) return;
      previousTool.current = selectedTool;
      const startX = e.clientX - c.getBoundingClientRect().left;
      const startY = e.clientY - c.getBoundingClientRect().top;

      const onMouseMove = (e: MouseEvent): void => {
        const x = e.clientX - c.getBoundingClientRect().left;
        const y = e.clientY - c.getBoundingClientRect().top;
        const translateMatrix = translate(startX - x, startY - y);
        const newMat = transform([matrix, translateMatrix]);
        changeMatrix({...newMat});
      };

      const onMouseUp = () => {
        document.removeEventListener('mousemove', onMouseMove);
        dispatchToReducer({type: 'SELECT_TOOL', selectedTool: previousTool.current});
      };
      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp, {once: true});
      e.preventDefault();
    }
  };

  return (
    <Container
      onMouseDown={onMouseDown}
      ref={containerRef}
      className={classnames(
        (selectedTool === 'zoom-in' || selectedTool === 'zoom-out' || selectedTool === 'pan') && 'selected'
      )}
    >
      {(selectedTool === 'zoom-in' || selectedTool === 'zoom-out') && (
        <SelectedArea startPos={zoomStartPos} endPos={zoomEndPos} />
      )}
    </Container>
  );
}

export default ZoomActionLayer;
