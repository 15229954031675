import React, {ReactElement, useContext, useState} from 'react';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash, faSliders} from '@fortawesome/pro-regular-svg-icons';
import Properties from 'components/mpfd/panel/Properties';
import AnnotationListView from 'components/mpfd/panel/parts/annotation/AnnotationListView';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {Button} from 'components/forms';
import Tooltip from 'components/common/Tooltip';
import {faDatabase as faDatabaseSolid, faTags as faTagsSolid} from '@fortawesome/pro-solid-svg-icons';
import {faDatabase, faTags} from '@fortawesome/pro-light-svg-icons';

const Container = styled.div`
  background-color: #f4f5f6;
  width: 400px;
  height: calc(100% - 40px);
  position: fixed;
  z-index: 10;
  top: 40px;
  right: 0;
  display: flex;
  flex-direction: column;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.04);
  border-left: 1px solid #bbbbbb;
  transform: translateX(400px);
  transition: transform 0.2s ease-in-out;

  &.show {
    transform: translateX(0);
  }
`;
const AbovePanelLayout = styled.div`
  height: 40%;

  &.underlined {
    border-bottom: 1px solid #c0c0c0;
  }
`;

const BelowPanelLayout = styled.div`
  height: 60%;

  &.underlined {
    border-bottom: 1px solid #c0c0c0;
  }
`;

const Tab = styled.div`
  background-color: #e1e2e3;
  border: 1px solid #bbbbbb;
  border-right: none;
  text-align: center;
  width: 30px;
  height: 100%;
  position: absolute;
  left: -30px;
  top: 0;
  padding-top: 7px;
  box-sizing: border-box;
  box-shadow: -10px 0 10px rgba(0, 0, 0, 0.1);

  &.show {
    height: 40px;
    box-shadow: none;
  }

  &:hover {
    background-color: #d5e3f1;
    color: #1c5ea1;
    border-color: #1c5ea1;
  }
`;

const GlobalOptionContainer = styled.div`
  position: absolute;
  height: 40px;
  display: flex;
  align-items: center;
  right: 0;
  top: 0;
`;

function InfoPanelLayout(): ReactElement {
  const {dispatchToReducer, state, isShowPanelLayout, setIsShowPanelLayout} = useContext(MetaPfdContext);

  const onMouseUp = () => {
    dispatchToReducer({type: 'onMouseUpOnOutOfCanvas'});
  };

  const onClick = (key: string, value: boolean) => {
    dispatchToReducer({
      type: 'CHANGE_CFG',
      cfgChangeAction: {
        key,
        value
      }
    });
  };

  return (
    <Container className={isShowPanelLayout && 'show'} onMouseUp={onMouseUp}>
      <GlobalOptionContainer>
        <Button variant="none" onClick={() => onClick('isDisplayLabels', !state?.cfg?.isDisplayLabels)}>
          <Tooltip content="Toggle Label" place="bottom">
            <FontAwesomeIcon icon={state?.cfg?.isDisplayLabels ? faEye : faEyeSlash} size="xl" color="#7C8D91" />
          </Tooltip>
        </Button>
        <Button variant="none" onClick={() => onClick('isDisplayDataValues', !state?.cfg?.isDisplayDataValues)}>
          <Tooltip content="Toggle Data" place="bottom">
            <FontAwesomeIcon
              icon={state?.cfg?.isDisplayDataValues ? faDatabaseSolid : faDatabase}
              size="xl"
              color="#7C8D91"
            />
          </Tooltip>
        </Button>
      </GlobalOptionContainer>
      <Tab className={isShowPanelLayout && 'show'} onClick={() => setIsShowPanelLayout(!isShowPanelLayout)}>
        <FontAwesomeIcon icon={faSliders} />
      </Tab>
      <AbovePanelLayout className="underlined">
        <AnnotationListView />
      </AbovePanelLayout>
      <BelowPanelLayout>
        <Properties />
      </BelowPanelLayout>
    </Container>
  );
}

export default InfoPanelLayout;
