import {ReactElement} from 'react';
import {getImage} from 'assets/images/svg-image';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  height: 18px;
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 14px;
`;

const Title = styled.div`
  font-size: 18px;
  line-height: 1em;
  //color: #525f7f;
  color: #575660;
`;

const CloseButton = styled.img`
  margin-left: auto;
  cursor: pointer;
`;

type IProps = {
  title?: string;
  onClose(): void;
};

function WidgetConfigLayerHeader({title, onClose}: IProps): ReactElement {
  return (
    <Container>
      <Title>{title}</Title>
      <CloseButton src={getImage('close')} className="close-button" alt="close button" onClick={onClose} />
    </Container>
  );
}

export default WidgetConfigLayerHeader;
