import {ChangeEvent, ReactElement} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import checkboxUnchecked from '../../assets/images/icon/icon-checkbox-unchecked-default.svg';
import checkboxUncheckedHover from '../../assets/images/icon/icon-checkbox-unchecked-hover.svg';
import checkboxChecked from '../../assets/images/icon/icon-checkbox-checked-default.svg';
import checkboxCheckedHover from '../../assets/images/icon/icon-checkbox-checked-hover.svg';

const Container = styled.div`
  display: flex;
  font-size: 15px;

  &.align {
    align-items: center;
  }

  input {
    appearance: none;
    background-image: url(${checkboxUnchecked});
    transition: background 0.3s;
    width: 15px;
    height: 15px;
    border-radius: 2px;
    background-repeat: no-repeat;
    cursor: pointer;
    background-color: white;

    &:hover {
      background-image: url(${checkboxUncheckedHover});
    }

    &:checked {
      background-image: url(${checkboxChecked});
      background-position: calc(100%);
      &:hover {
        background: url(${checkboxCheckedHover});
      }
      &:disabled {
        &:hover {
          background: url(${checkboxChecked});
        }
      }
      &:disabled:not(.read-only) {
        //disabled 변환
        opacity: 0.4;
        cursor: not-allowed;

        & + label:not(.read-only) {
          opacity: 0.4;
          //cursor: not-allowed;
        }
      }
    }

    &:disabled {
      opacity: 0.4;
      cursor: default;
      &:hover {
        background: url(${checkboxUnchecked});
      }
    }
  }

  label {
    margin-bottom: 1px;
    white-space: nowrap;
  }
  .disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
  .read-only {
    opacity: 1;
  }
`;

type IProps = {
  name: string;
  id?: string;
  align?: 'default' | 'center';
  value?: string | number | null;
  checked?: boolean;
  checkedValue?: string | number | boolean;
  uncheckedValue?: string | number | boolean;
  label?: string;
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
  readOnly?: boolean;
  onChange(e: ChangeEvent<HTMLInputElement>): void;
};

function CheckboxForm({
  name,
  id,
  align = 'default',
  onChange,
  value,
  checkedValue,
  uncheckedValue,
  label,
  labelPosition = 'left',
  checked,
  disabled,
  readOnly
}: IProps): ReactElement {
  const onChangeEvent = (e: ChangeEvent<HTMLInputElement>) => {
    if (readOnly) return;
    // checked 와는 별개로 value 에 값을 추가로 보낸다.
    e.target.value = e.target.checked ? (checkedValue as string) : (uncheckedValue as string);
    onChange?.(e);
  };

  return (
    <Container className={classNames({align}, 'checkbox-form')}>
      {label && labelPosition === 'left' && (
        <label style={{marginRight: '6px'}} className={classNames({disabled})} htmlFor={id}>
          {label}
        </label>
      )}
      <input
        className={classNames(readOnly && 'read-only')}
        type="checkbox"
        name={name}
        id={id}
        onChange={onChangeEvent}
        checked={typeof checked === 'boolean' ? checked : checkedValue === value}
        disabled={readOnly || disabled}
      />
      {label && labelPosition === 'right' && (
        <label style={{marginLeft: '6px'}} className={classNames({disabled})} htmlFor={id}>
          {label}
        </label>
      )}
    </Container>
  );
}

export default CheckboxForm;
