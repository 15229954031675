import {BaseSyntheticEvent, ReactElement, useContext, useState, useEffect} from 'react';
import {
  alreadyDetectedModalOptions,
  disableListByNoneFile,
  disableListByNotOwner,
  IPullDownMenu,
  metaPfdMenu,
  NEW_FILE_ROUTE,
  PullDownFunctions,
  disableListByNewFile,
  onlySvgFileDetectableModalOptions
} from 'components/menu/constants';
import styled from 'styled-components';
import PullDownMenuItem from 'components/menu/pulldown/PullDownMenuItem';
import useMetaPfdCommand from 'components/menu/pulldown/useMetaPfdCommand';
import {useParams} from 'react-router-dom';
import useHotkey from 'hooks/useHotkey';
import {MetaPfdContext} from 'components/mpfd';
import {CommonContext} from 'components/common/CommonProvider';

const MenuLayout = styled.ul`
  display: flex;
  height: 40px;
  user-select: none;
`;
const MainMenuItem = styled.li`
  &.active {
    background-color: rgba(0, 0, 0, 0.4);
  }
`;
const MainMenuItemTitle = styled.div`
  height: 40px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  cursor: default;
  font-size: 14px;
  color: #fff;
`;
const DropDownMenuList = styled.ul`
  position: absolute;
  background-color: #272546;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
`;

function MetaPfdPullDownMenu(): ReactElement {
  const {state, isFileOwner, showMPfdModal, file} = useContext(MetaPfdContext);
  const {showModal} = useContext(CommonContext);
  const commands = useMetaPfdCommand();
  const {
    create,
    change,
    close,
    save,
    saveAs,
    rename,
    openOwnershipModal,
    openChangeAccessibilityModal,
    showGlobalSettings,
    zoomIn,
    zoomOut,
    zoomTo,
    fullScreen,
    toggleData,
    toggleLabel,
    importFile,
    exportFile,
    toggleZoomScale
  } = commands;
  useHotkey(commands);
  const [menuList, setMenuList] = useState<IPullDownMenu[]>(metaPfdMenu);
  const [activeMenu, setActiveMenu] = useState<IPullDownMenu>({} as IPullDownMenu);
  const {id} = useParams();

  useEffect(() => {
    const viewMenu = metaPfdMenu.find((menu) => menu.id === 'view');
    viewMenu.children.find((child) => child.id === 'show-label').checked = state.cfg.isDisplayLabels;
    viewMenu.children.find((child) => child.id === 'show-data-table').checked = state.cfg.isDisplayDataValues;
  }, [state]);

  useEffect(() => {
    if (file) {
      const fileMenu = metaPfdMenu.find((menu) => menu.id === 'file');
      const changeAccessibilityItem = fileMenu.children.find((child) => child.id === 'change-accessibility');
      if (!changeAccessibilityItem.disabled) {
        changeAccessibilityItem.title = `Change to ${file.public ? 'Private' : 'Public'}`;
      }
    }
  }, [file]);

  const onClickMenu = (e: BaseSyntheticEvent, menu: IPullDownMenu): void => {
    const {id} = menu;
    switch (id) {
      case 'create':
        create();
        break;
      case 'close':
        close();
        break;
      case 'save':
        save();
        break;
      case 'save-as':
        saveAs();
        break;
      case 'rename':
        rename();
        break;
      case 'transfer-ownership':
        openOwnershipModal();
        break;
      case 'change-accessibility':
        openChangeAccessibilityModal();
        break;
      case 'settings':
        showGlobalSettings();
        break;
      case 'change-pid':
        change();
        break;
      case 'zoom-in':
        zoomIn();
        break;
      case 'zoom-out':
        zoomOut();
        break;
      case 'zoom-reset':
        zoomTo(0);
        break;
      case 'fullscreen':
        fullScreen();
        break;
      case 'show-label':
        toggleLabel();
        break;
      case 'show-data-table':
        toggleData();
        break;
      case 'line-detection':
        if (!!state?.detectionInfo?.detectedFileName) {
          showModal(alreadyDetectedModalOptions);
        } else if ((state?.images?.imgExtension || '').toLowerCase() !== 'svg') {
          showModal(onlySvgFileDetectableModalOptions);
        } else {
          showMPfdModal('detection');
        }
        break;
      case 'zoom-panel':
        setMenuList((prevState) =>
          prevState.map((menu) => {
            menu.children.map((child) => {
              if (child.id === 'zoom-panel') {
                child.checked = !child.checked;
              }
              return child;
            });
            return menu;
          })
        );
        toggleZoomScale();
        break;
      case 'import':
        importFile();
        break;
      case 'export':
        exportFile();
        break;
      default:
    }
    setActiveMenu({} as IPullDownMenu);
  };

  /**
   * 현재 메뉴의 disabled 현황
   * 1. 열려있는 파일이 없는 경우 disableListByNoneFile 에 있는 메뉴
   * 2. 새 파일인 경우 disableListByNewFile 에 있는 메뉴
   * 3. file 의 owner 가 아닌 경우 disableListByNotOwner 에 있는 메뉴
   * @param childId
   */
  const getMenuDisabled = (childId: PullDownFunctions): boolean => {
    return (
      (!file && disableListByNoneFile.includes(childId)) ||
      (id === NEW_FILE_ROUTE && disableListByNewFile.includes(childId)) ||
      (!isFileOwner && disableListByNotOwner.includes(childId))
    );
  };

  return (
    <MenuLayout>
      {menuList.map((menu) => (
        <MainMenuItem
          key={menu.id}
          className={activeMenu.id === menu.id && 'active'}
          onMouseEnter={() => setActiveMenu(menu)}
          onMouseLeave={() => setActiveMenu({} as IPullDownMenu)}
        >
          <MainMenuItemTitle>{menu.title}</MainMenuItemTitle>
          {activeMenu.id === menu.id && (
            <DropDownMenuList>
              {menu.children?.map((child) => (
                <PullDownMenuItem
                  key={child.id}
                  data={child}
                  disabled={getMenuDisabled(child.id)}
                  onClick={onClickMenu}
                />
              ))}
            </DropDownMenuList>
          )}
        </MainMenuItem>
      ))}
    </MenuLayout>
  );
}

export default MetaPfdPullDownMenu;
