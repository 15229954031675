import React, {memo, ReactElement, useContext, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {NodeProps, useReactFlow} from 'reactflow';
import styled from 'styled-components';
import {
  WidgetActionPanel,
  WidgetBody,
  WidgetConfigLayer,
  WidgetContainer,
  WidgetHeader
} from 'components/pc/widgets/parts';
import {IWeatherWidgetData, IWidgetNodeData} from 'components/pc/types';
import Autocomplete from 'components/pc/widgets/weather/AutoComplete';
import {API_KEY, historyTimeChange} from 'components/pc/widgets/weather/const';
import WeatherWidgetHeader from 'components/pc/widgets/weather/WeatherWidgetHeader';
import WeatherWidgetSummarize from 'components/pc/widgets/weather/WeatherWidgetSummarize';
import WeatherForeCast from 'components/pc/widgets/weather/WeatherForeCast';
import WeatherHistory from 'components/pc/widgets/weather/WeatherHistory';
import WeatherSetting from 'components/pc/widgets/weather/WeatherSetting';
import {ISizeType, SizeTypes} from 'components/common/types';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import dayjs from 'dayjs';
import {SwitchForm} from 'components/forms';
import classnames from 'classnames';
import {CommonContext} from 'components/common/CommonProvider';
import {getWidgetTitle} from 'utils/processCanvas-functions';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleExclamation} from '@fortawesome/pro-solid-svg-icons';
import WidgetApiCallFailStatus from 'components/pc/widgets/parts/WidgetApiCallFailStatus';
import WidgetLiveUpdateStatus from 'components/pc/widgets/parts/WidgetLiveUpdateStatus';

const WeatherWidgetWrapper = styled.div`
  display: flex;
  flex: 1;
  height: calc(100% - 40px);
  flex-direction: column;
  color: #525f7f;
  padding: 1rem 1rem 0 1rem;
  transition: padding 0.2s;

  &.md {
    padding: 0.8rem 0.8rem 0 0.8rem;
  }
  &.sm {
    padding: 0.6rem 0.6rem 0 0.6rem;
  }
`;

const WeatherHistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const UpdateTimeDisplay = styled.div`
  font-size: 14px;
  color: #888;
  align-self: center;
  margin-right: 10px;
  flex-shrink: 0;
`;

const SwitchLabel = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #98a9d5;

  &.active {
    color: #6287e8;
  }
`;

const ApiCallStatus = styled.div`
  font-size: 14px;
  color: ${(props) => props.theme.color.warning};
  align-self: center;
  margin-right: 10px;
  flex-shrink: 0;
  font-weight: bold;
  display: flex;
  gap: 2px;
  > span {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const getSizeType = (pixel: number): SizeTypes => {
  if (pixel >= 400 && pixel < 590) {
    return 'md';
  } else if (pixel < 400) {
    return 'sm';
  } else {
    return 'lg';
  }
};

const color = 'Color Type';
const fontSize = 'Font Size';
const defaultCfg = {
  isCelsius: true,
  updateIntervalUnit: 1000 * 60,
  updateIntervalVal: '30',
  autoUpdate: true
};
const defaultContent = {
  weatherData: {} as IWeatherData,
  forecastData: {} as IWeatherForcastData,
  historyData: {} as IWeatherHistoryData,
  lat: '',
  lon: ''
};
const defaultStatus = {
  hourlyOpen: false,
  dailyOpen: false,
  historyDailyOpen: false,
  settingModalOpen: false,
  autoCompleteOpen: false
};

export type IWeatherCfg = {
  isCelsius: boolean;
  updateIntervalUnit: number;
  updateIntervalVal: string;
  autoUpdate: boolean;
};

export type IWeatherContent = {
  weatherData: IWeatherData;
  forecastData: IWeatherForcastData;
  historyData: IWeatherHistoryData;
  lat: string;
  lon: string;
};

export type IWeatherStatus = {
  hourlyOpen: boolean;
  dailyOpen: boolean;
  historyDailyOpen: boolean;
  settingModalOpen: boolean;
  autoCompleteOpen: boolean;
};

type IWeatherApiTimer = {
  duration?: number;
  fnc(): void;
  autoUpdate?: boolean;
};

function WeatherWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const {onCanvasChange} = useContext(ProcessCanvasContext);
  const {showRemoteTooltip} = useContext(CommonContext);
  const dateList = historyTimeChange(7);
  const [cfg, setCfg] = useState<IWeatherCfg>(defaultCfg);
  const [content, setContent] = useState<IWeatherContent>(defaultContent);
  const [status, setStatus] = useState<IWeatherStatus>(defaultStatus);
  const [time, setTime] = useState<number>();
  const [apiTimer, setApiTimer] = useState<IWeatherApiTimer>(null);
  const [apiFlag, setApiFlag] = useState({});
  const cfgRef = useRef(null);
  const contentRef = useRef(null);
  const [isApiCallFail, setIsApiCallFail] = useState(false);
  cfgRef.current = cfg;
  contentRef.current = content;
  const {setNodes} = useReactFlow();

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (apiTimer?.autoUpdate) {
      let apiTimerDuration: number;
      if (isNaN(apiTimer?.duration)) {
        apiTimerDuration = 30 * 60 * 1000;
      } else if (apiTimer?.duration < 1000) {
        apiTimerDuration = 30 * 60 * 1000;
      } else {
        apiTimerDuration = apiTimer?.duration;
      }
      timer = setTimeout(() => apiTimer?.fnc(), apiTimerDuration);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [apiTimer, apiFlag]);

  useEffect(() => {
    if (data?.metaData) {
      const metaD = data.metaData as IWeatherWidgetData;
      const {coordinates, config} = metaD;
      let lat;
      let lon;
      /**
       * 기존의 저장되던 방식 호환
       */
      if (metaD?.coordinates?.lat && metaD?.coordinates?.lon) {
        lat = String(coordinates?.lat);
        lon = String(coordinates?.lon);
      } else if (metaD?.content) {
        lat = String(metaD?.content?.lat);
        lon = String(metaD?.content?.lon);
      } else {
        return;
      }
      const fnc = async () => {
        getWeather(String(lat), String(lon)).then((ctt) => {
          const now = Date.now();
          setTime(now);
          setApiFlag({});
          saveData(String(lat), String(lon), cfgRef.current, now, ctt);
        });
        const now = Date.now();
        setTime(now);
        setApiFlag({});
      };
      let apiTimerDuration: number;
      if (isNaN(config.updateIntervalUnit * Number(config.updateIntervalVal))) {
        apiTimerDuration = 30 * 60 * 1000;
      } else if (config.updateIntervalUnit * Number(config.updateIntervalVal) < 1000) {
        apiTimerDuration = 30 * 60 * 1000;
      } else {
        apiTimerDuration = config.updateIntervalUnit * Number(config.updateIntervalVal);
      }
      if (metaD?.content) {
        setContent(metaD?.content);
      }

      setApiTimer({
        duration: apiTimerDuration,
        autoUpdate: config?.autoUpdate,
        fnc
      });
      setCfg({
        updateIntervalUnit: config?.updateIntervalUnit || 60 * 1000,
        updateIntervalVal: config?.updateIntervalVal || '30',
        autoUpdate: config?.autoUpdate,
        isCelsius: config?.isCelsius || true
      });
      setTime(metaD?.time || 0);
      if (config?.autoUpdate || !metaD?.content) {
        fnc().then();
      }
    } else {
      const weatherLocation = async () => {
        try {
          const position: GeolocationPosition = await new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
          });
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          const fnc = async () => {
            getWeather(String(lat), String(lon)).then((ctt) => {
              const now = Date.now();
              setTime(now);
              setApiFlag({});
              saveData(String(lat), String(lon), cfgRef.current, now, ctt);
            });
          };
          setApiTimer({
            duration: defaultCfg.updateIntervalUnit * Number(defaultCfg.updateIntervalVal),
            autoUpdate: defaultCfg?.autoUpdate,
            fnc
          });
          fnc().then();
        } catch (error) {
          console.warn(error);
          // showRemoteTooltip('location');
          // console.error('Error weather Location');
        } finally {
        }
      };
      weatherLocation().then();
    }
  }, []);

  const onClickStatusToggle = (key: keyof IWeatherStatus) => {
    setStatus((prev) => ({...prev, [key]: !status[key]}));
  };

  const saveData = (lat: string, lon: string, cfg: IWeatherCfg, time: number, content: IWeatherContent) => {
    const metaData = {coordinates: {lat, lon}, config: cfg, time, content};
    setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
    );

    onCanvasChange();
  };

  const onChangeLive = (updateFlag: boolean) => {
    const autoUpdate = updateFlag;
    const newCfg = {
      ...cfg,
      autoUpdate,
      updateIntervalUnit: cfg?.updateIntervalUnit || 60 * 1000,
      updateIntervalVal: cfg?.updateIntervalVal || '30'
    };
    saveData(content.lat, content.lon, newCfg, time, content);
    setCfg(newCfg);
    setApiTimer((prev) => ({
      ...prev,
      autoUpdate,
      duration: prev?.duration && prev?.duration > 1000 ? prev?.duration : 60000 * 30
    }));
  };

  const onChangeCfg = (newCfg: IWeatherCfg) => {
    setCfg(newCfg);
    if (newCfg.updateIntervalUnit * Number(newCfg.updateIntervalVal) !== apiTimer?.duration) {
      setApiTimer((prev) => ({
        ...prev,
        duration: newCfg.updateIntervalUnit * Number(newCfg.updateIntervalVal),
        autoUpdate: newCfg?.autoUpdate
      }));
    }
    saveData(content.lat, content.lon, newCfg, time, content);
  };

  const onSelect = async (place: any) => {
    const placeName = place.name;
    const {lat, lon, weatherContent} = await searchWeather(placeName);
    const t = Date.now();
    saveData(lat, lon, cfgRef.current, t, weatherContent);
    const fnc = async () => {
      getWeather(String(lat), String(lon)).then((ctt) => {
        const now = Date.now();
        setTime(now);
        setApiFlag({});
        saveData(String(lat), String(lon), cfgRef.current, now, ctt);
      });
      const now = Date.now();
      setTime(now);
      setApiFlag({});
    };
    setApiTimer((prev) => ({
      ...prev,
      fnc
    }));
  };

  const getWeather = async (lat: string, lon: string) => {
    try {
      const result = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=Imperial&lang=kr`
        // `https://api.opeathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${API_KEY}&units=Imperial&lang=kr`
      );

      return getWeatherData(lat, lon, result.data);
    } catch (e) {
      setIsApiCallFail(true);
      onChangeLive(false);
    }
  };

  const searchWeather = async (loc: string) => {
    try {
      const url = `https://api.openweathermap.org/data/2.5/weather?q=${loc}&appid=${API_KEY}&units=Imperial`;
      const result = await axios.get(url);
      const weatherContent = await getWeatherData(result.data.coord.lat, result.data.coord.lon, result.data);
      return {lat: result.data.coord.lat, lon: result.data.coord.lon, weatherContent};
    } catch (e) {
      setIsApiCallFail(true);
      onChangeLive(false);
    }
  };

  const getWeatherData = async (lat: string, lon: string, weatherData: IWeatherData): Promise<IWeatherContent> => {
    const forecastUrl = `https://api.openweathermap.org/data/3.0/onecall?lat=${lat}&lon=${lon}&exclude=minutely&appid=${API_KEY}&units=Imperial`;
    const forecast = await axios.get(forecastUrl);
    const t = dateList.map(function (item) {
      const historyUrl = `https://api.openweathermap.org/data/3.0/onecall/day_summary?lat=${lat}&lon=${lon}&date=${item}&appid=${API_KEY}&units=imperial`;
      return axios.get(historyUrl);
    });
    const results = await Promise.all(t);
    setContent({
      weatherData: weatherData as IWeatherData,
      forecastData: forecast.data as IWeatherForcastData,
      historyData: results as IWeatherHistoryData,
      lat: lat,
      lon: lon
    });
    setIsApiCallFail(false);
    return {
      weatherData: weatherData as IWeatherData,
      forecastData: forecast.data as IWeatherForcastData,
      historyData: results as IWeatherHistoryData,
      lat: lat,
      lon: lon
    };
  };

  const ref = useRef(null);
  const [widgetSize, setWidgetSize] = useState<ISizeType>({width: 'lg', height: 'lg'});

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const [target] = entries;
        setWidgetSize({width: getSizeType(target.contentRect.width), height: getSizeType(target.contentRect.height)});
      });
      observer.observe(ref.current);
    }
  }, []);

  return (
    <WidgetContainer {...rest} type="WeatherWidget">
      <WidgetHeader
        {...rest}
        type="WeatherWidget"
        icon={data.icon}
        id={id}
        title={
          data.customizedTitle
            ? data.title
            : getWidgetTitle({type: 'WeatherWidget', titleData: content?.weatherData?.name, data})
        }
        suffix="- Weather"
        onConfig={() => onClickStatusToggle('settingModalOpen')}
      ></WidgetHeader>
      <WidgetActionPanel align="right">
        <WidgetLiveUpdateStatus
          id={id}
          updateTime={time}
          isApiCallFail={isApiCallFail}
          isLiveUpdate={cfg?.autoUpdate}
          onChangeLiveUpdate={onChangeLive}
        />
        <WidgetApiCallFailStatus isApiCallFail={isApiCallFail} />
      </WidgetActionPanel>
      <WidgetBody ref={ref} actionMenuHeight={42}>
        <WeatherWidgetWrapper
          className={widgetSize.width}
          style={status.settingModalOpen === true ? {opacity: '20%'} : {}}
        >
          <WeatherWidgetHeader
            darkModeChange={true}
            weatherData={content.weatherData}
            isCelsius={cfg?.isCelsius}
            size={widgetSize}
            openCheck={() => onClickStatusToggle('autoCompleteOpen')}
          />
          <WeatherWidgetSummarize
            darkModeChange={true}
            isCelsius={cfg?.isCelsius}
            size={widgetSize}
            weatherData={content?.weatherData}
            forecastData={content?.forecastData}
          />
          <Autocomplete open={status.autoCompleteOpen} size={widgetSize} onSelect={onSelect} />
          <WeatherHistoryWrapper className="thin-scrollbar">
            <WeatherForeCast
              color={color}
              hourlyCheck={() => onClickStatusToggle('hourlyOpen')}
              hourlyOpen={status?.hourlyOpen}
              darkModeChange={true}
              forecastData={content.forecastData}
              fontSize={fontSize}
              isCelsius={cfg?.isCelsius}
              size={widgetSize}
              dailyOpen={status?.dailyOpen}
              dailyCheck={() => onClickStatusToggle('dailyOpen')}
            />
            <WeatherHistory
              historyDailyCheck={() => onClickStatusToggle('historyDailyOpen')}
              historyDailyOpen={status.historyDailyOpen}
              historyData={content.historyData}
              fontSize={fontSize}
              isCelsius={cfg?.isCelsius}
              size={widgetSize}
              darkModeChange={true}
            />
          </WeatherHistoryWrapper>
        </WeatherWidgetWrapper>
      </WidgetBody>
      <WidgetConfigLayer
        title="Settings"
        isShow={status.settingModalOpen}
        onClose={() => setStatus((prev) => ({...prev, settingModalOpen: false}))}
      >
        <WeatherSetting
          fontSize={fontSize}
          color={color}
          cfg={cfg}
          onChangeCfg={onChangeCfg}
          setSettingModalOpen={() => onClickStatusToggle('settingModalOpen')}
        />
      </WidgetConfigLayer>
    </WidgetContainer>
  );
}

export default memo(WeatherWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
