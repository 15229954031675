import {BaseSyntheticEvent, CSSProperties, MouseEvent, ReactElement, ReactNode, useRef} from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import {VariantTypes} from 'components/forms/types';
import {SizeTypes} from 'components/common/types';

const Container = styled.button`
  border: none;
  border-radius: 5px;
  color: #ffffff;
  padding: 5px 10px;
  //font-size: 15px;
  //min-width: 70px;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  //&:focus-visible {
  //  border: none;
  //}

  &.company {
    background-color: ${({theme}) => theme.color.company};
    color: #ffffff;
  }
  &.primary {
    background-color: ${({theme}) => theme.color.primary};
    color: #ffffff;
    &.modal-btn {
      font-size: 15px;
      padding: 5px 30px;
    }
    &.short-word {
      font-size: 15px;
      padding: 5px 40px;
    }
  }

  &.secondary {
    background-color: ${({theme}) => theme.color.secondary};
  }

  &.light {
    background-color: ${({theme}) => theme.color.light};
    border: 1px solid #a2a2a2;
    color: #1f1f1f;
  }

  &.dark {
    background-color: ${({theme}) => theme.color.dark};
    color: #eeeeee;
  }

  &.optional {
    background-color: ${({theme}) => theme.color.optional};
    color: #1f1f1f;
  }

  &.info {
    background-color: ${({theme}) => theme.color.info};
  }

  &.reset {
    background-color: rgb(240, 240, 240);
    color: ${({theme}) => theme.color.info};
    border: 1px solid;
  }

  &.danger {
    background-color: ${({theme}) => theme.color.danger};
    &.modal-btn {
      font-size: 15px;
      padding: 5px 15px;
    }
  }

  &.warning {
    background-color: ${({theme}) => theme.color.warning};
  }
  &.record {
    background-color: ${({theme}) => theme.color.record};
  }

  &.cancel {
    background-color: ${({theme}) => theme.color.cancel};
    color: #fff;
    &.modal-btn {
      font-size: 15px;
      padding: 5px 15px;
    }
  }

  &.new {
    background-color: ${({theme}) => theme.color.new};
  }
  &.send {
    background-color: ${({theme}) => theme.color.send};
  }
  &.create {
    background-color: #74adff;
  }

  &.none {
    background-color: transparent;
    color: #1f1f1f;
    padding: 5px 5px;
  }

  &.sm {
    width: 120px;
    height: 38px;
    font-size: 18px;
  }

  &.lg {
    width: 100%;
  }
`;

type IProps<T> = {
  children?: ReactNode | ReactNode[];
  className?: string;
  disabled?: boolean;
  name?: string;
  title?: string;
  value?: T;
  variant?: VariantTypes;
  size?: SizeTypes;
  width?: number | string;
  height?: number | string;
  style?: CSSProperties;
  onClick?(e: BaseSyntheticEvent, value?: T): void;
  onMouseDown?(e: MouseEvent): void;
};

function Button<T>({
  children,
  value,
  name,
  title,
  disabled,
  variant = 'primary',
  className,
  width,
  height,
  style,
  onClick,
  onMouseDown
}: IProps<T>): ReactElement {
  const buttonRef = useRef(null);

  const onClickButton = (e: BaseSyntheticEvent): void => {
    onClick?.(e, value);
    if (buttonRef.current) {
      buttonRef.current.blur();
    }
  };

  return (
    <Container
      ref={buttonRef}
      name={name}
      disabled={disabled}
      title={title}
      style={{...style, width, height}}
      className={classNames('common-button', variant, className)}
      onClick={onClickButton}
      onMouseDown={onMouseDown}
    >
      {children}
    </Container>
  );
}

export default Button;
