import {ReactElement, useContext, useEffect, useRef, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button} from 'components/forms';
import {IImageData} from 'components/mpfd/type';
import {MetaPfdContext} from 'components/mpfd/MetaPfdProvider';
import {useNavigate} from 'react-router-dom';
import {capitalize} from 'utils/commons';
import {NEW_FILE_ROUTE} from 'components/menu/constants';
import ProcessImageSelector from 'components/mpfd/panel/modal/ProcessImageSelector';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';
import {useDrop} from 'components/dnd/useDrop';
import DropZoneOverlay from 'components/dnd/DropZoneOverlay';
import {IDragItem} from 'components/dnd/types';

function ProcessImageSelectModal(): ReactElement {
  const {create, change, mPfdModal, hideMPfdModal, checkIsChanged} = useContext(MetaPfdContext);
  const [existImgInfo, setExistImgInfo] = useState<Partial<IImageData>>({pidName: '', fileName: ''});
  // const [uploadImgInfo, setUploadImgInfo] = useState<Partial<IImageData>>({pidName: '', fileName: ''});
  const navigate = useNavigate();

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  const onConfirm = (): void => {
    if (existImgInfo?.pidName === '') {
      return;
    }
    if (mPfdModal?.type === 'create') {
      create(existImgInfo);
      navigate(`/mpfd/${NEW_FILE_ROUTE}`);
    } else if (mPfdModal?.type === 'change') {
      change(existImgInfo);
    }
    hideMPfdModal();
  };

  const onClose = (): void => {
    hideMPfdModal();
  };

  const onSelectFileName = (fileName: string) => {
    setExistImgInfo({fileName: fileName, pidName: fileName});
  };

  const onChangeUploadFile = (imageSrc: string, imgFile: File) => {
    setExistImgInfo({imageSrc, imgFile, fileName: imgFile?.name});
  };

  const onDropItem = (dragItem: IDragItem, fileList: FileList): void => {
    const file = fileList[0];
    console.log(file);
    if (file) {
      const fr = new FileReader();
      fr.onload = function () {
        onChangeUploadFile(fr.result as string, file);
      };
      fr.readAsDataURL(file);
    }
  };

  const [isOver, , dropRef] = useDrop([], onDropItem);

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>New Meta PFD</ModalHeader>
      <ModalBody ref={dropRef}>
        <ProcessImageSelector value={existImgInfo.fileName} onSelect={onSelectFileName} onChange={onChangeUploadFile} />
        <DropZoneOverlay isOver={isOver} />
      </ModalBody>
      <ModalFooter>
        <Button
          className="modal-btn"
          disabled={!existImgInfo.fileName}
          variant="primary"
          size="sm"
          width={100}
          onClick={onConfirm}
        >
          {capitalize(mPfdModal?.type)}
        </Button>
        <Button variant="optional" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default ProcessImageSelectModal;
