import React, {ReactElement, useState, useEffect, useRef} from 'react';
import Highcharts from 'highcharts';
import HighchartsReact, {HighchartsReactProps} from 'highcharts-react-official';
import {getImage} from 'assets/images/svg-image';
import styled from 'styled-components';
import useApi from 'api/useApi';
import {ICommodityItem} from 'components/pc/widgets/commodity/types';
import {formatter} from 'components/pc/widgets/timeseries/timeseries-functions';
import {ISizeType} from 'components/common/types';
import BasicSpinner from 'components/common/BasicSpinner';
import {IApiReturnBasic} from 'api/data-types';
import {ICommodityCfg} from 'components/pc/widgets/CommodityWidget';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //width: 460px;
  padding: 1.625rem;
  margin-top: 1.875rem;
  align-items: flex-start;
  gap: 25px;
  font-size: 21px;
  font-weight: 400;
  border-radius: 0.625rem;
  background: #fcfcfc;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
  justify-content: space-between;
  cursor: pointer;
  transition: padding 0.2s;

  &.md {
    font-size: 16px;
    padding: 1rem;
    margin-top: 1.6rem;
    border-radius: 0.6rem;
  }
  &.sm {
    font-size: 12px;
    padding: 0.6rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    cursor: auto;
  }
`;

const CommodityItemWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ItemName = styled.div`
  color: #171523;
  //width: 110px;
  text-transform: capitalize;
  margin-right: auto;
`;

const ItemValueWrap = styled.div`
  color: #575660;
  //width: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const ItemValue = styled.div`
  text-align: right;
  margin-left: auto;
  line-height: 1em;
`;

const ItemUnit = styled.div`
  font-size: 15px;
  margin-left: 8px;
  color: #b6b4c0;
  width: 100px;
  line-height: 1em;

  &.md {
    width: 130px;
  }
  &.sm {
    width: 146px;
  }
`;

const ItemValueChangePercent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;

  &.loading {
    color: #b6b4c0;
  }
  &.dash {
    color: #575660;
  }
  &.priceUp {
    color: #0bb244;
  }
  &.priceDown {
    color: #d93939;
  }
  img {
    width: 18px;
    padding-right: 10px;
    padding-top: 5px;
  }

  &.md {
    width: 90px;
    img {
      width: 16px;
      padding-right: 10px;
      padding-top: 3px;
    }
  }
  &.sm {
    width: 60px;
    img {
      width: 12px;
      padding-right: 10px;
      padding-top: 2px;
    }
  }
`;

const IconImg = styled.img`
  width: 100%;
`;

const CommodityChart = styled.div`
  position: relative;
  width: 100%;
`;

type IReturnGetNodeData = {
  database: string;
  node: string[];
  records: number[][];
};

type IProps = {
  data: ICommodityItem;
  fontSize?: string;
  size?: ISizeType;
  loading: boolean;
  cfg: ICommodityCfg;
  onOpen(item: ICommodityItem): void;
};

function CommodityItem({data, fontSize, size, loading, cfg, onOpen}: IProps): ReactElement {
  const api = useApi();

  const [, name] = data.tag;
  const ref = useRef(null);
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [open, setOpen] = useState(data.isOpen);
  const [chartData, setChartData] = useState<number[][]>([]);
  const [chartLoading, setChartLoading] = useState(true);
  const [now, setNow] = useState<number>(Date.now());

  const onClick = () => {
    const refined = {...data, isOpen: !data.isOpen};
    onOpen(refined);
  };

  const onClickPrice = () => {
    setOpen((prev) => !prev);
    onClick();
    if (size.width !== 'sm') {
      if (chartData.length === 0) {
        setChartLoading(true);
      }
    }
  };

  useEffect(() => {
    const endTimestamp = Math.floor(now / 1000);

    const fnc = async () => {
      const res = await api.post<IApiReturnBasic>('/node_manage/get_node_data', {
        node: data.tag,
        database: 'commodity',
        time_range: [0, endTimestamp]
      });
      if (res.data) {
        const nodeData = res.data as IReturnGetNodeData;
        const refined: number[][] = nodeData.records.map((point: number[]) => {
          point[0] = point[0] * 1000;
          point[1] = parseFloat(String(point[1]));
          return point;
        });

        setChartData(refined);
      }
      setChartLoading(false);
    };

    // timeRange의 endTimestamp를 update 했을 때 현재시간 기준으로 변경해줌
    const timeChange = () => {
      setNow(Date.now());
    };
    if (data.isOpen === true || open) {
      fnc();
      //autoupdate가 true 상태일 때만 chart data update
      if (cfg.autoUpdate) {
        const interval = setInterval(timeChange, cfg.updateIntervalUnit * Number(cfg.updateIntervalVal));
        return () => clearInterval(interval);
      }
    }
  }, [open, data.isOpen, cfg, now]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      chartRef?.current?.chart?.reflow();
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref.current]);

  const options = {
    credits: {
      enabled: false
    },
    rangeSelector: {
      selected: 1
    },
    chart: {
      backgroundColor: '#FCFCFC',
      height: size.width === 'md' ? 200 : 350
    },
    title: {
      text: ''
    },
    tooltip: {
      formatter: function () {
        return formatter.sharedTooltip(this.points, 4);
      },
      shared: true
    },
    xAxis: [
      {
        crosshair: true,
        tickPixelInterval: 100,
        labels: {
          formatter: function () {
            return Highcharts.dateFormat('%Y-%m-%d ', this.value);
            // return Highcharts.dateFormat('%Y-%m-%d %k:%M:%S', this.value);
          }
        }
      }
    ],
    yAxis: [
      {
        labels: {
          format: '{value}',
          style: {
            // color: '#A8D964'
            // color: Highcharts.getOptions().colors[2]
          }
        },
        title: {
          text: 'Price',
          style: {
            // color: '#A8D964'
            // color: Highcharts.getOptions().colors[2]
          }
        }
      }
    ],
    series: [
      {
        showInLegend: false,
        name: data?.tag[1],
        color: '#4E61E1',
        data: chartData
      }
    ]
  };

  const length = data?.data?.length ?? 0;
  const currentPrice = length > 0 ? data.data[length - 1] : [];
  const previousPrice = length > 1 ? data.data[length - 2] : [];

  let perChange = 'loading';
  if (currentPrice.length > 0 && previousPrice.length > 0) {
    perChange = ((Number(currentPrice[1]) / Number(previousPrice[1]) - 1) * 100).toFixed(2) + '%';
  }

  const isZero = parseFloat(perChange) === 0;
  const isIncludeMinusSymbol = perChange.includes('-');
  const cn = loading ? 'loading' : isZero ? 'dash' : isIncludeMinusSymbol ? 'priceDown' : 'priceUp';

  return (
    <Container key={name} className={size.width} onClick={onClickPrice}>
      <CommodityItemWrap>
        <ItemName className={fontSize}>{name}</ItemName>
        <ItemValueWrap>
          <ItemValue>{currentPrice[1]}</ItemValue>
          <ItemUnit className={size.width}>{data?.unit} </ItemUnit>
        </ItemValueWrap>
        <ItemValueChangePercent className={`${size.width} ${cn}`}>
          {!loading && <IconImg src={getImage(cn)} alt={cn} />}
          {perChange}
        </ItemValueChangePercent>
      </CommodityItemWrap>
      {open && size.width !== 'sm' && (
        <CommodityChart ref={ref} className={fontSize}>
          <HighchartsReact ref={chartRef} highcharts={Highcharts} options={options} />
          <BasicSpinner isShow={chartLoading} size="md" type="overlay" position="center-center"></BasicSpinner>
        </CommodityChart>
      )}
    </Container>
  );
}
export default CommodityItem;
