import React, {DragEvent, ReactElement, useContext, useEffect, useRef} from 'react';
import Toolbox from 'components/mpfd/panel/Toolbox';
import {AppContainer} from 'components/layout';
import {CommonContext} from 'components/common/CommonProvider';
import {useNavigate, useParams} from 'react-router-dom';
import PullDownMenu from 'components/menu/pulldown/PullDownMenu';
import FileInfoDisplay from 'components/menu/pulldown/FileInfoDisplay';
import MetaPfdPullDownMenu from 'components/menu/pulldown/MetaPfdPullDownMenu';
import {NEW_FILE_ROUTE} from 'components/menu/constants';
import {MetaPfdContext, Stage, MetaPfdAppGuide} from 'components/mpfd';
import {ProcessImageSelectModal, MetaPfdSaveAsModal, DetectionProcessModal} from 'components/mpfd/panel/modal';
import MetaPfdCloseWarningModal from 'components/menu/pulldown/MetaPfdCloseWarningModal';
import MetaPfdTransferOwnershipModal from 'components/menu/pulldown/MetaPfdTransferOwnershipModal';
import {AuthContext} from 'components/auth/AuthProvider';
import useApi from 'api/useApi';
import {IApiReturnBasic, IFile} from 'api/data-types';
import MetaPfdZoomScaleViewer from 'components/mpfd/MetaPfdZoomScaleViewer';
import MetaPfdExportModal from 'components/menu/pulldown/MetaPfdExportModal';
import MetaPfdImportModal from 'components/menu/pulldown/MetaPfdImportModal';
import MetaPfdChangeAccessibilityModal from 'components/menu/pulldown/MetaPfdChangeAccessibilityModal';
import SaveLoadingModal from 'components/menu/pulldown/SaveLoadingModal';

function MetaPfd(): ReactElement {
  const api = useApi();
  const {
    file,
    mPfdModal,
    isFileOwner,
    load,
    localSave,
    state,
    closeModalState,
    saveLoadingModalState,
    saveState,
    saveAsModalState,
    ownershipModalState,
    accessibilityModalState,
    hasZoomScaleState,
    exportModalState,
    importModalState
  } = useContext(MetaPfdContext);
  const {remoteAppMenu, showActionMenu, dragItem, onDragOverSettingZoneId, showModal} = useContext(CommonContext);
  const {userProfile} = useContext(AuthContext);
  const [closeModalElement] = closeModalState;
  const [saveLoadingModalElement] = saveLoadingModalState;
  const [saveLoadingState] = saveState;
  const [saveAsModalElement] = saveAsModalState;
  const [ownershipModalElement] = ownershipModalState;
  const [accessibilityModalElement] = accessibilityModalState;
  const [exportModalElement] = exportModalState;
  const [importModalElement] = importModalState;
  const [hasZoomScale] = hasZoomScaleState;
  const {id} = useParams();
  const navigate = useNavigate();
  const stageRef = useRef();
  const changeFlagRef = useRef(null);
  changeFlagRef.current = state?.isChanged;

  useEffect(() => {
    if (!id || !userProfile) return;
    if (id !== file?._id && id !== NEW_FILE_ROUTE) {
      api
        .post<IApiReturnBasic>('/file_manage/get_file_data', {
          fileType: 'meta_pfd',
          _id: id
        })
        .then((res) => {
          if (res?.success) {
            load(res?.data as IFile);
          } else {
            showModal({
              title: 'Invalid File',
              content: 'There is no Meta PFD file',
              onConfirm: () => navigate(`/mpfd`),
              onClose: () => navigate(`/mpfd`)
            });
          }
        });
    } else if (file?._id) {
      load(file);
    }
  }, [id, file?._id, userProfile]);

  useEffect(() => {
    return () => {
      localSave();
    };
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);
  const alertUser = (e) => {
    if (changeFlagRef.current) {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  const onClick = () => {
    if (remoteAppMenu) {
      showActionMenu(null);
    }
  };

  const onDragOver = (evt: DragEvent<HTMLDivElement>) => {
    onDragOverSettingZoneId('mpfd');
    !dragItem && evt.preventDefault();
    evt.dataTransfer.dropEffect = 'move';
  };

  return (
    <AppContainer type="meta-pfd" onClick={onClick} onDragOver={onDragOver}>
      <PullDownMenu>
        <FileInfoDisplay file={file} type="mpfd" isPrivate={file?.public && !isFileOwner} isFileOwner={isFileOwner} />
        <MetaPfdPullDownMenu />
      </PullDownMenu>
      {mPfdModal.type === 'create' && <ProcessImageSelectModal />}
      {importModalElement && <MetaPfdImportModal />}
      {(!id || id === NEW_FILE_ROUTE) && !file && <MetaPfdAppGuide />}
      {file && (
        <>
          {hasZoomScale && <MetaPfdZoomScaleViewer />}
          <Stage ref={stageRef} />
          <Toolbox boundaryRef={stageRef} />
          {mPfdModal.type === 'detection' && <DetectionProcessModal />}
          {saveLoadingModalElement && (
            <SaveLoadingModal modalState={saveLoadingState} loadingState={saveLoadingModalState} />
          )}
          {saveAsModalElement && <MetaPfdSaveAsModal />}
          {closeModalElement && <MetaPfdCloseWarningModal />}
          {ownershipModalElement && <MetaPfdTransferOwnershipModal />}
          {exportModalElement && <MetaPfdExportModal />}
          {accessibilityModalElement && <MetaPfdChangeAccessibilityModal />}
        </>
      )}
    </AppContainer>
  );
}

export default MetaPfd;
