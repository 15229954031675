import {memo, ReactElement, useContext, useEffect, useState} from 'react';
import {NodeProps, useReactFlow} from 'reactflow';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {WidgetBody, WidgetContainer, WidgetHeader} from 'components/pc/widgets/parts';
import {IStickyNoteWidgetData, IWidgetNodeData} from 'components/pc/types';
import StickyEditor from 'components/pc/widgets/stickyNote/StickyEditor';
import {getUniqueKey} from 'utils/commons';
import {ProcessCanvasContext} from 'components/pc/ProcessCanvasProvider';
import {getWidgetTitle} from 'utils/processCanvas-functions';

function StickyNoteWidget({data, id, ...rest}: NodeProps<IWidgetNodeData>): ReactElement {
  const {setNodes} = useReactFlow();
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [readOnly, setReadOnly] = useState(false);
  const {onCanvasChange} = useContext(ProcessCanvasContext);

  useEffect(() => {
    if (data?.metaData) {
      const metaData = data.metaData as IStickyNoteWidgetData;
      const refinedBlocks = [...(metaData.rawDraftContentState?.blocks || [])].map((item) => ({
        ...item,
        key: getUniqueKey()
      }));
      const refinedData = {...metaData?.rawDraftContentState, blocks: refinedBlocks};
      const initialEditorState = convertFromRaw(refinedData);
      setEditorState(EditorState.createWithContent(initialEditorState));
    }
  }, []);

  /**
   * focus 시 자동으로 onChange 작동하여 저장되는 문제 있음
   *  (클릭만하더라도 isChanged? Flag 변경됨)
   */
  const onEditorStateChange = (newEditorState: EditorState) => {
    const metaData = {rawDraftContentState: convertToRaw(newEditorState.getCurrentContent())};
    setNodes((nodes) =>
      nodes.map((node) => (node.id === id ? {...node, data: {...node.data, metaData: metaData}} : node))
    );
    onCanvasChange();
    setEditorState(newEditorState);
  };

  const onLock = () => {
    setReadOnly((prev) => !prev);
  };

  const plainText = editorState.getCurrentContent().getPlainText();

  return (
    <WidgetContainer {...rest} docked={data.docked} id={id} type="StickyNoteWidget">
      <WidgetHeader
        {...rest}
        type="StickyNoteWidget"
        icon={data.icon}
        id={id}
        title={
          data.customizedTitle ? data.title : getWidgetTitle({type: 'StickyNoteWidget', titleData: plainText, data})
        }
        suffix="- Sticky Note"
        hasDocking
        docked={data.docked}
        onLock={onLock}
      />
      <WidgetBody type="nowheel">
        <StickyEditor onEditorStateChange={onEditorStateChange} readOnly={readOnly} editorState={editorState} />
      </WidgetBody>
    </WidgetContainer>
  );
}
// export default StickyNoteWidget;

export default memo(StickyNoteWidget, (prevProps, nextProps) => {
  return prevProps.selected === nextProps.selected;
});
