import {ReactElement, useContext, useState} from 'react';
import {ModalBody, ModalFooter, ModalHeader, ModalWrapper} from 'components/common';
import {Button, TextForm} from 'components/forms';
import styled from 'styled-components';
import {CopilotContext} from 'components/menu/copilot/CopilotProvider';
import {ICopilotThread} from 'components/menu/copilot/types';
import useModalKeyBoardEvent from 'hooks/useModalKeyBoardEvent';

const Text = styled.div`
  margin-bottom: 20px;
`;

type IProps = {
  onClose(): void;
  item: ICopilotThread;
};

function OptionRenameModal({item, onClose}: IProps): ReactElement {
  const [newName, setNewName] = useState(item.title);
  const copilotContext = useContext(CopilotContext);

  useModalKeyBoardEvent({
    onConfirm: () => onConfirm(),
    onCancel: () => onClose()
  });

  const changeTitle = (newTitle: string) => {
    setNewName(newTitle);
  };

  const onConfirm = (): void => {
    copilotContext.rename(item.id, newName);
    onClose();
  };

  return (
    <ModalWrapper type="sm">
      <ModalHeader onClose={onClose}>Rename</ModalHeader>
      <ModalBody>
        <Text>Rename "{item.title}" to :</Text>
        {/*<TextForm placeholder={item.title} name="name" value={newName} onChange={(e) => setNewName(e.target.value)} />*/}
        <TextForm placeholder={item.title} name="name" value={newName} onChange={(e) => changeTitle(e.target.value)} />
      </ModalBody>
      <ModalFooter>
        <Button className="modal-btn" variant="primary" size="sm" onClick={onConfirm}>
          Rename
        </Button>
        <Button className="modal-btn" variant="cancel" size="sm" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </ModalWrapper>
  );
}

export default OptionRenameModal;

// todo modal을 띄울 때 thread_id를 받도록
